import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataContextService } from '../datacontext.service';
import { CoreEmployeeView } from 'src/app/_models/employee-profile/CoreEmployeeView';
import { AppConstants } from 'src/app/_utils/app-constants';
import { InvEmployeeView } from 'src/app/_models/employee-profile/InvEmployeeView';
import { BillingEmployeeView } from 'src/app/_models/employee-profile/BillingEmployeeView';
import { EligibilityDetails, OrderEmployeeView } from 'src/app/_models/employee-profile/OrderEmployeeView';
import { MTNCancelledSuspendStatus } from 'src/app/_models/employee-profile/MTNCancelledSuspendStatus';
import { CompEmployeeView } from 'src/app/_models/employee-profile/CompEmployeeView';
import { ComplianceViolations } from 'src/app/_models/employee-profile/ComplianceViolations';
import { CompIntuneSync } from 'src/app/_models/employee-profile/CompIntuneSync';
import { OrderDetails } from 'src/app/_models/employee-profile/OrderDetails';
import { InventoryEmployeeDetails } from 'src/app/_models/employee-profile/InventoryEmployeeDetails';
import { MBRBlowOut } from 'src/app/_models/employee-profile/MBRBlowOut';
import { ComplianceExceptions } from 'src/app/_models/employee-profile/ComplianceExceptions';
import { VIPExceptions } from 'src/app/_models/employee-profile/VIPExceptions';
import { ComplianceComments } from 'src/app/_models/employee-profile/ComplianceComments';
import { Notifications } from 'src/app/_models/employee-profile/Notifications';
import { CoreEmployeeTransfer } from 'src/app/_models/employee-profile/CoreEmployeeTransfer';
import { EmpIncidentHistory } from 'src/app/_models/employee-profile/EmpIncidentHistory';
import { CoreCommentsModel } from 'src/app/_models/employee-profile/CoreCommentsModel';
import { LoaHistory } from 'src/app/_models/employee-profile/loaHistory.model';
import { LaptopEmpTilesData } from 'src/app/_models/employee-profile/laptopEmpTiles.model';
import { LaptopInventoryDetails } from 'src/app/_models/employee-profile/laptop-invdetails.model';
import { LaptopViolationDetails } from 'src/app/_models/employee-profile/laptop-violationdetail.model';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class EmpProfileService {
  apiurl : string  = localStorage.getItem('apiurl');
  _httpHeader: HttpHeaders;
  apiurlv2 : string = localStorage.getItem("apiurlv2");
  constructor(private _http: DataContextService) {
    this._httpHeader = new HttpHeaders().set('content-type', 'application/json');
  }
  GetCoreEmployeeViewDetails(personnelno: string): Observable<CoreEmployeeView> {
    return this._http.getData<CoreEmployeeView>(this.apiurl+AppConstants.GPA_ENDPOINTS.CoreService.GetEmployeeViewDetails + personnelno);
  }
  GetInvEmployeeViewDetails(personnelno: string): Observable<InvEmployeeView> {
    return this._http.getData<InvEmployeeView>(this.apiurl+AppConstants.GPA_ENDPOINTS.Inventory.GetEmployeeViewDetails + personnelno);
  }
  GetBillingEmployeeViewDetails(personnelno: string): Observable<BillingEmployeeView> {
    return this._http.getData<BillingEmployeeView>(this.apiurl+AppConstants.GPA_ENDPOINTS.MBR.GetEmployeeViewDetails + personnelno);
  }
  GetOrdersEmployeeViewDetails(personnelno: string): Observable<OrderEmployeeView> {
    return this._http.getData<OrderEmployeeView>(this.apiurl+AppConstants.GPA_ENDPOINTS.Orders.GetEmployeeViewDetails + personnelno);
  }
  GetMTNCancelledSuspendStatus(mtns : string) : Observable<MTNCancelledSuspendStatus[]>
  {
    return this._http.getData<MTNCancelledSuspendStatus[]>(this.apiurlv2+AppConstants.GPA_ENDPOINTS.MBR.GetMTNCancelledSuspendStatus + mtns)
  }
  GetCompEmployeeViewDetails(personnelno: string): Observable<CompEmployeeView> {
    return this._http.getData<CompEmployeeView>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetEmployeeViewDetails + personnelno);
  }

  GetInvViolationDetails(personnelno: string): Observable<ComplianceViolations[]> {
    return this._http.getData<ComplianceViolations[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Inventory.GetComplianceViolationDetailsByPersonnelNbr + personnelno)
  }
  GetCompViolationDetails(personnelno: string): Observable<ComplianceViolations[]> {
    return this._http.getData<ComplianceViolations[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetComplianceViolationDetailsByPersonnelNbr + personnelno)
  }
  GetMBRViolationDetails(personnelno: string): Observable<ComplianceViolations[]> {
    return this._http.getData<ComplianceViolations[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.MBR.GetComplianceViolationDetails + personnelno)
  }
  GetIntuneSyncDetails(personnelno: string): Observable<CompIntuneSync[]> {
    return this._http.getData<CompIntuneSync[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetIntuneSyncDetails + personnelno);
  }
  GetEmployeeOrderDetails(personnelno: string): Observable<OrderDetails[]> {
    return this._http.getData<OrderDetails[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Orders.GetEmployeeOrderDetails + personnelno);
  }
  GetEmployeeInventoryDetails(personnelno: string): Observable<InventoryEmployeeDetails[]> {
    return this._http.getData<InventoryEmployeeDetails[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Inventory.GetEmployeeInventoryDetails + personnelno);
  }
  GetMBRDetailsForBlowOut(personnelno: string): Observable<MBRBlowOut[]> {
    return this._http.getData<MBRBlowOut[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.MBR.GetMBRDetailsForBlowOut + personnelno);
  }
  GetMBRDetails(personnelno: string, mtn: string, carrier: string, isAutoPay: number): Observable<MBRBlowOut[]> {
    return this._http.getData<MBRBlowOut[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.MBR.GetMBRDetails + personnelno + '&MTN=' + mtn + '&Carrier=' + carrier + '&IsAutoPay=' + isAutoPay);
  }

  GetInvExceptionDetails(personnelno: string): Observable<ComplianceExceptions[]> {
    return this._http.getData<ComplianceExceptions[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Inventory.GetComplianceExceptionDetailsByPersonnelNbr + personnelno)
  }
  GetCompExceptionDetails(personnelno: string): Observable<ComplianceExceptions[]> {
    return this._http.getData<ComplianceExceptions[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetComplianceExceptionsByPersonnelNbr + personnelno)
  }
  GetMBRExceptionDetails(personnelno: string): Observable<ComplianceExceptions[]> {
    return this._http.getData<ComplianceExceptions[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.MBR.GetExceptionsByPersonnelNbr + personnelno)
  }
  GetVIPExceptionDetails(personnelno: string): Observable<VIPExceptions[]> {
    return this._http.getData<VIPExceptions[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.GetVipExceptionsByPersonnelNbr + personnelno)
  }

  GetCoreCommentsDetails(personnelno: string): Observable<ComplianceComments[]> {
    return this._http.getData<ComplianceComments[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.CoreService.GetComplianceCommentsByPersonnelNbr + personnelno);
  }
  GetInvCommentsDetails(personnelno: string): Observable<ComplianceComments[]> {
    return this._http.getData<ComplianceComments[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Inventory.GetComplianceCommentsByPersonnelNbr + personnelno);
  }
  GetBillingCommentsDetails(personnelno: string): Observable<ComplianceComments[]> {
    return this._http.getData<ComplianceComments[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.MBR.GetComplianceCommentsByPersonnelNbr + personnelno);
  }
  GetOrdersCommentsDetails(personnelno: string): Observable<ComplianceComments[]> {
    return this._http.getData<ComplianceComments[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Orders.GetComplianceCommentsByPersonnelNbr + personnelno);
  }
  GetCompCommentsDetails(personnelno: string): Observable<ComplianceComments[]> {
    return this._http.getData<ComplianceComments[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetComplianceCommentsByPersonnelNbr + personnelno);
  }

  GetInvNotificationsDetails(personnelno: string): Observable<Notifications[]> {
    return this._http.getData<Notifications[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Inventory.GetNotificationsByPersonnelNo + personnelno);
  }
  GetBillingNotificationsDetails(personnelno: string): Observable<Notifications[]> {
    return this._http.getData<Notifications[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.MBR.GetNotificationsByPersonnelNo + personnelno);
  }
  GetCompNotificationsDetails(personnelno: string): Observable<Notifications[]> {
    return this._http.getData<Notifications[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetNotificationsByPersonnelNo + personnelno);
  }
  GetOrdersNotificationsDetails(personnelno: string): Observable<Notifications[]> {
    return this._http.getData<Notifications[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Orders.GetNotificationsByPersonnelNo + personnelno);
  }
  GetCoreTransferDetails(personnelno: string): Observable<CoreEmployeeTransfer[]> {
    return this._http.getData<CoreEmployeeTransfer[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.CoreService.GetEmployeeTransferDetails + personnelno);
  }
  GetEmployeeEligibilityDetails(personnelno: string, isitsReportWithPriNbrFromInv: boolean, isElilgible: boolean, eligibilityDate: string): Observable<EligibilityDetails> {
    return this._http.getData<EligibilityDetails>(this.apiurl+AppConstants.GPA_ENDPOINTS.Orders.GetEmployeeEligibilityDetails+personnelno+'&IsitsReportWithPriNbrFromInv='+isitsReportWithPriNbrFromInv+'&isElilgible='+isElilgible+'&eligibilityDate='+eligibilityDate);
  }
  getHistoryData(personalOrTicketNumber) {
    return this._http.getData<EmpIncidentHistory[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.CoreService.GetUserTicketsListByPersonalOrTicketNumber+personalOrTicketNumber)
    .pipe(map(data => {
      return data;
    })
    );
  }

  GetSearchEmployeeToApplyAccess(alias) {
    return this._http.getData<any>(this.apiurl+AppConstants.GPA_ENDPOINTS.Orders.GetSearchEmployeeToApplyAccess + alias)
      .pipe(map(data => {
        return data;
      })
      );
  }

  AddCoreComments(obj: CoreCommentsModel): Observable<boolean> {
    return this._http.postData<boolean>(this.apiurl+AppConstants.GPA_ENDPOINTS.CoreService.AddEmployeeComments, obj);
  }
  
  GetLOADetails(personnelno: string) {
    return this._http.getData<LoaHistory[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.CoreService.GetLOADetails + personnelno);
  }
  GetEmpProfileHeadersForLaptopByPersonnelNbr(PersonnelNbr: string) {
    return this._http.getData<LaptopEmpTilesData[]>(this.apiurlv2+AppConstants.GPA_ENDPOINTS.Laptop.GetEmpProfileHeadersForLaptopByPersonnelNbr + '?PersonnelNbr=' + PersonnelNbr).pipe(map(data => data));
  }
  getLaptopInventoryDetailsByPersonnelNbr(PersonnelNbr: string) {
    return this._http.getData<LaptopInventoryDetails[]>(this.apiurlv2+AppConstants.GPA_ENDPOINTS.Laptop.GetLaptopInventoryDetailsByPersonnelNbr + '?PersonnelNbr=' + PersonnelNbr).pipe(map(data => data));
  }
  GetLaptopViolationDetailsByPersonnelNbr(PersonnelNbr: string) {
    return this._http.getData<LaptopViolationDetails[]>(this.apiurlv2+AppConstants.GPA_ENDPOINTS.Laptop.GetLaptopViolationDetailsByPersonnelNbr + '?PersonnelNbr=' + PersonnelNbr).pipe(map(data => data));
  }
}

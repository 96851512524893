import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GridData } from 'src/app/_models/grid.model';
import { DataContextService } from '../datacontext.service';
import { AppConstants } from 'src/app/_utils/app-constants';
import { DeviceModelCompliance, EmpStatusModel, MtnStatusModel, Office, region } from 'src/app/_models/side-filter-nav/sidenav-filter';
import { EmpProfileService } from '../employee-profile/emp-profile.service';
import { MTNCancelledSuspendStatus } from 'src/app/_models/employee-profile/MTNCancelledSuspendStatus';
import { AssignmentGroupModel } from 'src/app/_models/inventory/assignmentgroupmodels';
import { ComplianceViolationEx } from 'src/app/_models/compliance/compliance-search.model';
@Injectable({
  providedIn: 'root'
})
export class FiltersMenuService {

 apiurl : string = localStorage.getItem('apiurl');
 selectedMainModuleName: string = '';
 // baseUrl = environment.baseUrl;

  //searchResultsList: ComplianceViolationEx[] = [];
  searchResultsList: any[] = [];
  complianceIssue: string;
  searchCriteria: GridData;
  totalItemsForGrid:number = 10;
  complianceDDLObj: any;
  totalRecords:number=0;
  objMtnStatusDetails: MTNCancelledSuspendStatus[];

  constructor(private _empService: EmpProfileService,private http: HttpClient, private _datacontextService: DataContextService) { }
  filterItems() {
    //return this.http
    //  .get<any[]>(AppConstants.GPA_ENDPOINTS.Compliance.GetComplianceDDL)
    //  .pipe(map(data => {
    //    this.complianceDDLObj = data;
    //    return data;
    //  }));
    return this._datacontextService.getData<any[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetComplianceDDL)
      .pipe(map(data => {
        this.complianceDDLObj = data;
        return data;
      }));;
  }
  regionFilterItems() {
    return this._datacontextService.getData<any>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetLocationfromRegion)
      .pipe(map(data => data));

  }
  getDeviceType() {
    return this._datacontextService.getData<DeviceModelCompliance[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceType+'?DeviceTransactionTypeID=0')
      .pipe(map(data => data));
  }
  getEmpStatus() {
    return this._datacontextService.getData<EmpStatusModel>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetEMPStatusDetails)
      .pipe(map(data => data));
  }
  getMtnStatus() {
    return this._datacontextService.getData<MtnStatusModel>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetMTNStatusDetails)
      .pipe(map(data => data));
  }
  getFacilityCodes(FacilityRegionCode) {
    return this._datacontextService.getData<any[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetAdminFacilityCodes + '?FacilityRegionCode=' + FacilityRegionCode).pipe(map(data => data));;
  }

  getGriddata(obj: GridData): Observable<ComplianceViolationEx[]> {
    this.searchResultsList= null;
    this.searchCriteria = obj;
    this.complianceIssue = obj.complianceIssue;
    return this._datacontextService.postData<ComplianceViolationEx[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetComplianceSearchResult, obj).pipe(map(data => {
      // this.searchResultsList = data;
      this.searchResultsList = data.map(obj=>({...obj,isHighlighter:false}));
      let objPageResData = this.searchResultsList
      let mtnList = '';
      if (objPageResData)
      {
        objPageResData.forEach((x,index)=>{
          if(x.serverSync['mtn'].length > 0)
          {
            if (mtnList.length <= 0) mtnList = x.serverSync['mtn'];
            else  mtnList = mtnList + ',' + x.serverSync['mtn'];
          }
        })
      }        
      this._empService.GetMTNCancelledSuspendStatus(mtnList).subscribe(
        (data) => {            
          this.objMtnStatusDetails = data;
          objPageResData.forEach((x,index)=>{
              this.objMtnStatusDetails.forEach((y,index)=>{                  
                if(x.serverSync['mtn']== y['mtn'])
                {                    
                  x['isMarkedForCancellation'] = y['isMarkedForCancellation'];
                  x['isMarkedForSuspend'] = y['isMarkedForSuspend'];                    
                }
                else
                {
                  x['isMarkedForCancellation'] = 0;
                  x['isMarkedForSuspend'] = 0; 
                }                
              });            
          })
        });

      this.searchResultsList = objPageResData;
     // // console.log("this.searchResultsList.length",this.searchResultsList.length);
      if(this.searchResultsList.length !=undefined && this.searchResultsList.length !=null && this.searchResultsList.length > 0)
      {
        this.totalRecords= Number(this.searchResultsList[0].totalResultCount);
      }else{
        this.totalRecords=0;
      }
      return data;
    }));
  }

  getAllAssignmentgroupItems(loggedInUser: string) {
    return this._datacontextService.getData<AssignmentGroupModel[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.CoreService.GetGroupsForLoggedInUser +
      loggedInUser
    );
    //TODO: remove static loggedin user
  }
  getPendingReturnData(obj: GridData): Observable<ComplianceViolationEx[]> {
    this.searchResultsList= null;
    this.searchCriteria = obj;
    this.complianceIssue = obj.complianceIssue;
    return this._datacontextService.postData<ComplianceViolationEx[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Inventory.GetComplianceSearchPROrPWResult, obj).pipe(map(data => {
      // this.searchResultsList = data;
      this.searchResultsList = data.map(obj=>({...obj,isHighlighter:false}));
      if(this.searchResultsList.length !=undefined && this.searchResultsList.length !=null && this.searchResultsList.length > 0)
      {
        this.totalRecords= Number(this.searchResultsList[0].totalResultCount);
      }else{
        this.totalRecords=0;
      }
      return data;
    }));

  }
  fn_refreshGridResult() {
    this.getGriddata(this.searchCriteria);
  }
   getAllRegion() {
    let header = new HttpHeaders().set('content-type', 'application/json;charset=utf-8;');
    return this.http.get<region[]>(
      this.apiurl + "Inventory/GetAllFacilityRegion", { headers: header }
    );
  }
  getAllOffice(FacilityRegionCode: any) {
    // return this.http.get<Office[]>(this.API_GPAAPI+'Inventory/GetAllFacility?facilityRegionCode=' +facilityRegionCode);
    return this.http.get<Office[]>(
      this.apiurl +
        "Inventory/GetAllFacility?FacilityRegionCode=" +
        FacilityRegionCode
    );

    // regionCode
  }

  ClearComplianceViolationDuringWipe(mobileDeviceID: string){
    let header = new HttpHeaders().set(
      "content-type",
      "application/json;charset=utf-8;"
    );
    var obj = {
      "mobileDeviceID": mobileDeviceID
    }
    return this._datacontextService.postData<boolean>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Compliance.ClearComplianceViolationDuringWipe + '?mobileDeviceID=' + mobileDeviceID,
      null
    );
  }

}

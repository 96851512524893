<div *ngIf="isAuthenticated" class="col-md-12 header-part">
  <app-header></app-header>
  <div class="env-banner {{ currentEnvironment }}" *ngIf="isEnvBannerOpen">
    <div class="env-text justify-content-center">
      <ng-container *ngIf="!isProdEnv">
        This is a non-production environment for {{ envTitle }} purposes. Requests in this environment will not be processed. 
      </ng-container>
    </div>
    <div class="env-close" *ngIf="!isProdEnv" (click)="toggleEnvBanner()">
      <i class="fa fa-times"></i>
    </div>
  </div>
  <div class="copied-alert" #copiedAlert>
    <i class="fa fa-check-circle"></i>&nbsp; Copied!
  </div>
</div>
<app-shell></app-shell>
import { Component, Input, OnInit } from '@angular/core';
import { EmpIncidentHistory } from 'src/app/_models/employee-profile/EmpIncidentHistory';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
declare var _satellite: any;
declare var window: any;
@Component({
  selector: 'app-empIncHistory',
  templateUrl: './emp-inc-history.component.html',
  styleUrls: ['./emp-inc-history.component.scss']
})
export class EmpIncHistoryComponent implements OnInit {

  @Input() LegalName: string;
  @Input() Alias: string;

  historyData: any;
  @Input() userName: any;
  @Input() historyDataByInc: EmpIncidentHistory[];
  @Input() historyDataByReq: EmpIncidentHistory[];
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];

  constructor(private _empServie: EmpProfileService) { }
  ngOnInit() {
    //this.fn_DatalayerPageLoad();
  }
  ngOnChanges() {
    // this.historyDataByReq = this.historyDataByReq.map(x => {
    //   const date = moment(x['sys_Created_On']).format('MMMM Do YYYY, h:mm:ss a');
    //   if (date && date !== 'Invalid date') {
    //     x['sys_Created_On'] = date;
    //   }
    //   return x
    // })
    // this.fn_GetHistoryData();
    this.userName = this.LegalName;
  }

  fn_GetHistoryData() {
    this._empServie.getHistoryData(this.Alias).subscribe((data) => {
      this.historyData = data;
      this.historyDataByInc = this.historyData.filter(x => x.ticketNumber.startsWith('INC'));
      // console.log('this.historyDataByInc', this.historyDataByInc);
      this.historyDataByReq = this.historyData.filter(x => x.ticketNumber.startsWith('REQ'));
      // console.log('this.historyDataByReq', this.historyDataByReq);
      this.historyData.forEach((user) => {
        this.userName = user.affectedEndUser;
      })
      // console.log('this.historyData', this.historyData);
      // if (this.exceptionData.length != undefined && this.exceptionData != null && this.exceptionData.length > 0) {
      //   this.emitt_ExceptionCount.emit(this.exceptionData.length);
      // }
    });
  }
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee History Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

}

<div *ngIf="transferDetails">
    <div class="mt-4 comments-Data"
        *ngIf="transferDetails !== null && transferDetails !== undefined && transferDetails.length !== 0">
        <div class="row">
            <div class="ml-4 past-comment-text">
                <p class="past-comments">Transfers details:</p>
            </div>
        </div>
        <div class="emp-comment-scroll">
            <div class="card" *ngFor="let transferResult of transferDetails;let i=index">
                <div [ngClass]="(i%2===0)?'card-header':'card-body'">
                    <div class="row">
                        <div class="col-md-6 total">
                            <div class="vip">
                                {{transferResult.fromCity}} <i class="fa fa-arrow-right"></i> {{transferResult.toCountry}}
                            </div>
                            <div class="sometext">
                                <p class="mt-1" style="font-weight:400">{{transferResult.transferType}} </p>
                                <p class="medium">{{transferResult.assignmentType}} </p>
                                <p class="medium">{{transferResult.assignmentInitiationDate | date}} -
                                    {{transferResult.assignmentEndDate|date}} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" style="visibility: hidden;">
            <div class="card-body">

            </div>
        </div>
    </div>
</div>
<div *ngIf="transferDetails === null || transferDetails === undefined || transferDetails.length === 0"
    class="notification-data">
    <p class="no-notifications">No Records Found</p>
</div>
<div>
    <div class="modal-header">
        <div class="col-md-12 not-exist-img">
            <img class="not-exist-logo" src="assets/images/Picture1.png" />
        </div>
    </div>
    <div class="modal-body">
      <p>{{message}}</p>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 footer">
      <button type="button"
        class="btn btn-primary ok-button"
        (click)="activeModal.close(false)">OK</button>
      </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as FileSaver from 'file-saver'; 
import * as XLSX from 'xlsx';
import { ReportGeneratingStatus } from '../_models/reportGeneratingStatus.mdel';
import { DataContextService } from './datacontext.service';
import { ReportCategory } from '../_models/reportCategory.model';
import { AppConstants } from '../_utils/app-constants';
import { FacilityByRegion } from '../_models/facilityByRegion.model';
import { InsertReportDetails } from '../_models/insertReportDetails.model';
import { OrderReportReq } from '../_models/oderReport.model';
import { OrderReportRes } from '../_models/orderReportRes.model';
import { InventoryReconHistReport } from '../_models/inventoryReconHisReport.model';
import { GetFullInventoryReport } from '../_models/getFullInventoryReport.model';
import { ReportSubCategory } from '../_models/reportSubCategory.model';
import { Region } from '../_models/region.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
 // public API_GPAAPI: string = environment.appEndpoints.gpaApi;
  reportData = new BehaviorSubject<any[]>([]); 
  apiurl : string = localStorage.getItem('apiurl');
  apiurlv2 : string = localStorage.getItem("apiurlv2");
  // baseUrl = environment.baseUrl;
  public EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  public EXCEL_EXTENSION = '.xlsx'; 
  getReportServiceData: ReportGeneratingStatus[];
  getReportServiceDataOrders: ReportGeneratingStatus[];
  getReportServiceDataCore : ReportGeneratingStatus[];
  constructor(private http: HttpClient, private _datacontextService: DataContextService) { }
  getReportCategory() {
    return this._datacontextService.getData<ReportCategory>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.GetReportCategory).pipe(map(data => data));
  }
  getRegionData() {
    return this._datacontextService.getData<Region>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.GetRegionDLL).pipe(map(data => data));
  }
  getFacilityByRegion(strRegion) {
    return this._datacontextService.getData<FacilityByRegion[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.GetFacilityByRegion+ '?strRegion=' + strRegion).pipe(map(data => data));
  }
  getDeviceReplacementCounts(obj: InsertReportDetails) {
    return this._datacontextService.postData<string>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.GetDeviceReplacementCounts,obj).pipe(map(data => data));
  }
  getOrdersReport(obj: OrderReportReq) {
    return this._datacontextService.postData<OrderReportRes[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.GetOrdersReport,obj).pipe(map(data => data));
  }
  getInventoryReconHistReport(strRegionCode, strOfficeCode, StartDate, EndDate) {
    return this._datacontextService.getData<InventoryReconHistReport[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.GetInventoryReconHistReport+ '?strRegionCode=' + strRegionCode + '&strOfficeCode=' + strOfficeCode + '&StartDate=' + StartDate + '&EndDate=' + EndDate).pipe(map(data => data));
  }
  getInventoryReconciliationByRegionandOffice(strRegionCode, strOfficeCode) {
    return this._datacontextService.getData<InventoryReconHistReport[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.GetInventoryReconciliationByRegionandOffice+ '?strRegionCode=' + strRegionCode + '&strOfficeCode=' + strOfficeCode).pipe(map(data => data));
  }
  getFullInventoryReport() {
    return this._datacontextService.getData<GetFullInventoryReport[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.GetFullInventoryReport).pipe(map(data => data));
  }
  getReportSubCategory(ReportCategory) {
    return this._datacontextService.getData<ReportSubCategory[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.GetReportSubCategory+ '?ReportCategory=' + ReportCategory).pipe(map(data => data));
  }
    //Export Excel
  exportAsExcelFile(json: any, excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
        type: this.EXCEL_TYPE
    });
    if(fileName=="Delinquency"){
      FileSaver.saveAs(data, fileName);
    }else{
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION);
    }  
  } 

  insertReportDetailsComp(obj: InsertReportDetails) {
    return this._datacontextService.postData<boolean>(this.apiurl+ AppConstants.GPA_ENDPOINTS.Reports.InsertReportDetailsComp, obj).pipe(map(data => data));
  }

  insertReportDetails(obj: InsertReportDetails) {
    return this._datacontextService.postData<boolean>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.InsertReportDetails,obj).pipe(map(data => data));
  }
  GetReportGeneratingStatus(RequestedUserAlias) {
    return this._datacontextService.getData<ReportGeneratingStatus[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.GetReportGeneratingStatus+ '?RequestedUserAlias=' + RequestedUserAlias)

  }
  GetReportGeneratingStatusComp(RequestedUserAlias) {
    return this._datacontextService.getData<ReportGeneratingStatus[]>(this.apiurlv2+AppConstants.GPA_ENDPOINTS.Reports.GetReportGeneratingStatusComp+ '?RequestedUserAlias=' + RequestedUserAlias)
  
  }
  InsertReportDetailsOrder(obj: InsertReportDetails) {
    return this._datacontextService.postData<boolean>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.InsertReportDetailsOrder,obj)
    .pipe(map(data => data));
  }
  GetReportGeneratingStatusOrder(RequestedUserAlias) {
    return this._datacontextService.getData<ReportGeneratingStatus[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.GetReportGeneratingStatusOrder+ '?RequestedUserAlias=' + RequestedUserAlias)
    
  }
  GetReportGeneratingStatusCore(RequestedUserAlias) {
    return this._datacontextService.getData<ReportGeneratingStatus[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.GetReportGeneratingStatusCore+ '?RequestedUserAlias=' + RequestedUserAlias)
  }
  InsertReportDetailsCore(obj: InsertReportDetails) {
    return this._datacontextService.postData<boolean>(this.apiurl+AppConstants.GPA_ENDPOINTS.Reports.InsertReportDetailsCore,obj).pipe(map(data => data));
  }
  GetLaptopReportReq(ToMAilAddress) {
    return this._datacontextService.getData<boolean>(this.apiurlv2+AppConstants.GPA_ENDPOINTS.Laptop.GetLaptopReportReq+ '?ToMAilAddress=' + ToMAilAddress).pipe(map(data => data));
  }
  InsertReportDetailsLaptop(obj: InsertReportDetails) {
    return this._datacontextService.postData<boolean>(this.apiurlv2+AppConstants.GPA_ENDPOINTS.Reports.InsertReportDetailsLaptop,obj).pipe(map(data => data));
  }

}

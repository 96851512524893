import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { AppConfig } from '../app-config/app-config.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { MsalBroadcastService, MsalInterceptorAuthRequest, MsalInterceptorConfiguration, MsalService, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { JwtService } from 'src/app/_services/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(@Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private jwtService: JwtService) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.getToken();

    if (!AppConfig.settings.debug && this.jwtService.isTokenExpired(token))
      return this.handleError(this.handleRefreshToken(request, next));

    if (token) {
      request = this.addToken(request, token);
    }
    return this.handleError(next.handle(request));
  }

  private handleError(
    res: Observable<HttpEvent<unknown>>
  ): Observable<HttpEvent<unknown>> {
    return res.pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          return throwError(err.error);
        }
        return of(err);
      })
    );
  }

  private getToken(): string {
    if (AppConfig.settings.debug) {
      return AppConfig.settings.token;
    }
    let token = null;
    const tokenKeys = Object.keys(localStorage);
    if (tokenKeys) {
      const accessTokenKey = tokenKeys.find(key => key.includes('accesstoken') && key.includes(`${AppConfig.settings.msal.clientId}`));
      const tokenObj = localStorage.getItem(accessTokenKey);
      token = JSON.parse(tokenObj).secret;
    }
    return token;
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handleRefreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      let account = this.authService.instance.getActiveAccount();

      const scopes = AppConfig.settings.msal.scopes;
      const authRequest = { ...this.msalInterceptorConfig.authRequest, account };
      return this.acquireToken(authRequest, scopes, account).pipe(
        switchMap((res: AuthenticationResult) => {
          this.isRefreshing = false;
          if (res) {
            this.refreshTokenSubject.next(res.accessToken);
            return next.handle(this.addToken(request, res.accessToken));
          }
          return throwError("error occurred while getting token");
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((accessToken) => {
          return next.handle(this.addToken(request, accessToken));
        })
      );
    }
  }

  /**
     * Try to acquire token silently. Invoke interaction if acquireTokenSilent rejected with error or resolved with null access token
     * @param authRequest Request
     * @param scopes Array of scopes for the request
     * @param account Account
     * @returns Authentication result
     */
  private acquireToken(authRequest: MsalInterceptorAuthRequest, scopes: string[], account: AccountInfo): Observable<AuthenticationResult> {
    return this.authService.acquireTokenSilent({ ...authRequest, scopes, account })
      .pipe(
        catchError(() => {
          return this.msalBroadcastService.inProgress$
            .pipe(
              take(1),
              switchMap((status: InteractionStatus) => {
                if (status === InteractionStatus.None) {
                  return this.acquireTokenInteractively(authRequest, scopes);
                }

                return this.msalBroadcastService.inProgress$
                  .pipe(
                    filter((status: InteractionStatus) => status === InteractionStatus.None),
                    take(1),
                    switchMap(() => this.acquireToken(authRequest, scopes, account))
                  );
              })
            );
        }),
        switchMap((result: AuthenticationResult) => {
          if (!result.idToken) {
            return this.msalBroadcastService.inProgress$
              .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                take(1),
                switchMap(() => this.acquireTokenInteractively(authRequest, scopes))
              );
          }
          return of(result);
        })
      );
  }

  /**
   * Invoke interaction for the given set of scopes
   * @param authRequest Request
   * @param scopes Array of scopes for the request
   * @returns Result from the interactive request
   */
  private acquireTokenInteractively(authRequest: MsalInterceptorAuthRequest, scopes: string[]): Observable<AuthenticationResult> {
    const redirectStartPage = window.location.href;
    this.authService.acquireTokenRedirect({ ...authRequest, scopes, redirectStartPage });
    return EMPTY;
  }

}

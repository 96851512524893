

 <div *ngIf="isPageLoad"
 class="col-md-4" style="margin-left:-30px;cursor: pointer;">
 <div (click)="backtoSearch()">
     <img src="assets/images/collapse arrows.svg"><span class="hidedata-text"
         style="font-size:12px;color: #212529;">Back</span>
 </div>
</div>

<div *ngIf="isPageLoad">
    <div class="mtntable">
        <div class="row">
            <div class="col-md-8 intune-padding">
                <p class="compliance-text">Intune: {{totalnumber}}</p>
            </div>


        </div>
        <div class="row search-filter">

            <div class="pane pane--table2">
                <table class="table-style">

                    <thead>


                        <tr>
                            <th style="min-width:160px;">
                                Last heartbeat</th>
                            <th style="min-width:150px;">
                                Device model</th>
                            <th style="min-width:150px;">
                                Operation system</th>
                            <th style="min-width:122px">
                                Client version</th>
                            <th style="min-width:140px;">
                                Mobile No</th>
                            <th style="min-width:206px">Subscriber carrier network
                            </th>
                            <th style="min-width:170px;">IMEI</th>
                            <th style="min-width:140px;">
                                Serial number</th>
                            <th style="min-width:142px;">
                                Compliance status</th>
                            <!-- <th style="min-width:120px;">Last policy request</th> -->

                        </tr>
                    </thead>
                    <!-- <tbody *ngIf="isGridDataNotAvailable">
                        <tr>
                            <td colspan="15" style="text-align: center">
                                <p class="compliance-text">No history found!</p>
                            </td>
                        </tr>
                    </tbody> -->
                    <tbody *ngIf="totalnumber>0">
                        <tr *ngFor="let row of intuneDetails;let i=index">
                            <td style="min-width: 160px;">{{row.lastHeartbeat | date:'mediumDate'}}</td>
                            <td class="t-{{i}}"  data-toggle="tooltip" title="{{row.deviceModel}}">
                                <span style="white-space: nowrap;
                                width: 120px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: block;cursor: pointer;">{{ row.deviceModel.length > 0 ? row.deviceModel : '--' }}</span>
                            </td>
                            <!-- <td  style="padding-top:8px;padding-bottom:22px">
                                        {{x.info.alias}}</td> -->

                            <td data-toggle="tooltip" title="{{row.operatingSystem}}">
                                <span  style="white-space: nowrap; width: 120px; overflow: hidden;display: block; text-overflow: ellipsis; cursor: pointer;">
                                    {{row.operatingSystem.length>0?row.operatingSystem:'--'}}
                                </span>
                            </td>
                            <td style="min-width: 122px;">
                                {{row.clientVersion.length>0?row.clientVersion:'--'}}</td>
                            <td data-toggle="tooltip" title="{{row.phoneNumber}}">
                                <span  style="white-space: nowrap;
                                width: 120px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: block;"
                                    copyToClipboard copiedString="{{row.phoneNumber}}">
                                    {{row.phoneNumber.length>0?row.phoneNumber:'--' }}
                                </span>
                            </td>
                            <td style="min-width: 206px;">
                                {{row.subscriberCarrierNetwork.length>0?row.subscriberCarrierNetwork:'--'}}</td>
                            <td style="min-width: 170px;"
                                copyToClipboard copiedString="{{row.imei}}"
                            >{{row.imei.length>0?row.imei:'--' }}</td>
                            <td style="min-width: 140px;">{{row.serialNumber.length>0?row.serialNumber:'--'}}</td>
                            <td style="min-width: 142px;">{{row.complianceStatus.length>0?row.complianceStatus:'--'}}
                            </td>
                            <!-- <td style="min-width: 120px;">
                                {{(row.lastPolicyRequest !== '0001-01-01T00:00:00') ? (row.lastPolicyRequest   | date) : '--' }}
                            </td> -->
                        </tr>
                    </tbody>
                    <tfoot *ngIf="totalnumber === 0">
                        <tr>
                            <td colspan="15" style="text-align: center;">
                                <b>
                                    <p class="no-data">No Data Available</p>
                                </b>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>

</div>
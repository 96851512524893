<section class="employee-profile-comments-comp">
    <div class="row">
        <div class="col-md-11 comment-data">
            <form [formGroup]="commentForm" (ngSubmit)="submit($event)">
                <div class="form-row filter-data">
                    <div class="form-group col">
                        <div class="row">
                            <label class="comment-text" for="emp"><b>Add Comments <sup>*</sup></b></label>
                        </div>
                        <textarea id="commentDataIDFromCommentComp" rows="2" cols="50" class="form-control"
                            formControlName="comment" placeholder="Add Comment"></textarea>
                    </div>
                </div>
                <div class="text-right">
                    <button class="btn btn-primary mr-1 apply" [disabled]="!commentForm.valid">Submit</button>
                </div>
            </form>
        </div>
    </div>
    <div class="row history-tabs">
        <ul class="col-md-12 nav nav-tabs" role="tablist">
            <li class="col-md-6 nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Mobile Comments: {{commentData?.length}}
                </a>
            </li>
            <li class="col-md-6 nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Laptop Comments: {{laptopEmpCommentsData?.length}}
                </a>
            </li>
        </ul>
    </div>
    <div class="tab-content">
        <div class="tab-pane active" id="tabs-1" role="tabpanel">
            <div *ngIf="commentData">
                <div class="comments-Data" *ngIf="commentData.length !== 0">
                    <!-- <div class="row">
                        <div class="ml-4 past-comment-text">
                            <p class="past-comments">Past Comments:</p>
                        </div>
                    </div> -->
                    <div class="emp-addcomment-scroll tab-violation-laptop">
                        <div class="card" *ngFor="let comment of commentData;let i=index">
                            <div [ngClass]="(i%2===0)?'card-header':'card-body'">
                                <div class="issue">
                                    <label class="issue-text">Date/Time</label>
                                    <p class="issue-data">{{comment.dateCreated | date:'medium'}}</p>
                                    <label class="issue-text">Added By</label>
                                    <p class="issue-data">{{comment.createdBy}}</p>
                                    <label class="issue-text">Comments</label>
                                    <p class="issue-data">{{comment.commentText}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" style="visibility: hidden;">
                        <div class="card-body">
    
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="(!commentData) || (commentData.length == 0)" class="notification-data">
                <p class="no-notifications">No Records Found</p>
            </div>
        </div>
        <div class="tab-pane" id="tabs-2" role="tabpanel">
            <div *ngIf="laptopEmpCommentsData">
                <div class="comments-Data" *ngIf="laptopEmpCommentsData.length !== 0">
                    <!-- <div class="row">
                        <div class="ml-4 past-comment-text">
                            <p class="past-comments">Past Comments:</p>
                        </div>
                    </div> -->
                    <div class="emp-addcomment-scroll tab-violation-laptop">
                        <div class="card" *ngFor="let comment of laptopEmpCommentsData;let i=index">
                            <div [ngClass]="(i%2===0)?'card-header':'card-body'">
                                <div class="issue">
                                    <label class="issue-text">Date/Time</label>
                                    <p class="issue-data">{{comment.dateCreated | date:'medium'}}</p>
                                    <label class="issue-text">Added By</label>
                                    <p class="issue-data">{{comment.createdBy}}</p>
                                    <label class="issue-text">Comments</label>
                                    <p class="issue-data">{{comment.commentText}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" style="visibility: hidden;">
                        <div class="card-body">
    
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="(!laptopEmpCommentsData) || (laptopEmpCommentsData.length == 0)" class="notification-data">
                <p class="no-notifications">No Records Found</p>
            </div>
        </div>
    </div>
    </section>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { CoreCommentsModel } from 'src/app/_models/employee-profile/CoreCommentsModel';
import { LaptopCommentsResponse } from 'src/app/_models/laptop-compliance/laptopComment.model';
import { FiltersMenuService } from 'src/app/_services/compliance/filters-menu.service';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
import { LaptopComplianceService } from 'src/app/_services/laptop-compliance/laptop-compliance.service';
import { ModalService } from 'src/app/_services/modal.service';
import { GlobalVariables } from 'src/app/_utils/global-variables';
import $ from 'jquery';
declare var _satellite: any;
declare var window: any;

@Component({
  selector: 'app-empcomments',
  templateUrl: './empcomments.component.html',
  styleUrls: ['./empcomments.component.scss']
})
export class EmpcommentsComponent implements OnInit {
  @Input() rowObj: any;
  @Output() emitt_CommentCount = new EventEmitter<number>();
  @Output() emitt_CommentCount_laptop = new EventEmitter<number>();
  commentData = [];
  commentForm:FormGroup;
  messageResult: boolean;
  coreCommentModel = new CoreCommentsModel();
  laptopCommentsData = new LaptopCommentsResponse();
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];
  laptopEmpCommentsData: LaptopCommentsResponse[];
  savedEmpLaptopComments: any;
  @Input() toggleValueForMobileLaptop:boolean;
  moduleName:string = this.filtersMenuService.selectedMainModuleName;

  constructor(private formBuilder: FormBuilder,private _empService: EmpProfileService,private modalService: ModalService,private laptopComplianceService: LaptopComplianceService, private filtersMenuService: FiltersMenuService ) 
  {
    this.emitt_CommentCount = new EventEmitter<number>();
    this.emitt_CommentCount_laptop = new EventEmitter<number>();
   }

  ngOnInit() {
    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required,GlobalVariables.noWhitespaceValidator]
    });
    this.GetEmployeeCommentsDetails();
    this.GetEmployeeLaptopCommentsDetails();
    console.log('rowObj',this.rowObj);
    console.log('onint-toggle');
    //this.fn_DatalayerPageLoad();
  }
  ngOnChanges() {
    // this.GetEmployeeCommentsDetails();
    // this.GetEmployeeLaptopCommentsDetails();
    console.log('onchanges-toggle');
  }

  submit(event) {
    // if((this.moduleName === 'Orders') || (this.moduleName === 'Compliance')|| (this.moduleName === 'Delinquencies')|| (this.moduleName === 'Monthly Bill Review')|| (this.moduleName === 'Terms and Cancellation')|| (this.moduleName === 'Inventory')|| (this.moduleName === 'Inventory Reconciliation') || (this.moduleName == '')) {
   if(this.toggleValueForMobileLaptop == false) {
    this.coreCommentModel.comment= this.commentForm.controls["comment"].value;
    this.coreCommentModel.employeeID = this.rowObj;
    this.coreCommentModel.createdBy=localStorage.getItem("loggedInUserName").split('@')[0];
    this._empService.AddCoreComments(this.coreCommentModel).subscribe(
      (data) => {
        this.modalService.message('Comment saved successfully.').pipe(take(1) // take() manages unsubscription for us
        ).subscribe((result:any) => {
          // console.log({ messageResult: result });
          this.messageResult = result;
          this.commentData=[];
          this.commentForm.controls['comment'].setValue('');
          this.GetEmployeeCommentsDetails();
        });
      }
    );
    } else if(this.toggleValueForMobileLaptop == true)  {
    this.laptopCommentsData.personnelNbr = this.rowObj;
    this.laptopCommentsData.serialNumber = '';
    this.laptopCommentsData.commentText = this.commentForm.value.comment;
    this.laptopCommentsData.commentDate = '';
    this.laptopCommentsData.complianceViolationID = 0;
    this.laptopCommentsData.complianceOverrideID = 0;//this.rowObj.complianceOverrideID;
    this.laptopCommentsData.dateCreated = '';
    this.laptopCommentsData.createdBy = this.loggedInUserName;
    this.laptopCommentsData.ruleId = $('#laptop_audit_type').val();
    this.laptopComplianceService.AddLaptopComments(this.laptopCommentsData).subscribe((data) => {
      this.savedEmpLaptopComments = data;
      if (this.savedEmpLaptopComments == 1) {
        this.modalService.message(
          'Comment Saved Successfully.'
        ).pipe(take(1)
          // take() manages unsubscription for us
        ).subscribe((result:any) => {
          this.messageResult = result;
          this.GetEmployeeLaptopCommentsDetails();
        });
      }
    });
  }
  this.commentForm.reset();
  }
  GetEmployeeLaptopCommentsDetails() {
    let personnelNumber = this.rowObj;
    this.laptopComplianceService.getLaptopComments(personnelNumber, "", 0).subscribe((data) => {
      this.laptopEmpCommentsData = data;
      if (this.laptopEmpCommentsData.length != undefined && this.laptopEmpCommentsData != null && this.laptopEmpCommentsData.length > 0) {
      this.emitt_CommentCount_laptop.emit(this.laptopEmpCommentsData.length);
      }
    });
  }
  GetEmployeeCommentsDetails() {
    let personnelNumber = this.rowObj;
    this._empService.GetInvCommentsDetails(personnelNumber).subscribe(
      (data) => {
        if (data.length > 0) {
          this.commentData = this.commentData.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.commentData.push(data);
          }          
        }
        this.emitt_CommentCount.emit(this.commentData.length);
        this.commentData = this.commentData.sort(function (a, b) {
          let dateA = +new Date(a.dateCreated), dateB = +new Date(b.dateCreated)
          return dateB - dateA
        }
        );
        // this.commentData = this.commentData.map(x => {
        //   const date = moment(x['dateCreated']).format('MMMM Do YYYY, h:mm:ss a');
        //   if (date && date !== 'Invalid date') {
        //     x['dateCreated'] = date;
        //   }
        //   return x
        // })
      } 
    )

    this._empService.GetCompCommentsDetails(personnelNumber).subscribe(
      (data) => {
        if (data.length > 0) {
          this.commentData = this.commentData.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.commentData.push(data);
          }
        }
        this.emitt_CommentCount.emit(this.commentData.length);
        this.commentData = this.commentData.sort(function (a, b) {
          let dateA = +new Date(a.dateCreated), dateB = +new Date(b.dateCreated)
          return dateB - dateA
        }
        );
        // this.commentData = this.commentData.map(x => {
        //   const date = moment(x['dateCreated']).format('MMMM Do YYYY, h:mm:ss a');
        //   if (date && date !== 'Invalid date') {
        //     x['dateCreated'] = date;
        //   }
        //   return x
        // })
      }
    )
    this._empService.GetBillingCommentsDetails(personnelNumber).subscribe(
      (data) => {
        if (data.length > 0) {
          this.commentData = this.commentData.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.commentData.push(data);
          }
        }
        this.emitt_CommentCount.emit(this.commentData.length);
        this.commentData = this.commentData.sort(function (a, b) {
          let dateA = +new Date(a.dateCreated), dateB = +new Date(b.dateCreated)
          return dateB - dateA
        }
        );
        // this.commentData = this.commentData.map(x => {
        //   const date = moment(x['dateCreated']).format('MMMM Do YYYY, h:mm:ss a');
        //   if (date && date !== 'Invalid date') {
        //     x['dateCreated'] = date;
        //   }
        //   return x
        // })
      }
    )
    this._empService.GetOrdersCommentsDetails(personnelNumber).subscribe(
      (data) => {
        if (data.length > 0) {
          this.commentData = this.commentData.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.commentData.push(data);
          }
        }
        this.emitt_CommentCount.emit(this.commentData.length);
        this.commentData = this.commentData.sort(function (a, b) {
          let dateA = +new Date(a.dateCreated), dateB = +new Date(b.dateCreated)
          return dateB - dateA
        }
        );
        // this.commentData = this.commentData.map(x => {
        //   const date = moment(x['dateCreated']).format('MMMM Do YYYY, h:mm:ss a');
        //   if (date && date !== 'Invalid date') {
        //     x['dateCreated'] = date;
        //   }
        //   return x
        // })
      }
    )
    this._empService.GetCoreCommentsDetails(personnelNumber).subscribe(
      (data) => {
        if (data.length > 0) {
          this.commentData = this.commentData.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.commentData.push(data);
          }
        }
        this.emitt_CommentCount.emit(this.commentData.length);
        this.commentData = this.commentData.sort(function (a, b) {
          let dateA = +new Date(a.dateCreated), dateB = +new Date(b.dateCreated)
          return dateB - dateA
        }
        );
        // this.commentData = this.commentData.map(x => {
        //   const date = moment(x['dateCreated']).format('MMMM Do YYYY, h:mm:ss a');
        //   if (date && date !== 'Invalid date') {
        //     x['dateCreated'] = date;
        //   }
        //   return x
        // })
      }
    )

  }
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee Side-Nav Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

}

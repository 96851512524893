import { Injectable } from '@angular/core';
import { Subject, Observable, pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface BroadcastEvent {
    key: any;
    data?: any;
}

@Injectable()
export class Broadcaster {
    private _eventBus: Subject<BroadcastEvent>;

    //Events Admin Module
    public readonly filtertableresult = "filtertableresult";

    static instance: Broadcaster;

    constructor() {
        this._eventBus = new Subject<BroadcastEvent>();

        Broadcaster.instance = this;
    }

    broadcast(key: any, data?: any) {
        this._eventBus.next({ key, data });
    }

    on<T>(key: any): Observable<T> {
        return this._eventBus.asObservable()
            .pipe(filter((event:any) => event.key === key))
            .pipe(map((event: any) => <T>event.data));
    }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public showLoaderForGridData: boolean = false;
  constructor() { }

  public status: Subject<boolean> = new Subject();
  private _active: boolean = false;

  public get active(): boolean {
    return this._active;
  }

  public set active(v: boolean) {
    this._active = v;
    this.status.next(v);
  }

  public start(): void {
    document.body.classList.add("customBackgroundClass");
    this.active = true;
  }

  public stop(): void {
    document.body.classList.remove("customBackgroundClass");
    this.active = false;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataContextService } from '../datacontext.service';
import { AppConstants } from 'src/app/_utils/app-constants';
import { CommentModel } from 'src/app/_models/compliance/comment.model';
import { ExceptionModel_DTO } from 'src/app/_models/compliance/exception.model';
import { NotificationModel } from 'src/app/_models/compliance/notification.model';
import { historyModel } from 'src/app/_models/compliance/history.model';
import { EmployeeCommentsModel, RootObject } from 'src/app/_models/compliance/compliance-search.model';

@Injectable({
  providedIn: 'root'
})
export class RightSideCommentNavService {
  //baseUrl = environment.baseUrl;
  auditType:any;
  apiurl: string = localStorage.getItem('apiurl');
  exceptionList = new ExceptionModel_DTO();
  commentList = new CommentModel();

  NotificationDataResult = new RootObject();

  constructor(private http: HttpClient, private _datacontextService: DataContextService) { }

  getNotificationsData(ComplianceViolationID): Observable<any> {
    return this._datacontextService.getData<NotificationModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Compliance.GetComplianceNotificationDeatils + ComplianceViolationID)
      .pipe(map(data => {
        return data;
      }));
  }

  getViolationsData(personalID): Observable<any> {

    return this._datacontextService.getData<EmployeeCommentsModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Compliance.GetComplianceIssues + personalID)
      .pipe(map(data => {
        return data;
      }));
  }
  getInvViolationsData(personalID): Observable<any> {

    return this._datacontextService.getData<EmployeeCommentsModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetComplianceViolationDetailsByPersonnelNbr + personalID)
      .pipe(map(data => {
        return data;
      }));
  }
  getCommentsData(violationId): Observable<EmployeeCommentsModel[]> {
    return this._datacontextService.getData<EmployeeCommentsModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Compliance.GetComments + violationId);
  }
  getExcetionData(PersonID,ComplianceViolationID:number): Observable<any> {

    return this._datacontextService.getData<EmployeeCommentsModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Compliance.GetExceptionList + PersonID+ "&ComplianceViolationId=" + ComplianceViolationID)
      .pipe(map(data => {
        return data;
      })
      );;

  }

  getAddUpdateExceptionList(obj: ExceptionModel_DTO): Observable<any> {

    return this._datacontextService.postData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Compliance.AddUpdateAdminExceptionList, obj)
      .pipe(map(data => {
        this.exceptionList = null;
        this.exceptionList = data;
        return data;
      }));

  }
  getAddUpdateCommentList(obj: CommentModel): Observable<any> {
    return this._datacontextService.postData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Compliance.AddComplianceComments, obj)
      .pipe(map(data => {
        this.commentList = null;
        this.commentList = data;
        return data;
      }));

  }

  getAddUpdateExceptionListPRorPW(obj: ExceptionModel_DTO): Observable<any> {

    return this._datacontextService.postData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Compliance.AddUpdateAdminExceptionListPRorPW, obj)
      .pipe(map(data => {
        this.exceptionList = null;
        this.exceptionList = data;
        return data;
      }));

  }

  getExcetionDataPRorPW(PersonID,ComplianceViolationID:number): Observable<any> {

    return this._datacontextService.getData<EmployeeCommentsModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Compliance.GetExceptionListProrPW + PersonID+ "&ComplianceViolationId=" + ComplianceViolationID)
      .pipe(map(data => {
        return data;
      })
      );;

  }

  getAddUpdateCommentListPRorPW(obj: CommentModel): Observable<any> {
    return this._datacontextService.postData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Compliance.AddComplianceCommentsPRorPW, obj)
      .pipe(map(data => {
        this.commentList = null;
        this.commentList = data;
        return data;
      }));

  }

  getCommentsDataPRorPW(violationId): Observable<EmployeeCommentsModel[]> {
    return this._datacontextService.getData<EmployeeCommentsModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Compliance.GetCommentsPRorPW + violationId);
  }
  getHistoryData(personalOrTicketNumber) {
    return this._datacontextService.getData<historyModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.GetUserTicketsListByPersonalOrTicketNumber + personalOrTicketNumber)
      .pipe(map(data => {
        return data;
      })
      );
  }

  getNotificationsDataPRorPW(ComplianceViolationID): Observable<any> {
    return this._datacontextService.getData<NotificationModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetComplianceNotificationDeatils + ComplianceViolationID)
      .pipe(map(data => {
        return data;
      }));
  }

  setAuditType(val){
    this.auditType = val;
  }
  getAuditType(){
    return this.auditType;
  }



}

import { Component, Input, OnInit } from '@angular/core';
import { ComplianceViolations } from 'src/app/_models/employee-profile/ComplianceViolations';
import { ViolationResponse } from 'src/app/_models/laptop-compliance/laptopViolation.model';
declare var _satellite: any;
declare var window: any;
@Component({
  selector: 'app-violations',
  templateUrl: './violations.component.html',
  styleUrls: ['./violations.component.scss']
})
export class ViolationsComponent implements OnInit {

  @Input() violationDetails: ComplianceViolations[];
  @Input()  laptopEmpViolationData: ViolationResponse[];
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];

  constructor() { }

  ngOnInit() {
    //this.fn_DatalayerPageLoad();

  }
  ngOnChanges() {
    // // console.log(this.violationDetails);
  }
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee Violations Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

}

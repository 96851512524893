import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mbrsearch'
})
export class MbrSearchPipe implements PipeTransform {

    transform(value: any, args?: any): any {

        console.log('mbr filter called  ', value)
        if (!value) return null;
        if (!args) return value;
        args = args.toLowerCase();
        return value.filter(function (item) {
            return JSON.stringify(item.value.feactureCode).toLowerCase().includes(args);
        });

    }

}

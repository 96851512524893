import { Injectable } from '@angular/core';
import { map, catchError, finalize, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SpinnerService } from '../_shared-component/spinner/spinner.service';


@Injectable({
  providedIn: 'root'
})

export class DataContextService {
  header: any = new HttpHeaders().set('content-type', 'application/json;charset=utf-8;');
  cnt:number = 0;
  constructor(private http: HttpClient, public spinner: SpinnerService) {

  }

  getPlainTextData(_dataUrl: string){   
    this.cnt++;  
    this.spinner.start(); 
    return this.http.get<string>(
      _dataUrl, {responseType: 'text' as 'json'})
      .pipe(map((response) => <string>response), catchError(this.handleError), finalize(() => {this.cnt--; if(this.cnt==0) this.spinner.stop()}))
      .pipe(tap(data => {
      }));
  }

  getData<T>(_dataUrl: string): Observable<T> {  
    this.cnt++;  
    this.spinner.start();
    return this.http.get<T>(_dataUrl, { headers: this.header })
      .pipe(map((response) => <T>response), catchError(this.handleError), finalize(() => {this.cnt--; if(this.cnt==0) this.spinner.stop()}))
      .pipe(tap(data => {
      }));
  }

  postData<T>(_dataUrl: string, data: Object): Observable<T> {
    this.cnt++;
    this.spinner.start();
    return this.http.post<T>(_dataUrl, data, { headers: this.header })
      .pipe(map((response) => <T>response), catchError(this.handleError), finalize(() => {this.cnt--; if(this.cnt==0) this.spinner.stop()}))
  }

  handleError(error:any) {
    console.error(error);
    return Observable.throw(error.json().error || 'Server error');
  }

  post<T>(_dataUrl: string): Observable<T> {
    this.cnt++;
    this.spinner.start();
    return this.http.post<T>(_dataUrl, { headers: this.header })
      .pipe(map((response) => <T>response), catchError(this.handleError), finalize(() => {this.cnt--; if(this.cnt==0) this.spinner.stop()}))
  }


}


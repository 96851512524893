<div *ngIf="showPage" class="container-fluid landing-page-comp">
    <div class="row">
        <div class="col-md-6 people-picker-data" *ngIf="!employeeinformation">
            <p class="back-to-home" *ngIf="showEmpTable" (click)="backToHome($event)"><img
                    src="../../../assets/images/collapse arrows.svg"><span class="home">Back to home</span></p>
            <p class="search-text" *ngIf="!showEmpTable">Use powerful search of</p>
            <p class="dashboard-text" *ngIf="!showEmpTable">Device Compliance Dashboard</p>
            <!-- <p class="dashboard-text" *ngIf="!showEmpTable">dashboard</p> -->
            <div class="col-md-12 search-div">
                <div class="col-md-10 people-picker-landing">
                    <app-people-picker (selectedAlias)="selectedAlias($event)"
                        (personnelNumberEmitEvent)="personnelNumber($event)" (inputItemEventEmit)="inputItem($event)">
                    </app-people-picker>
                </div>
                <!-- <div class="col-md-4 people-picker-submit">
                    <button id="laning_page_submit" type="button" class="btn btn-primary" (click)="searchEmp()"
                        [disabled]="!enableSearchButton">Search</button>
                </div> -->
            </div>
        </div>
        <div class="col-md-6 mt-25" *ngIf="!showEmpTable && !employeeinformation">
            <img class="landing-page-icon" src="../../../assets/images/Group 3.png" />
        </div>
    </div>
    <!-- <div *ngIf="spinnerService.showLoaderForGridData">
        <div class="loading">Loading&#8230;</div>
    </div> -->
    <!-- <div class="row scroll-table" *ngIf="showEmpTable">
        <app-emp-details [employeeData]="employeeData" (empProfilePersonalNo)=empProfile($event)></app-emp-details>
    </div> -->

    <div class="row back-to-emp-landing">
        <div *ngIf="employeeinformation" class="col-md-4 backemp-landing">
            <div (click)="backToSearch()">
                <img src="assets/images/collapse arrows.svg"><span class="hidedata-text backto-emp-landingcomp">Back</span>
            </div>
        </div>
    </div>
    

    <div *ngIf="employeeinformation">
        <app-employee-profile *ngIf="employeeinformation" [personnelNumber]="personnelNo"></app-employee-profile>
    </div>
</div>
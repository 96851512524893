<div class="modal fade" id="uploadactivitymodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true"  data-backdrop="static"  data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Upload Activity Log</h5>
        <button type="button" class="close" data-dismiss="modal"  aria-label="Close" >
          <span aria-hidden="true" (click)="closemethod()" >&times;</span>
        </button>
      </div>
      <div class="row pane pane--table2">
        <table class="table">
            <!-- <col width="180"> -->
            <!-- <col width="80"> -->

            <thead style="background-color:#F8F8FA !important;">
                <tr class="tabrow">

                    <th class="t-3" style="min-width:148px">
                     Name of the Activity

                    <th class="t-3" style="min-width:108px">
                       Report Name

                    <th class="t-2" style="min-width:108px">
                      Date of Upload
                    </th>

                    <th class="t-1" style="min-width:108px">
                      Date of Report
                    </th>

                </tr>
            </thead>
            <tbody>

                <tr
                    *ngFor="let x of uploadactivityDetails; let i=index;">

                    <td class="t-{{i}}" >{{x.processName}}</td>
                    <td class="t-{{i}}" style="min-width: 130px;">{{x.fileName}}</td>
                    <td class="t-{{i}}">{{x.dateOfUpload | date: "MMM d, y, h:mm:ss a"}}</td>
                    <td class="t-{{i}}">{{x.dateOfReport}}</td>

                </tr>

            </tbody>
        </table>
        </div>
</div>



    </div>
  </div>

import { ElementRef, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ModalService } from './modal.service';
import { getLoggedInUser } from '../_models/loggedInUser.model';
import { TssUserDetails } from '../_models/tssUserDetails.model';
import { DataContextService } from './datacontext.service';
import { LoggedInUserModules } from '../_models/getLoggeinUserModules';
import { AppConstants } from '../_utils/app-constants';
import { BnNgIdleService } from 'bn-ng-idle';


@Injectable({
  providedIn: 'root'
})
export class LoggedInUserModulesService {
  //public API_GPAAPI: string = environment.appEndpoints.gpaApi;
  apiurl: string = localStorage.getItem('apiurl');
  getLoggedInUser: getLoggedInUser;
  loggedInUserRegionFromService: TssUserDetails;
  isAuthorized$ = new BehaviorSubject(true);
  isAuthenticated$ = new BehaviorSubject(false);
  user$ = new ReplaySubject<getLoggedInUser>(1);
  userModules$ = new ReplaySubject<LoggedInUserModules[]>(1);
  copyElementRef: ElementRef;
  //baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private _datacontextService: DataContextService, private router: Router, private bnIdle: BnNgIdleService, private modalsvc: ModalService) { }


  setSessionTimeout() {

    this.bnIdle.startWatching(600).subscribe((res) => {
      if (res) {
        this.modalsvc.sessionTimeOut(
          'Sorry, your session has expired. Please refresh the page to continue.'
        ).subscribe(d => {
          this.bnIdle.resetTimer();
          this.bnIdle.stopTimer();
          window.location.href = '';
        });
        return;

      }
    })
  }
  GetLoggedInUserModules(useralias) {
    return this._datacontextService.getData<LoggedInUserModules[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.GetLoggedInUserModules + '?useralias=' + useralias)
      .pipe(map(data => {
        this.userModules$.next(data);
        return data;
      }));
  }
  GetLoggedInUser(useralias) {
    return this._datacontextService.getData<getLoggedInUser>(this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.GetLoggedInUser + '?useralias=' + useralias)
      .pipe(map(data => {
        //return data;
        this.user$.next(data);
        return this.getLoggedInUser = data;
      }));
  }
  GetLoggedInuserRegion(userAlias) {
    return this._datacontextService.getData<TssUserDetails>(this.apiurl + AppConstants.GPA_ENDPOINTS.Reconciliation.GetLoggedInuserRegion + '?userAlias=' + userAlias)
      .pipe(map(data => {
        return this.loggedInUserRegionFromService = data;
      }));
  }

  Unauthorized(moduleName: string, authorizeWOModule?: boolean) {
    let loggeInUserJsonData = JSON.parse(localStorage.getItem('loggedInUserModules'));
    if(authorizeWOModule || !loggeInUserJsonData.some(data => data.displayName === moduleName)) {
      this.isAuthorized$.next(false);
      this.router.navigate(['unauthorized']);
    }
  }
  unAuthorizedSubModules(moduleName: string) {
    let loggeInUserJsonData = JSON.parse(localStorage.getItem('loggedInUserSubModules'));
    if (!loggeInUserJsonData.some(data => data.displayName === moduleName)) {
      this.isAuthorized$.next(false);
      this.router.navigate(['unauthorized']);
    }
  }
  
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { InventorySearchResult } from "src/app/_models/inventory/InventorySearchResult.model";
import { InventorySearchCrieteria } from "src/app/_models/inventory/InventorySearchCrieteria.model";
import { AssignDevice } from "src/app/_models/inventory/AssignDevice.model";
import { DataContextService } from "../datacontext.service";
import { DeviceType } from "src/app/_models/inventory/DeviceType.model";
import { AppConstants } from "src/app/_utils/app-constants";
import { DeviceLocation } from "src/app/_models/inventory/DeviceLocation.model";
import { Broadcaster } from "../broadcaste.service";
import { DeviceStatus } from "src/app/_models/inventory/DeviceStatus.model";
import { EmployeeStatus } from "src/app/_models/inventory/EmployeeStatus.model";
import { JoblevelGroup } from "src/app/_models/inventory/JoblevelGroup.model";
import { DeviceColor } from "src/app/_models/inventory/DeviceColor.model";
import { DeviceSize } from "src/app/_models/admin/DeviceSize.model";
import { Deviceos } from "src/app/_models/inventory/Deviceos.model";
import { region } from "src/app/_models/inventory/Region.model";
import { Office } from "src/app/_models/inventory/Office.model";
import { CarrierModel } from "src/app/_models/inventory/carrier.model";
import { CategoryModel } from "src/app/_models/inventory/category.model";
import { SubCategoryModel } from "src/app/_models/inventory/subcategory.model";
import { CatalogModel } from "src/app/_models/inventory/catalog.model";
import { ServiceNowLookup } from "src/app/_models/inventory/ServiceNowLookup.model";
import { AssignmentGroupModel } from "src/app/_models/inventory/assignmentgroupmodels";
import { UserTicketDetailsModel } from "src/app/_models/inventory/userticketdetailsmodel";
import { ServicenowTicketDetails } from "src/app/_models/inventory/servicenowticketdetails.model";
import { Mtn } from "src/app/_models/inventory/mtnmodels";
import { Imei } from "src/app/_models/inventory/imei.model";
import { MTNList } from "src/app/_models/inventory/MTNList";
import { InsertEmailScheduleInventory, InventoryNotification } from "src/app/_models/inventory/InventoryNotification.model";
import { CoreCommentsModel } from "src/app/_models/employee-profile/CoreCommentsModel";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class InventoryService {
  // public API_GPAAPI: string = environment.appEndpoints.gpaApi;
  //public API_COREAPI: string = environment.appEndpoints.CoreApi;
  public apiurl: string = localStorage.getItem('apiurl');
  public SN_ConfigItem: string = environment.serviceNowKeys.SNConfigItem;
  public SN_impact: string = environment.serviceNowKeys.SNimpact;
  public SN_urgency: string = environment.serviceNowKeys.SNurgency;
  searchResultData: InventorySearchResult[];
  searchCrieteria: InventorySearchCrieteria;
  isMultiSelect: boolean;
  totalItemsforGrid = 10;
  facilityRegionCode;
  selectRow: any;
  assignDevice: AssignDevice;
  xyz: any[];

  // searchResultData:InventoryResultItem[];
  constructor(private http: DataContextService, private broadcatser: Broadcaster) { }

  // searchResultsList=new InventorySearchCrieteria();
  getallDeviceType() {
    return this.http.getData<DeviceType[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceType
    );
  }

  getallDeviceLocation(locationid: string) {
    return this.http.getData<DeviceLocation[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceLocation + '?Locationid=' + locationid
    );
  }
  
  getallDeviceLocationForTSS() {
    return this.http.getData<DeviceLocation[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceLocationForTSS
    );
  }

  getDeviceLocationsByFromLocation(fromLocationid: any) {
    return this.http.getData<DeviceLocation[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetDeviceLocationsByFromLocation + '?fromLocationid=' + fromLocationid
    );
  }
  // passs the values 0 or 1 static is usas carrier

  getallDeviceStatus() {
    return this.http.getData<DeviceStatus[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceStatus + '?DeviceStatusID=0'
    );
  }
  getallCarrierStatus() {
    // return this.http.get<>(this.apiurl + 'Inventory/GetAllCarrier');
  }

  getallemployeeStatus() {
    return this.http.getData<EmployeeStatus[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetAllEmployeeStatus
    );
  }
  getJoblevelGroup() {
    return this.http.getData<JoblevelGroup[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetAllUserTypes
    );
  }
  getallcolors() {
    return this.http.getData<DeviceColor[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceColors
    );
  }
  getMemorySize() {
    return this.http.getData<DeviceSize[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceSize
    );
  }
  getDeviceOs(deviceOSID: number) {
    return this.http.getData<Deviceos[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceOS + '?DeviceOSID=' + deviceOSID
    );
  }
  getAllRegion() {
    return this.http.getData<region[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAllFacilityRegionWithDeviceOrigin
    );
  }
  getAllOffice(facilityRegionCode: any) {
    // return this.http.get<Office[]>(this.apiurl+'Inventory/GetAllFacility?facilityRegionCode=' +facilityRegionCode);
    return this.http.getData<Office[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetAllFacility + '?facilityRegionCode=' +
      facilityRegionCode
    );

    // regionCode
  }

  getAllCarrier() {
    //TODO: change input once global memberfirm comes
    return this.http.getData<CarrierModel[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardCarrier
    );
  }

  getallgriddata_old(obj) {
    //  // console.log("objvalue",obj);
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let params = new HttpParams().set("searchCriteria", obj);

    //.set("searchCriteria",obj);
    // // console.log("params",params.get('searchCriteria'));
    let results = this.http.getData<InventorySearchResult[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetInventorySearchResult
    );
    let resultsSubs = results.subscribe(data => {
      // // console.log("data",data);
    });

    return results;
  }
  getInventorySearch(obj: InventorySearchCrieteria, isMultiSelect: boolean) {
    this.isMultiSelect = isMultiSelect;
    return this.getallgriddata(obj);
  }

  getallgriddata(obj: InventorySearchCrieteria): Observable<any[]> {
    // alert('service layer');
    // debugger;
    this.searchCrieteria = obj;
    // console.log("searchCrieteria", obj);
    //  alert(JSON.stringify(obj));

    // let body={obj,LoggedUser:'',totalNoOfRecords:50}
    let header = new HttpHeaders().set(
      "content-type",
      "application/json;charset=utf-8;"
    );

    return this.http
      .postData<InventorySearchResult[]>(
        this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetInventorySearchResult,
        obj
      )
      .pipe(
        map(data => {
          //       this.searchResultsList=data;

          // debugger;
          this.searchResultData = data;
          // // console.log('kkkkkkklll', this.searchResultData.inventorySearchResultItems);
          // console.log('llllllll', this.searchResultData);
          this.broadcatser.broadcast(this.broadcatser.filtertableresult);

          return data;
        },
          err => {

            // console.log("err", err);
          }), catchError(err => {
            console.log("err", err);
            this.searchResultData= null;
            this.broadcatser.broadcast(this.broadcatser.filtertableresult);
            const error: Observable<any[]> = Observable.create((observer) => {
              observer.error(['error'])
            });
            return error
          })
      );
  }
  filtertableinformation() {
    return this.searchResultData;
  }
  getIsMultiSelect() {
    return this.isMultiSelect;
  }
  refreshSearchInformation() {
    this.getallgriddata(this.searchCrieteria).subscribe((searchresult) => {

      // console.log('griddata', searchresult);
      //this.chartGridData = data;
    })
  }
  SetSearchResultRowData(SearchResultRowData: any) {
    alert("service: " + SearchResultRowData);
    this.selectRow = SearchResultRowData;
  }
  GetSearchResultRowData() {
    alert("Service sent data to assigned device: " + this.selectRow);
    return this.selectRow;
  }
  SubmitAssignedDevice(dt: AssignDevice): Observable<string> {
    let header = new HttpHeaders().set(
      "content-type",
      "application/json;charset=utf-8;"
    );
    return this.http.postData<string>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.InsertUpdateMobileDeviceHistory,
      dt
    );
  }
  // SubmitAssignedDevice(dt: AssignDevice): Observable<string> {
  //   let header = new HttpHeaders().set(
  //     "content-type",
  //     "application/json;charset=utf-8;"
  //   );
  //   return this.http.post<string>(
  //     this.apiurl + "Inventory/InsertUpdateMobileDeviceHistory",
  //     dt,
  //     { headers : header }
  //   ).pipe(
  //     map(data=> {
  //       // console.log('submitassigndeviceresult', data);
  //       //this.broadcatser.broadcast(this.broadcatser.filtertableresult);
  //       this.getallgriddata(this.searchCrieteria).subscribe((searchresult) => {

  //         // console.log('griddata', searchresult);
  //         //this.chartGridData = data;
  //       })
  //       return data;
  //     },err => {

  //       // console.log("err", err);
  //     })
  //   );
  // }
  SubmitMultiAssignedDevice(dt: AssignDevice[]): Observable<string[]> {
    let header = new HttpHeaders().set(
      "content-type",
      "application/json;charset=utf-8;"
    );
    let _httpHeadercommon = new HttpHeaders().set('content-type', 'application/json');
    //let obj: AssignDeviceBatch = { Devices: []};
    //obj["Devices"] = dt;    
    // console.log("multiobj", dt);
    return this.http.postData<string[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.InsertUpdateMultipleMobilesDeviceHistory,
      dt
    );
  }
  GetAssignedDeviceData(mobileDeviceID: any, personID: any) {
    //TODO: change input once global memberfirm comes
    return this.http.getData<AssignDevice[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetMobileDeviceHistory + '?mobileDeviceID=' +
      mobileDeviceID
      +
      "&personID=0"

    );
  }
  GetAssignedDeviceDataBatch(mobileDeviceID: any, personID: any) {
    //TODO: change input once global memberfirm comes
    return this.http.getData<AssignDevice[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetMobileDevicesHistoryInBatch + '?mobileDeviceID=' +
      mobileDeviceID +
      "&personID=0"
    );
  }
  getAllCategory() {
    return this.http.getData<CategoryModel[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetCategoryDetails
    );
  }
  getAllSubcategory(catId: any) {
    return this.http.getData<SubCategoryModel[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetSubCategoryDetails + '?catId=' + catId
    );
  }
  getAllCatalogItmes(
    FromAction: string,
    ToAction: string,
    DeviceOS: string,
    DeviceType: string
  ): Observable<any[]> {
    return this.http.getData<CatalogModel[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetCatalogItemsBasedOnAssignment + '?FromAction=' +
      FromAction +
      "&ToAction=" +
      ToAction +
      "&DeviceOS=" +
      DeviceOS +
      "&DeviceType=" +
      DeviceType
    );
  }

  GetIncidentAreaandCheckListBasedOnAssignment(
    FromAction: string,
    ToAction: string,
    DeviceOS: string,
    DeviceType: string
  ): Observable<ServiceNowLookup[]> {
    if (ToAction == 'Lost/Stolen') {
      ToAction = 'LostORStolen';
    }
    return this.http.getData<ServiceNowLookup[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetIncidentAreaandCheckListBasedOnAssignment + '?FromAction=' +
      FromAction +
      "&ToAction=" +
      ToAction
      +
      "&DeviceOS=" +
      DeviceOS +
      "&DeviceType=" +
      DeviceType
    );
  }

  getAllAssignmentgroupItems(loggedInUser: string) {
    return this.http.getData<AssignmentGroupModel[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.GetGroupsForLoggedInUser +
      loggedInUser
    );
    //TODO: remove static loggedin user
  }
  getTicketDetialsByPersonalOrTicketNumber(personalOrTicketNumber: string) {
    return this.http.getData<UserTicketDetailsModel[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.GetUserTicketsListByPersonalOrTicketNumber +
      personalOrTicketNumber
    );
  }
  createIncidentTicketWithGroupAssignment(obj: ServicenowTicketDetails): Observable<string> {
    obj.configItem = this.SN_ConfigItem;
    obj.impact = this.SN_impact;
    obj.urgency = this.SN_urgency;
    let header = new HttpHeaders().set('content-type', 'application/json;charset=utf-8;');
    return this.http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.CreateIncidentTicketWithGroupAssignment, obj);

  }
  // createRequestNUpdateState(obj:ServicenowTicketDetails): Observable<string> {
  //   let header=new HttpHeaders().set('content-type','application/json;charset=utf-8;');
  //   return this.http.postData<string>(AppConstants.GPA_ENDPOINTS.CoreService.CreateRequestNUpdateState,obj);

  // }
  createRequestNUpdateState(obj: ServicenowTicketDetails): Observable<string> {
    obj.configItem = this.SN_ConfigItem;
    obj.impact = this.SN_impact;
    obj.urgency = this.SN_urgency;
    let header = new HttpHeaders().set(
      "content-type",
      "application/json;charset=utf-8;"
    );
    return this.http.postData<string>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.CreateRequestNUpdateState,
      obj
    );
  }
  UpdateDeviceWipeStatus(mobileDeviceID: string, personnelNbr: string, mobileDeviceHistoryID, wipeOption: string, ticketNo: string, mtn: string, tssRep: string, complainceViolationStatus: string): Observable<any> {
    return this.http.post<any>(this.apiurl + `${AppConstants.GPA_ENDPOINTS.Inventory.UpdateDeviceWipeStatus}` + `?mobileDeviceID=${mobileDeviceID}&&personnelNbr=${personnelNbr}&&wipeOption=${wipeOption}&&ticketNo=${ticketNo}&&mtn=${mtn}&&tssRep=${tssRep}&&complainceViolationStatus=${complainceViolationStatus}&&mobileDeviceHistoryID=${mobileDeviceHistoryID}`);
  }
  updateTicketStatus(obj: ServicenowTicketDetails): Observable<string> {
    obj.configItem = this.SN_ConfigItem;
    obj.impact = this.SN_impact;
    obj.urgency = this.SN_urgency;
    let header = new HttpHeaders().set('content-type', 'application/json;charset=utf-8;');
    return this.http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.UpdateTicketStatus, obj);

  }
  getEmployeeFacility(employeeAlias: string) {
    let header = new HttpHeaders().set('responseType', 'text');
    return this.http.getData<string>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetEmployeeFacility + '?employeeAlias=' + employeeAlias
    );
  }
  updateTicketNumToMobileDeviceHistory(mobDeviceHisId: string, ticketNumber: string) {
    let header = new HttpHeaders().set(
      "content-type",
      "application/json;charset=utf-8;"
    );
    var obj = {
      "mobDeviceHisId": mobDeviceHisId,
      "ticketNumber": ticketNumber
    }
    return this.http.postData<boolean>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.UpdateTicketNumToMobileDeviceHistory + '?mobDeviceHisId=' + mobDeviceHisId + '&ticketNumber=' + ticketNumber,
      null
    );
  }
  getEmployeeFacilitiesByRegion(employeeAlias: string, isNMCUser: boolean, isUSCarrier: boolean) {
    return this.http.getData<Office[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetEmployeeFacilitiesByRegion + '?employeeAlias=' + employeeAlias + "&isNMCUser=" + isNMCUser + "&isUSCarrier=" +
      isUSCarrier
    );
  }
  getAllMtnDetails(mtn: string) {
    return this.http.getData<Mtn[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetMtnDeviceDetails + '?mtn=' + mtn);
  }
  getAllImeiDetails(deviceID: string) {
    return this.http.getData<Imei[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetDeviceHistory + '?deviceID=' + deviceID);
  }
  getMTNList(personnelNumber: string, deviceType: string, mobileDeviceId: string) {
    return this.http.getData<MTNList[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetMTNList + '?personnelNumber=' + personnelNumber + "&deviceType=" + deviceType + "&mobileDeviceId=" + mobileDeviceId
    );
  }
  GetInventoryNotifyCarrierDetails(CarrierCode: string) {
    return this.http.getData<InventoryNotification>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetInventoryNotifyCarrierDetails + '?CarrierCode=' + CarrierCode
    );
  }
  InsertEmailToSchedule(obj: InsertEmailScheduleInventory) {
    // :Observable<InsertEmailSchedule[]>
    return this.http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.InsertEmailToSchedule, obj)
      .pipe(map((data) => {
        return data;
      }
      ));
  }
  AddCoreComments(obj: CoreCommentsModel): Observable<boolean> {
    return this.http.postData<boolean>(this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.AddEmployeeComments, obj);
  }
  UpdateComplianceViolationFromAssignDevice(mobileDeviceID:string, personnelNbr:string): Observable<boolean> {
    return this.http.post<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Compliance.UpdateComplianceViolationFromAssignDevice + '?mobileDeviceID=' + mobileDeviceID +'&personnelNbr=' + personnelNbr);
  }
}

<div *ngIf="showImei && showBackButon"
    class="col-md-4" style="margin-left:-30px;cursor: pointer;">
    <div (click)="backtoSearch()" >
        <img src="assets/images/collapse arrows.svg"><span class="hidedata-text"
            style="font-size:12px;color: #212529;">Back</span>
    </div>
</div>


<div *ngIf="showImei" class="imeitable">
  <form #myForm="ngForm">
    <div class="row">
      <div class="col-md-3 mackbooksidefilter">
        <div class="row">
          <div class="col-md-1 p-0">
            <img src="/assets/images/device.svg">
          </div>
          <div class="col-md-11">
            <p class="macbook">{{ImeiModel?.deviceHistoryDetails?.deviceName}}</p>
            <p class="deviceid">
              {{ImeiModel?.deviceHistoryDetails?.imei}}
            </p>
          </div>
        </div>
        <div class="row prexception">
          <div class="offset-md-2 account-status">
          <p>{{ImeiModel?.deviceHistoryDetails?.accountStatus}}</p>
          </div>
        </div>
        <hr>
        <div class="prexception">
          <p *ngIf="ImeiModel?.deviceHistoryDetails?.isPRExceptionGranted">PR Exception Granted</p>
          <p *ngIf="ImeiModel?.deviceHistoryDetails?.isBlocklisted">Blacklisted</p>
          <p *ngIf="ImeiModel?.deviceHistoryDetails?.isPersonnel">Personal Device</p>
          <p *ngIf="ImeiModel?.replacedDeviceList?.length > 0">Replaced Device</p>
        </div>
        <hr>
        <!-- <div class="row">
          <div class="col-md-6">
            <p class="deviceid">Carrier:</p>
          </div>
          <div class="col-md-6 prexception">
            <p>{{ImeiModel?.deviceHistoryDetails?.carrier}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p class="deviceid">Device Status:</p>
          </div>
          <div class="col-md-6 prexception">
            <p> {{ImeiModel?.deviceHistoryDetails?.deviceStatus}}</p>
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-md-6">
            <p class="deviceid">Assigned To:</p>
          </div>
          <div class="col-md-6 prexception">
            <p> {{ImeiModel?.deviceHistoryList[0].assignedTo}}</p>
          </div>

        </div> -->
        <div class="row carrier-margin">
          <p class="deviceid">Carrier:</p>
        </div>
        <div class="row prexception carrier-margin">
          <p>{{ImeiModel?.deviceHistoryDetails?.carrier}}</p>
        </div>
        <div class="row carrier-margin">
          <p class="deviceid">Device Status :</p>
        </div>
        <div class="row prexception carrier-margin">
          <p> {{ImeiModel?.deviceHistoryList[0].deviceStatus}}</p>
        </div>
        <div class="row carrier-margin">
          <p class="deviceid">Assigned To:</p>
        </div>
        <div class="row prexception carrier-margin">
          <p class="id-name">{{ImeiModel?.deviceHistoryList[0]?.assignedTo?.split('ID:')[0]}}</p>
        </div>
        <div class="row prexception carrier-margin">
          <p class="id-name">ID: {{ImeiModel?.deviceHistoryList[0]?.assignedTo?.split('ID:')[1]?.split('Alias:')[0]}}</p>
        </div>
        <div class="row prexception carrier-margin">
          <p>Alias: {{ImeiModel?.deviceHistoryList[0]?.assignedTo?.split('ID:')[1]?.split('Alias:')[1]}}</p>
        </div>
        <div class="row carrier-margin">
          <p class="deviceid">Office:</p>
        </div>
        <div class="row prexception carrier-margin">
          <p> {{ImeiModel?.deviceHistoryList[0]?.facilityName}}</p>
        </div>
        <div class="row carrier-margin">

          <p class="deviceid">TSS Rep:</p>
        </div>
        <div class="row prexception carrier-margin">
          <p>{{ImeiModel?.deviceHistoryList[0]?.tssRep}}</p>
        </div>
      </div>
      <div class="col-md-9 jumbotron">
        <div class="table-responsive-imei">
          <p class="history-text">History</p>

          <div class="table-responsive mb-4" id="responsivetable">
            <table>
              <thead>
                <tr>
                  <th style="min-width: 300px;" [class.active]="order === 'assignedTo'"
                  (click)="setOrder('assignedTo')">Assigned To <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>
                  <th style="min-width: 250px;" [class.active]="order === 'facilityName'"
                  (click)="setOrder('facilityName')">Office <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>
                  <th style="min-width: 250px !important;" [class.active]="order === 'shippingTrackingNo'"
                  (click)="setOrder('shippingTrackingNo')">Shipping Tracking No. <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>

                  <th style="min-width: 250px !important;" [class.active]="order === 'mtn'"
                  (click)="setOrder('mtn')">Mobile Telephone Number <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>
                  <th style="min-width: 140px;" [class.active]="order === 'tssRep'"
                  (click)="setOrder('tssRep')">TSS Rep <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>
                  <th style="min-width: 140px;" [class.active]="order === 'activationDate'"
                  (click)="setOrder('activationDate')">Date Modified <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>
                  <th style="min-width: 140px;" [class.active]="order === 'accountStatus'"
                  (click)="setOrder('accountStatus')">Account Status <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>
                  <th style="min-width: 140px;" [class.active]="order === 'deviceStatus'"
                  (click)="setOrder('deviceStatus')">Device Status <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>
                  <th style="min-width: 140px;" [class.active]="order === 'assetStatus'"
                  (click)="setOrder('assetStatus')">Asset Status <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>
                  <th [class.active]="order === 'adminTransactionComments'"
                  (click)="setOrder('adminTransactionComments')">Comments <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of ImeiModel?.deviceHistoryList | orderBy: order:reverse:true; let i = index">

                  <td data-toggle="tooltip"
                    style="padding-top:10px;padding-left: 17px;max-width: 300px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;cursor:pointer"
                    title="{{row.assignedTo}}">{{row.assignedTo}}
                    <span data-toggle="tooltip" title="{{row.assignedTo}}"></span>
                  </td>
                  <td data-toggle="tooltip"
                    style="padding-top:6px;padding-left: 17px;padding-bottom:7px;padding-left:17px;max-width: 250px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;cursor:pointer"
                    title="{{row.facilityName}}">{{row.facilityName}}
                    <span data-toggle="tooltip" title="{{row.facilityName}}"></span></td>
                  <td data-toggle="tooltip"
                    style="cursor:pointer;padding-top:6px;padding-bottom:7px;max-width: 250px;max-width: 150px;padding-left: 17px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">
                    {{row.shippingTrackingNo}}
                    <span data-toggle="tooltip" title="{{row.shippingTrackingNo}}"></span>
                  </td>
                  <td
                    style="padding-top:6px;padding-bottom:7px;max-width: 250px;padding-left: 17px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">
                    <span data-toggle="tooltip" title="{{row.mtn}}">{{row.mtn}}</span></td>
                  <td
                    style="padding-top:6px;padding-bottom:7px;max-width: 140px;padding-left: 17px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">
                    <span data-toggle="tooltip" title="{{row.tssRep}}">{{row.tssRep}}</span></td>
                  <td data-toggle="tooltip"
                    style="cursor:pointer;padding-left: 17px;padding-top:6px;padding-bottom:7px;max-width: 140px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;"
                    title="{{row.activationDate|date}}">{{row.activationDate|date}}
                    <span data-toggle="tooltip" title="{{row.activationDate|date}}"></span></td>

                  <td data-toggle="tooltip"
                    style="cursor:pointer;padding-top:6px;padding-bottom:7px;max-width: 140px;padding-left: 17px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">
                    {{row.accountStatus}}
                    <span data-toggle="tooltip" title="{{row.accountStatus}}"></span>
                  </td>
                  <td data-toggle="tooltip"
                    style="cursor:pointer;padding-top:6px;padding-bottom:7px;padding-left: 17px;max-width: 140px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;"
                    title="{{row.deviceStatus}}">{{row.deviceStatus}}
                    <span data-toggle="tooltip" title="{{row.deviceStatus}}"></span>
                  </td>
                  <td
                    style="max-width: 140px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;padding-left: 17px;padding-top:6px;padding-bottom:7px">
                    <span data-toggle="tooltip" title="{{row.assetStatus}}">{{row.assetStatus}}</span></td>
                  <td
                    style="white-space: nowrap;padding-left: 17px;padding-top:6px;padding-bottom:7px;">
                    <span data-toggle="tooltip"
                      title="{{row.adminTransactionComments}}">{{row.adminTransactionComments}}</span></td>

                </tr>

              </tbody>
              <tfoot *ngIf="!ImeiModel?.deviceHistoryList.length">
                <tr>
                  <td colspan="15" style="text-align: center;">
                    <b>
                      <p class="no-data">No Data Available</p>
                    </b>
                  </td>
                </tr>
              </tfoot>
            </table>

          </div>
          <p class="history-text">Device Replaced History</p>
          <div class="table-responsive" id="responsivetable">
            <table>
              <thead>
                <tr>
                  <th style="min-width: 250px;" [class.active]="order === 'mobileDeviceID'"
                  (click)="setOrder('mobileDeviceID')">Device ID <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>
                  <th style="min-width: 250px;" [class.active]="order === 'createdBy'"
                  (click)="setOrder('createdBy')">Replaced By <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>
                  <th style="min-width: 330px;" [class.active]="order === 'createdDate'"
                  (click)="setOrder('createdDate')">Date Replaced <img src="assets/images/sorting.svg"
                    class="img-cursor"></th>

                </tr>
              </thead>
              <tbody *ngIf="ImeiModel">
                <tr *ngFor="let row of ImeiModel?.replacedDeviceList | orderBy: order:reverse:true; let i = index">
                  <td
                    style="cursor:pointer;padding-top:6px;padding-bottom:7px;max-width: 250px;padding-left: 17px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">
                    <span data-toggle="tooltip" title="{{row.mobileDeviceID}}">{{row.mobileDeviceID}}</span>
                  </td>
                  <td
                    style="cursor:pointer;padding-top:6px;padding-bottom:7px;max-width: 250px;padding-left: 17px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">
                    <span data-toggle="tooltip" title="{{row.createdBy}}">{{row.createdBy}}</span>
                  </td>
                  <td
                    style="cursor:pointer;padding-top:6px;padding-bottom:7px;max-width: 330px;padding-left: 17px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">
                    <span data-toggle="tooltip" title="{{row.createdDate}}">{{row.createdDate|date}}</span>
                  </td>
                </tr>
              </tbody>
              <tfoot *ngIf="!ImeiModel?.replacedDeviceList.length">
                <tr>
                  <td colspan="15" style="text-align: center;">
                    <b>
                      <p class="no-data">No Data Available</p>
                    </b>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
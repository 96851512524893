<div class="row" >
    <!-- <div class="col-md-1"></div> -->
    <!-- <div class="col-md-12 ml-4" *ngIf="isGridDataNotAvailable">
        <p class="compliance-text">No history found!</p>
    </div> -->
    <div  >
        <div class="mtntable">
            <div class="row">
                <div class="col-md-8">
                    <p class="compliance-text">Mobile Telephone Number</p>
                </div>
               
              
            </div>
            <div class="table-responsive" id="responsivetable">
                <table class="table" id="customers">

                    <thead class="">
                      

                        <tr style="line-height:5px">
                            <th style="min-width:130px;padding-bottom:22px">
                                Device</th>
                            <th style="min-width:111px;padding-bottom:22px">
                                Mobile No</th>
                            <th style="min-width:111px;padding-bottom:22px">
                                Assign to</th>
                            <th  style="min-width:130px;padding-bottom:22px">
                                Distinction</th>
                            <th  style="min-width:130px;padding-bottom:22px">
                                 Carrier</th>
                            <th style="min-width:130px;padding-bottom:22px">IMEI No
                            </th>
                            <th 
                                style="min-width:130px;padding-bottom:22px">Device Location</th>
                            <th  style="min-width:150px;padding-bottom:22px">
                               Device status</th>
                            <th style="min-width:142px;padding-bottom:22px">
                             Account status</th>
                            <th style="min-width:136px;padding-bottom:22px">
                              Modified</th>
                            <th style="min-width:121px;padding-bottom:22px">
                                Modified by</th>
                            <th 
                                style="min-width:145px;padding-bottom:22px">Activation date</th>
                            <th 
                                style="min-width:163px;padding-bottom:22px">Contract start date </th>
                            <th 
                                style="min-width:143px;padding-bottom:22px">Contract end date</th>
                           
                        </tr>
                    </thead>
                    <!-- <tbody *ngIf="isGridDataNotAvailable">
                        <tr>
                            <td colspan="15" style="text-align: center">
                                <p class="compliance-text">No history found!</p>
                            </td>
                        </tr>
                    </tbody> -->
                    <tbody>
                        <tr *ngFor="let x of collection">
                            <td  style="padding-top:7px;padding-bottom:9px;white-space: nowrap;
                            width: 150px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;cursor: pointer;" data-toggle="tooltip" title="{{x.info.name}}">
                                {{x.info.name}}<span data-toggle="tooltip" title="{{x.info.name}}"
                                    ></span></td>
                                    
                                    <!-- <td  style="padding-top:8px;padding-bottom:22px">
                                        {{x.info.alias}}</td> -->

                            <td  style="padding-top:8px;padding-bottom:22px">
                                {{x.info.alias}}</td>
                            <!-- <td  data-toggle="tooltip" title="{{x.info.devicemodel}}"
                                style="width:200px;padding-top:5px;padding-bottom:22px;border-top:0px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block;cursor: pointer;">
                                {{x.info.devicemodel}}<span data-toggle="tooltip" title="{{x.info.devicemodel}}"
                                    ></span></td> -->
                                    <td data-toggle="tooltip"  title="{{x.info.alias}}"  style="padding-top:5px;padding-bottom:22px;border-top:0px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block;cursor: pointer;">
                                        {{x.info.alias}}
                                        <span data-toggle="tooltip" title="{{x.info.devicemodel}}"></span></td>
                            <td  style="padding-top:5px;padding-bottom:22px">
                                {{x.info.name}}</td>
                            <td  style="padding-top:5px;padding-bottom:22px">
                                {{x.info.alias}}</td>
                            <td  style="padding-top:5px;padding-bottom:22px"><a
                                    class="popups" name="imei" id="imei"
                                    >{{x.info.devicemodel }}</a></td>
                            <td  style="width:100px;padding-top:5px;padding-bottom:22px;white-space: nowrap;
                            width: 105px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;cursor: pointer;border-top:0px;" data-toggle="tooltip"
                                title="{{x.info.name}}">{{x.info.name}}<span data-toggle="tooltip"
                                    title="{{x.info.name}}" ></span></td>
                            <td  style="padding-top:5px;padding-bottom:22px">
                                {{x.info.alias}}</td>
                            <td  style="padding-top:5px;padding-bottom:22px">
                                {{x.info.name}}</td>
                            <td  style="padding-top:5px;padding-bottom:22px">
                                {{x.info.name}}</td>
                            <td  data-toggle="tooltip" style="padding-top:5px;padding-bottom:22px;white-space:nowrap;
                            width: 100px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;cursor: pointer;border-top:0px;" title="{{x.info.alias}}">
                                {{x.info.alias}}
                                <span data-toggle="tooltip" title="{{x.info.alias}}" ></span>

                            </td>
                            <td  style="padding-top:5px;padding-bottom:22px">
                                {{x.info.name}}</td>
                            <td 
                                style="padding-top:5px;padding-bottom:22px">
                                {{x.info.alias}}</td>
                            <td 
                                style="padding-top:5px;padding-bottom:22px">
                                {{x.info.devicemodel}}</td>
                         
                           
                        </tr>
                        <tr>


                    </tbody>
                </table>
            </div>

        </div>
      
      
    </div>
</div>
<!-- <app-imei *ngIf="ShowIMEI" [selectedIMEI]="selectedIMEI"></app-imei> -->
<!-- <div *ngIf="spinnerService.showLoaderForGridData">
            <div class="loading">Loading&#8230;</div>
      </div> -->
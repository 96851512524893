<div>
    <form class="emp-profile-sidenav">
      <div>
        <h4 class="emp-legalname"  copyToClipboard copiedString="{{employeeDetails?.legalName}}">{{employeeDetails?.legalName}}</h4>
        <h4 class="vip-text">{{employeeDetails?.jobLevelText}}</h4>
        <p class="email" *ngIf="employeeDetails?.emailAddress != ''"><a href="mailto:{{employeeDetails?.emailAddress}}">{{employeeDetails?.emailAddress}}</a></p>
        <p class="email" *ngIf="employeeDetails?.emailAddress == ''"><a href="mailto:{{employeeDetails?.alias}}@deloitte.com">{{employeeDetails?.alias}}@deloitte.com</a></p>
        <span class="row ml-1">
          <span class="greendot mt-1" *ngIf="activeflag"></span>
          <span class="reddot mt-1" *ngIf="inactiveflag"></span>
          <span class="greydot mt-1" *ngIf="withdrawnflag"></span>
          <span class="ml-2"
            style="font-size:12px">{{employeeDetails?.groupDesc + ' - ' + employeeDetails?.employeeStatus}}</span>
        </span>
  
      </div>
      <div class="side-overlay">
        <div class="controls-border">
          <div class="row controls">
            <!-- <div class="col-md-10"> -->
            <div class="show-pointer" (click)="openRightSideMailsNav()"
              [ngClass]="showNotificationData?'notification-border':''" data-toggle="tooltip" data-placement="bottom"
              title="Notifications">
              <img class="padding-4" src="assets/images/Mail.svg">
              <span class="w3-badge w3-blue">{{notificationCount+laptopNotificationsData?.laptopExceptionResult?.length}}</span>
            </div>
            <div class="show-pointer" (click)="openRightSideNotificationNav()"
              [ngClass]="showViolationData?'violation-border':''" data-toggle="tooltip" data-placement="bottom"
              title="Violations">
              <img class="padding-4" src="assets/images/Notifications.svg">
              <span class="w3-badge w3-pink">{{violationCount+laptopEmpViolationData?.length}}</span>
            </div>
            <div class="show-pointer" (click)="openRightSideCommentsNav()" [ngClass]="showCommentData?'comment-border':''"
              data-toggle="tooltip" data-placement="bottom" title="Comments">
              <img class="padding-4" src="assets/images/Comments.svg">
              <span class="w3-badge w3-green">{{commentCount+laptopCommentCount}}</span>
            </div>
            <div class="show-pointer" (click)="openRightSideExceptionsNav()"
              [ngClass]="showExceptionData?'exception-border':''" data-toggle="tooltip" data-placement="bottom"
              title="Exceptions">
              <img class="padding-4" src="assets/images/Exception.svg">
              <span class="w3-badge w3-yellow">{{exceptionCount+exceptionDataForLaptop?.length}}</span>
            </div>
            <div class="show-pointer plane-img" (click)="openRightSideAirplaneNav()"
              [ngClass]="showTransferData?'history-border':''"  data-toggle="tooltip"  data-placement="bottom"
              title="Transfers"><span class="icon-Plane_1_POS"></span>
              <span class="w3-badge1 w3-blue">{{transferCount}}</span>
            </div>
            <div class="show-pointer plane-img" (click)="openRightSideLoaHistoryNav()"
              [ngClass]="showTransferData?'history-border':''"  data-toggle="tooltip"  data-placement="bottom"
              title="LOA History">
              <span class="icon-Home_house_POS"></span>
              <span class="w3-badge1 w3-pink">{{loaHistoryCount}}</span>
            </div>
            <div class="show-pointer" (click)="openRightSideHistoryNav()" [ngClass]="showHistoryData?'history-border':''" data-toggle="tooltip" data-placement="bottom"
              title="Ticket History">
              <span class="icon-Dice_POS"></span>
              <span class="w3-badge1 w3-green">{{TotalTicketsCount}}</span>
            </div>
          </div>
        </div>
      </div>
      <hr class="hr-empprofile">
      <div class="vip-details-scroll">
        <div class="vip">
          <p class="vip-text" *ngIf="employeeDetails?.vipException != 0">VIP</p>
          <!-- <P class="vip-text">{{employeeDetails?.jobLevelText}}</P> -->
          <p *ngIf="((actionType=='3'&& actionType!='1'&& actionType!='2')|| (!isEligible && actionType!='1'&& actionType!='2') )"
            style="color: red;;">Not
            Eligible for Smartphone</p>
          <p *ngIf="(actionType=='1'||actionType=='2'||(actionType!='3' && isEligible) || ((actionType=='4'||actionType==''||actionType==undefined)&&(isEligible)))"
            style="color:  #43B02A;;">Eligible for Smartphone</p>
          <p *ngIf="actionType=='2'" style="color: rgb(157, 159, 161);">Note: Enabled with permanent ordering.</p>
        </div>
  
        <div class="row ">
          <div class="col-md-4">
            <p class="alias">Personnel No.:</p>
          </div>
          <div class="col-md-8 prexception">
            <p
            copyToClipboard copiedString="{{employeeDetails?.personnelNumber}}"
            [ngStyle]="{'color': 
            (employeeDetails?.isCurrentPersonnelNumber =='Y') ? '#43B02A' : 
            (employeeDetails?.isCurrentPersonnelNumber =='N') ? '#75787B' : ''}"
            class="vip">
              {{employeeDetails?.personnelNumber}}</p>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-4">
            <p class="alias">Personnel ID:</p>
          </div>
          <div class="col-md-8 prexception">
            <p 
            copyToClipboard copiedString="{{employeeDetails?.personId}}"
            [ngStyle]="{'color': 
            (employeeDetails?.isCurrentPersonnelNumber !='Y') ? '#43B02A' : 
            (employeeDetails?.isCurrentPersonnelNumber !='N') ? '#75787B' : ''}"
            class="vip">
              {{employeeDetails?.personId}}</p>
          </div>
        </div>
        <div class="row " *ngIf="withdrawnflag ||inactiveflag">
          <div class="col-md-4">
            <p class="alias">Separation Date:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip">{{employeeDetails?.dateTerminated|date}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p class="alias">Home Office:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.facilityName}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p class="alias">Postal Code:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.facilityPostalCode}}</p>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-4">
            <p class="alias">FSS/Co.Code:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.firmFunctionDesc}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p class="alias">Business Line:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.serviceLineDesc}}</p>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-4">
            <p class="alias">Business Area:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.serviceAreaDesc}}</p>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-4">
            <p class="alias">Region:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.region}}</p>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-4">
            <p class="alias">State:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.facilityState}}</p>
          </div>
        </div>
  
        <div class="row ">
          <div class="col-md-4">
            <p class="alias">Cost Center Code:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.masterCostCenterNumber}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p class="alias">Cost Center Name:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.masterCostCenterDesc}}</p>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-4">
            <p class="alias">Cost Center Lead:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.costCenterLeader}}</p>
          </div>
        </div>
  
        <div class="row ">
          <div class="col-md-4">
            <p class="alias">Employee Sub Group:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.subGroupCode + ' - ' + employeeDetails?.subGroupDesc}}</p>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-4">
            <p class="alias">Office Number:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.deskPhoneNumber}}</p>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-4">
            <p class="alias" style="cursor:pointer">Mobile Number:</p>
          </div>
          <div class="col-md-8 prexception" copyToClipboard copiedString="{{inventoryEmpView?.currentMTN?.mobileTelephoneNbr}}">
            <a class="popup" name="mtn" id="mtn" (click)="mtnmethod(inventoryEmpView?.currentMTN?.mobileTelephoneNbr)">
              {{inventoryEmpView?.currentMTN?.mobileTelephoneNbr}}
            </a>
            <!-- <p class="vip">{{inventoryEmpView?.currentMTN?.mobileTelephoneNbr}}</p> -->
          </div>
        </div>
        <!-- <div class="row ">
          <div class="col-md-4">
            <p class="alias">Admin Assistant:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip"> {{employeeDetails?.adminAsstName}}</p>
          </div>
        </div> -->
        <div class="row ">
          <div class="col-md-4">
            <p class="alias">Assignment Status:</p>
          </div>
          <div class="col-md-8 prexception">
            <p class="vip" *ngIf="assignmentStatus">Yes</p>
            <p class="vip" *ngIf="!assignmentStatus">No</p>
          </div>
        </div>
        <div *ngIf="employeeDetails?.loaIndicator == 'Yes'">
          <h4>LOA</h4>
          <div class="row ">
            <div class="col-md-4">
              <p class="alias">LOA Status:</p>
            </div>
            <div class="col-md-8 prexception">
              <p class="vip"> {{employeeDetails?.loaIndicator}}</p>
              <!-- <p class="vip">No</p> -->
            </div>
          </div>
          <div class="row ">
            <div class="col-md-4">
              <p class="alias">LOA Begin Date:</p>
            </div>
            <div class="col-md-8 prexception">
              <p class="vip">
                {{(employeeDetails?.loaStartDate !== '0001-01-01T00:00:00') ? (employeeDetails?.loaStartDate | date: 'MM/dd/yyyy') : '' }}
              </p>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-4">
              <p class="alias">LOA End Date:</p>
            </div>
            <div class="col-md-8 prexception">
              <p class="vip">
                {{(employeeDetails?.loaEndDate !== '0001-01-01T00:00:00') ? (employeeDetails?.loaEndDate | date: 'MM/dd/yyyy') : '' }}
              </p>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-4">
              <p class="alias">LOA Type:</p>
            </div>
            <div class="col-md-8 prexception">
              <p class="vip"> {{employeeDetails?.loaType}}</p>
            </div>
          </div>
        </div>
  
        <div *ngIf="showTransferDetails">
          <a href="#">
            <h4>Transfer</h4>
          </a>
          <!-- <div class="row ">
        <div class="col-md-8">
          <p class="alias">Postal code:</p>
        </div>
        <div class="col-md-8 prexception">
          <p class="vip"> 500081</p>
        </div>
      </div> -->
          <div class="row ">
            <div class="col-md-4">
              <p class="alias">From City:</p>
            </div>
            <div class="col-md-8 prexception">
              <p class="vip"> {{employeeDetails?.employeeTransferDetails[0]?.officeCity}}</p>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-4">
              <p class="alias">To Country:</p>
            </div>
            <div class="col-md-8 prexception">
              <p class="vip"> {{employeeDetails?.employeeTransferDetails[0]?.toCountry}}</p>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-4">
              <p class="alias">Transfer Type:</p>
            </div>
            <div class="col-md-8 prexception">
              <p class="vip"> {{employeeDetails?.employeeTransferDetails[0]?.transferType}}</p>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-4">
              <p class="alias">Assignment Type:</p>
            </div>
            <div class="col-md-8 prexception">
              <p class="vip"> {{employeeDetails?.employeeTransferDetails[0]?.assignmentType}}</p>
            </div>
          </div>
        </div>
      </div>
  
  
  
  
    </form>
  
    <div class="row" *ngIf="rightNav">
      <app-empgridoverlay [actionTypeValue]="actionTypeValue" [rowObj]="personnelNumber"
        [rightSideNavWidth]="rightSideNavWidth"
        [notificationCount]="notificationCount" [commentCount]="commentCount" [laptopCommentCount]="laptopCommentCount" [LegalName]="LegalName" [Alias]="Alias"
        [violationCount]="violationCount" [exceptionCount]="exceptionCount" [violationDetails]="violationDetails" [notificationDetails]="notificationDetails"
        [exceptionDetails]="exceptionDetails" [exceptionDataForLaptop]="exceptionDataForLaptop" [historyDataByInc]="historyDataByInc" [historyDataByReq]="historyDataByReq" [transferDetails]="transferDetails"
        [loaHistoryDetails]="loaHistoryDetails" [userName]="userName" [transferCount]="transferCount"
        [loaHistoryCount]="loaHistoryCount" [TotalTicketsCount]="TotalTicketsCount" [laptopNotificationsData]="laptopNotificationsData"
        (emitt_CommentCount)="fn_Emitt_CommentCount($event)" (emitt_CommentCount_laptop)="fn_Emitt_CommentCountLaptop($event)" [toggleValueForMobileLaptop]="toggleValueForMobileLaptop" [laptopEmpViolationData]="laptopEmpViolationData" [employeeDetails]="employeeDetails"></app-empgridoverlay>
      <!-- <app-empmtn *ngIf="mtninformation"></app-empmtn> -->
    </div>
  
  </div>
export class InventoryNotification{
  emailAddress: string;
  empName: string;
  carrierCode: string
  notificationcode: string;
  notificationmessage: string;
  mailFrom: string;
  replyTo: string;
  templateBody: string;
  templateSubject: string;
  cc: string;
  bcc: string;
}

export class InsertEmailScheduleInventory {
  emailrepid: number;
  toaddress: string;
  fromaddress: string;
  ccaddress: string;
  bccaddress: string;
  subject: string;
  body: string;
  modulename: string;
  createdate: Date;
  status: boolean;
  createdby: string;
  sentdate: Date;
  batchId: number;
}


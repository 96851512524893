import { Component, Input, OnInit } from '@angular/core';
import { LaptopExceptionResponse } from 'src/app/_models/laptop-compliance/laptopException.model';

@Component({
  selector: 'app-empexceptions',
  templateUrl: './exceptions.component.html',
  styleUrls: ['./exceptions.component.scss']
})
export class ExceptionsComponent implements OnInit {

  @Input() exceptionData: any[];
  @Input() exceptionDataForLaptop: LaptopExceptionResponse[];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    // console.log('0987654567890',this.exceptionData);
    
  }

}

import { Component, OnInit, Input, OnChanges, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
//import { EmpProfileService } from '../emp-profile-service/emp-profile.service';
import { Router } from '@angular/router';
import { CoreEmployeeView } from 'src/app/_models/employee-profile/CoreEmployeeView';
import { InvEmployeeView } from 'src/app/_models/employee-profile/InvEmployeeView';
import { BillingEmployeeView } from 'src/app/_models/employee-profile/BillingEmployeeView';
import { CompEmployeeView } from 'src/app/_models/employee-profile/CompEmployeeView';
import { OrderEmployeeView } from 'src/app/_models/employee-profile/OrderEmployeeView';
import { LoaHistory } from 'src/app/_models/employee-profile/loaHistory.model';
import { EmpIncidentHistory } from 'src/app/_models/employee-profile/EmpIncidentHistory';
import { LaptopEmpTilesData } from 'src/app/_models/employee-profile/laptopEmpTiles.model';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
import { FiltersMenuService } from 'src/app/_services/compliance/filters-menu.service';
import { LaptopCommentsResponse } from 'src/app/_models/laptop-compliance/laptopComment.model';
import { ViolationResponse } from 'src/app/_models/laptop-compliance/laptopViolation.model';
import { LaptopNotificationResponse } from 'src/app/_models/laptop-compliance/laptopNotification.model';
import { LaptopExceptionResponse } from 'src/app/_models/laptop-compliance/laptopException.model';
import { LaptopComplianceService } from 'src/app/_services/laptop-compliance/laptop-compliance.service';
import { GlobalVariables } from 'src/app/_utils/global-variables';

declare var _satellite: any;
declare var window: any;
@Component({
  selector: 'app-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.scss']
})
export class EmployeeProfileComponent implements OnInit, OnChanges, AfterViewInit {
  filter_col = 'col-lg-3 col-md-3 col-sm-3 col-xs-3';
  table_col = 'col-lg-9 col-md-9 col-sm-9 col-xs-9';
  openSideNavEvent: any;
  closeSideNavEvent: any;
  closeFilterNavEvent: any;
  openFilterNavEvent: any;
  selectedMTN: any;
  selectedIMEI: any;
  mtngridinformation: boolean = false;
  imeigridinformation: boolean = false;
  toggleValueForMobileLaptop: boolean = false;
  // violationDetails: ComplianceViolations[];
  // exceptionDetails: ComplianceExceptions[];

  violationDetails = [];
  exceptionDetails = [];
  notificationDetails = [];
  actionType: any;
  totalCommentsCount: number = 0;
  totalViolationCount: number = 0;
  totalExceptionCount: number = 0;
  totalNotificationCount: number = 0;
  employeeDetails: CoreEmployeeView;
  inventoryEmpView: InvEmployeeView;
  billingEmpView: BillingEmployeeView;
  compEmpView: CompEmployeeView;
  ordersEmpView: OrderEmployeeView;
  showTransferDetails: boolean = false;
  commentCount: number = 0;
  notificationCount: number = 0;
  violationCount: number = 0;
  exceptionCount: number = 0;
  billingDetails: any;

  loaHistoryDetails: LoaHistory[];
  transferDetails: any = [];
  historyData: any;
  userName: any;
  historyDataByInc: EmpIncidentHistory[];
  historyDataByReq: EmpIncidentHistory[];
  laptopEmpCommentsData: LaptopCommentsResponse[];

  isEligible: boolean = false;
  ishideToggleEmpChild: boolean;
  empCoreInv: boolean = false;
  moduleName: string = this.filtersMenuService.selectedMainModuleName;

  filterApplied: boolean = false;
  @Input() personnelNumber: string;
  // toggleEventForMobileAndLaptop: any = false;
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];
  laptopTileData: LaptopEmpTilesData[];
  laptopEmpViolationData: ViolationResponse[];
  laptopNotificationsData: LaptopNotificationResponse[];
  laptopCommentCount: number = 0;
  exceptionDataForLaptop: LaptopExceptionResponse[];
  moduleRouterName: boolean;
  @ViewChild('hideToggleEmpChilds') hideToggleEmpChilds: ElementRef<any>;
  constructor(private _empService: EmpProfileService, private filtersMenuService: FiltersMenuService, private laptopComplianceService: LaptopComplianceService, private router: Router) {
    this.moduleRouterName = this.router.url.split('/')[2] === "laptopcompliancesearch" || this.router.url.split('/')[2] === "laptopinventorysearch";
  }

  ngOnInit() {
    // console.log('onInit')
    this.GetEmployeeViewDetails(this.personnelNumber);
    this.GetEmployeeViolationDetails(this.personnelNumber);
    // this.GetLaptopDetails(this.personnelNumber);
    this.GetLaptopEmployeeViolationDetails(this.personnelNumber);
    this.GetEmployeeExceptionDetails(this.personnelNumber);
    this.GetEmployeeNotificationDetails(this.personnelNumber);
    this.GetLoaHistoryDetails(this.personnelNumber);
    this.GetCoreTransferDetails(this.personnelNumber);
    //this.GetEmployeeCommentsDetails(this.personnelNumber);
    GlobalVariables.fn_DatalayerPageLoad("Employee Profile Page");
    if (this.moduleRouterName == false) {
      this.toggleValueForMobileLaptop = false;
    } else {
      this.toggleValueForMobileLaptop = true;
    }

  }
  ngAfterViewInit() {
    if (this.moduleRouterName == false) {
      this.toggleValueForMobileLaptop = false;
    } else {
      this.toggleValueForMobileLaptop = true;
    }
  }
  ngOnChanges() {
    if (this.moduleRouterName == false) {
      this.toggleValueForMobileLaptop = false;
    } else {
      this.toggleValueForMobileLaptop = true;
    }
    // console.log('onChangesFired')
    this.commentCount = 0;
    this.notificationCount = 0;
    this.violationCount = 0;
    this.exceptionCount = 0;
    // this.totalCommentsCount =  0;
    // this.totalViolationCount= 0;
    // this.totalExceptionCount= 0;
    // this.violationDetails = [];
    // this.exceptionDetails = [];
    // this.GetEmployeeViewDetails(this.personnelNumber);
    // this.GetEmployeeViolationDetails(this.personnelNumber);
    // this.GetEmployeeExceptionDetails(this.personnelNumber);
    // this.getAllData(this.personnelNumber);
  }
  toggleForMobile(event) {
    console.log('toggle event', event);
    this.toggleValueForMobileLaptop = event;
  }
  mobileChange() {
    this.toggleValueForMobileLaptop = false;
  }
  laptopChange() {
    this.toggleValueForMobileLaptop = true;
  }
  GetEmployeeViewDetails(personnelNumber: string) {
    this._empService.GetOrdersEmployeeViewDetails(personnelNumber).subscribe(
      (data) => {
        this.ordersEmpView = data;
        if (this.ordersEmpView != null) {
          this.commentCount += +this.ordersEmpView.commentCount;
          // this.notificationCount += +this.ordersEmpView.notificationCount;
        }

        // // console.log(JSON.stringify(this.inventoryEmpView));
      }
    )

    this._empService.GetCoreEmployeeViewDetails(personnelNumber).subscribe(
      (data) => {
        this.employeeDetails = data;
        if(this.employeeDetails.employeeStatus != 'Withdrawn'){
          this.isEligible = this.employeeDetails.isEligibleFromCore;
        }
        this.laptopComplianceService.getComplianceNotificationDetails(0, this.employeeDetails.alias + '@deloitte.com').subscribe((data) => {
          this.laptopNotificationsData = data;
        });
        if (this.employeeDetails != null) {
          this.commentCount += +this.employeeDetails.employeeCommentCount;
          this.exceptionCount += +this.employeeDetails.employeeExceptionCount;
        }
        if (this.employeeDetails != null) {
          if (this.employeeDetails.alias != '') {
            this.fn_GetHistoryData(this.employeeDetails.alias);
          }
        }
        if (this.employeeDetails != null) {
          if (this.employeeDetails.alias != '') {
            this._empService.GetSearchEmployeeToApplyAccess(this.employeeDetails.alias).subscribe(x => {
              if (x && x.length > 0) {
                this.actionType = x[0].actionTypeID;
              } else {
                this.actionType = "";
              }
            });
          }
        }
        this._empService.GetInvEmployeeViewDetails(personnelNumber).subscribe(
          (data) => {
            this.inventoryEmpView = data;
            if (this.inventoryEmpView != null) {
              this.commentCount += this.inventoryEmpView.commentsCount;
              this.exceptionCount += this.inventoryEmpView.exceptionsCount;
              // this.notificationCount += this.inventoryEmpView.notificationCount;
              this.violationCount += this.inventoryEmpView.violationsCount;
              if (this.inventoryEmpView.eligibilityDateString == '' || this.inventoryEmpView.eligibilityDateString == null || this.inventoryEmpView.eligibilityDateString == undefined) {
                this.inventoryEmpView.eligibilityDate = null;
                if(this.inventoryEmpView.totalCount > 0)
                {
                  this.inventoryEmpView.isitsReportWithPriNbrFromInv = false;
                }
              }

              if (this.employeeDetails != null && this.inventoryEmpView != null) {
                this.getEligibilityCoreInv();
              }
             
              if (this.inventoryEmpView.currentMTN != null || this.inventoryEmpView.currentMTN != undefined) {
                this.getMBRDetails();              
              }

             

              // if (this.inventoryEmpView.totalCount > 0) {
              //   //this.inventoryEmpView.eligibilityDate = (this.inventoryEmpView.eligibilityDate == null || this.inventoryEmpView.eligibilityDate == undefined) ? null : new Date(this.inventoryEmpView.eligibilityDate);
              //   if (this.inventoryEmpView.currentMTN == null || this.inventoryEmpView.currentMTN == undefined) {
              //     //this.isEligible = this.employeeDetails.isEligibleFromCore;
              //     if (this.employeeDetails != null && this.inventoryEmpView != null) {
              //       this.getEligibilityCoreInv();
              //     }
              //   }
              //   else {
              //     // scenario - 10: if this.inventoryEmpView.currentMTN.isPersonalDevice then eligible
              //     // scenario - 10: els
              //     if (this.inventoryEmpView['currentMTN']['isPersonalDevice']) {
              //       this.isEligible = true;
              //     } else {
              //       if (this.inventoryEmpView.eligibilityDateString == '' || this.inventoryEmpView.eligibilityDateString == null || this.inventoryEmpView.eligibilityDateString == undefined) {
              //         this.inventoryEmpView.eligibilityDate = null;
              //       }

              //       if (this.employeeDetails != null && this.inventoryEmpView != null) {
              //         this.getEligibilityCoreInv();
              //       }
              //     }
              //     this.getMBRDetails();
              //   }
              // }
              // else {        

              //   if (this.employeeDetails != null && this.inventoryEmpView != null) {
              //     this.getEligibilityCoreInv();
              //   }
                  
              //   //       // // console.log(JSON.stringify(this.inventoryEmpView));
              //   // if(this.employeeDetails.isEligibleFromCore) {
              //   // // Order date < 30 days is eligible -- scenario 8
              //   // // Order date > 30 days it not eligible if no devices found. -- scenario 9
              //   // if (this.ordersEmpView != null && this.ordersEmpView != undefined && this.ordersEmpView.totalCount > 0) {
              //   //   // sort by date ascending order
              //   //   this.ordersEmpView['orderDetails'].sort((a, b) => {
              //   //     const date1: any = new Date(a.orderCreateDate);
              //   //     const date2: any = new Date(b.orderCreateDate);
              //   //     return date1 - date2;
              //   //   })
              //   //   const presentDate: any = new Date();
              //   //   const orderDate: any = new Date(this.ordersEmpView['orderDetails'][0]['orderCreateDate'])
              //   //   const noOfDaysDifference = (presentDate - orderDate) * (1.15741e-8);
              //   //   // if (noOfDaysDifference < 30) {
              //   //   //   this.isEligible = true;
              //   //   // } else {
              //   //     // need to call added by venkat& KC according to requirment
              //   //     const checkEligibility = this.ordersEmpView.orderDetails.some(x => {
              //   //       return x.orderStatus === 'Declined';
              //   //     })
              //   //     if (checkEligibility) {
              //   //       this.isEligible = true;
              //   //     } else {
              //   //       this.isEligible = false;
              //   //     }
              //   //   }
              //   // }
              
              //   // // }
                
              //   // // this._empService.GetSearchEmployeeToApplyAccess(this.employeeDetails.alias).subscribe(x => {
              //   // //   this.actionType = x[0].actionTypeID;
              //   // // });
                
              // }

            }


          }
        )
        // // console.log(JSON.stringify(this.employeeDetails));
      }
    )





    this._empService.GetBillingEmployeeViewDetails(personnelNumber).subscribe(
      (data) => {
        this.billingEmpView = data;
        if (this.billingEmpView != null) {
          this.commentCount += +this.billingEmpView.commentsCount;
          this.exceptionCount += +this.billingEmpView.exceptionsCount;
          // this.notificationCount += +this.billingEmpView.notificationCount;
          this.violationCount += +this.billingEmpView.violationsCount;
        }

        // // console.log(JSON.stringify(this.inventoryEmpView));
      }
    )


    this._empService.GetCompEmployeeViewDetails(personnelNumber).subscribe(
      (data) => {
        this.compEmpView = data;
        if (this.compEmpView != null) {
          this.commentCount += this.compEmpView.commentsCount;
          this.exceptionCount += this.compEmpView.exceptionsCount;
          // this.notificationCount += this.compEmpView.notificationCount;
          this.violationCount += this.compEmpView.violationsCount;
        }
        // // console.log(JSON.stringify(this.inventoryEmpView));
      }
    )


  }

  GetEmployeeViolationDetails(personnelNumber: string) {
    // // console.log('method call - 1');
    this._empService.GetInvViolationDetails(personnelNumber).subscribe(
      (data) => {
        // // console.log('response1', data)
        if (data.length > 0) {
          this.violationDetails = this.violationDetails.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.violationDetails.push(data);
          }
        }

      }
    )
    // this._empService.GetSearchEmployeeToApplyAccess(this.employeeDetails.alias).subscribe(x => {
    //   this.actionType = x[0].actionTypeID;
    // });
    // // console.log('method call - 2');
    this._empService.GetCompViolationDetails(personnelNumber).subscribe(
      (data) => {
        // // console.log('response2', data)
        if (data.length > 0) {
          this.violationDetails = this.violationDetails.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.violationDetails.push(data);
          }
        }

      }
    )
    // // console.log('method call - 3');
    this._empService.GetMBRViolationDetails(personnelNumber).subscribe(
      (data) => {
        // // console.log('response3', data)
        // this.violationDetails = this.violationDetails.concat(MBRDetails);
        if (data.length > 0) {
          this.violationDetails = this.violationDetails.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.violationDetails.push(data);
          }
        }

      }
    )
  }

  GetEmployeeExceptionDetails(personnelNumber: string) {
    // // console.log('method call - 1');
    this._empService.GetInvExceptionDetails(personnelNumber).subscribe(
      (data) => {
        // // console.log('response1', data)
        if (data.length > 0) {
          this.exceptionDetails = this.exceptionDetails.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.exceptionDetails.push(data);
          }
        }
      }
    )

    // // console.log('method call - 2');
    this._empService.GetCompExceptionDetails(personnelNumber).subscribe(
      (data) => {
        // // console.log('response2', data)
        if (data.length > 0) {
          this.exceptionDetails = this.exceptionDetails.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.exceptionDetails.push(data);
          }
        }

      }
    )
    // // console.log('method call - 3');
    this._empService.GetMBRExceptionDetails(personnelNumber).subscribe(
      (data) => {
        // // console.log('response3', data)
        // this.exceptionDetails = this.exceptionDetails.concat(MBRDetails);
        if (data.length > 0) {
          this.exceptionDetails = this.exceptionDetails.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.exceptionDetails.push(data);
          }
        }

      }
    )
    this._empService.GetVIPExceptionDetails(personnelNumber).subscribe(
      (data) => {
        // // console.log('response3', data)
        // this.exceptionDetails = this.exceptionDetails.concat(MBRDetails);
        if (data.length > 0) {
          this.exceptionDetails = this.exceptionDetails.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.exceptionDetails.push(data);
          }
        }

      }
    )
    this.laptopComplianceService.getComplianceExceptions(personnelNumber, 0).subscribe((data) => {
      this.exceptionDataForLaptop = data;
    });
  }


  fn_GetHistoryData(alias: string) {
    this._empService.getHistoryData(alias).subscribe((data) => {
      this.historyData = data;
      this.historyDataByInc = this.historyData.filter(x => x.ticketNumber.startsWith('INC'));
      // console.log('this.historyDataByInc', this.historyDataByInc);
      this.historyDataByReq = this.historyData.filter(x => x.ticketNumber.startsWith('REQ'));
      // console.log('this.historyDataByReq', this.historyDataByReq);
      this.historyData.forEach((user) => {
        this.userName = user.affectedEndUser;
      })
      // console.log('this.historyData', this.historyData);
      // if (this.exceptionData.length != undefined && this.exceptionData != null && this.exceptionData.length > 0) {
      //   this.emitt_ExceptionCount.emit(this.exceptionData.length);
      // }
    });
  }

  GetLoaHistoryDetails(personnelNumber: string) {
    this._empService.GetLOADetails(personnelNumber).subscribe(
      (data) => {
        // console.log("GetLoaHistoryDetails", data);
        this.loaHistoryDetails = data;
      }
    )
  }
  GetCoreTransferDetails(personnelNumber: string) {
    this._empService.GetCoreTransferDetails(personnelNumber).subscribe(
      (data) => {
        // console.log("GetCoreTransferDetails", data);

        this.transferDetails = data;
      }
    )
  }
  getEligibilityCoreInv(): void {
    let val = false;
    if (this.inventoryEmpView != null) {

      this._empService.GetEmployeeEligibilityDetails(this.personnelNumber, this.inventoryEmpView.isitsReportWithPriNbrFromInv, this.employeeDetails.isEligibleFromCore, this.inventoryEmpView.eligibilityDateString).subscribe(
        (data1) => {
          // console.log("data1", data1);
          if (data1 != null) {
            if (this.inventoryEmpView.currentMTN != null && this.inventoryEmpView.currentMTN != undefined) {
              if (this.inventoryEmpView.currentMTN.deviceTypeDesc == 'PDA') {
                val = data1.isEligible;
              }
              else {
                val = data1.isEligibleForDevice;
              }
            }
            else {
              val = data1.isEligible;
            }

            this.isEligible = val;
          }
        }
      )

    }
    this.empCoreInv = false;

  }
  // GetEmployeeLaptopNotificationDetails(violationID,alias) {

  // }
  GetEmployeeNotificationDetails(personnelNumber: string) {
    this._empService.GetInvNotificationsDetails(personnelNumber).subscribe(
      (data) => {
        // console.log("invNotifi", data);
        if (data.length > 0) {
          this.notificationDetails = this.notificationDetails.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.notificationDetails.push(data);
          }
        }


        this.notificationDetails = this.notificationDetails.sort(function (a, b) {
          let dateA = +new Date(a.dateCreated), dateB = +new Date(b.dateCreated)
          return dateB - dateA
        }
        );
        this.notificationCount = this.notificationDetails.length;
      }
    )

    this._empService.GetCompNotificationsDetails(personnelNumber).subscribe(
      (data) => {
        // console.log("compNotifi", data);
        if (data.length > 0) {
          this.notificationDetails = this.notificationDetails.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.notificationDetails.push(data);
          }
        }

        this.notificationDetails = this.notificationDetails.sort(function (a, b) {
          let dateA = +new Date(a.dateCreated), dateB = +new Date(b.dateCreated)
          return dateB - dateA
        }
        );
        this.notificationCount = this.notificationDetails.length;

      }
    )
    this._empService.GetBillingNotificationsDetails(personnelNumber).subscribe(
      (data) => {
        // console.log("billingNotifi", data);
        if (data.length > 0) {
          this.notificationDetails = this.notificationDetails.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.notificationDetails.push(data);
          }
        }

        this.notificationDetails = this.notificationDetails.sort(function (a, b) {
          let dateA = +new Date(a.dateCreated), dateB = +new Date(b.dateCreated)
          return dateB - dateA
        }
        );

        this.notificationCount = this.notificationDetails.length;
      }
    )

    this._empService.GetOrdersNotificationsDetails(personnelNumber).subscribe(
      (data) => {
        console.log("order notification", data);
        if (data.length > 0) {
          this.notificationDetails = this.notificationDetails.concat(data);
        } else {
          if (!Array.isArray(data)) {
            this.notificationDetails.push(data);
          }
        }

        this.notificationDetails = this.notificationDetails.sort(function (a, b) {
          let dateA = +new Date(a.dateCreated), dateB = +new Date(b.dateCreated)
          return dateB - dateA
        }
        );

        this.notificationCount = this.notificationDetails.length;
      }
    )
  }

  getMBRDetails() {
    if (this.inventoryEmpView != null && this.inventoryEmpView != undefined && this.inventoryEmpView.currentMTN != null) {
      this._empService.GetMBRDetails(this.personnelNumber, this.inventoryEmpView.currentMTN.mobileTelephoneNbr, this.inventoryEmpView.currentMTN.carrier, this.inventoryEmpView.isAutoPay).subscribe(x => {
        this.billingDetails = x;
        // // console.log('---------222222222222222222---------',this.billingDetails);
      });
    }
  }
  applyFilterforMbr(event) {
    this.filterApplied = true;
  }
  openSideNav(event) {
    this.openSideNavEvent = event;
    this.filter_col = 'col-lg-3 col-md-3 col-sm-3 col-xs-3';
    this.table_col = 'col-lg-9 col-md-9 col-sm-9 col-xs-9';
  }
  closeSideNav(event) {
    this.closeSideNavEvent = event;
    this.table_col = 'col-lg-9 col-md-9 col-sm-9 col-xs-9';
  }
  CloseFilterNav(event) {
    this.closeFilterNavEvent = event;
    document.getElementById("tableCol").style.marginLeft = "-20%";
    this.table_col = 'col-lg-12 col-md-12 col-sm-12 col-xs-12';
    if (this.openSideNavEvent && this.closeFilterNavEvent) {
      this.table_col = 'col-lg-9 col-md-9 col-sm-9 col-xs-9';
      document.getElementById("tableCol").style.marginLeft = "-20%";
    }
  }
  OpenFilterNav(event) {
    this.openFilterNavEvent = event;
    // document.getElementById("tableCol").style.marginLeft="0px";
    this.table_col = 'col-lg-9 col-md-9 col-sm-9 col-xs-9';
    // document.getElementById("tableCol").style.width="754px";
  }
  openImei(imei) {
    this.selectedIMEI = imei;
    // this.emitIMIE.emit(imei);
    this.imeigridinformation = true;
    this.mtngridinformation = false;

  }
  openMtn(mtn) {
    this.selectedMTN = mtn;
    this.mtngridinformation = true;
    this.imeigridinformation = false;
    // this.emitMTN.emit(mtn);
  }
  GetLaptopEmployeeViolationDetails(personnelNumber) {
    this._empService.GetEmpProfileHeadersForLaptopByPersonnelNbr(personnelNumber).subscribe((data) => {
      this.laptopTileData = data;
    });
    this.laptopComplianceService.getLaptopComments(personnelNumber, "", 0).subscribe((data) => {
      this.laptopEmpCommentsData = data;
      this.laptopCommentCount = this.laptopEmpCommentsData.length;
    });
    this.laptopComplianceService.getComplianceIssues(personnelNumber).subscribe((data) => {
      this.laptopEmpViolationData = data;
    });

  }

  back() {
    this.mtngridinformation = false;
    this.imeigridinformation = false;
  }
  /*fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee Profile Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }*/

  closetilefilternav(event){

  }

  opentilefilternav(event) {
    
  }

  hideToggleEmpChild(event) {
    this.ishideToggleEmpChild = event;
  }

}

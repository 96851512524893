
export class ExceptionModel_DTO {
    exceptioncategoryid: number;
    exceptionviolationid: number;
    expirationdate: Date;
    createdby: string;
    overrideComment: string;
    modifiedby: string;
    personnelalias: string;
    comment: string;
    voilationId: number;
    // PersonID : string;
    // CategoryID : number;
    // ExceptionComment:string;
    // AddedBy:string;
    // ExpirationDate:string;
    // ViolationID:number;
    // RuleID:number;
    // MTN:string;
    // IsOneTimeException:boolean;
    // ComplianceRuleTypeDesc:string;
    // OrderNumber:string;    
}

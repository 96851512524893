<div>
    <div class="modal-header">
      <!-- <div class="row"> -->
        <div class="col-md-12 confirm-box">
          <img class="confirm-image" src="../../../../assets/images/Picture1.png"/>   
        </div>
      <!-- </div> -->
    </div>
    <div class="modal-body">
        
      <p class="prompt-msg">{{prompt}}</p>
    </div>
    <div class="modal-footer">
      <button type="button"
        class="btn btn-outline-cancel"
        (click)="activeModal.close(false)">Cancel</button>
        <!-- <span><img class="confirm-image-cancel" src="../../../../assets/images/Picture3.png"/></span><span class="cancel-text">Cancel</span> -->
      <button type="button"
        class="btn btn-outline-confirm"
        (click)="activeModal.close(1)">Confirm</button>

        <!-- <span><img class="confirm-image-cancel" src="../../../../assets/images/Picture2.png"/></span><span class="confirm-text">Confirm</span> -->
    </div>
</div>
  
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  //   transform(value: any, args?: any): any {

  //       if (!value) return null;
  //       if (!args) return value;
  //       args = args.toLowerCase();
  //       return value.filter(function (item) {
  //         return JSON.stringify(item).toLowerCase().includes(args);
  //       });
  //     } 
  transform(value: any, args?: any): any {
    if (!value) return null;
    if (!args) return value;
    args = args.toLowerCase();

    if (args === 'active' || args === 'inactive') {
      if (args === 'active') {
        return value.filter(x =>  x['isActive'] === true)
      } 
      else {
        return value.filter(x =>  x['isActive'] === false)
      }
    } else {
      return value.filter(function (item) {
        return JSON.stringify(item).toLowerCase().includes(args);
      });
    }
  }

}

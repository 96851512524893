
<div *ngIf="loaHistoryDetails">
    <div class="mt-4 comments-Data" *ngIf="loaHistoryDetails.length !== 0">
        <div class="row">
            <div class="ml-4 past-comment-text">
                <p class="past-comments">LOA History</p>
            </div>
        </div>
        <div class="emp-comment-scroll">
        <div class="card" *ngFor="let loaHistory of loaHistoryDetails;let i=index">
            <div [ngClass]="(i%2===0)?'card-header':'card-body'">
                <div class="issue">
                    <label class="issue-text">LOA Begin Date</label>
                    <p class="issue-data">{{loaHistory.startDate|date}}</p>
                    <label class="issue-text">LOA End Date</label>
                    <p class="issue-data">{{loaHistory.endDate|date}}</p>
                    <!-- <label class="issue-text">LOA Status</label>
                    <p class="issue-data">{{loaHistory.loA_Type}}</p> -->
                    <label class="issue-text">LOA Type</label>
                    <p class="issue-data">{{loaHistory.loA_Type}}</p>
                </div>
            </div>
        </div>
        </div>
        <div class="card" style="visibility: hidden;">
            <div class="card-body">

            </div>
        </div>
    </div>
</div>
<div class="notification-data" *ngIf="loaHistoryDetails === null || loaHistoryDetails === undefined || loaHistoryDetails.length === 0">
    <p class="no-notifications">No Records Found</p>
</div>
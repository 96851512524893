<div id="RightSidenav" class="sidenav">
    <div class="row side-indicators">
        <div class="col-md-8 indicators-text">
            Indicators
        </div>
        <div class="col-md-3 close-icon-overlay">
            <button id="btn_close" type="button" class="close" (click)="closeRightSideNav()">&times;</button>
        </div>
    </div>
    <div class="side-overlay" *ngIf="sideOverlay">

        <div class="controls-border">
            <div class="row controls">
                <!-- <div class="col-md-10"> -->
                <div class="show-pointer" (click)="fn_NavigateChildComponent(1)"
                    [ngClass]="showNotificationsData?'notification-border':''" data-toggle="tooltip"
                    data-placement="bottom" title="Notifications">
                    <img src="assets/images/Mail.svg">
                    <span class="w3-badge w3-blue">{{notificationCount+laptopNotificationsData?.laptopExceptionResult?.length || 0}}</span>
                </div>
                <div class="show-pointer" (click)="fn_NavigateChildComponent(2)"
                    [ngClass]="showViolationsData?'violation-border':''" data-toggle="tooltip" data-placement="bottom"
                    title="Violations">
                    <img src="assets/images/Notifications.svg">
                    <span class="w3-badge w3-pink">{{violationCount+laptopEmpViolationData?.length || 0}}</span>
                </div>
                <div class="show-pointer" (click)="fn_NavigateChildComponent(3)"
                    [ngClass]="showCommentsData?'comment-border':''" data-toggle="tooltip" data-placement="bottom"
                    title="Comments">
                    <img src="assets/images/Comments.svg">
                    <span class="w3-badge w3-green">{{commentCount+laptopCommentCount || 0}}</span>
                </div>
                <div class="show-pointer" (click)="fn_NavigateChildComponent(4)"
                    [ngClass]="showExceptionsData?'exception-border':''" data-toggle="tooltip" data-placement="bottom"
                    title="Exceptions">
                    <img src="assets/images/Exception.svg">
                    <span class="w3-badge w3-yellow">{{exceptionCount+exceptionDataForLaptop?.length||0}}</span>
                </div>
                 <div class="show-pointer" (click)="fn_NavigateChildComponent(5)"
                    [ngClass]="showTransfersData?'history-border':''"  data-toggle="tooltip"  data-placement="bottom"
                    title="Transfers"><span class="icon-Plane_1_POS"></span>
                    <span class="w3-badge1 w3-blue">{{transferCount}}</span>
                </div>
                <div class="show-pointer" (click)="fn_NavigateChildComponent(7)"
                    [ngClass]="showLoaHistoryData?'history-border':''" data-toggle="tooltip" data-placement="bottom"
                    title="LOA History"><span class="icon-Home_house_POS"></span>
                    <span class="w3-badge1 w3-pink">{{loaHistoryCount}}</span>
                </div>
                <div class="show-pointer" (click)="fn_NavigateChildComponent(6)"
                    [ngClass]="showHistoryData?'history-border':''" data-toggle="tooltip" data-placement="bottom"
                    title="Ticket History"><span class="icon-Dice_POS"></span>
                    <span class="w3-badge1 w3-green">{{TotalTicketsCount}}</span>
                </div>
            </div>
        </div>

        <div *ngIf="showCommentsData">
            <app-empcomments [rowObj]="rowObj" (emitt_CommentCount)="fn_Emitt_CommentCount($event)" (emitt_CommentCount_laptop)="fn_Emitt_CommentCount_laptop($event)" [toggleValueForMobileLaptop]="toggleValueForMobileLaptop"></app-empcomments>
        </div>

        <div *ngIf="showViolationsData">
            <app-violations [rowObj]="rowObj" [IsResolve]="IsResolve" [violationDetails]="violationDetails" [laptopEmpViolationData]="laptopEmpViolationData"
                (emitt_CommentCount)="fn_Emitt_CommentCount($event)"
                (submitEventFromCommon)="submitEventFromCommonComp($event)"></app-violations>
        </div>
        <div *ngIf="showNotificationsData">
            <app-notifications [notificationDetails]="notificationDetails" [laptopNotificationsData]="laptopNotificationsData" [employeeDetails]="employeeDetails" (emit_ExceptionCount)="fn_Emitt_ExceptionCount($event)"
                (submitEventFromExceptionComp)="submitEventFromExceptionComp($event)"></app-notifications>
        </div>
        <div *ngIf="showExceptionsData">
            <app-empexceptions [rowObj]="rowObj" (emit_ExceptionCount)="fn_Emitt_ExceptionCount($event)"
                [exceptionData]="exceptionDetails" [exceptionDataForLaptop]="exceptionDataForLaptop"
                (submitEventFromExceptionComp)="submitEventFromExceptionComp($event)"></app-empexceptions>
        </div>
        <div *ngIf="showTransfersData">
            <app-transfers [rowObj]="rowObj"  [transferDetails]="transferDetails"></app-transfers>
        </div>
        <div *ngIf="showLoaHistoryData">
            <app-loa-history [rowObj]="rowObj" [loaHistoryDetails]="loaHistoryDetails"></app-loa-history>
        </div>
        <div *ngIf="showHistoryData">
            <app-empIncHistory [LegalName]="LegalName" [userName]="userName" [historyDataByInc]="historyDataByInc"
            [historyDataByReq]="historyDataByReq"></app-empIncHistory>
        </div>

    </div>

</div>
<div *ngIf="!mtngridinformation&&!imeigridinformation"
    class="col-md-4" style="margin-left:-30px;cursor: pointer;">
    <div (click)="backtoSearch()">
        <img src="assets/images/collapse arrows.svg"><span class="hidedata-text"
            style="font-size:12px;color: #212529;">Back</span>
    </div>
</div>

<div class="mtntable" *ngIf="!mtngridinformation&&!imeigridinformation">
    <div class="row">
        <div class="col-md-8 p-0">
            <p class="compliance-text-current-devices">Current Devices: {{totalnumbercurrent}}</p>
        </div>
        <div class="col-md-4 legend">
            <span *ngIf="pendingReturn">
                <label class="legend-text"><span><i class="fa fa-square c1" aria-hidden="true"></i><span
                            class="pr-pw-text">Pending Return</span></span></label>
            </span>
            <span *ngIf="pendingWipe">
                <label class="legend-text"><span><i class="fa fa-square c2" aria-hidden="true"></i><span
                            class="pr-pw-text">Pending Wipe</span></span></label>
            </span>
        </div>
    </div>
    <div class="row search-filter">
        <div class="pane pane--table2">
            <table class="table-style">
                <thead>
                    <tr>
                        <th style="min-width: 106px">Action</th>
                        <!-- <th style="min-width: 100px"></th> -->
                        <th style="min-width:180px;">

                        </th>
                        <th style="min-width:250px;">
                            Device Name</th>
                         <th style="min-width:180px;">
                                Manufacturer</th>
                        <th style="min-width:150px;">
                            Size</th>
                        <th style="min-width:150px;">
                            Color</th>
                        <!-- <th style="min-width: 70px;"></th> -->
                        <th style="min-width:180px;">
                            IMEI/ ESN</th>
                      <th style="min-width:150px;">
                            Asset Status</th>
                      <th style="min-width:180px;">
                            Expected Return Date</th>
                        <th style="min-width:130px;">Device Carrier</th>
                        <th style="min-width:150px;">
                            Serial Number</th>
                        <th style="min-width:130px;">
                            Mobile No</th>
                           
                        <th style="min-width:90px;">
                        </th>
                        <!-- <th style="min-width:130px;">
                            Device Status</th> -->

                        <!-- <th style="min-width:130px;">Account Status
                        </th> -->
                        <th style="min-width:130px;">MTN Carrier</th>
                        <th style="min-width:175px;">
                            Account No</th>
                        <th style="min-width:160px;">
                            Contract Start Date</th>
                        <th style="min-width:150px;">
                            Contract End Date</th>
                        <th style="min-width:136px;">
                            Eligibility Date</th>
                       <th style="min-width:136px;">
                            Is Excluded Device</th>
                        <!-- <th style="min-width:148px;">
                            Assigned to</th>
                        <th style="min-width:130px;">TSS Rep</th>
                        <th style="min-width:123px;">Modified</th>
                        <th style="min-width:130px;">Modified by</th>
                        <th style="min-width:130px;">
                            Distinction</th>
                        <th style="min-width:70px;"> 
                        </th>-->

                    </tr>
                </thead>

                <tbody *ngIf="totalnumbercurrent>0">
                    <tr *ngFor="let row of currentInventory; index as i"
                        [ngClass]="row.isPendingReturn == 1 ? 'applyBgcolorForPR' : '' || row.showWipeColor == true ? 'applyBgcolorForPW' : ''">
                        <td style="min-width:106px; cursor: pointer;">
                            <span
                                *ngIf="row.isPersonnel==0  && Role != 'CallCenter'  && row.deviceType.toLowerCase() != 'accessory'">
                                <img src="assets/images/return_device.png" class="presenting-mobile" title="Device Return"
                                    (click)="ReturnInventory(row)" data-toggle="modal" data-target="#myModal"
                                    data-backdrop="static" data-keyboard="false">
                                <!-- <app-assigndevice *ngIf="assigndevicebutton" [rowData]="assignDeviceData" [multiRowData]="multiassignDeviceData" ></app-assigndevice> -->

                            </span>
                            <span
                                *ngIf="!row?.isWiped && !row?.isNotWiped && Role != 'CallCenter'  && (row.deviceType.toLowerCase() == 'accessory' || row.deviceType.toLowerCase() == 'pda' || row.deviceType == 'Tablet')">
                                <img src="assets/images/device_wipe.png" class="presenting-mobile" title="Wipe Device"
                                    (click)="WipeInventory(row)" data-toggle="modal" data-target="#myModalhistory"
                                    data-backdrop="static" data-keyboard="false">
                                <!-- <app-assigndevice *ngIf="assigndevicebutton" [rowData]="assignDeviceData" [multiRowData]="multiassignDeviceData" ></app-assigndevice> -->

                            </span>
                        </td>
                        <!-- <td style="min-width:100px; cursor: pointer;">
                             </td> -->
                        <!-- <td style="min-width:106px; cursor: pointer;" *ngIf="row?.isPendingReturn == 1 && row?.isWiped ">
                        </td> -->

                        <td style="min-width:180px;">
                            <span>
                                <img class="padding-4 presenting-mobile" src="assets/images/Presenting.png"
                                    title="Personal Device" *ngIf="row?.isPersonnel == 1">
                            </span>
                            <span>
                                <img class="padding-4 presenting-mobile" src="assets/images/Mobile.png"
                                    title="Blacklisted Device" *ngIf="row?.isBlackListDevice == 1">
                            </span>
                            <span>
                                <img class="padding-4 presenting-mobile" src="assets/images/Wiped.png"
                                    title="Wiped Device" *ngIf="row?.isWiped">
                            </span>
                            <span>
                                <img class="padding-4 presenting-mobile" src="assets/images/Notwiped.png"
                                    title="Not Wiped Device" *ngIf="row?.isNotWiped">
                            </span>
                        </td>
                        <td class="t-{{i}}" style="
                            width: 250px;
                            cursor: pointer;" 
                            copyToClipboard 
                            copiedString="{{row.deviceModel}}" data-toggle="tooltip" title="{{row.deviceModel}}">
                            <span style="
                                white-space: nowrap;
                                overflow: hidden;
                                width: 200px;
                                text-overflow: ellipsis;
                                display: inline-block;
                                vertical-align: middle;">
                            {{ row.deviceModel }}</span>
                        </td>
                            <td style="min-width: 180px;" data-toggle="tooltip" title="{{row.deviceManufacturer}}">
                                {{row.deviceManufacturer}}

                        <td style="min-width: 150px;" data-toggle="tooltip" title="{{row.size}}">
                            {{row.size}}
                        </td>
                        <td style="min-width: 150px;" data-toggle="tooltip" title="{{row.color}}">
                            {{row.color}}
                        </td>
                        <!-- <td style="min-width:70px">
                            <img class="presenting-mobile" src="assets/images/Comments.svg" *ngIf="row.prExceptionGranted">
                        </td> -->

                        <td style="min-width:180px;">
                            <a copyToClipboard copiedString="{{row.mobileDeviceID}}" class="popups" name="imei" id="imei" (click)="imeimethod(row.mobileDeviceID)">
                                {{row.carrierCode.toLowerCase() =="wifi only"?'': row.mobileDeviceID}}
                            </a>
                        </td>
                        <td style="min-width:150px;">                           
                                {{row.assetStatus}}                         
                        </td>
                        <td style="min-width:180px;">                           
                                {{row.expectedReturnDate}}                         
                        </td>
                        <td style="min-width:130px;">
                            {{row.carrierCode.length>0?row.carrierCode:'--'}}</td>
                        <td style="min-width:150px;">
                            <a
                            copyToClipboard copiedString="{{row.serialNumber}}"
                            class="popups" name="imei" id="imei" (click)="imeimethod(row.serialNumber)">
                                {{row.serialNumber}}
                            </a>
                        </td>
                        
                        <td style="min-width:130px;">
                            <a class="popups" copyToClipboard copiedString="{{row.mobileTelephoneNbr}}" name="mtn" id="mtn" (click)="mtnmethod(row.mobileTelephoneNbr)">
                                {{row.mobileTelephoneNbr}}
                            </a>
                        </td>
                        <td style="min-width:90px;">
                            <!-- <img src="assets/images/cancel.png" *ngIf="(row.mtnStatus == 'Cancelled' || row.isMarkedForCancellation)" -->
                            <img src="assets/images/cancel.png" *ngIf="(row.mtnStatus == 'Cancelled')"
                                style="height: 20px; padding-left: 10px;" title="Cancelled">
                            <!-- <img src="assets/images/Suspend_icon.ico" *ngIf=
                            "(row.mtnStatus == 'Suspended' && row.mtnStatus != 'Cancelled' && !row.isMarkedForCancellation)||
                            (row.isMarkedForSuspend && row.mtnStatus != 'Cancelled' && !row.isMarkedForCancellation)" -->
                            <img src="assets/images/Suspend_icon.ico" *ngIf="(row.mtnStatus == 'Suspended')"
                                style="height: 20px; padding-left: 10px;" title="Suspended">                
                        </td>
                        <!-- <td style="min-width:130px;">
                            {{row.deviceStatus.length>0?row.deviceStatus:'--'}}</td> -->

                        <!-- <td style="min-width:130px;">
                            {{row.accountStatus.length>0?row.accountStatus:'--'}}</td> -->
                        <td style="min-width:132px;">
                            {{row.mtnCarrier.length>0?row.mtnCarrier:'--'}}</td>
                        <td style="min-width: 175px; overflow: hidden; text-overflow: ellipsis;">
                            <span 
                            copyToClipboard copiedString="{{row.mobileAccountNbr}}"
                            data-toggle="tooltip" data-placement="top"
                                title="{{row.mobileAccountNbr.length>0?row.mobileAccountNbr:'--'}}">{{row.mobileAccountNbr.length>0?row.mobileAccountNbr:'--'}}</span>
                        </td>
                        <td style="min-width:160px;">
                            {{(row.contractStartDate !== '1900-01-01T00:00:00' && row.contractStartDate !== '0001-01-01T00:00:00') ? (row.contractStartDate | date) : '--' }}
                        </td>
                        <td style="min-width:152px;">
                            {{(row.contractEndDate !== '1900-01-01T00:00:00' && row.contractEndDate !== '0001-01-01T00:00:00') ? (row.contractEndDate | date) : '--' }}
                        </td>
                        <td style="min-width:136px;">
                            {{row.eligibilityDateString.length>0?row.eligibilityDateString:'--'}}</td>
                        <td style="min-width:136px;">
                          {{row.isExcludedDevice===true?'Yes':'No'}}
                        </td>
                        <!-- <td style="white-space: nowrap;
                        width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;margin-top: 8px;
                        display: block;cursor: pointer;" data-toggle="tooltip" title="{{row.assignedTo.length>0?row.assignedTo:'--'}}">
                            {{row.assignedTo.length>0?row.assignedTo:'--'}}
                        </td>
                        <td style="max-width: 130px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;" data-toggle="tooltip" title="{{row.tssRep.length>0?row.tssRep:'--'}}">
                            {{row.tssRep.length>0?row.tssRep:'--'}}</td>
                        <td style="max-width: 123px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;" data-toggle="tooltip" title="{{row.modifiedBy.length>0?row.modifiedBy:'--'}}">
                            {{row.modifiedBy.length>0?row.modifiedBy:'--'}}</td>
                        <td style="min-width:130px;">
                            {{(row.dateModified !== '0001-01-01T00:00:00') ? (row.dateModified | date) : '--' }}</td>
                        <td style="min-width:130px;">
                            {{row.distinction.length>0?row.distinction:'--'}}
                        </td> -->


                        <!-- <td style="min-width:70px;" *ngIf="!row.prExceptionGranted">
                            <img src="assets/images/Comments.svg" (click)="WipeInventory(row)">
                        </td> -->
                        <!-- <td style="min-width:70px;" *ngIf="!row.prExceptionGranted">
                            <img src="assets/images/history.svg"  (click)="ReturnInventory(row)" data-toggle="modal" data-target="#myModal"  data-backdrop="static" data-keyboard="false" >
                        </td>
                        <td style="min-width:70px;" *ngIf="!row.prExceptionGranted">
                            <img src="assets/images/Comments.svg" (click)="WipeInventory(row)" >
                        </td> -->

                    </tr>



                </tbody>
                <tfoot *ngIf="totalnumbercurrent === 0">
                    <tr>
                        <td colspan="15" style="text-align: center;">
                            <b>
                                <p class="no-data">No Data Available</p>
                            </b>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
<div class="mtntable" *ngIf="!mtngridinformation&&!imeigridinformation">
    <div class="row">
        <div class="col-md-8 p-0">
            <p class="compliance-text-current-devices">Previous Devices: {{totalnumberprevious}}</p>
        </div>


    </div>
    <div class="row search-filter">

        <div class="pane pane--table2">

            <table class="table-style">
                <thead>

                    <tr>
                        <th style="min-width:100px;">
                        </th>
                        <th style="min-width:250px;">
                            Device Name</th>
                        <th style="min-width:180px;" >
                            Manufacturer</th>
                        <th style="min-width:150px;">
                            Size</th>
                        <th style="min-width:150px;">
                            Color</th>
                        <!-- <th style="min-width: 70px;"></th> -->
                        <th style="min-width:170px;">
                            IMEI/ ESN</th>
                            <th style="min-width:130px;">Device Carrier</th>
                        <th style="min-width:180px;">
                            Serial Number</th>
                            <th style="min-width:130px;">
                            Mobile No</th>
                        <!-- <th style="min-width:130px;">
                            Device status</th> -->

                        <!-- <th style="min-width:130px;">Account Status
                        </th> -->
                        <th style="min-width:130px;">Carrier</th>

                        <th style="min-width:190px;">
                            Account No</th>
                        <th style="min-width:160px;">
                            Contract Start Date</th>
                        <th style="min-width:150px;">
                            Contract End Date</th>
                        <th style="min-width:136px;">
                            Eligibility Date</th>
                        <!-- <th style="min-width:150px;">
                            Assigned to</th>
                        <th style="min-width:130px;">TSS Rep</th>
                        <th style="min-width:115px;">Modified</th>
                        <th style="min-width:130px;">Modified by</th>
                        <th style="min-width:130px;">
                            Distinction</th> -->
                        <!-- <th style="min-width:70px;">
                        </th>
                        <th style="min-width:70px;">
                        </th> -->
                    </tr>
                </thead>
                <tbody *ngIf="totalnumberprevious>0">
                    <tr *ngFor="let row of previousInventory; index as i">
                        <td style="min-width:100px;">
                            <img class="padding-4 presenting-mobile" src="assets/images/Presenting.png"
                                title="Personal Device" *ngIf="row?.isPersonnel == 1">
                        </td>
                        <td class="t-{{i}}" style="white-space: nowrap;
                            width: 250px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;cursor: pointer;" 
                            copyToClipboard copiedString="{{row.deviceModel}}"
                            data-toggle="tooltip" title="{{row.deviceModel}}">
                            {{ row.deviceModel }}
                            <!-- <img class="padding-4 presenting-mobile" src="assets/images/Comments.svg" *ngIf="row.prExceptionGranted"> -->
                        </td>
                        <td style="min-width:180px; "data-toggle="tooltip" title="{{row.deviceManufacturer}}">
                         {{row.deviceManufacturer}} 
                        </td>
                        <td style="min-width: 150px;" data-toggle="tooltip" title="{{row.size}}">
                            {{row.size}}
                        </td>
                        <td style="min-width: 150px;" data-toggle="tooltip" title="{{row.color}}">
                            {{row.color}}
                        </td>
                        <!-- <td style="min-width:70px">
                            <img  class="padding-4 presenting-mobile" src="assets/images/Comments.svg" *ngIf="row.prExceptionGranted">
                        </td> -->
                        <td style="min-width:170px;">
                            <a 
                            copyToClipboard copiedString="{{row.mobileDeviceID}}"
                            class="popups" name="imei" id="imei" (click)="imeimethod(row.mobileDeviceID)">
                                {{row.carrierCode.toLowerCase() =="wifi only"?'': row.mobileDeviceID}}
                            </a>
                        </td>
                        <td style="min-width:130px;">
                            {{row.carrierCode.length>0?row.carrierCode:'--'}}</td>
                        <td style="min-width:180px;">

                            <a 
                            copyToClipboard copiedString="{{row.serialNumber}}"
                            class="popups" name="imei" id="imei" (click)="imeimethod(row.serialNumber)">
                                {{row.serialNumber}}
                            </a>
                        </td>
                        <td style="min-width:130px;">
                            <a 
                            copyToClipboard copiedString="{{row.mobileTelephoneNbr}}"
                            class="popups" name="mtn" id="mtn" (click)="mtnmethod(row.mobileTelephoneNbr)">
                                {{row.mobileTelephoneNbr}}
                            </a>
                        </td>
                        <!-- <td style="min-width:130px;">
                            {{row.deviceStatus.length>0?row.deviceStatus:'--'}}</td> -->

                        <!-- <td style="min-width:130px;">
                            {{row.accountStatus.length>0?row.accountStatus:'--'}}</td> -->
                        <td style="min-width:130px;">
                            {{row.mtnCarrier.length>0?row.mtnCarrier:'--'}}</td>
                        <td style="min-width:190px;"
                            copyToClipboard copiedString="{{row.mobileAccountNbr}}">
                            {{row.mobileAccountNbr.length>0?row.mobileAccountNbr:'--'}}</td>
                        <td style="min-width:160px;">
                            {{(row.contractStartDate !== '1900-01-01T00:00:00' && row.contractStartDate !== '0001-01-01T00:00:00') ? (row.contractStartDate | date) : '--' }}
                        </td>
                        <td style="min-width:150px;">
                            {{(row.contractEndDate !== '1900-01-01T00:00:00' && row.contractEndDate  !== '0001-01-01T00:00:00') ? (row.contractEndDate | date) : '--' }}
                        </td>
                        <td style="min-width:136px;">
                            {{row.eligibilityDateString.length>0?row.eligibilityDateString:'--'}}</td>
                        <!-- <td style="white-space: nowrap;
                        width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;cursor: pointer;" data-toggle="tooltip" title="{{row.assignedTo.length>0?row.assignedTo:'--'}}">
                            {{row.assignedTo.length>0?row.assignedTo:'--'}}
                        </td>
                        <td style="max-width: 130px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;" data-toggle="tooltip" title="{{row.tssRep.length>0?row.tssRep:'--'}}">
                            {{row.tssRep.length>0?row.tssRep:'--'}}</td>
                        <td style="max-width: 115px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;" data-toggle="tooltip" title="{{row.modifiedBy.length>0?row.modifiedBy:'--'}}">
                            {{row.modifiedBy.length>0?row.modifiedBy:'--'}}</td>
                        <td style="min-width:130px;">
                            {{(row.dateModified !== '0001-01-01T00:00:00') ? (row.dateModified | date) : '--' }}
                        </td>
                        <td style="min-width:130px;">
                            {{row.distinction.length>0?row.distinction:'--'}}
                        </td> -->

                        <!-- <td style="min-width:70px;" *ngIf="!row.prExceptionGranted">
                            <img src="assets/images/history.svg">
                        </td>
                        <td style="min-width:70px;" *ngIf="!row.prExceptionGranted">
                            <img src="assets/images/Comments.svg">
                        </td> -->

                    </tr>
                    <tr>


                </tbody>
                <tfoot *ngIf="totalnumberprevious === 0">
                    <tr>
                        <td colspan="15" style="text-align: center;">
                            <b>
                                <p class="no-data">No Data Available</p>
                            </b>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
<!-- <app-assigndevice *ngIf="showAssignDevice" [rowData]="assignDeviceData" [multiRowData]="multiassignDeviceData"
    (AssignmentChanged)="deviceAssignmentChanged($event)" [sourceItem]="EMPProfile"></app-assigndevice>

<div style="position: relative;" data-toggle="modal" data-target="#myModalhistory">
    <app-device-wipe [rowData]="assignDeviceData"></app-device-wipe>
</div> -->

<app-mtn *ngIf="mtngridinformation" [imeiFrom]="fromComp"  (backToInventory)="backToInventory()" [selectedMTN]="selectedMTN"></app-mtn>
<app-imei *ngIf="imeigridinformation"  [imeiFrom]="fromComp"  (backToInventory)="backToInventory()" [selectedIMEI]="selectedIMEI"></app-imei>
<app-assigndevice [rowData]="assignDeviceData" [multiRowData]="multiassignDeviceData"
    (AssignmentChanged)="deviceAssignmentChanged($event)" [sourceItem]="'EMPProfile'">
</app-assigndevice>
<app-device-wipe [rowObj]="assignDeviceData" [isUSI]="isUSI" (AssignmentChanged)="deviceAssignmentChanged($event)"></app-device-wipe>

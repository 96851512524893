<section class="employee-profile-violation-comp">
    <!-- <div class="row">
        <div class="col-md-12 compliance-issues-header">
            <p class="compliance-issues">Compliance Issues:</p>
        </div>
        <div class="col-md-11 violation-issue">
            <div class="issue" *ngFor="let violationresult of violationDetails">
                <label class="issue-text">Issue</label>
                <p class="issue-data">{{violationresult.complianceRuleDesc}}</p>
                <label class="issue-text">Since</label>
                <p class="issue-data" *ngIf="violationresult.dateCreated !== ''">{{violationresult.dateCreated|date}}</p>
                <p class="empty-data" *ngIf="violationresult.dateCreated === ''"> -- </p>
            </div>
            <div *ngIf="(!violationDetails) || (violationDetails.length == 0)" class="notification-data">
                No Data Available
            </div>
        </div>
    </div> -->
    <div class="row history-tabs">
        <ul class="col-md-12 nav nav-tabs" role="tablist">
            <li class="col-md-6 nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Mobile Compliance Issues: {{violationDetails.length}}
                    </a>
            </li>
            <li class="col-md-6 nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Laptop Compliance Issues: {{laptopEmpViolationData?.length}}
                    </a>
            </li>
        </ul>
    </div>
    <div class="tab-content">
        <div class="tab-pane active" id="tabs-1" role="tabpanel">
            <div *ngIf="violationDetails">
                <div class="comments-Data" *ngIf="violationDetails.length !== 0">
                    <!-- <div class="row">
                        <div class="ml-4 past-comment-text">
                            <p class="past-comments">Mobile Compliance Issues:</p>
                        </div>
                    </div> -->
                    <div class="emp-comment-scroll tab-violation-laptop">
                        <div class="card" *ngFor="let violationresult of violationDetails;let i=index">
                            <div [ngClass]="(i%2===0)?'card-header':'card-body'">
                                <div class="issue">
                                    <label class="issue-text">Issue</label>
                                    <p class="issue-data">{{violationresult.complianceRuleDesc}}</p>
                                    <label class="issue-text">Since</label>
                                    <p class="issue-data" *ngIf="violationresult.dateCreated !== ''">
                                        {{violationresult.dateCreated|date}}</p>
                                    <p class="empty-data" *ngIf="violationresult.dateCreated === ''"> -- </p>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="visibility: hidden;">
                            <div class="card-body">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="(!violationDetails) || (violationDetails.length == 0)" class="notification-data">
                <p class="no-notifications">No Records Found</p>
            </div>
        </div>
        <div class="tab-pane" id="tabs-2" role="tabpanel">
            <div *ngIf="laptopEmpViolationData">
                <div class="comments-Data" *ngIf="laptopEmpViolationData.length !== 0">
                    <!-- <div class="row">
                        <div class="ml-4 past-comment-text">
                            <p class="past-comments">Laptop Compliance Issues:</p>
                        </div>
                    </div> -->
                    <div class="emp-comment-scroll tab-violation-laptop">
                        <div class="card" *ngFor="let violationresult of laptopEmpViolationData;let i=index">
                            <div [ngClass]="(i%2===0)?'card-header':'card-body'">
                                <div class="issue">
                                    <label class="issue-text">Issue</label>
                                    <p class="issue-data">{{violationresult.complianceRuleDesc}}</p>
                                    <label class="issue-text">Since</label>
                                    <p class="issue-data" *ngIf="violationresult.dateCreated !== ''">
                                        {{violationresult.dateCreated|date}}</p>
                                    <p class="empty-data" *ngIf="violationresult.dateCreated === ''"> -- </p>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="visibility: hidden;">
                            <div class="card-body">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="(!laptopEmpViolationData) || (laptopEmpViolationData.length == 0)" class="notification-data">
                <p class="no-notifications">No Records Found</p>
            </div>
        </div>
    </div>
    </section>
import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { Mtn } from 'src/app/_models/inventory/mtnmodels';
import { InventoryService } from 'src/app/_services/inventory/inventory.service';
import { SpinnerService } from 'src/app/_shared-component/spinner/spinner.service';
import { GlobalVariables } from 'src/app/_utils/global-variables';

@Component({
  selector: 'app-mtn',
  templateUrl: './mtn.component.html',
  styleUrls: ['./mtn.component.scss']
})
export class MtnComponent implements OnInit {
  loggedInUserName: string = GlobalVariables.loggedInUserName;
  showMtn = true;
  @Output() backToInventory = new EventEmitter<any>();
  showBackButon = false
  @Input() imeiFrom: string;  
  Mtnmodel: Mtn[] = [];
  filterdata:boolean=false;
  masterSelected:any;
  mobilenumbervalue:any;
  checkedList: any[];

  carriercheck:boolean=true;  
  devicenamefilter:boolean=true;
  mobilenumbercheck:boolean=true;
  assignedtocheck:boolean=true;
  distinctioncheck:boolean=false;
  isPersonalcheck:boolean=true;
  imeicheck:boolean=true;
  devicelocationcheck:boolean=true;
  devicestatuscheck:boolean=true;
  accountstatuscheck:boolean=true;
  modifiedcheck:boolean=true;
  modifiedbycheck:boolean=true;  
  activationdatecheck:boolean=false;
  contractstartdatecheck:boolean=false;
  contractenddatecheck:boolean=false;
  isGridDataNotAvailable:boolean = false;
  devicefilter:any;
  mobilenumberfilter:any;
  assignedtofilter:any;
  distinctionfilter:any;
  ispersonalfilter:any;
  carrierfilter:any;
  imeinofilter:any;
  devicelocationfilter:any;
  devicestatusfilter:any;
  accountstatusfilter:any;
  modifiedfilter:any;
  modifiedbyfilter:any;
  activationdatefilter:any;
  contractstartdatefilter:any;
  contractenddatefilter:any;
  ShowGrid: boolean = true;
  ShowIMEI: boolean = false;
  selectedIMEI: string;
  order: string = 'deviceModelName';
  reverse: boolean = false;
  @Input() selectedMTN: string;
  showLoaderForGridData: any;
  constructor( 
    private inventoryservice:InventoryService,
     private spinnerService: SpinnerService, 
     private orderPipe: OrderPipe) { 

      this.showLoaderForGridData = spinnerService.showLoaderForGridData;
     }

  ngOnInit() {

    this.devicefilter=true;
    this.mobilenumberfilter = true;
    this.assignedtofilter=true;
    this.distinctionfilter=false;
    this.ispersonalfilter = true;
    this.carrierfilter = true;
    this.imeinofilter=true;
    this.devicelocationfilter=true;
    this.devicestatusfilter=true;
    this.accountstatusfilter=true;
    this.modifiedfilter=true;
    this.modifiedbyfilter=true;
    this.activationdatefilter=false;
    this.contractstartdatefilter=false;
    this.contractenddatefilter=false;

    this.getAllMtnDetails();
    // document.getElementById("responsivetable").style.overflowX="auto";
    // document.getElementById("responsivetable").style.maxWidth="85%";
 
    // this.spinnerService.showLoaderForGridData = true;
    
    GlobalVariables.fn_DatalayerPageLoad('MTN Page');
  }

  getAllMtnDetails() {
    this.inventoryservice.getAllMtnDetails(this.selectedMTN).subscribe((data)=>{
      this.Mtnmodel=data;
      // console.log('mtnnnnnnnnnnnnnnnnnnnnn', this.Mtnmodel);
      if(data.length == 0)
      {
        this.isGridDataNotAvailable = true;
      }else
      {
        this.isGridDataNotAvailable = false;
      }
      
      // this.spinnerService.showLoaderForGridData =false;
      // alert(JSON.stringify(data));
      // // console.log('mtnnnnnnnnnnnnnnnnnnnnnnn', data);
      
    });
  }


  imeimethod(deviceID) {
    this.ShowGrid = false;
    this.selectedIMEI = deviceID;
    this.ShowIMEI = true;
  }

  ngOnChanges() {
    if (this.imeiFrom === 'INV_SEARCH') {
      this.showBackButon = true;
    }
  }
  backtoSearch() {
    this.showMtn = false;
    this.backToInventory.emit()
  }


  addFilters(){
    this.filterdata=true;
  }
  cancelAddFilters(){
    this.filterdata=false;
  }
  setOrder(value: string) {
    this.order = value;
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

  }
  addData(event){
    // document.getElementById("responsivetable").style.width="90%";
    // document.getElementById("responsivetable").style.overflowX="auto";

    this.filterdata=false;

   if(this.devicefilter == false){
     this.devicenamefilter=false;
   }
   else{
     this.devicenamefilter=true;
   }

    if(this.mobilenumberfilter == false) {
      this.mobilenumbercheck = false;
      // document.getElementById("responsivetable").style.overflowX="auto";
      // document.getElementById("responsivetable").style.width="88%";
   
    }
    else{
      this.mobilenumbercheck=true;
    }
    if(this.assignedtofilter == false){
      this.assignedtocheck=false;
  
    }
    else{
      this.assignedtocheck =true;
    }
    if(this.distinctionfilter == false ){
      this.distinctioncheck=false;
    }
    else{
      this.distinctioncheck =true;
    }
    if(this.ispersonalfilter == false ){
      this.isPersonalcheck=false;
    }
    else{
      this.isPersonalcheck =true;
    }

    if(this.carrierfilter == false){
      this.carriercheck = false;
    }
    else{
      this.carriercheck =true;
    }
    if(this.imeinofilter == false) {
      this.imeicheck =false;
    }
    else{
      this.imeicheck =true;
    }
    if(this.devicelocationfilter ==false){
      this.devicelocationcheck =false;
    }
    else{
      this.devicelocationcheck =true;
    }
    if(this.devicestatusfilter ==false){
      this.devicestatuscheck =false;

    }
    else{
    this.devicestatuscheck=true;
    }
    if(this.accountstatusfilter == false){
      this.accountstatuscheck =false;
    }
    else{
      this.accountstatuscheck =true;
    }
    if(this.modifiedfilter ==false) {
      this.modifiedcheck=false;
    }
    else{
      this.modifiedcheck=true;
    }
    if(this.modifiedbyfilter == false ){
      this.modifiedbycheck = false;
    }
    else{
      this.modifiedbycheck=true;
    }
    if(this.activationdatefilter ==false){
      this.activationdatecheck = false;
    }
    else{
      this.activationdatecheck=true;
    }
    if(this.contractstartdatefilter == false){
      this.contractstartdatecheck =false;
    }
    else{
      this.contractstartdatecheck=true;
    }
    if(this.contractenddatefilter ==false){
      this.contractenddatecheck =false;

    }
    else{
      this.contractenddatecheck=true;
    }

  
    

    



  }

}

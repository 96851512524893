<div class="row history-tabs">
    <ul class="col-md-12 nav nav-tabs" role="tablist">
        <li class="col-md-6 nav-item">
            <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Request History ({{historyDataByReq?.length}})</a>
        </li>
        <li class="col-md-6 nav-item">
            <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Incident History ({{historyDataByInc?.length}})</a>
        </li>
    </ul>
</div> 
<!-- <div class="emp-comment-scroll-req"> -->
<div class="tab-content">
    <div class="tab-pane active" id="tabs-1" role="tabpanel">
        <div *ngIf="historyDataByReq">
            <div class="history-Data" *ngIf="historyDataByReq.length > 0">
                <div class="row">
                    <div class="col-md-12 request-his">
                        <p class="req-history">Request History for: {{userName}}</p>

                    </div>
                </div>
                <!-- <div class="emp-comment-scroll"> -->
                <div class="emp-comment-scroll-req">
                    <div class="card" *ngFor="let historydatareq of historyDataByReq;let i=index">
                        <div [ngClass]="(i%2===0)?'card-header':'card-body'">
                            <div class="issue">
                                <label class="issue-text">Request#</label>
                                <p class="issue-data"><a class="popups" [href]="historydatareq?.redirectUrl"
                                        target="_blank">{{historydatareq.ticketNumber}}</a></p>
                                <label class="issue-text">Request State</label>
                                <p class="issue-data">{{historydatareq.ticketStatus}}</p>
                                <label class="issue-text">OpenDate</label>
                                <p class="issue-data">{{historydatareq.sys_Created_On | date:'medium'}}</p>
                                <label class="issue-text">Assignee</label>
                                <p class="issue-data">{{historydatareq.assignee_To}}</p>
                                <label class="issue-text">Short Description</label>
                                <p class="issue-data">{{historydatareq.short_Description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="incident-history-Data" *ngIf="historyDataByReq.length=== 0">
            Request History is not present for {{userName}}
        </div>
    </div>
    <!-- <div class="card" style="visibility: hidden;">
        <div class="card-body">

        </div>
    </div> -->
    <!-- </div> -->
    <div class="tab-pane" id="tabs-2" role="tabpanel">
        <div *ngIf="historyDataByInc">
            <div class="history-Data" *ngIf="historyDataByInc.length > 0">
                <div class="row">
                    <div class="col-md-12 request-his">
                        <p class="req-history">Incident History for: {{userName}}</p>

                    </div>
                </div>
                <div class="emp-comment-scroll-req">
                    <div class="card" *ngFor="let historydatainc of historyDataByInc;let i=index">
                        <div [ngClass]="(i%2===0)?'card-header':'card-body'">
                            <div class="issue">
                                <label class="issue-text">Incident#</label>
                                <p class="issue-data"><a class="popups" [href]="historydatainc?.redirectUrl"
                                        target="_blank">{{historydatainc.ticketNumber}}</a></p>
                                <label class="issue-text">Incident State</label>
                                <p class="issue-data">{{historydatainc.ticketStatus}}</p>
                                <label class="issue-text">OpenDate</label>
                                <p class="issue-data">{{historydatainc.sys_Created_On | date:'medium'}}</p>
                                <label class="issue-text">Assignee</label>
                                <p class="issue-data">{{historydatainc.assignee_To}}</p>
                                <label class="issue-text">Short Description</label>
                                <p class="issue-data">{{historydatainc.short_Description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="incident-history-Data" *ngIf="historyDataByInc.length=== 0">
                Incident History is not present for {{userName}}
            </div>
            <div class="card" style="visibility: hidden;">
                <div class="card-body">

                </div>
            </div>
        </div>
    </div>
</div>
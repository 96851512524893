import { Injectable } from '@angular/core'
import { HttpClient, HttpBackend } from '@angular/common/http';
import { IAppConfig } from './AppConfig.model';


@Injectable()
export class AppConfig {
    static settings: IAppConfig;
    _http: HttpClient
    constructor(http: HttpClient, handler: HttpBackend) {
        this._http = new HttpClient(handler);
    }
    load() {
        const jsonFile = `config.json`;
        return new Promise<void>((resolve, reject) => {
            this._http.get(jsonFile).toPromise().then((response : IAppConfig) => {
               AppConfig.settings = <IAppConfig>response;
               resolve();
               //console.log(response);
            }).catch((response: any) => {
               reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }

    public refrshAppSettings()
    {
        if(AppConfig.settings == null || AppConfig.settings == undefined)
        {
            this.load();
        }
    }
}
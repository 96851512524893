import { Component, OnInit, Input } from '@angular/core';
import $ from 'jquery';
import { LaptopDeviceHistoryService } from 'src/app/_services/laptop-device-history.service';
import { LaptopDeviceHistoryReq, LaptopDeviceHistoryResponse } from 'src/app/_models/laptop-device-history.model';

@Component({
  selector: 'app-laptop-device-history',
  templateUrl: './laptop-device-history.component.html',
  styleUrls: ['./laptop-device-history.component.scss']
})
export class LaptopDeviceHistoryComponent implements OnInit {
  @Input() selectedSerialNumber: any;
  @Input() sourceType: string;
  isSidenavOpen: any= false;
  scrollHeight:boolean=false;
  laptopReqData = new LaptopDeviceHistoryReq();
  laptopHisData: LaptopDeviceHistoryResponse[];
  constructor(private laptopDeviceHistoryService: LaptopDeviceHistoryService) { }

  ngOnInit() {
    $('.pane--table2').scroll(function () {
      $('.pane--table2 table').width($('.pane--table2').width() + $('.pane--table2').scrollLeft());
    });
    // console.log('selected serial number in laptop-device-history', this.selectedSerialNumber);
    this.laptopReqData.startNo = 1;
    this.laptopReqData.endNo = 10;
    this.laptopReqData.orderby = '';
    this.laptopReqData.personnelNbr = '';
    this.laptopReqData.serialNumber = this.selectedSerialNumber;
    this.laptopDeviceHistoryService.getLaptopInventoryDeviceHistory(this.laptopReqData).subscribe((data) => {
      this.laptopHisData = data;
      // console.log('laptop-device-history data', data);
    })
  }
  get selectedHisData(){
    return this.laptopHisData? this.laptopHisData[0] : undefined;
  }
  checkSize() {
    //     if (this.itemsPerPage <= 10) {
    //       if (window.devicePixelRatio <= 1) {
    //         this.scrollHeight = false;
    //       } else {
    //         this.scrollHeight = true;
    //       }
    //     }else if(this.itemsPerPage <= 25){
    //       if (window.devicePixelRatio <= 0.5) {
    //         this.scrollHeight = false;
    //       } else {
    //         this.scrollHeight = true;
    //       }
    //     }
      }
    checkTableZoom() {
      // console.log(window.devicePixelRatio, this.isSidenavOpen)
      if (!this.isSidenavOpen) {
        if (window.devicePixelRatio <= 1) {
          return true;
        } else {
          return false
        }
      } else {
        if (window.devicePixelRatio < 1.5) {
          return true;
        } else {
          return false
        }
      }
  
    } 
}

export class ComplianceModel {
    complianceIssue?: (ComplianceIssueEntity)[] | null;
    compRegion?: (CompRegionEntity)[] | null;
    compFacility?: (CompFacilityEntity)[] | null;
    category?: (CategoryEntity)[] | null;
    exceptionSubCategory?: (ExceptionSubCategoryEntity)[] | null;
  }
  export class ComplianceIssueEntity {
    complianceIssueID: string;
    complianceIssueDesc: string;
  }
  export class CompRegionEntity {
    regionID: string;
    regionName: string;
  }
  export class CompFacilityEntity {
    facilityCode: string;
    facilityName: string;
  }
  export class CategoryEntity {
    categoryID: string;
    categoryDesc: string;
  }
  export class ExceptionSubCategoryEntity {
    categoryID: string;
    categoryDesc: string;
    isOneTimeException: boolean;
    exceptionCategoryID: number;
  }
  //filtered data model
    export interface Employee {
        careerLevel: string;
        companyCode: string;
        groupCode: string;
        groupDesc: string;
        firmFunction: string;
        hrRegion: string;
        isExpat: boolean;
        isPPD: boolean;
        loaIndicator: string;
        isInpatORLTA: boolean;
        jobLevelCode: string;
        jobLevelText: string;
        jobLevelAbbreviation: string;
        loaEndDate: Date;
        loaStartDate: Date;
        loAtype: string;
        hireDate: Date;
        terminatedDate: Date;
        masterCostCenterNumber: string;
        masterCostCenterDesc: string;
        region: string;
        serviceAreaCode: string;
        serviceAreaDesc: string;
        serviceLineCode: string;
        serviceLineDesc: string;
        subGroupCode: string;
        subGroupDesc: string;
        employeeStatus: number;
        isEligible: boolean;
        isVIPLeader: number;
        isEligibleForDevice: boolean;
        personnelNumber: string;
        isCurrentPersonnelNumber: boolean;
        isIntern: boolean;
        isEmployeeAtHomeOfficeWithPW: boolean;
        facilityName: string;
        costCenterLeader: string;
        lastDayWorked: Date;
        deviceMTN: string;
        employeeStatusString: string;
        isVoluntaryCancel: boolean;
        isMarkedForSuspend: string;
        isMarkedForCancellation: string;
        isPermanentOrdering: boolean;
        vipException: boolean;
        lastDayWorkedString: string;
        loaEndDateString: string;
        loaStartDateString: string;
        terminatedDateString: string;
        hireDateString: string;
        separationType: string;
        facilityState: string;
        facilityPostalCode: string;
        employeeCommentCount: string;
        adminAssistant: string;
        empStatus: string;
        accessTypeDescription: string;
        actionTypeID: string;
        firstName: string;
        lastName: string;
        middleName: string;
        alias: string;
        personId: string;
        priorName: string;
        preferredName: string;
        legalName: string;
        email: string;
        personalEmail: string;
        fullName: string;
    }

    export interface ServerSync {
        syncType: number;
        lastSyncDate: Date;
        agent: string;
        device: string;
        mtn: string;
        isPersonalMTN: boolean;
        besLastSynchDate: Date;
        easLastSynchDate: Date;
        besReportUploadDate: Date;
        easReportUploadDate: Date;
        besPolicyAppliedDate: Date;
        easPolicyAppliedDate: Date;
        besPolicyUpdatedTime: Date;
        easPolicyUpdatedTime: Date;
        besMobileNo: string;
        easMobileNo: string;
        besDevice: string;
        easDevice: string;
        besUserAgent: string;
        easUserAgent: string;
        besDevicePolicyApplied: string;
        easDevicePolicyApplied: string;
        intuneLastHeartbeat: Date;
        intunePhoneNumber: string;
        intuneDeviceModel: string;
        intuneIMEI: string;
        intuneLastPolicyRequest: Date;
        intuneOperatingSystem: string;
        intuneClientVersion: string;
        intuneSerialNumber: string;
        complianceStatus: string;
        intuneSubscriberCarrierNetwork: string;
        intuneLastHeartbeatString: string;
        intuneLastPolicyRequestString: string;
        userName: string;
        fullName: string;
        besDeviceIdentifier: string;
        easDeviceIdentifier: string;
        besDeviceModel: string;
        easDeviceModel: string;
        besNetworkType: string;
        easNetworkType: string;
        besimei: string;
        easimei: string;
        besOperatingSystem: string;
        easOperatingSystem: string;
        besLastSynchString: string;
        easLastSynchString: string;
        besReportUploadString: string;
        easReportUploadString: string;
        lastSyncDateString: string;
        easPolicyUpdatedTimeString: string;
        besPolicyUpdatedTimeString: string;
        easPolicyAppliedDateString: string;
        besPolicyAppliedDateString: string;
    }

    export interface PrException {
        mobileDeviceID: string;
        model: string;
        createdDate: Date;
        createdBy: string;
        dateBegin: Date;
        dateEnd: Date;
        dateEndString: string;
    }

    export interface RootObject {
        employee: Employee;
        lastNotifiedDate: Date;
        exceptionCount: number;
        notificationCount: number;
        commentsCount: number;
        complianceIssueCount: number;
        totalResultCount: string;
        maxNotifications: number;
        usersNoOfNotification: number;
        exceptionSubCategoryDesc: string;
        isMaxNotification: string;
        resolvedViolationCount: number;
        hasOverride: number;
        carrierCode: string;
        lastNotifiedDateString: string;
        violation: number;
        serverSync: ServerSync;
        isExempted: boolean;
        violationID: number;
        isCleared: boolean;
        isActive: boolean;
        sdTicketNumber: string;
        reason: string;
        complianceRuleDesc: string;
        userAgent: string;
        deviceType: string;
        addedBy: string;
        deviceManufacturer: string;
        deviceModelName: string;
        mobileDeviceID: string;
        dateEnd: Date;
        dateBegin: Date;
        dateCreated: Date;
        lastSyncDate: Date;
        prException: PrException;
        violationString: string;
        dateEndString: string;
        dateBeginString: string;
        lastSyncDateString: string;
    }
    export class EmpStatusModel {
      employeeStatusCode: number;
      employeeStatusDesc: string;
  }
  export class MtnStatusModel {
    mtnStatusID: number;
    mtnStatus: string;
}
export class Offices {
  facilityCode: string;
  facilityName: string;
}
export class DeviceModelCompliance {
    deviceTypeID:number;
    deviceTypeDesc: string;
    devicetype: string;
    smallImageURL: string;
    largeImageURL: string;
    createdBy: string;
    modifiedBy: string;
    isActive: boolean;
    isInUse: boolean;

}
export class region{
  facilityRegionCode:string;
  facilityRegionMediumText:string;
}
export class Office{
  facilityCode:string;
  facilityName:string;
  facilityRegionMediumText:string;
}

export class ProcessType {
  processTypeId: number;
  processTypeName: string;
}
export class ToLocation {
  locationId: number;
  locationName: string;
}



import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import $ from 'jquery';
import { Router } from '@angular/router';
import { EmployeeDetails } from 'src/app/_models/peoplePicker.model';
import { PeoplePickerService } from 'src/app/_services/people-picker.service';
import { SpinnerService } from 'src/app/_shared-component/spinner/spinner.service';
import {takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoggedInUserModulesService } from 'src/app/_services/login.service';
declare var _satellite: any;
declare var window: any;
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  selectedAliasName: any;
  employeeinformation: boolean = false;
  personnelNo: string = '';
  showEmpTable: boolean;
  employeeData: EmployeeDetails[];
  personnelNumberOfEmp: any;
  enableSearchButton: any;
  inputItemData: any;
  backToHomeEvent: any;
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];
  showPage: boolean= false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private peoplePickerService: PeoplePickerService,private loginsvc : LoggedInUserModulesService, private router: Router, private spinnerService: SpinnerService) { }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit() {
    this.loginsvc.isAuthorized$.pipe(takeUntil(this._destroying$)).subscribe((data:any) => {
      this.showPage = data;
    })
    this.fn_DatalayerPageLoad();
    this.peoplePickerService.backTOSearch.subscribe(res=> {
      if(res !== null){
        this.employeeinformation = false;
      }
    })
  }
  selectedAlias(alias) {
    this.selectedAliasName = alias;
  }
  personnelNumber(personnelNumberOfEmp) {
    this.personnelNumberOfEmp = personnelNumberOfEmp;
    this.employeeData = [];
    this.spinnerService.showLoaderForGridData = true;
    this.showEmpTable = true;
    this.peoplePickerService.getEmployeeDetails(this.personnelNumberOfEmp).subscribe((employeeData) => {
      this.spinnerService.showLoaderForGridData = false;
      this.employeeData = employeeData;
      if (this.employeeData && this.employeeData.length == 1) {
        this.showEmpTable = false;
        this.personnelNo = this.employeeData[0].personnelNumber;
        this.employeeinformation=true;
      }
      // console.log('employeeData from landing-page', this.employeeData);
    })
  }
  inputItem(event) {

    this.inputItemData = event;
    if (event) {
      this.enableSearchButton = true;
    } else if (event == '') {
      this.enableSearchButton = false;
    }
    else {
      this.enableSearchButton = false;
    }
  }
  // searchEmp() {
  //   this.employeeData = [];
  //   this.spinnerService.showLoaderForGridData = true;
  //   this.showEmpTable = true;
  //   this.peoplePickerService.getEmployeeDetails(this.personnelNumberOfEmp).subscribe((employeeData) => {
  //     this.spinnerService.showLoaderForGridData = false;
  //     this.employeeData = employeeData;
  //     if (this.employeeData && this.employeeData.length == 1) {
  //       this.showEmpTable = false;
  //       this.personnelNo = this.employeeData[0].personnelNumber;
  //       this.employeeinformation=true;
  //     }
  //     // console.log('employeeData from landing-page', this.employeeData);
  //   })
  // }
  backToHome(event) {
    this.showEmpTable = false;
    $('#peoplePickerInput').val('').change();
    //$('#laning_page_submit').prop("disabled", "disabled");
    $('.combobox-options').hide();
  }

  backToSearch() {
    this.employeeinformation = false;
  }

  // empProfile(event) {
  //   if (event != "") {
  //     this.personnelNo = event;
  //     this.employeeinformation = true;
  //     this.showEmpTable = false;
  //   } else {
  //     this.employeeinformation = false;
  //     this.showEmpTable = true;
  //   }
    
  // }
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Landing Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ComplianceComments } from 'src/app/_models/employee-profile/ComplianceComments';
import { ComplianceExceptions } from 'src/app/_models/employee-profile/ComplianceExceptions';
import { ComplianceViolations } from 'src/app/_models/employee-profile/ComplianceViolations';
import { CoreEmployeeView } from 'src/app/_models/employee-profile/CoreEmployeeView';
import { EmpIncidentHistory } from 'src/app/_models/employee-profile/EmpIncidentHistory';
import { InvEmployeeView } from 'src/app/_models/employee-profile/InvEmployeeView';
import { LoaHistory } from 'src/app/_models/employee-profile/loaHistory.model';
import { Notifications } from 'src/app/_models/employee-profile/Notifications';
import { LaptopCommentsResponse } from 'src/app/_models/laptop-compliance/laptopComment.model';
import { LaptopExceptionResponse } from 'src/app/_models/laptop-compliance/laptopException.model';
import { LaptopNotificationResponse } from 'src/app/_models/laptop-compliance/laptopNotification.model';
import { ViolationResponse } from 'src/app/_models/laptop-compliance/laptopViolation.model';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
declare var _satellite: any;
declare var window: any;
import $ from 'jquery';

@Component({
  selector: 'app-empsidenav',
  templateUrl: './empsidenav.component.html',
  styleUrls: ['./empsidenav.component.scss']
})
export class EmpsidenavComponent implements OnInit, OnChanges {

  rightNav: boolean = false;
  actionTypeValue: number = 0;
  activeflag: boolean = true;
  inactiveflag: boolean = false;
  withdrawnflag: boolean = false;
  show: boolean = true;
  mtninformation: boolean = false;
  LegalName: string;
  Alias: string;
  @Input() actionType: any;
  @Input() employeeDetails: CoreEmployeeView;
  // inventoryEmpView: InvEmployeeView;
  // billingEmpView: BillingEmployeeView;
  // compEmpView: CompEmployeeView;
  // ordersEmpView: OrderEmployeeView;
  showTransferDetails: boolean = false;
  assignmentStatus: boolean = false;
  @Input() commentCount: number = 0;
  @Input() laptopCommentCount:number = 0;
  @Input() notificationCount: number = 0;
  @Input() violationCount: number = 0;
  @Input() exceptionCount: number = 0;
  @Input() personnelNumber: string;
  @Input() isEligible: boolean = false;
  @Input() violationDetails: ComplianceViolations[];
  @Input() exceptionDetails: ComplianceExceptions[];
  @Input() exceptionDataForLaptop: LaptopExceptionResponse[];
  @Input() commentDetails: ComplianceComments[];
  @Input() inventoryEmpView: InvEmployeeView;
  @Input() notificationDetails: Notifications[];
  @Output() emitIMIE = new EventEmitter<any>();
  @Output() emitMTN = new EventEmitter<any>();
  @Input() toggleValueForMobileLaptop:boolean;
  @Input() laptopEmpViolationData: ViolationResponse[];
  @Input() laptopNotificationsData: any;
  @Input() laptopEmpCommentsData: LaptopCommentsResponse[];
  
  @Input() loaHistoryDetails: LoaHistory[];
  @Input() transferDetails: [];
  @Input() userName: any;
  @Input() historyDataByInc: EmpIncidentHistory[];
  @Input() historyDataByReq: EmpIncidentHistory[];
  rightSideNavWidth: string = "";
  showNotificationData: boolean = false;
  showViolationData: boolean = false;
  showCommentData: boolean = false;
  showExceptionData: boolean = false;
  showTransferData: boolean = false;
  showHistoryData: boolean = false;
  transferCount: number = 0;
  loaHistoryCount: number = 0;
  incTicketsCount: number = 0;
  reqTicketsCount: number = 0;
  TotalTicketsCount: number = 0;


  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];

  constructor(private _empService: EmpProfileService) { }

  ngOnInit() {
    //this.fn_DatalayerPageLoad();

  }
  ngOnChanges() {
    if (this.transferDetails != null) {
      this.transferCount = this.transferDetails.length;
    }
    if (this.loaHistoryDetails != null) {
      this.loaHistoryCount = this.loaHistoryDetails.length;
    }
    if (this.historyDataByInc != null) {
      this.incTicketsCount = this.historyDataByInc.length;
    }
    if (this.historyDataByReq != null) {
      this.reqTicketsCount = this.historyDataByReq.length;
    }
    this.TotalTicketsCount = this.incTicketsCount + this.reqTicketsCount;
    // console.log(this.exceptionDetails);
    if (this.employeeDetails && this.employeeDetails.employeeTransferDetails.length != 0) {
      this.showTransferDetails = true;
      if (this.employeeDetails.employeeTransferDetails[0].assignmentInitiationDate && this.employeeDetails.employeeTransferDetails[0].assignmentEndDate) {
        let date = new Date();
        let sDate = new Date(this.employeeDetails.employeeTransferDetails[0].assignmentInitiationDate);
        let eDate = new Date(this.employeeDetails.employeeTransferDetails[0].assignmentEndDate);
        if (date > sDate && date < eDate) {
          this.assignmentStatus = true;
        } else {
          this.assignmentStatus = false;
        }
      }
    }

    this.violationDetails = this.violationDetails.sort(function (a, b) {
      let dateA = +new Date(a.dateCreated), dateB = +new Date(b.dateCreated)
      return dateB - dateA
    }
    );
    this.exceptionDetails = this.exceptionDetails.sort(function (a, b) {
      let dateA = +new Date(a.addedDate), dateB = +new Date(b.addedDate)
      return dateB - dateA
    }
    );
    if (this.employeeDetails) {
      if (this.employeeDetails.employeeStatus.trim() == "Withdrawn") {
        this.withdrawnflag = true;
        this.activeflag = false;
        this.inactiveflag = false;
      } else if (this.employeeDetails.employeeStatus.trim() == "Separated") {
        this.withdrawnflag = true;
        this.activeflag = false;
        this.inactiveflag = false;
      } else if (this.employeeDetails.employeeStatus.trim() == "Active") {
        this.withdrawnflag = false;
        this.activeflag = true;
        this.inactiveflag = false;
      } else if (this.employeeDetails.employeeStatus.trim() == "Retiree") {
        this.withdrawnflag = false;
        this.activeflag = false;
        this.inactiveflag = true;
      }

    }
    // console.log('-----------------------',this.actionType);
    // // console.log('123456789', this.violationDetails);
    // // console.log('777777777777777777777777777777777', this.exceptionDetails);
  }

  openRightSideMailsNav() {
    this.rightNav = true;
    this.actionTypeValue = 1;
    // // console.log("row data " + this.rowObj);
    // this.rightNavViolationID = event;
    $('#RightSidenav').css('width', '30%');

  }
  openRightSideNotificationNav() {
    this.rightNav = true;
    this.actionTypeValue = 2;
    // // console.log("row data " + this.rowObj);
    // this.rightNavViolationID = event;
    $('#RightSidenav').css('width', '30%');
  }
  openRightSideCommentsNav() {
    this.rightNav = true;
    this.actionTypeValue = 3;
    // // console.log("row data " + this.rowObj);
    // this.rightNavViolationID = event;
    $('#RightSidenav').css('width', '30%');

  }
  openRightSideExceptionsNav() {
    this.rightNav = true;
    this.actionTypeValue = 4;
    // // console.log("row data " + this.rowObj);
    // this.rightNavViolationID = event;
    $('#RightSidenav').css('width', '30%');

  }
  openRightSideHistoryNav() {
    this.rightNav = true;
    this.actionTypeValue = 6;
    this.LegalName = this.employeeDetails.legalName;
    this.Alias = this.employeeDetails.alias;
    $('#RightSidenav').css('width', '30%');

  }
  openRightSideAirplaneNav() {
    this.rightNav = true;
    this.actionTypeValue = 5;
    // // console.log("row data " + this.rowObj);
    // this.rightNavViolationID = event;
    $('#RightSidenav').css('width', '30%');
  }
  openRightSideLoaHistoryNav() {
    this.rightNav = true;
    this.actionTypeValue = 7;
    // // console.log("row data " + this.rowObj);
    // this.rightNavViolationID = event;
    $('#RightSidenav').css('width', '30%');
  }
  mtnwindow() {
    this.mtninformation = true;

  }
  fn_Emitt_CommentCount(value) {
    if (value != null && value != undefined) {
      this.commentCount = value;
    }
  }
  fn_Emitt_CommentCountLaptop(value) {
    if (value != null && value != undefined) {
      this.laptopCommentCount = value;
    }
  }
  imeimethod(imei) {
    // this.selectedIMEI = imei;
    this.emitIMIE.emit(imei);
    // this.imeigridinformation = true;
  }
  mtnmethod(mtn) {
    // this.selectedMTN = mtn;
    // this.mtngridinformation=true;
    this.emitMTN.emit(mtn);
  }
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee Side-Nav Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

}


<section class="laptop-empinventory-comp">
    <div class="col-md-4 back-to-laptopInv">
        <div (click)="backtoSearch()">
            <img src="assets/images/collapse arrows.svg"><span class="hidedata-text"
                style="font-size:12px;color: #212529;">Back</span>
        </div>
    </div>
    <div class="mtntable">
        <div class="row">
            <div class="col-md-8 p-0">
                <p class="compliance-text-current-devices">Current Devices: {{inventoryDetailsForLaptopCurrentDevices?.length}}</p>
            </div>
        </div>
        <div class="row search-filter">
            <div class="pane pane--table2">
                <table class="table-style">
                    <thead>
                        <tr>
                            <th style="min-width: 150px">Model</th>
                            <th style="min-width: 250px">Description</th>
                            <th style="min-width: 150px">Serial Number</th>
                            <th style="min-width: 150px">Machine Name</th>
                            <th style="min-width: 150px">Asset Status</th>
                            <th style="min-width: 150px">Lease End Date</th>
                        </tr>
                    </thead>
                    <tbody  *ngIf="inventoryDetailsForLaptopCurrentDevices?.length !== 0">
                        <tr *ngFor="let inventoryDetailsForLaptop of inventoryDetailsForLaptopCurrentDevices">
                            <td style="min-width: 150px;max-width: 150px;
                            overflow: hidden;word-break: break-word !important;">{{inventoryDetailsForLaptop.model}}</td>
                            <td style="min-width: 250px;max-width: 250px;
                            overflow: hidden;word-break: break-word !important;">{{inventoryDetailsForLaptop.description}}</td>
                             <td style="min-width: 150px;max-width: 150px;
                             overflow: hidden;word-break: break-word !important;">{{inventoryDetailsForLaptop.serialNumber}}</td>
                            <td style="min-width: 150px;max-width: 150px;
                            overflow: hidden;word-break: break-word !important;">{{inventoryDetailsForLaptop.machineName}}</td>
                            <td style="min-width: 150px;max-width: 150px;
                            overflow: hidden;word-break: break-word !important;">{{inventoryDetailsForLaptop.deviceStatusName}}</td>
                            <td style="min-width: 150px;max-width: 150px;
                            overflow: hidden;
                            text-overflow: ellipsis;">{{inventoryDetailsForLaptop.currentEOLDate | date}}</td>
                        </tr>
                    </tbody>
                    <tfoot  *ngIf="inventoryDetailsForLaptopCurrentDevices?.length === 0">
                        <tr>
                            <td colspan="15" style="text-align: center;">
                                <b>
                                    <p class="no-data">No Data Available</p>
                                </b>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
    <div class="mtntable">
        <div class="row">
            <div class="col-md-8 p-0">
                <p class="compliance-text-current-devices">Previous Devices: {{inventoryDetailsForLaptopPreviousDevices?.length}}</p>
            </div>


        </div>
        <div class="row search-filter">

            <div class="pane pane--table2">

                <table class="table-style">
                    <thead>

                        <tr>
                            <th style="min-width: 150px">Model</th>
                            <th style="min-width: 250px">Description</th>
                            <th style="min-width: 150px">Serial Number</th>
                            <th style="min-width: 150px">Machine Name</th>
                            <th style="min-width: 150px">Asset Status</th>
                            <th style="min-width: 150px">Lease End Date</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="inventoryDetailsForLaptopPreviousDevices?.length !== 0">
                        <tr *ngFor="let inventoryDetailsForLaptop of inventoryDetailsForLaptopPreviousDevices">
                            <td style="min-width: 150px;max-width: 150px;
                            overflow: hidden;word-break: break-word !important;">{{inventoryDetailsForLaptop.model}}</td>
                            <td style="min-width: 250px;max-width: 250px;
                            overflow: hidden;word-break: break-word !important;">{{inventoryDetailsForLaptop.description}}</td>
                             <td style="min-width: 150px;max-width: 150px;
                             overflow: hidden;word-break: break-word !important;">{{inventoryDetailsForLaptop.serialNumber}}</td>
                            <td style="min-width: 150px;max-width: 150px;
                            overflow: hidden;word-break: break-word !important;">{{inventoryDetailsForLaptop.machineName}}</td>
                            <td style="min-width: 150px;max-width: 150px;
                            overflow: hidden;word-break: break-word !important;">{{inventoryDetailsForLaptop.deviceStatusName}}</td>
                            <td style="min-width: 150px;max-width: 150px;
                            overflow: hidden;
                            text-overflow: ellipsis;">{{inventoryDetailsForLaptop.currentEOLDate | date}}</td>
                        </tr>
                    </tbody>

                    <tfoot *ngIf="inventoryDetailsForLaptopPreviousDevices?.length == 0">
                        <tr>
                            <td colspan="15" style="text-align: center;">
                                <b>
                                    <p class="no-data">No Data Available</p>
                                </b>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</section>
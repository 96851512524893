import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { Imei } from 'src/app/_models/inventory/imei.model';
import { InventoryService } from 'src/app/_services/inventory/inventory.service';
import { SpinnerService } from 'src/app/_shared-component/spinner/spinner.service';
import { GlobalVariables } from 'src/app/_utils/global-variables';

@Component({
  selector: 'app-imei',
  templateUrl: './imei.component.html',
  styleUrls: ['./imei.component.scss']
})
export class ImeiComponent implements OnInit {

  showImei = true;
  showBackButon = false;
  @Input() imeiFrom: string;
  @Output() backToInventory = new EventEmitter<any>();
  ImeiModel: Imei;
  @Input() selectedIMEI: string;
  order: string = 'assignedTo';
  reverse: boolean = false;
  loggedInUserName: string = GlobalVariables.loggedInUserName;

  constructor(private inventoryservice:InventoryService, 
    private spinnerService: SpinnerService, private orderPipe: OrderPipe) { }
  backtoSearch() {
    this.showImei = false;
    this.backToInventory.emit()
  }

  ngOnChanges() {
    if (this.imeiFrom === 'INV_SEARCH') {
      this.showBackButon = true;
      console.log('-------------------w---------------')
    }
  }
  ngOnInit() {
    // alert(this.selectedIMEI);
    // this.spinnerService.showLoaderForGridData = true;
    this.inventoryservice.getAllImeiDetails(this.selectedIMEI).subscribe((data:any)=>{
      this.ImeiModel=data; 
      this.setOrder('activationDate');
      
    });
    GlobalVariables.fn_DatalayerPageLoad('IMEI Page');
  }
  setOrder(value: string) {
    this.order = value;
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

  }


}

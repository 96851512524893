import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { CoreCommentsModel } from 'src/app/_models/employee-profile/CoreCommentsModel';
import { AssignmentGroupModel } from 'src/app/_models/inventory/assignmentgroupmodels';
import { ServiceNowLookup } from 'src/app/_models/inventory/ServiceNowLookup.model';
import { ServicenowTicketDetails } from 'src/app/_models/inventory/servicenowticketdetails.model';
import { FiltersMenuService } from 'src/app/_services/compliance/filters-menu.service';
import { InventoryService } from 'src/app/_services/inventory/inventory.service';
import { ModalService } from 'src/app/_services/modal.service';
import $ from 'jquery';

@Component({
  selector: 'app-device-wipe',
  templateUrl: './device-wipe.component.html',
  styleUrls: ['./device-wipe.component.scss']
})
export class DeviceWipeComponent implements OnInit, OnChanges {
  wipeform: FormGroup;
  iswipeselected: boolean = true;
  isnotwipeselected: boolean = false;
  @Input() rowObj: any;
  @Input() isUSI:string;
  @Output() AssignmentChanged = new EventEmitter();
  actionType: string = '';
  serviceNowChecklistItems: any[] = [];
  ServiceDeskLookup: ServiceNowLookup[] = [];
  assignmentGroupModeldata: AssignmentGroupModel[] = [];
  optionType: string = '';
  complainceViolationStatus: string = '';
  mobileDeviceHistoryID: string = '';
  ticketNo: string = '';
  loggedinUser: string = '';
  // loggedinUser: 'amlkumar';
  serviceNowTicketDetail: ServicenowTicketDetails;
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];
  constructor(private complianceService: FiltersMenuService,private inventoryservice: InventoryService, private modalService: ModalService, private formBuilder: FormBuilder) {
    this.loggedinUser = localStorage.getItem('loggedInUserName').split('@')[0];
  }

  ngOnInit() {
    if (this.iswipeselected == true) {
      this.wipeform = this.formBuilder.group({
        iswiped: ['WIPED', Validators.required],
        reasonforwipe: ['', Validators.required],
        assignmentGroup: ['', Validators.required],
        mtnstatus: ['', Validators.required],
        email: [''],
        personaldevice: ['', Validators.required],
        remotewipesent: ['', Validators.required],
        comment: ['', Validators.required],
        reasonfornotwiping: [''],
        notwipedmtnstatus: [''],
        notwipedemail: [''],
        notwipedpersonaldevice: [''],
        notwipedcomment: ['']
      });
    } else if (this.isnotwipeselected == true) {
      this.wipeform = this.formBuilder.group({
        iswiped: [''],
        reasonforwipe: [''],
        assignmentGroup: ['', Validators.required],
        mtnstatus: [''],
        email: [''],
        personaldevice: [''],
        // remotewipesent: ['', Validators.required],
        comment: [''],
        reasonfornotwiping: ['', Validators.required],
        notwipedmtnstatus: ['', Validators.required],
        notwipedemail: [''],
        notwipedpersonaldevice: ['', Validators.required],
        notwipedcomment: ['', Validators.required]
      });
    }

    this.LoadAssignmentGroupData();

  }
  ngOnChanges() {
    this.wipeform = this.formBuilder.group({
      iswiped: ['WIPED', Validators.required],
      reasonforwipe: ['', Validators.required],
      assignmentGroup:['',Validators.required],
      mtnstatus: ['', Validators.required],
      email: [''],
      personaldevice: ['', Validators.required],
      remotewipesent: ['', Validators.required],
      comment: ['', Validators.required],
      reasonfornotwiping: [''],
      notwipedmtnstatus: [''],
      notwipedemail: [''],
      notwipedpersonaldevice: [''],
      notwipedcomment: ['']
    });
    this.wipeform.patchValue({ "iswiped": 'WIPED' });
    if (this.wipeform.value.iswiped == "WIPED") {
      this.iswipeselected = true;
    }
    else {
      this.isnotwipeselected = true;
    }

    // this.wipeform.value.iswiped = "WIPED";
    // console.log('22222222222222222222222222222222222', this.rowObj);
  }
  reasonforwiping: any[] = [
    { id: 1, name: 'Separation' },
    { id: 2, name: 'Upgrade' },
    { id: 3, name: 'Compliance Violation - Device will not be used again' },
    { id: 4, name: 'Compliance Violation - Device will be used after wipe' }
  ];
  mtnstatusvalues: any[] = [
    { id: 1, name: 'Cancel the MTN' },
    { id: 2, name: 'Keep MTN active (i.e. device upgrade)' },
    { id: 3, name: "Release MTN to user's ownership" },
    { id: 4, name: "MTN Not Applicable" }
  ];
  ispersonaldevice: any[] = [
    { id: 1, name: 'User states the device is personally owned. Regional AOP Manager must review and document' },
    { id: 2, name: 'Device is deloitte-owned' }
  ];
  isremotewipesent: any[] = [
    { id: 1, name: 'Yes' },
    { id: 2, name: 'No' }
  ];
  reasonfornotwipingValues: any[] = [
    { id: 1, name: 'OGC permanent hold' },
    { id: 2, name: 'SDHD 10 day hold' },
    { id: 3, name: 'Physical damage prevents data wipe' },
    { id: 4, name: 'Software issue prevents data wipe' },
    { id: 5, name: 'User has already wiped device' },
    { id: 6, name: 'Talent/OGC has already wiped device' },
    { id: 7, name: 'Remote Wipe Command has been sent' }
  ];
  checkwipe() {
    if (this.wipeform.value.iswiped == "WIPED") {
      this.wipeform.value.iswiped = "NOTWIPED";
    }
    else {
      this.wipeform.value.iswiped = "WIPED";
    }
    if (this.wipeform.value.iswiped == "WIPED") {
      this.iswipeselected = true;
      this.isnotwipeselected = false;
    }
    else {
      this.iswipeselected = false;
      this.isnotwipeselected = true;
    }

    if (this.iswipeselected == true) {
      this.wipeform = this.formBuilder.group({
        iswiped: ['WIPED', Validators.required],
        reasonforwipe: ['', Validators.required],
        mtnstatus: ['', Validators.required],
        assignmentGroup: ['', Validators.required],
        email: [''],
        personaldevice: ['', Validators.required],
        remotewipesent: ['', Validators.required],
        comment: ['', Validators.required],
        reasonfornotwiping: [''],
        notwipedmtnstatus: [''],
        notwipedemail: [''],
        notwipedpersonaldevice: [''],
        notwipedcomment: ['']
      });
    } else if (this.isnotwipeselected == true) {
      this.wipeform = this.formBuilder.group({
        iswiped: ['NOTWIPED'],
        reasonforwipe: [''],
        assignmentGroup: ['', Validators.required],
        mtnstatus: [''],
        email: [''],
        personaldevice: [''],
        //remotewipesent: ['', Validators.required],
        comment: [''],
        reasonfornotwiping: ['', Validators.required],
        notwipedmtnstatus: ['', Validators.required],
        notwipedemail: [''],
        notwipedpersonaldevice: ['', Validators.required],
        notwipedcomment: ['', Validators.required]
      });
    }
  }
  DeviceWipeSubmit() {
    $(".closeDevice-wipe").click();
    if (this.rowObj.deviceType == "Accessory" && this.wipeform.value.iswiped == "WIPED") {
      this.optionType = 'Accessory Wipe'
      this.complainceViolationStatus = this.wipeform.value.reasonforwipe;
    }
    else if (this.rowObj.deviceType == "Accessory" && this.wipeform.value.iswiped == "NOTWIPED") {
      this.optionType = 'Accessory Not Wiped'
      this.complainceViolationStatus = this.wipeform.value.reasonfornotwiping;
    }
    else if (this.rowObj.deviceType != "Accessory" && this.wipeform.value.iswiped == "WIPED") {
      this.optionType = 'WIPE'
      this.complainceViolationStatus = this.wipeform.value.reasonforwipe;
    }
    else if (this.rowObj.deviceType != "Accessory" && this.wipeform.value.iswiped == "NOTWIPED") {
      this.complainceViolationStatus = this.wipeform.value.reasonfornotwiping;
      this.optionType = 'NOT WIPED'
    }
    this.getRDPDAChecklistValues();
    var strCheckList: string[] = [];
    var strAppendCheckList = '';

    var j = 0;
    for (var i = 0; i <= this.serviceNowChecklistItems.length - 1; i++) {
      if (this.wipeform.value.iswiped == 'WIPED' && this.serviceNowChecklistItems[i].checkListText == 'Reason for Not Wiping Data') {
        continue;
      }

      if (this.wipeform.value.iswiped != 'WIPED' && this.serviceNowChecklistItems[i].checkListText == 'Reason for Wipe') {
        continue;
      }
      strCheckList[j] = this.serviceNowChecklistItems[i].checkListText;
      j++;

      if (this.serviceNowChecklistItems[i].givenValue == "NA")
        strCheckList[j] = "";
      else {
        strCheckList[j] = this.serviceNowChecklistItems[i].givenValue;
        if ((this.wipeform.value.iswiped == 'WIPED' || this.serviceNowChecklistItems[i].checkListText != 'Remote Wipe Sent?') &&
          this.serviceNowChecklistItems[i].checkListText != 'Wipe Status' &&
          this.serviceNowChecklistItems[i].checkListText != 'Mobile Telephone Number' &&
          this.serviceNowChecklistItems[i].checkListText != 'Wireless Carrier' &&
          this.serviceNowChecklistItems[i].checkListText != 'IMEI or ESN Hex Number') {
          strAppendCheckList = strAppendCheckList + this.serviceNowChecklistItems[i].givenValue + ", ";
        }

      }
      j++;

    }

    if (this.wipeform.value.iswiped == 'WIPED') {
      this.actionType = 'wiped';
    }

    if (this.wipeform.value.iswiped == 'NOTWIPED') {
      this.actionType = 'not wiped';
    }

    let DeviceWipeSDTicket = {
      WipeOption: this.rowObj.deviceType == 'Accessory' ? 'Cross-Functional.Hardware.Mobile Email Device.Apple Watch.Wipe' : 'Cross-Functional.Hardware.Mobile Email Device.Wipe',//this.optionType,
      Assignee: this.rowObj.assignedTo,
      AffectedEndUser: this.loggedinUser,//replace this with loggedinsuer
      Priority: '',//DeviceWipeLookUp.d.TicketPriority,
      IncidentArea: '',//this.rowObj.deviceType == 'Accessory' ? 'Cross-Functional.Hardware.Mobile Email Device.Apple Watch.Wipe' : 'Cross-Functional.Hardware.Mobile Email Device.Wipe',
      CallSource: 'Walk-Up',
      Summary: this.wipeform.value.iswiped == 'WIPED' ? this.wipeform.value.comment : this.wipeform.value.notwipedcomment,
      Description: this.wipeform.value.iswiped == 'WIPED' ? this.wipeform.value.comment : this.wipeform.value.notwipedcomment,
      Group: '',
      Comments: this.wipeform.value.iswiped == 'WIPED' ? this.wipeform.value.comment : this.wipeform.value.notwipedcomment,
      DeviceWipeLookUpChkList: strCheckList,
      MobileDeviceID: this.rowObj.deviceID,
      MobileDeviceHistoryID: this.rowObj.mobileDeviceHistoryID,
      Mtn: this.rowObj.mobileNumber,
      Carrier: this.rowObj.carrier,
      DeviceModel: this.rowObj.deviceModelDescription,
      DeviceType: this.rowObj.deviceType,
      TssrepGroup: this.wipeform.value.assignmentGroup,
      Impact: 'Medium',
      Urgency: 'Medium'
    }

    let comments = '';
    if (this.rowObj.deviceType == 'Accessory') {
      if (this.wipeform.value.iswiped == 'WIPED') {
        comments = 'Watch has been wiped and reset to factory defaults OR a Remote Wipe command has been sent - ';
        comments = comments + (this.rowObj.carrier == undefined ? '' : this.rowObj.carrier) + ', ';
        comments = comments + (this.rowObj.deviceID == undefined ? '' : this.rowObj.deviceID) + ', ';
        comments = comments + (this.rowObj.mobileNumber == undefined ? '' : this.rowObj.mobileNumber) + ', ';
        comments = comments + (this.rowObj.deviceModelDescription == undefined ? '' : this.rowObj.deviceModelDescription) + ', ';
      }
      else {
        comments = 'Watch has not been wiped - ';
        comments = comments + (this.rowObj.carrier == undefined ? '' : this.rowObj.carrier) + ', ';
        comments = comments + (this.rowObj.deviceID == undefined ? '' : this.rowObj.deviceID) + ', ';
        comments = comments + (this.rowObj.mobileNumber == undefined ? '' : this.rowObj.mobileNumber) + ', ';
        comments = comments + (this.rowObj.deviceModelDescription == undefined ? '' : this.rowObj.deviceModelDescription) + ', ';
      }
    }
    else {
      if (this.wipeform.value.iswiped == 'WIPED') {
        comments = 'Mobile Device has been wiped and reset to factory defaults OR a Remote Wipe command has been sent - ';
        comments = comments + (this.rowObj.carrier == undefined ? '' : this.rowObj.carrier) + ', ';
        comments = comments + (this.rowObj.deviceID == undefined ? '' : this.rowObj.deviceID) + ', ';
        comments = comments + (this.rowObj.mobileNumber == undefined ? '' : this.rowObj.mobileNumber) + ', ';
        comments = comments + (this.rowObj.deviceModelDescription == undefined ? '' : this.rowObj.deviceModelDescription) + ', ';
      }
      else {
        comments = 'Mobile Device has not been wiped - ';
        comments = comments + (this.rowObj.carrier == undefined ? '' : this.rowObj.carrier) + ', ';
        comments = comments + (this.rowObj.deviceID == undefined ? '' : this.rowObj.deviceID) + ', ';
        comments = comments + (this.rowObj.mobileNumber == undefined ? '' : this.rowObj.mobileNumber) + ', ';
        comments = comments + (this.rowObj.deviceModelDescription == undefined ? '' : this.rowObj.deviceModelDescription) + ', ';
      }
    }

    comments = comments + strAppendCheckList;
    if (DeviceWipeSDTicket.Comments.length > 0) {
      comments = comments + ' - ' + DeviceWipeSDTicket.Comments;
    }

    comments = comments.replace(', ,', ',');
    comments = comments.replace(', - ', ' - ');

    this.serviceNowTicketDetail = new ServicenowTicketDetails();
    this.serviceNowTicketDetail.itemName = DeviceWipeSDTicket.WipeOption;
    this.serviceNowTicketDetail.callSource = DeviceWipeSDTicket.CallSource;
    this.serviceNowTicketDetail.assignee = this.loggedinUser;
    this.serviceNowTicketDetail.requestedFor = this.rowObj.alias;
    this.serviceNowTicketDetail.priority = DeviceWipeSDTicket.Priority;
    this.serviceNowTicketDetail.incidentArea = DeviceWipeSDTicket.IncidentArea;
    this.serviceNowTicketDetail.summary = comments;
    this.serviceNowTicketDetail.description = comments;
    this.serviceNowTicketDetail.group = DeviceWipeSDTicket.TssrepGroup;
    this.serviceNowTicketDetail.comments = comments;
    this.serviceNowTicketDetail.checkListProperties = DeviceWipeSDTicket.DeviceWipeLookUpChkList;
    this.serviceNowTicketDetail.impact = DeviceWipeSDTicket.Impact;
    this.serviceNowTicketDetail.urgency = DeviceWipeSDTicket.Urgency;

    this.serviceNowTicketDetail.shortDescription = comments;
    this.serviceNowTicketDetail.special_Instructions = '';
    this.serviceNowTicketDetail.dueDate = '';
    this.serviceNowTicketDetail.status = '';
    // this.serviceNowTicketDetail.t =  DeviceWipeSDTicket.TssrepGroup;
    //this.serviceNowTicketDetail.callSource = DeviceWipeSDTicket.CallSource;

    this.inventoryservice.UpdateDeviceWipeStatus(this.rowObj.imei, this.rowObj.personnelNbr, this.rowObj.mobileDeviceHistoryID, this.optionType, this.ticketNo, this.rowObj.mobileNumber, DeviceWipeSDTicket.TssrepGroup, this.complainceViolationStatus)
      .subscribe(res => {
        this.mobileDeviceHistoryID = res;
        let CoreComments = new CoreCommentsModel();
        CoreComments.createdBy = this.loggedinUser;
        CoreComments.employeeID = this.rowObj.personnelNbr;
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var hours = today.getHours();
        var minutes = today.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        var min = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + min + ' ' + ampm;
        var textComment = this.GetMonthName(mm) + ' ' + dd + ', ' + yyyy + ' ' + strTime;

        if (this.wipeform.value.iswiped == 'WIPED') {
          CoreComments.comment = `GENERIC COMMENTS - From WIPE: IMEI: ${this.rowObj.imei} is Wiped on ${textComment} by Employee:${this.loggedinUser}`
        }
        else {
          CoreComments.comment = `GENERIC COMMENTS - From NOT WIPED: IMEI: ${this.rowObj.imei} is Not Wiped on ${textComment} by Employee:${this.loggedinUser}`

        }
        this.inventoryservice.AddCoreComments(CoreComments).subscribe(res => {
          // console.log('Comments added successfully');
        })
        let message="";
        this.serviceNowTicketDetail.incidentArea= 'Device Wipe';
        if(this.serviceNowTicketDetail.requestedFor && this.serviceNowTicketDetail.group){
        this.inventoryservice
          .createRequestNUpdateState(this.serviceNowTicketDetail)
          .subscribe(data => {
            this.UpdateTicketNumToMobileDeviceHistory(this.mobileDeviceHistoryID, this.ticketNo);
            // document.getElementById("btnCancel").click();
            $(".closeDevice-wipe").click();
            this.ticketNo = data;
            if (this.isUSI == "US") {
              if (data.startsWith('REQ') || data.startsWith('INC')) {
                message = `Device ${this.actionType} and Ticket #${this.ticketNo} is created successfully.`;
              } else if (data.toString().length > 10) {
                message = this.ticketNo;
              }
              // message=`Device ${this.actionType} and Ticket #${this.ticketNo} is created successfully.`;
            }
            else {
              if (data.startsWith('REQ') || data.startsWith('INC')) {
                // message = `Device ${this.actionType}, is marked as personal and Ticket #${this.ticketNo} is created successfully.`;
                if(this.actionType === 'not wiped'){
                  message = `Device ${this.actionType} Ticket #${this.ticketNo} is created successfully.`;
                }else{
                  message = `Device ${this.actionType}, is marked as personal and Ticket #${this.ticketNo} is created successfully.`;
                }
              } else if (data.toString().length > 10) {
                message = this.ticketNo;
              }
              // message=`Device ${this.actionType}, is marked as personal and Ticket #${this.ticketNo} is created successfully.`;
            }
            this.modalService.message(
              message
            ).pipe(
              take(1) // take() manages unsubscription for us
            ).subscribe(result => {
              // console.log({ messageResult: result });
              this.AssignmentChanged.emit(true);
              // this.messageResult = result;
            });
            // this.inventoryservice.UpdateDeviceWipeStatus(this.rowObj.imei, this.rowObj.personnelNbr, this.rowObj.mobileDeviceHistoryID, this.optionType, this.ticketNo, this.rowObj.mobileNumber, DeviceWipeSDTicket.TssrepGroup, this.complainceViolationStatus).subscribe(res => {
            //   this.mobileDeviceHistoryID = res;
            //   this.UpdateTicketNumToMobileDeviceHistory(this.mobileDeviceHistoryID, this.ticketNo);

          },
            (err) => {
              // document.getElementById("btnCancel").click();
              // console.log("err", err);
              $(".closeDevice-wipe").click();
              if(this.isUSI == "US")
            {
              message='Device wiped successfully.Could not generate Ticket.';
            }
            else{
              message='Device wiped successfully and is marked as personal.Could not generate Ticket.';
            }
              this.modalService.notExist(
                message
              ).pipe(
                take(1) // take() manages unsubscription for us
              ).subscribe(result => {
                // console.log({ messageResult: result });
                this.AssignmentChanged.emit(true);
              });
            });
          }else{
            document.getElementById('insufficientData').click();
          }

      },
        (err) => {
          $(".closeDevice-wipe").click();
          // console.log("err", err);
          this.modalService.notExist(
            'Device wiped Failed.'
          ).pipe(
            take(1) // take() manages unsubscription for us
          ).subscribe(result => {
            // console.log({ messageResult: result });
          });
        });
    //call updatewipestatus API call
    //Call ADDcomments api call in success of updatewipestatus api
    //call service now ticket API call in parelllel  in success of updatewipestatus api -- i have given input for servicenow ticket above. just call the api with this DeviceWipeSDTicket object
    //call updatemobilehistorywithticket number api call in sucess of service now api
  }
  private LoadAssignmentGroupData() {
    this.inventoryservice
      .getAllAssignmentgroupItems(this.loggedinUser)
      .subscribe(data => {
        this.assignmentGroupModeldata = data;
      });
  }
  getRDPDAChecklistValues() {
    this.serviceNowChecklistItems = [{ checkListText: 'Reason for Wipe', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.wipeform.value.reasonforwipe },
    { checkListText: 'Reason for Not Wiping Data', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.wipeform.value.reasonfornotwiping },
    { checkListText: 'MTN Status', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.wipeform.value.iswiped == 'WIPED' ? this.wipeform.value.mtnstatus : this.wipeform.value.notwipedmtnstatus },
    { checkListText: 'Personal Device?', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.wipeform.value.iswiped == 'WIPED' ? this.wipeform.value.personaldevice : this.wipeform.value.notwipedpersonaldevice },
    { checkListText: 'Remote Wipe Sent?', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.wipeform.value.remotewipesent },
    { checkListText: 'Wipe Status', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.optionType },
    { checkListText: 'Employee’s Personal Email Address', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.wipeform.value.iswiped == 'WIPED' ? this.wipeform.value.email : this.wipeform.value.notwipedemail },
    { checkListText: 'Mobile Telephone Number', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.rowObj.mobileNumber },
    { checkListText: 'Wireless Carrier', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.rowObj.carrier },
    { checkListText: 'IMEI or ESN Hex Number', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.rowObj.deviceID }];

  }

  UpdateTicketNumToMobileDeviceHistory(mobDeviceHisId: string, ticketNumber: string) {
    this.inventoryservice
      .updateTicketNumToMobileDeviceHistory(mobDeviceHisId, ticketNumber)
      .subscribe(data => {
        // console.log("Ticket and Assigned Device History save successfully in Inventory", data);

      });

      this.complianceService.ClearComplianceViolationDuringWipe(this.rowObj.imei)
      .subscribe(data => {
        // console.log("ClearComplianceViolationDuringWipe successful", data);

      });
  }
  
  GetMonthName(monthNumber) {
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[monthNumber - 1];
  }

  submit(event) {

  }
}

import { Component, OnInit } from '@angular/core';
import { UploadActivityService } from 'src/app/_services/uploadactivitylog.service';

@Component({
  selector: 'app-upload-activity-log',
  templateUrl: './upload-activity-log.component.html',
  styleUrls: ['./upload-activity-log.component.scss']
})
export class UploadActivityLogComponent implements OnInit {

  uploadactivityDetails = [];

  constructor(private uploadactivityService: UploadActivityService) { }

  ngOnInit() {
    this.uploadactivityService.uploadActivityLogData.subscribe((data) => {
      if(data){
        this.uploadactivityDetails = data;
        this.uploadactivityDetails.sort((a,b) => {
          const dateA : any = new Date(a.dateOfUpload);
          const dateB: any = new Date(b.dateOfUpload);
          return dateB -dateA;
        });
      }
    });
  }

  closemethod(){
    //$('#uploadactiveModel').removeClass('active');
    let uploadactiveModel = document.getElementById('uploadactiveModel');
    uploadactiveModel.classList.remove('active');
  }

}

import { Component, OnInit } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
    selector: 'my-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
   
})
export class SpinnerComponent implements OnInit {
    public active: boolean = false;

    public constructor(spinner: SpinnerService) {
         spinner.status.subscribe((status: boolean) => {
             this.active = status;
         });
    }
    ngOnInit() {
        
    }
}




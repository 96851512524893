import { environment } from '../../environments/environment';
import { EnvService } from '../env.service';
export class AppConstants {
    public static API_URL: string = ''; //environment.appEndpoints.gpaApi;
    public static config: string = '{ "tenant": "@tenant", "clientId": "@clientid","redirectUri": "@redirect","endpoint":{"https://graph.microsoft.com/":"https://graph.microsoft.com/","@weburl":"@weburl2"},"cacheLocation":"localStorage"}';

    constructor(private env: EnvService) {
        AppConstants.API_URL = this.env.apiurl;
        // console.log('from const file');
        // console.log(AppConstants.API_URL);
    }
    ;


    public static GPA_ENDPOINTS = {
        Admin: {

            GetAdminDashBoardAuditDeviceModel: 'Inventory/GetAdminDashBoardDeviceModel',
            InsertUpdateAdminDashboardDeviceModel: 'Inventory/InsertUpdateAdminDashboardDeviceModel',
            InsertUpdateAdminDashboardDeviceOS: 'Inventory/InsertUpdateAdminDashboardDeviceOS',
            InsertUpdateAdminDashboardDeviceSize: 'Inventory/InsertUpdateAdminDashboardDeviceSize',
            GetAdminDashboardDeviceImage: 'Inventory/GetAdminDashboardDeviceImage',
            InsertUpdateAdminDashboardDeviceType: 'Inventory/InsertUpdateAdminDashboardDeviceType',
            DeleteAdminDashboardDeviceTransactionType: 'Inventory/DeleteAdminDashboardDeviceTransactionType',
            DeleteAdminDashboardDeviceStatus: 'Inventory/DeleteAdminDashboardDeviceStatus',
            DeleteAdminDashboardDeviceSize: 'Inventory/DeleteAdminDashboardDeviceSize',
            DeleteAdminDashboardDeviceOS: 'Inventory/DeleteAdminDashboardDeviceOS',
            DeleteAdminDashboardDeviceManufacturer: 'Inventory/DeleteAdminDashboardDeviceManufacturer',
            DeleteAdminDashboardDeviceLocation: 'Inventory/DeleteAdminDashboardDeviceLocation',
            DeleteAdminDeviceModelMapping: 'Inventory/DeleteAdminDeviceModelMapping',
            DeleteAdminDashboardDeviceType: 'Inventory/DeleteAdminDashboardDeviceType',
            DeleteAdminDashboardDeviceColor: 'Inventory/DeleteAdminDashboardDeviceColor',
            InsertUpdateAdminDashboardDeviceColor: 'Inventory/InsertUpdateAdminDashboardDeviceColor',
            GetAdminDashboardDeviceColors: 'Inventory/GetAdminDashboardDeviceColors',
            GetAdminDashboardDeviceColor: 'Inventory/GetAdminDashboardDeviceColor',
            GetInventorySearchResult: 'Inventory/GetInventorySearchResult',
            GetAdminDashboardDeviceOS: 'Inventory/GetAdminDashboardDeviceOS',
            GetAdminDashboardDeviceType: 'Inventory/GetAdminDashboardDeviceType',
            GetAdminDashboardDeviceTypeByID: 'Inventory/GetAdminDashboardDeviceType',
            GetAdminDashboardDeviceSize: 'Inventory/GetAdminDashboardDeviceSize',
            InsertUpdateAdminDashboardDeviceImage: 'Inventory/InsertUpdateAdminDashboardDeviceImage',
            GetAllDeviceOs: 'Inventory/GetAllDeviceOs',
            GetAllLocations: 'Inventory/GetAllLocations',
            InsertUpdateAdminDashboardDeviceLocation: 'Inventory/InsertUpdateAdminDashboardDeviceLocation',
            GetAdminDashboardDeviceLocation: 'Inventory/GetAdminDashboardDeviceLocation',
            GetAdminDashboardDeviceLocationForTSS: 'Inventory/GetAdminDashboardDeviceLocationForTSS',
            GetAdminDashboardDeviceAlternateNames: 'Inventory/GetDeviceAlternateNames',
            GetAdminDashboardVipException: 'CoreService/GetAdminDashboardVipLeaders?deviceModelID=',
            InsertUpdateVIPException: 'CoreService/InsertUpdateAdminDashboardVipLeaders',
            GetAdminDashboardCarrier: 'Inventory/GetAdminDashboardCarrier',
            GetUSCarrier: 'Inventory/GetAllCarrier',
            InsertUpdateCarrier: 'Inventory/InsertUpdateAdminDashboardCarrier',
            GetAdminDashboardDeviceManufacturer: 'Inventory/GetAdminDashboardDeviceManufacturer',
            InsertUpdateAdminDashboardDeviceManufacturer: 'Inventory/InsertUpdateAdminDashboardDeviceManufacturer',
            InsertUpdateAdminDashboardAdminDashboardMtn: 'Inventory/InsertUpdateMTN',
            GetAdminDashboardMTN: 'Inventory/GetAdminDashboardMobileTelephoneNumber',
            DeleteAdminDashBoardDeviceModel: 'Inventory/DeleteAdminDashboardDeviceModel',
            GetCarrierAuditDetails: 'Inventory/GetCarrierAuditDetails',
            DeleteAdminDashboardCarrierAuditDetails: 'Inventory/DeleteAdminDashboardCarrierAuditDetails',
            InsertUpdateCarrierAuditDetails: 'Inventory/InsertUpdateCarrierAuditDetails',
            DeleteAdminDashboardCarrier: 'Inventory/DeleteAdmindashboardcarrier',
            AddUpdateExceptionList: 'Compliance/AddUpdateExceptionList',
            savependingreturnexception: 'Inventory/SavePendingReturnException',
            GetExceptionDetailsById: 'Inventory/GetExceptionDetailsById',
            GetAllException: 'Inventory/GetExceptionDetails',
            GetAllExceptionCategory: 'CoreService/Getexceptioncategorylist',
            GetAllExceptionSubCategory: 'CoreService/Getexceptionsubcategorylist',
            InsertComplianceException: 'Compliance/SaveMobileComplianceException',
            DeleteMobileException: 'Inventory/DeleteMobileException',
            DeleteVIPMobileexception: 'CoreService/DeleteAdminDashboardVIPException',
            GetComplianceException: 'Compliance/GetAllExceptionDetails',
            GetAllRegion: 'CoreService/GetRegion',
            GetFirmFunction: 'CoreService/GetFss',
            GetServiceArea: 'CoreService/GetServiceArea',
            GetServiceLine: 'CoreService/GetServiceLine',
            //GetRevokeEligible:'CoreService/GetRevokeEligible',
            //SetRevokeEligible:'CoreService/setGrantEligible',
            //GetGrantEligibility:'CoreService/GetGrantEligible',
            //SetGrantEligible:'CoreService/setRevokeEligible',
            GetMobilityHistory: 'CoreService/GetEligibilityHistory',
            DeleteComplianceException: 'Compliance/DeleteAdminDashboardComplianceException',
            GetMBRThresholdFilters: 'Billing/GetMBRThresholdFilters',
            SetMBRComplianceParameter: 'Billing/SetMBRComplianceParameter',
            GetJobLevelGroup: 'Inventory/GetJobLevelGroups',
            GetAdminDeviceModelForPaging: 'Inventory/GetAdminDashBoardDeviceModelForPaging',
            CheckDeviceModelValidation: 'Inventory/DeviceModelValidation',
            GetRevokeEligible: 'CoreService/GetRevokeEligibleForPaging',
            SetRevokeEligible: 'CoreService/setRevokeEligible',
            GetGrantEligibility: 'CoreService/GetGrantEligibleForPaging',
            SetGrantEligible: 'CoreService/setGrantEligible',
            GetEligibilityHistoryForPaging: 'CoreService/GetEligibilityHistoryForPaging',
            GetAllFacilityRegionWithDeviceOrigin: 'Inventory/GetAllFacilityRegionWithDeviceOrigin',
            GetAdminDashboardDeviceStatus: 'Inventory/GetAdminDashboardDeviceStatus',
            DeleteAdminDashboardMobileTelephoneNumber: 'Inventory/DeleteAdminDashboardMobileTelephoneNumber',
            InsertAdminDashboardMobileDevice: 'Inventory/InsertAdminDashboardMobileDevice',
            UpdateAdminDashboardMobileDevice: 'Inventory/UpdateAdminDashboardMobileDevice',
            GetAdminDashboardMobileDevice: 'Inventory/GetAdminDashboardMobileDevice',
            DeleteAdminMobileDeviceMapping: 'Inventory/DeleteAdminMobileDeviceMapping',
            InsertUpdateIMEIAssociation: 'Inventory/InsertUpdateIMEIAssociation',
            DeleteBillingException: 'Billing/DeleteBillingException',
            GetAllBillingException: 'Billing/GetBillingExceptionDetails',
            InsertMBRException: 'Billing/SaveBillingException',
            InsertTnCException: 'TnC/SaveTnCException',
            DeleteTnCException: 'TnC/DeleteTnCException',
            InsertDeliquencyException: 'Delinquency/SaveDeliquencyException',
            DeleteDeliquencyException: 'Delinquency/DeleteDeliquencyException',
            GetSearchEmployeeToApplyAccess: 'OrderCapabilities/GetSearchEmployeeToApplyAccess',
            AddEmployeeOrderCapabilities: 'OrderCapabilities/AddEmployeeOrderCapabilities',
            GetEmployeeOrderCapabilitiesHistory: 'OrderCapabilities/GetEmployeeOrderCapabilitiesHistory',
            GetAllAdminDashboardDeviceModelBinding: 'Inventory/GetAllAdminDashboardDeviceModelBinding',

            DeleteVIPMobileexceptionInventory: 'Inventory/DeleteAdminDashboardVIPException',
            DeleteVIPMobileexceptionCompliance: 'Compliance/DeleteAdminDashboardVIPException',
            DeleteVIPMobileexceptionBilling: 'Billing/DeleteAdminDashboardVIPException',


            InsertUpdateVIPExceptionInventory: 'Inventory/InsertUpdateAdminDashboardVipLeaders',
            InsertUpdateVIPExceptionBilling: 'Billing/InsertUpdateAdminDashboardVipLeaders',
            InsertUpdateVIPExceptionCompliance: 'Compliance/InsertUpdateAdminDashboardVipLeaders',
            CheckMobileAccountNo: 'Inventory/ChecKmobileAccountNoExists',
            UpdateAdminDashboardRoleMenu: 'CoreService/UpdateRoleMenu',
            GetLoggedInUserSubModules: 'CoreService/GetLoggedInUserSubModules',

            GetMBRThresholdFeatureCode: 'Billing/GetMBRThresholdFeatureCode',
            SetMBRFeatureCode: 'Billing/SetMBRFeatureCode',
            DeleteMBRFeatureCode: 'Billing/DeleteMBRFeatureCode',
            UpdateMBRFeatureCode: 'Billing/UpdateMBRFeatureCode',
            GetAutoApprovalConfigSettings: 'Orders/GetAutoApprovalConfigSettings',
            GetEmployeeSubGroupCodes: 'Orders/GetEmployeeSubGroupCodes',
            SaveAutoApprovalConfigSettings: 'Orders/SaveAutoApprovalConfigSettings',
            InsertExcludeEmpSubGrpToOrderSettings: 'Orders/InsertExcludeEmpSubGrpToOrderSettings',
            GetExcludeEmpSubGroups: 'Orders/GetExcludeEmpSubGroups',
            GetAutoApprovalSettingForCarrier: 'Orders/GetAutoApprovalSettingForCarrier'

        },
        Compliance: {
            GetComplianceDDL: 'Compliance/GetComplianceDDL',
            GetLocationfromRegion: 'Compliance/GetLocationfromRegion?RegionCode=USI',
            GetEMPStatusDetails: 'Compliance/GetEMPStatusDetails',
            GetMTNStatusDetails: 'Compliance/GetMTNStatusDetails',
            GetAdminFacilityCodes: 'Compliance/GetAdminFacilityCodes',
            GetComplianceSearchResult: 'Compliance/GetComplianceSearchResult',
            GetComplianceSearchPROrPWResult: 'Compliance/GetComplianceSearchPROrPWResult',
            GetDetailForComplianceViolationNotification: 'Compliance/GetDetailForComplianceViolationNotification?violationIDs=',
            GetComplianceIssues: 'Compliance/GetComplianceIssues?personalID=',
            GetEmployeeComments: 'Compliance/GetEmployeeComments?PersonID=',
            GetExceptionList: 'Compliance/GetExceptionList?PersonID=',
            AddUpdateAdminExceptionList: 'Compliance/AddUpdateAdminExceptionList',
            AddEmployeeComment: 'Compliance/AddEmployeeComment',
            AddComplianceComments: 'Compliance/AddComplianceComments',
            GetComments: 'Compliance/GetComments?violationID=',
            AddUpdateAdminExceptionListPRorPW: 'Inventory/AddUpdateAdminExceptionList',
            GetExceptionListProrPW: 'Inventory/GetExceptionList?PersonID=',
            AddComplianceCommentsPRorPW: 'Inventory/AddComplianceComments',
            GetCommentsPRorPW: 'Inventory/GetComments?violationID=',
            DisconnectUser: 'Compliance/DisconnectUser',
            GetDetailForCarrierNotification: 'Compliance/GetDetailForCarrierNotification',
            SendEmailTemplateNotification: 'Compliance/SendEmailTemplateNotification',
            GetComplianceNotificationDeatils: 'Compliance/GetComplianceNotificationDeatils?ComplianceViolationID=',
            SendCarrierEmailTemplateNotification: 'Compliance/SendCarrierEmailTemplateNotification',
            GetCarrierDetailForComplianceViolationNotification: 'Compliance/GetCarrierDetailForComplianceViolationNotification',
            GetComplianceViolationDetailsByPersonnelNbr: 'Compliance/GetComplianceViolationDetailsByPersonnelNbr?personnelno=',
            GetIntuneSyncDetails: 'Compliance/GetIntuneSyncDetails?personnelno=',
            GetEmployeeViewDetails: 'Compliance/GetEmployeeViewDetails?personnelno=',
            GetComplianceExceptionsByPersonnelNbr: 'Compliance/GetComplianceExceptionsByPersonnelNbr?personnelno=',
            GetComplianceCommentsByPersonnelNbr: 'Compliance/GetComplianceCommentsByPersonnelNbr?personnelno=',
            GetNotificationsByPersonnelNo: 'Compliance/GetNotificationsByPersonnelNo?personnelno=',
            GetUploadActivityDetailsCompliance: 'Compliance/GetUploadActivityDetailsCompliance',
            ClearComplianceViolationDuringWipe: 'Compliance/ClearComplianceViolationDuringWipe',
            UpdateComplianceViolationFromAssignDevice: 'Compliance/UpdateComplianceViolationFromAssignDevice'


        },
        Inventory: {
            InsertEmailToSchedule: 'Inventory/InsertEmailToSchedule',
            GetAdminDashboardDeviceType: 'Inventory/GetAdminDashboardDeviceType',
            GetAllLocations: 'Inventory/GetAllLocations',
            GetDeviceLocationsByFromLocation: 'Inventory/GetDeviceLocationsByFromLocation',
            GetAdminDashboardDeviceStatus: 'Inventory/GetAdminDashboardDeviceStatus',
            GetAllEmployeeStatus: 'Inventory/GetAllEmployeeStatus',
            GetAllUserTypes: 'Inventory/GetAllUserTypes',
            GetAdminDashboardDeviceColors: 'Inventory/GetAdminDashboardDeviceColors',
            GetAllDeviceMemorySize: 'Inventory/GetAllDeviceMemorySize',
            GetAllDeviceOs: 'Inventory/GetAllDeviceOs',
            GetAllFacilityRegion: 'Inventory/GetAllFacilityRegion',
            GetAllFacility: 'Inventory/GetAllFacility',
            GetAdminDashboardCarrier: 'Inventory/GetAdminDashboardCarrier',
            GetInventorySearchResult: 'Inventory/GetInventorySearchResult',
            InsertUpdateMobileDeviceHistory: 'Inventory/InsertUpdateMobileDeviceHistory',
            GetMobileDeviceHistory: 'Inventory/GetMobileDeviceHistory',
            GetCategoryDetails: 'Inventory/GetCategoryDetails',
            GetSubCategoryDetails: 'Inventory/GetSubCategoryDetails',
            GetCatalogItemsBasedOnAssignment: 'Inventory/GetCatalogItemsBasedOnAssignment',
            GetGroupsForLoggedInUser: 'CoreService/GetGroupsForLoggedInUser',
            GetUserTicketsListByPersonalOrTicketNumber: 'CoreService/GetUserTicketsListByPersonalOrTicketNumber',
            CreateIncidentTicketWithGroupAssignment: 'CoreService/CreateIncidentTicketWithGroupAssignment',
            CreateRequestNUpdateState: 'CoreService/CreateRequestNUpdateState',
            UpdateTicketStatus: 'CoreService/UpdateTicketStatus',
            GetEmployeeFacility: 'Inventory/GetEmployeeFacility',
            UpdateTicketNumToMobileDeviceHistory: 'Inventory/UpdateTicketNumToMobileDeviceHistory',
            GetEmployeeFacilitiesByRegion: 'Inventory/GetEmployeeFacilitiesByRegion',
            GetMtnDeviceDetails: 'Inventory/GetMtnDeviceDetails',
            GetDeviceHistory: 'Inventory/GetDeviceHistory',
            GetComplianceSearchPROrPWResult: 'Inventory/GetComplianceSearchPROrPWResult',
            DisconnectUser: 'Inventory/DisconnectUser',
            GetDetailForComplianceViolationNotification: 'Inventory/GetDetailForComplianceViolationNotification?violationIDs=',
            SendEmailTemplateNotification: 'Inventory/SendEmailTemplateNotification',
            GetComplianceNotificationDeatils: 'Inventory/GetComplianceNotificationDeatils?ComplianceViolationID=',
            GetMTNList: 'Inventory/GetMTNList',
            GetMobileDevicesHistoryInBatch: 'Inventory/GetMobileDevicesHistoryInBatch',
            InsertUpdateMultipleMobilesDeviceHistory: 'Inventory/InsertUpdateMultipleMobilesDeviceHistory',
            GetIncidentAreaandCheckListBasedOnAssignment: 'Inventory/GetIncidentAreaandCheckListBasedOnAssignment',
            GetComplianceViolationDetailsByPersonnelNbr: 'Inventory/GetComplianceViolationDetailsByPersonnelNbr?personnelno=',
            GetEmployeeInventoryDetails: 'Inventory/GetEmployeeInventoryDetails?personnelno=',
            GetEmployeeViewDetails: 'Inventory/GetEmployeeViewDetails?personnelno=',
            GetComplianceExceptionDetailsByPersonnelNbr: 'Inventory/GetComplianceExceptionsByPersonnelNbr?personnelno=',
            GetComplianceCommentsByPersonnelNbr: 'Inventory/GetComplianceCommentsByPersonnelNbr?personnelno=',
            GetNotificationsByPersonnelNo: 'Inventory/GetNotificationsByPersonnelNo?personnelno=',
            GetInventoryNotifyCarrierDetails: 'Inventory/GetInventoryNotifyCarrierDetails',
            GetInventoryNotifyCarrierDetailsByIMEI: 'Inventory/GetInventoryNotifyCarrierDetailsByIMEI',
            UpdateDeviceWipeStatus: 'Inventory/UpdateDeviceWipeStatus',
            GetUploadActivityDetailsInv: 'Inventory/GetUploadActivityDetailsInv',
            InsertIsExcludedDeviceForPR: 'Inventory/InsertIsExcludedDeviceForPR'
        },
        MBR: {
            GetMBRViolationType: 'Billing/GetMbrViolationType',
            GetCategory: 'Billing/GetCategory',
            GetMBRCategory: 'Billing/GetMBRCategory',
            GetMBRSearchResult: 'Billing/GetMBRSearchResult',
            GetSubCategoryByCategoryID: 'Billing/GetSubCategoryByCategoryID?id=',
            AddMBRComplianceExceptions: 'Billing/AddComplianceExceptions',
            GetMbrComplianceExceptions: 'Billing/GetComplianceExceptions?personnelNbr=',
            AddMBRComment: 'Billing/AddComplianceComment',
            GetComplianceComments: 'Billing/GetComplianceComments',
            MBRCancelAccount: 'Billing/MBRCancelAccount',
            MBRSuspendAccount: 'Billing/MBRSuspendAccount',
            UpdateMTNStatus: 'Inventory/UpdateMTNStatus',
            GetDetailForComplianceViolationNotification: 'Billing/GetDetailForComplianceViolationNotification?violationIDs=',
            GetCarrierDetailForComplianceViolationNotification: 'Billing/GetCarrierDetailForComplianceViolationNotification',
            SendEmailTemplateNotification: 'Billing/SendEmailTemplateNotification',
            GetComplianceNotificationDeatils: 'Billing/GetMBRComplianceNotificationDeatils?ComplianceViolationID=',
            SendCarrierEmailTemplateNotification: 'Billing/SendCarrierEmailTemplateNotification',
            GetComplianceViolationDetails: 'Billing/GetComplianceViolationDetails?PersonnelNbr=',
            GetMBRDetailsForBlowOut: 'Billing/GetMBRDetailsForBlowOut?PersonnelNbr=',
            GetMBRDetails: 'Billing/GetMBRDetails?PersonnelNbr=',
            GetEmployeeViewDetails: 'Billing/GetEmployeeViewDetails?PersonnelNbr=',
            GetExceptionsByPersonnelNbr: 'Billing/GetExceptionsByPersonnelNbr?personnelno=',
            GetComplianceCommentsByPersonnelNbr: 'Billing/GetCommentsByPersonnelNbr?PersonnelNbr=',
            GetNotificationsByPersonnelNo: 'Billing/GetNotificationsByPersonnelNo?personnelno=',
            InsertEmailToSchedule: 'Billing/InsertEmailToSchedule',
            GetMBRCarrierNotificationDetails: 'Billing/GetMBRCarrierNotificationDetails',
            SendMBRNotifyCarrierEmail: 'Billing/SendMBRNotifyCarrierEmail',
            GetUploadActivityDetailsBill: 'Billing/GetUploadActivityDetailsBill',
            GetMTNCancelledSuspendStatus: 'Billing/GetMTNCancelledSuspendStatus?mtn=',
        },
        Delinquency: {
            GetDelinquenciesStatusType: 'Delinquency/GetDelinquenciesStatusType',
            GetDelinquencySearchResult: 'Delinquency/GetDelinquencySearchResult',
            SendDelinquneciesLinesToTNC: 'Delinquency/SendDelinquneciesLinesToTNC',

            GetDetailForComplianceViolationNotification: 'Delinquency/GetDetailForComplianceViolationNotification?violationIDs=',
            GetCarrierDetailForComplianceViolationNotification: 'Delinquency/GetCarrierDetailForComplianceViolationNotification',
            SendEmailTemplateNotification: 'Delinquency/SendEmailTemplateNotification',
            GetComplianceNotificationDeatils: 'Delinquency/GetComplianceNotificationDeatils?ComplianceViolationID=',
            SendCarrierEmailTemplateNotification: 'Delinquency/SendCarrierEmailTemplateNotification',
            GetDelinquncyCarrierNotificationDetails: 'Delinquency/GetDelinquncyCarrierNotificationDetails',
            SendDelinquencyNotifyCarrierEmail: 'Delinquency/SendDelinquencyNotifyCarrierEmail'
        },
        CoreService: {
            CreateIncidentWithCI: 'CoreService/CreateIncidentWithCI',
            CreateRequestInBatch: 'CoreService/CreateRequestInBatch',
            GetUserTicketsListByPersonalOrTicketNumber: 'CoreService/GetUserTicketsListByPersonalOrTicketNumber?personalOrTicketNumber=',
            CreateRequestNUpdateState: 'CoreService/CreateRequestNUpdateState',
            GetDelinquencyCompanyCode: 'CoreService/GetDelinquencyCompanyCode',
            GetGroupsForLoggedInUser: 'CoreService/GetGroupsForLoggedInUser?loggedInUser=',
            CreateIncidentTicketWithGroupAssignment: 'CoreService/CreateIncidentTicketWithGroupAssignment',
            UpdateTicketStatus: 'CoreService/UpdateTicketStatus',
            GetEmployeeViewDetails: 'CoreService/GetEmployeeViewDetails?personnelno=',
            GetEmployeeTransferDetails: 'CoreService/GetTransferDetails?personnelno=',
            GetComplianceCommentsByPersonnelNbr: 'CoreService/GetComplianceCommentsByPersonnelNbr?personnelno=',
            GetNotificationsByPersonnelNo: 'CoreService/GetNotificationsByPersonnelNo?personnelno=',
            GetLoggedInUserModules: 'CoreService/GetLoggedInUserModules',
            GetLoggedInUser: 'CoreService/GetLoggedInUser',
            GetLOADetails: 'CoreService/GetLOADetails?personnelno=',
            AddEmployeeComments: 'CoreService/AddEmployeeComments',
            GetUploadActivityDetailsCore: 'CoreService/GetUploadActivityDetailsCore',
            GetAdminUserRolesHeader: 'CoreService/GetAllRoles',
            GetAllMenuRoles: 'CoreService/GetAllMenuRoles',
            Insertorupdateroleinfo: 'CoreService/InsertOrUpdateUserRole',
            GetAdminUserRolesTableData: 'CoreService/GetAllUserRoleDetails',
            DeleteUserRole: 'CoreService/DeleteUserRole',
            GetVipExceptionsByPersonnelNbr: 'CoreService/GetVipExceptionsByPersonnelNbr?PersonnelNbr='

        },
        Orders: {
            GetSearchResult: 'Orders/GetOrderProcessingSearchResult',
            GetDetailForUserNotification: 'Orders/GetDetailForUserNotification',
            sendHtmlContent: 'Orders/sendHtmlContent',
            GetOrdersRejectTemplate: 'Orders/GetOrdersRejectTemplate',
            GetDeclainTemplateBody: 'Orders/GetDeclainTemplateBody',
            SaveOrdersNotification: 'Orders/SaveOrdersNotification',
            GetOrderNotificationDetails: 'Orders/GetOrderNotificationDetails',
            GetComments: 'Orders/GetComments',
            AddOrdersComments: 'Orders/AddOrdersComments',
            IsLockedOrder: 'Orders/IsLockedOrder',
            LockOrderForUser: 'Orders/LockOrderForUser',
            GetEmployeeViewDetails: 'Orders/GetEmployeeViewDetails?PersonnelNbr=',
            GetEmployeeOrderDetails: 'Orders/GetEmployeeOrderDetails?personnelno=',
            GetComplianceCommentsByPersonnelNbr: 'Orders/GetCommentsByPersonnelNbr?PersonnelNbr=',
            GetNotificationsByPersonnelNo: 'Orders/GetNotificationsByPersonnelNo?personnelno=',
            GetEmployeeEligibilityDetails: 'Orders/GetEmployeeEligibilityDetails?personnelNbr=',
            GetFrameUrl: 'Orders/GetFrameUrl',
            GetSearchEmployeeToApplyAccess: 'OrderCapabilities/GetSearchEmployeeToApplyAccess?userAlias=',
            GetUploadActivityDetailsOrders: 'Orders/GetUploadActivityDetailsOrders',
            UpdateUSIOrderStatus:'Orders/UpdateUSIOrderStatus',
            GetUSIVendorNames: 'Orders/GetUSIVendorNames'


        },
        Reconciliation: {
            GetTSSEmployeeFacilityByRegion: 'Reconciliation/GetTSSEmployeeFacilityByRegion',
            GetTSSUserDetails: 'Reconciliation/GetTSSUserDetails',
            GetInventoryReconciliationSearchResult: 'Reconciliation/GetInventoryReconciliationSearchResult',
            CreateReconBatchID: 'Reconciliation/CreateReconBatchID',
            SubmitInventoryReconciliation: 'Reconciliation/SubmitInventoryReconciliation',
            IsDeviceReconciled: 'Reconciliation/IsDeviceReconciled',
            // Get_InventoryReconCount_mail: 'Reconciliation/Get_InventoryReconCount_mail',
            GetNotificationDetailsForReconciliation: 'Reconciliation/GetNotificationDetailsForReconciliation',
            GetSDInventoryReconDetail: 'Reconciliation/GetSDInventoryReconDetail',
            GetFacilityNamesByRegion: 'Reconciliation/GetFacilityNamesByRegion',
            GetLoggedInuserRegion: 'Reconciliation/GetLoggedInuserRegion',
        },
        DeviceShipment: {
            InsertDeviceShipmentBatch: 'Reconciliation/InsertDeviceShipmentBatch',
            GetDeviceShipmentSearchResult: 'Reconciliation/GetDeviceShipmentSearchResult',
            InsertDeviceShipDetails: 'Reconciliation/InsertDeviceShipDetails',
            GetDeviceShipmentStatus: 'Reconciliation/GetDeviceShipmentStatus',
            SubmitDeviceShipment: 'Reconciliation/SubmitDeviceShipment'
        },
        TnC: {
            GetDetailForComplianceViolationNotification: 'TnC/GetDetailForComplianceViolationNotification',
            GetCarrierDetailForComplianceViolationNotification: 'TnC/GetCarrierDetailForComplianceViolationNotification',
            SendEmailTemplateNotification: 'TnC/SendEmailTemplateNotification',
            GetComplianceNotificationDeatils: 'TnC/GetComplianceNotificationDeatils?ComplianceViolationID=',
            SendCarrierEmailTemplateNotification: 'TnC/SendCarrierEmailTemplateNotification',
            GetTnCSeperationType: 'TnC/GetTnCSeperationType',
            GetTncSearchResult: 'TnC/GetTncSearchResult',
            GetETFWaivers: 'TnC/GetETFWaivers',
            SendTnCCarrierNotification: 'TnC/SendTnCCarrierNotification'
        },
        EmailTemplateEndPoint: {
            Compliance: {
                GetAllModules: 'Compliance/GetAllModules',
                GetAllTemplates: 'Compliance/GetAllTemplates',
                GetTemplateType: 'Compliance/GetTemplateType',
                GetAllCarrierList: 'Compliance/GetAllCarrierList',
                LoadTemplate: 'Compliance/LoadTemplate',
                SaveEmailTemplate: 'Compliance/SaveEmailTemplate',
                GetTemplatevariables: 'Compliance/GetTemplatevariables',
                GetCarrierVisibilityForNotification: 'Compliance/GetCarrierVisibilityForNotification'
            },
            Billing: {
                GetAllModules: 'Billing/GetAllModules',
                GetAllTemplates: 'Billing/GetAllTemplates',
                GetTemplateType: 'Billing/GetTemplateType',
                GetAllCarrierList: 'Billing/GetAllCarrierList',
                LoadTemplate: 'Billing/LoadTemplate',
                SaveEmailTemplate: 'Billing/SaveEmailTemplate',
                GetTemplatevariables: 'Billing/GetTemplatevariables',
                GetCarrierVisibilityForNotification: 'Billing/GetCarrierVisibilityForNotification'
            },
            Orders: {
                GetAllModules: 'Orders/GetAllModules',
                GetAllTemplates: 'Orders/GetAllTemplates',
                GetTemplateType: 'Orders/GetTemplateType',
                GetAllCarrierList: 'Orders/GetAllCarrierList',
                LoadTemplate: 'Orders/LoadTemplate',
                SaveEmailTemplate: 'Orders/SaveEmailTemplate',
                GetTemplatevariables: 'Orders/GetTemplatevariables',
                GetCarrierVisibilityForNotification: 'Orders/GetCarrierVisibilityForNotification'
            },
            Inventory: {
                GetAllModules: 'Inventory/GetAllModules',
                GetAllTemplates: 'Inventory/GetAllTemplates',
                GetTemplateType: 'Inventory/GetTemplateType',
                GetAllCarrierList: 'Inventory/GetAllCarrierList',
                LoadTemplate: 'Inventory/LoadTemplate',
                SaveEmailTemplate: 'Inventory/SaveEmailTemplate',
                GetTemplatevariables: 'Inventory/GetTemplatevariables',
                GetCarrierVisibilityForNotification: 'Inventory/GetCarrierVisibilityForNotification'
            },
            Laptop: {
                GetAllModules: 'Laptop/GetAllModules',
                GetAllTemplates: 'Laptop/GetAllTemplates',
                GetTemplateType: 'Laptop/GetTemplateType',
                GetAllCarrierList: 'Laptop/GetAllCarrierList',
                LoadTemplate: 'Laptop/LoadTemplate',
                SaveEmailTemplate: 'Laptop/SaveEmailTemplate',
                GetTemplatevariables: 'Laptop/GetTemplatevariables',
                GetCarrierVisibilityForNotification: 'Laptop/GetCarrierVisibilityForNotification'
            }
        },
        Reports: {
            GetReportCategory: 'InventoryReports/GetReportCategory',
            GetRegionDLL: 'InventoryReports/GetRegionDLL',
            GetFacilityByRegion: 'InventoryReports/GetFacilityByRegion',
            GetOrdersReport: 'OrderReports/GetOrdersReport',
            GetInventoryReconHistReport: 'InventoryReports/GetInventoryReconHistReport',
            GetInventoryReconciliationByRegionandOffice: 'InventoryReports/GetInventoryReconciliationByRegionandOffice',
            GetFullInventoryReport: 'InventoryReports/GetFullInventoryReport',
            GetReportSubCategory: 'InventoryReports/GetReportSubCategory',
            InsertReportDetails: 'InventoryReports/InsertReportDetails',
            InsertReportDetailsOrder: 'OrderReports/InsertReportDetails',
            InsertReportDetailsComp: 'Compliance/InsertReportDetails',
            GetReportGeneratingStatus: 'InventoryReports/GetReportGeneratingStatus',
            GetReportGeneratingStatusComp: 'Compliance/GetReportGeneratingStatus',
            GetReportGeneratingStatusOrder: 'OrderReports/GetReportGeneratingStatus',
            UpdateReportStatus: 'InventoryReports/UpdateReportStatus',
            InsertReportDetailsCore: 'CoreServiceReports/InsertReportDetails',
            GetReportGeneratingStatusCore: 'CoreServiceReports/GetReportGeneratingStatus',
            InsertReportDetailsLaptop: 'Laptop/InsertReportDetails',
            GetDeviceReplacementCounts: 'InventoryReports/GetDeviceReplacementCounts',
            InsertODCReportDetailsLaptop: 'Laptop/InsertODCReportDetails'
        },

        Laptop: {
            GetLaptopInventorySearchResult: 'Laptop/GetLaptopInventorySearchResult',
            GetLaptopInventorySearchResultForODC: 'Laptop/GetLaptopInventorySearchResultForODC',
            GetAllLaptopDeviceStatus: 'Laptop/GetAllLaptopDeviceStatus',
            GetLaptopInventoryDeviceHistory: 'Laptop/GetLaptopInventoryDeviceHistory',
            GetComplianceRules: 'Laptop/GetComplianceRules',
            GetLaptopComplianceSearchResult: 'Laptop/GetLaptopComplianceSearchResult',
            GetLaptopComments: 'Laptop/GetLaptopComments',
            AddLaptopComments: 'Laptop/AddLaptopComments',
            GetComplianceIssues: 'Laptop/GetComplianceIssues',
            GetComplianceNotificationDetails: 'Laptop/GetComplianceNotificationDetails',
            AddLaptopException: 'Laptop/AddLaptopException',
            GetComplianceExceptions: 'Laptop/GetComplianceExceptions',
            GetNotificationTemplateDetails: 'Laptop/GetNotificationTemplateDetails',
            SaveComplianceNotification: 'Laptop/SaveComplianceNotification',
            GetEmpProfileHeadersForLaptopByPersonnelNbr: 'Laptop/GetEmpProfileHeadersForLaptopByPersonnelNbr',
            GetLaptopInventoryDetailsByPersonnelNbr: 'Laptop/GetLaptopInventoryDetailsByPersonnelNbr',
            GetLaptopViolationDetailsByPersonnelNbr: 'Laptop/GetLaptopViolationDetailsByPersonnelNbr',
            InsertUpdateLaptopException: 'Laptop/InsertUpdateLaptopException',
            GetLaptopBaselineSettings: 'Laptop/GetLaptopBaselineSettings',
            UpdateLaptopBaselineSettings: 'Laptop/UpdateLaptopBaselineSettings',
            DeleteLaptopException: 'Laptop/DeleteLaptopException',
            GetLaptopReportReq: 'Laptop/GetLaptopReportReq',
            InsertEmailToSchedule: 'Laptop/InsertEmailToSchedule',
            DisconnectUser: 'Laptop/DisconnectUser',
            GetAllLaptopModels: 'Laptop/GetAllLaptopModels',
            DeleteJAMFVersion: 'Laptop/DeleteJAMFVersion',
            DeleteCrowdStrikeVersion: 'Laptop/DeleteCrowdStrikeVersion',
            GetAllOSVersion: 'Laptop/GetAllLaptopOSVersion',
            GetODCLaptopComments: 'Laptop/GetODCLaptopComments',
            GetODCComplianceIssues: 'Laptop/GetODCComplianceIssues',
            GetODCComplianceExceptions: 'Laptop/GetODCComplianceExceptions',
            AddODCLaptopException: 'Laptop/AddODCLaptopException',
            UpdateViolationIncident: 'Laptop/UpdateViolationIncident',
            GetODCLaptopComplianceSearchResult: 'Laptop/GetODCLaptopComplianceSearchResult',
            GetODCNames: 'Laptop/GetODCNames',
            DeleteODCJAMFVersion: 'Laptop/DeleteODCJAMFVersion',
            DeleteODCCrowdStrikeVersion: 'Laptop/DeleteODCCrowdStrikeVersion',
            GetODCLaptopBaselineSettings: 'Laptop/GetLaptopODCBaselineSettings',
            UpdateODCLaptopBaselineSettings: 'Laptop/UpdateLaptopODCBaselineSettings',
        }
    }
} 
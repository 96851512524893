export class ComplianceViolationEx {
    Employee: Employee[];
    LastNotifiedDate: Date;
    ExceptionCount: number;
    NotificationCount:number;
    CommentsCount:number;
    ComplianceIssueCount:number;
    totalResultCount:string;
    MaxNotifications:number;
    UsersNoOfNotification:number;
    ExceptionSubCategoryDesc:string;
    IsMaxNotification:string;
    ResolvedViolationCount:number;
    HasOverride:number;
    carrierCode:string;
    LastNotifiedDateString:string;
    isHighlighter?:boolean;
    isMarkedForCancellation: number;
    isMarkedForSuspend: number;
    serverSync: any;
    assetStatus: string;
}
export class Employee {
         CareerLevel: string;
         CompanyCode : string;
         GroupCode : string;
         GroupDesc : string;
         FirmFunction : string;
         HRRegion : string;
         IsExpat:boolean;
        IsPPD :boolean;
         LoaIndicator:string;
        IsInpatORLTA:string;
         JobLevelCode:string;
         JobLevelText :string;
         JobLevelAbbreviation :string;
         LOAEndDate :Date
         LOAStartDate :Date
         LOAtype :string;
         HireDate:Date; 
         TerminatedDate :Date;
         MasterCostCenterNumber :string;
         MasterCostCenterDesc :string;
         Region :string;
         ServiceAreaCode :string;
         ServiceAreaDesc :string;
         ServiceLineCode :string;
         ServiceLineDesc :string;
         SubGroupCode:string; 
         SubGroupDesc:string; 
         EmployeeStatus:string;
          IsEligible:boolean; 
         IsVIPLeader:number; 
         isEligibleForDevice:boolean;
         PersonnelNumber:string;        
         IsCurrentPersonnelNumber:boolean;
         IsIntern:boolean; 
        IsEmployeeAtHomeOfficeWithPW:boolean;  
        FacilityName:string;

         CostCenterLeader:string;

        LastDayWorked:Date;

        DeviceMTN:string;
       EmployeeStatusString:string;
       IsVoluntaryCancel:boolean;
       IsMarkedForSuspend:string;
       IsMarkedForCancellation:string;
        isPermanentOrdering:boolean;
       VIPException:boolean;
        LastDayWorkedString:boolean;
     

         LOAEndDateString:string;

         LOAStartDateString:string;

         TerminatedDateString:string;
    
         HireDateString:string;
  
         SeparationType:string;
     

         FacilityState:string;
         FacilityPostalCode:string;
         EmployeeCommentCount:string;
         AdminAssistant:string;

         EmpStatus:string;
 

         AccessTypeDescription:string;
         ActionTypeID:string;
    }

    export class AdditionalProp1 {
        xslt: string;
        from: string;
        replyTo: string;
        subject: string;
        ccList: string;
        bccList: string;
        isTermsandCancellation: number;
        isWaiversInvolved: boolean;
    }

    export class AdditionalProp2 {
        xslt: string;
        from: string;
        replyTo: string;
        subject: string;
        ccList: string;
        bccList: string;
        isTermsandCancellation: number;
        isWaiversInvolved: boolean;
    }

    export class AdditionalProp3 {
        xslt: string;
        from: string;
        replyTo: string;
        subject: string;
        ccList: string;
        bccList: string;
        isTermsandCancellation: number;
        isWaiversInvolved: boolean;
    }

    export class RootObject {
        additionalProp1: AdditionalProp1;
        additionalProp2: AdditionalProp2;
        additionalProp3: AdditionalProp3;
    }

    
    export class EmployeeCommentsModel {
        commentText: string;
        addedBy: string;
        personID: string;
        addedDate: string;
    }


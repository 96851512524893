<section class="laptop-device-history-comp">
  <div class="row">

    <div class="col-md-3">
      <div class="row laptop_data_img">
        <div class="col-md-2 p-0">
          <img class="laptop_img" src="../../../assets/images/laptop-device.png" />
        </div>
        <div class="col-md-9 p-0">
          <label class="laptop-history-header">Laptop Details</label>
        </div>
      </div>
      <!-- <div class="laptop-device-history-sidenav"> -->
      <!-- <div class="row carrier-margin">
          <p class="deviceid">Manufacturer:</p>
        </div>
        <div class="row prexception carrier-margin">
          <p>Apple</p>
        </div> -->
      <div class="row carrier-margin">
        <p class="deviceid">Model:</p>
      </div>
      <div class="row prexception carrier-margin">
        <p>{{selectedHisData?.model}}</p>
      </div>
      <div class="row carrier-margin">
        <p class="deviceid">Operating System:</p>
      </div>
      <div class="row prexception carrier-margin">
        <p>{{selectedHisData?.os}}</p>
      </div>
      <div class="row carrier-margin">
        <p class="deviceid">Serial Number:</p>
      </div>
      <div class="row prexception carrier-margin">
        <p>{{selectedHisData?.serialNumber}}</p>
      </div>
      <div class="row carrier-margin">
        <p class="deviceid">Machine Name:</p>
      </div>
      <div class="row prexception carrier-margin">
        <p>{{selectedHisData?.deviceName}}</p>
      </div>
      <div class="row carrier-margin">
        <p class="deviceid">Asset Status:</p>
      </div>
      <div class="row prexception carrier-margin">
        <p>{{selectedHisData?.deviceStatusName}}</p>
      </div>
      <!-- <div class="row carrier-margin">
          <p class="deviceid">LAM Status:</p>
        </div>
        <div class="row prexception carrier-margin">
          <p>Apple</p>
        </div> -->
      <div class="row carrier-margin">
        <p class="deviceid">Lease End Date:</p>
      </div>
      <div class="row prexception carrier-margin">
        <p>{{selectedHisData?.currentEOLDate | date}}</p>
      </div>
      <div class="row carrier-margin">
        <p class="deviceid">Is Exception Granted:</p>
      </div>
      <div class="row prexception carrier-margin" *ngIf="selectedHisData?.isExceptionGranted == true">
        <p>Yes</p>
      </div>
      <div class="row prexception carrier-margin" *ngIf="selectedHisData?.isExceptionGranted == false">
        <p>No</p>
      </div>
      <!-- </div> -->
    </div>
    <div class="col-md-9 jumbotron">
      <div class="laptop-device-history-table">
        <div [ngClass]="checkTableZoom() ? 'pane--table1' : 'pane--table2'">
          <table [ngStyle]="{'width': checkTableZoom() ? '100%' : ''}" (window:resize)="checkSize()">
            <thead>
              <tr>
                <th style="min-width: 165px;">Assigned To</th>
                <th style="min-width:220px">
                  Office
                </th>
                <th style="min-width: 190px;">
                  Date Of Assignment
                </th>
                <th style="min-width:200px">Comments
                </th>

              </tr>
            </thead>
            <tbody *ngIf="laptopHisData?.length > 0"
              [ngStyle]="{'height':scrollHeight ? 'calc(100vh - 300px)' : ''}">
              <tr *ngFor="let data of laptopHisData">
                <td style="min-width: 165px;max-width: 165px;
                overflow: hidden;word-break: break-word !important;">
                    {{data.assignedTo}}
                </td>
                <td style="min-width: 220px;max-width: 220px;
            overflow: hidden;word-break: break-word !important;">
                    {{data.office}}
                </td>
                <td style="min-width: 190px;max-width: 190px;
            overflow: hidden;word-break: break-word !important;">
           
                    {{data.dateOfAssignment | date}}
                </td>
                <td style="white-space: nowrap;
            min-width: 200px;max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;">
                </td>
              </tr>
            </tbody>
            <tfoot *ngIf="laptopHisData?.length == 0"
              [ngStyle]="{'height':scrollHeight ? 'calc(100vh - 300px)' : ''}">
              <tr>
                <td colspan="15" style="text-align: center;">
                  <b>
                    <p class="no-data">No Data Available</p>
                  </b>
                </td>
              </tr>
            </tfoot>
          </table>

        </div>
      </div>
    </div>
  </div>
</section>
export class LaptopCommentsResponse {
    personnelNbr: string;
    serialNumber: string;
    commentText: string;
    complianceViolationID: number;
    complianceOverrideID: number;
    commentDate: string;
    dateCreated: string;
    createdBy: string;
    ruleId : number;
  }
export class LaptopDeviceHistoryReq {
    serialNumber: string;
    personnelNbr: string;
    startNo: number;
    endNo: number;
    orderby: string;
}
export class LaptopDeviceHistoryResponse {
    laptopDeviceHistoryID: number;
    serialNumber: string;
    deviceStatusID: string;
    deviceType: string;
    regionID: string;
    office: string;
    deviceName: string;
    make: string;
    model: string;
    os: string;
    createdBy: string;
    createdDate: Date;
    modifiedBy: string;
    modifieddate: Date;
    personnelNbr: string;
    isCurrentDevice: boolean;
    deviceStatusName:string;
    currentEOLDate: any;
    isExceptionGranted:boolean;
    assignedTo:string;
    dateOfAssignment:any;
    
  }
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DataContextService } from './datacontext.service';
import { AppConstants } from '../_utils/app-constants';
import { LaptopDeviceHistoryReq, LaptopDeviceHistoryResponse } from '../_models/laptop-device-history.model';

@Injectable({
  providedIn: 'root'
})
export class LaptopDeviceHistoryService {

  apiurlv2 : string = localStorage.getItem("apiurlv2");
  constructor(private http: HttpClient, private _datacontextService: DataContextService) { }

  getLaptopInventoryDeviceHistory(obj: LaptopDeviceHistoryReq): Observable<LaptopDeviceHistoryResponse[]> {
    return this._datacontextService.postData<any>(this.apiurlv2+AppConstants.GPA_ENDPOINTS.Laptop.GetLaptopInventoryDeviceHistory, obj).pipe(map(data => {
      return data;
    }));
  }
}

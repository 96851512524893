import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MBRBlowOut } from 'src/app/_models/employee-profile/MBRBlowOut';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';

declare var _satellite: any;
declare var window: any;
@Component({
  selector: 'app-empmbr',
  templateUrl: './empmbr.component.html',
  styleUrls: ['./empmbr.component.scss']
})
export class EmpmbrComponent implements OnInit {

  childId = 0;
  sameChild = false;
  totalnumber = 5;
  mbrBlowOut:MBRBlowOut[];
  verizoneResultSet:MBRBlowOut[];
  attResultSet:MBRBlowOut[];
  sprintResultSet:MBRBlowOut[];
  tmobileResultSet:MBRBlowOut[];
  firstfilterflag: boolean = false;
  Secondfilterflag: boolean = false;
  thirdfilterflag: boolean = false;
  thirdspanflag: boolean = false;
  secondspanflag: boolean = false;
  firstspanflag: boolean = false;
  resultset:Array<any>=[];
  //header:string;
  @Input() personnelNumber: string;
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];
  @Output() backToTails = new EventEmitter<any>();
  @Input() isPageLoad;

  constructor(private _empService:EmpProfileService) { }


   backtoSearch() {
     this.backToTails.emit()
     this.isPageLoad= false;
   }
 
  ngOnInit() {
    // document.getElementById("collapseFilterfirst").style.display="none";
    // document.getElementById("collapseFiltersecond").style.display="none";
    // document.getElementById("collapseFilterthird").style.display="none";
    //this.personnelNumber='183133';
    this.GetMBRDetailsForBlowOut(this.personnelNumber);
    //this.fn_DatalayerPageLoad();
  }
  GetMBRDetailsForBlowOut(personnelno:string)
  {
      return this._empService.GetMBRDetailsForBlowOut(personnelno).subscribe(
        (data)=>{
          if(data){
          this.mbrBlowOut = data;
          this.verizoneResultSet = this.mbrBlowOut.filter(x=>x.carrier == 'VERIZON');
          this.attResultSet = this.mbrBlowOut.filter(x=>x.carrier=='ATT');
          this.sprintResultSet= this.mbrBlowOut.filter(x=>x.carrier=='SPRINT');
          this.tmobileResultSet= this.mbrBlowOut.filter(x=>x.carrier=='TMOBILE');
          
          if(this.attResultSet.length>0)
          {
            this.resultset.push(this.attResultSet);
          }
          if(this.sprintResultSet.length>0)
          {
            this.resultset.push(this.sprintResultSet)
          }
          if(this.tmobileResultSet.length>0)
          {
            this.resultset.push(this.tmobileResultSet)
          }
          if(this.verizoneResultSet.length>0)
          {
            this.resultset.push(this.verizoneResultSet);
          }
          

        //   this.header=this.mbrBlowOut[0].carrier;
        // }
        }
      }
      );
  }
  firstcollapsefiltericon(id) {
    if(this.childId == id){
      this.sameChild = true;
    }
    if(!this.sameChild){
      this.childId = id;
    }
    else{
      this.childId = 0;
      this.sameChild = false;
    }
    this.firstfilterflag = true;
    this.Secondfilterflag = false;
    this.thirdfilterflag = false;
    this.firstspanflag = true;
    // document.getElementById("collapseFilterfirst").style.display="block";
    // document.getElementById("collapseFiltersecond").style.display="none";
    // document.getElementById("collapseFilterthird").style.display="none";
  }
  secondcollapsefiltericon() {
    this.firstfilterflag = false;
    this.Secondfilterflag = true;
    this.thirdfilterflag = false;
    this.secondspanflag = true;
    // document.getElementById("collapseFiltersecond").style.display="block";
    // document.getElementById("collapseFilterfirst").style.display="none";
    // document.getElementById("collapseFilterthird").style.display="none";
  }
  thirdcollapsefiltericon() {
    this.firstfilterflag = false;
    this.Secondfilterflag = false;
    this.thirdfilterflag = true;
    this.thirdspanflag = true;
    // document.getElementById("collapseFilterthird").style.display="block";
    // document.getElementById("collapseFilterfirst").style.display="none";
    // document.getElementById("collapseFiltersecond").style.display="none";
  }

  collection: any[] = [
    {
      id: 1,
      featureDetails: "jioo",
      wireLessNo: "dnesley",
      TotalCurrCharges: "Apple iphone 11",
      MonthlyAccCharges: "12 May 2019",
      voiceAirCharges: 66,
      TotDataUsageCharges: false,
      Used: 55,
      Billable: 88,
      TotalRemaining: 99,
      totalEqpCharges: 345,
      child: [
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        }
      ]
    },
    {
      id: 2,
      featureDetails: "jioo",
      wireLessNo: "dnesley",
      TotalCurrCharges: "Apple iphone 11",
      MonthlyAccCharges: "12 May 2019",
      voiceAirCharges: 66,
      TotDataUsageCharges: false,
      Used: 55,
      Billable: 88,
      TotalRemaining: 99,
      totalEqpCharges: 345,
      child: [
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        }
      ]
    }, {
      id: 3,
      featureDetails: "jioo",
      wireLessNo: "dnesley",
      TotalCurrCharges: "Apple iphone 11",
      MonthlyAccCharges: "12 May 2019",
      voiceAirCharges: 66,
      TotDataUsageCharges: false,
      Used: 55,
      Billable: 88,
      TotalRemaining: 99,
      totalEqpCharges: 345,
      child: [
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        }
      ]
    },
    {
      id: 4,
      featureDetails: "jioo",
      wireLessNo: "dnesley",
      TotalCurrCharges: "Apple iphone 11",
      MonthlyAccCharges: "12 May 2019",
      voiceAirCharges: 66,
      TotDataUsageCharges: false,
      Used: 55,
      Billable: 88,
      TotalRemaining: 99,
      totalEqpCharges: 345,
      child: [
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        }
      ]
    },
    {
      id: 5,
      featureDetails: "jioo",
      wireLessNo: "dnesley",
      TotalCurrCharges: "Apple iphone 11",
      MonthlyAccCharges: "12 May 2019",
      voiceAirCharges: 66,
      TotDataUsageCharges: false,
      Used: 55,
      Billable: 88,
      TotalRemaining: 99,
      totalEqpCharges: 345,
      child: [
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        }
      ]
    },
    {
      id: 6,
      featureDetails: "jioo",
      wireLessNo: "dnesley",
      TotalCurrCharges: "Apple iphone 11",
      MonthlyAccCharges: "12 May 2019",
      voiceAirCharges: 66,
      TotDataUsageCharges: false,
      Used: 55,
      Billable: 88,
      TotalRemaining: 99,
      totalEqpCharges: 345,
      child: [
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        }
      ]
    },
    {
      id: 7,
      featureDetails: "jioo",
      wireLessNo: "dnesley",
      TotalCurrCharges: "Apple iphone 11",
      MonthlyAccCharges: "12 May 2019",
      voiceAirCharges: 66,
      TotDataUsageCharges: false,
      Used: 55,
      Billable: 88,
      TotalRemaining: 99,
      totalEqpCharges: 345,
      child: [
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        },
        {
          itemCategory: "Mobile",
          Allowances: 777,
          itemDesc: "Comment nasjdha"
        }
      ]
    }
  ];

  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee MBR Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }
}

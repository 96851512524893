<section class="laptop-violationemp-comp">
    <div class="col-md-4 back-to-laptopViolation">
        <div (click)="backtoSearch()">
            <img src="assets/images/collapse arrows.svg"><span class="hidedata-text"
                style="font-size:12px;color: #212529;">Back</span>
        </div>
    </div>
    <div class="mtntable">
        <div class="row">
            <div class="col-md-8 intune-padding">
                <p class="compliance-text">Laptop Violations: {{violationDetailsForLaptop?.length}}</p>
            </div>
        </div>
        <div class="row search-filter">
            <div class="pane pane--table2">
                <table class="table-style">
                    <thead>
                        <tr class="tabrow">
                            <th style="min-width: 150px;">Violation</th>
                            <th style="min-width: 150px;">Since</th>
                            <th style="min-width: 200px;">Reason</th>
                            <th style="min-width: 150px;">Exception Status</th>
                            <th style="min-width: 210px;">Date of Exception Granted</th>
                            <th style="min-width: 150px;">Expiration Date</th>
                            <th style="min-width: 150px;">Added By</th>
                            <th style="min-width: 200px;">Comments</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="violationDetailsForLaptop?.length > 0">
                        <tr *ngFor="let violationDetailsForLaptop of violationDetailsForLaptop">
                            <td style="min-width: 150px;max-width: 150px;
                                overflow: hidden;word-break: break-word !important;">{{violationDetailsForLaptop.complianceRuleDesc}}</td>
                            <td style="white-space: nowrap;
                                min-width: 150px;max-width: 150px;
                                overflow: hidden;
                                text-overflow: ellipsis;">{{violationDetailsForLaptop.dateCreated | date}}</td>
                                <td style="min-width: 200px;max-width: 200px;
                                overflow: hidden;word-break: break-word !important;">{{violationDetailsForLaptop.reason}}</td>
                            <td style="white-space: nowrap;
                                min-width: 150px;max-width: 150px;
                                overflow: hidden;
                                text-overflow: ellipsis;">{{violationDetailsForLaptop.hasOverride == 1 ? 'Yet to Expire': violationDetailsForLaptop.hasOverride == 2 ? 'Expired' : violationDetailsForLaptop.hasOverride == 3 ? 'Deleted' : 'No'}}</td>
                            <!-- <td style="white-space: nowrap;
                                min-width: 150px;max-width: 150px;
                                overflow: hidden;
                                text-overflow: ellipsis;" *ngIf="violationDetailsForLaptop.hasOverride == 0">No</td> -->
                            <td style="white-space: nowrap;
                                min-width: 210px;max-width: 210px;
                                overflow: hidden;
                                text-overflow: ellipsis;">{{violationDetailsForLaptop.dateBegin | date}}</td>
                            <td style="white-space: nowrap;
                                min-width: 150px;max-width: 150px;
                                overflow: hidden;
                                text-overflow: ellipsis;">{{violationDetailsForLaptop.dateEnd | date}}</td>
                            <td style="min-width: 150px;max-width: 150px;
                                overflow: hidden;word-break: break-word !important;">{{violationDetailsForLaptop.createdBy}}</td>
                            <td style="min-width: 150px;max-width: 150px;
                                overflow: hidden;word-break: break-word !important;">{{violationDetailsForLaptop.overrideComment}}</td>
                        </tr>
                    </tbody>
                    <tfoot  *ngIf="violationDetailsForLaptop?.length == 0">
                        <tr>
                          <td style="text-align: center;">
                            <b><p class="no-data">No Data Available</p></b>
                          </td>
                        </tr>
                      </tfoot>
                </table>
            </div>
        </div>
    </div>
    </section>
<section class="header-comp">
    <div class="row bg-light" id="header_component_hide" *ngIf="showHeader">
      <div class="col-md-8 solid-with-logo align-self-center" >
        <img class="deloitte-logo" src="assets/images/DeloitteImageWhite.png" />
        <p class="solid-line">|</p>
        <span class="dcd-text" (click)="goToHome()"><img class="house-logo" title="Home"  src="assets/images/HouseWhite.png"/>&nbsp;&nbsp;  Device Compliance Dashboard</span>
        <!-- <span class="dcd-text ml-1" style="color: #ff5959;" *ngIf="environmentName">- This is Non Prod Environment</span> -->
      </div>
      <div class="col-md-4 member-firm justify-content-end">
        <div class="profile-text align-self-center">
          <span class="icon-Profile_2_POS align-middle"></span>
        </div>
        <div class="loggedUser-text">
          <span class="user-name-header-inline">Welcome<br />{{displayName}}</span>
          <div class="d-inline" id="profileDropDownBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-caret-down"></i>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="profileDropDownBtn">
              <a class="optanon-toggle-display dropdown-item">Cookies Settings</a> 
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
  
<div class="combobox">
    <div class="has-search">
      <span class="fa fa-search form-control-feedback"></span>
      <!-- <input id="peoplePickerInput" autocomplete="off" 
          type="text" [(ngModel)]="inputItem" (ngModelChange)="getFilteredList(inputItem)" 
          class="form-control combobox-input" (keyup)="onKeyPress($event)"  
          placeholder="Alias, PersonnelNumber or LastName, FirstName" [ngClass]="{'error': showError}" #peoplePickerTemp> -->
      <input id="peoplePickerInput" autocomplete="off" type="text" [(ngModel)]="inputItem"
        class="form-control combobox-input" placeholder="Start typing to find a person" [ngClass]="{'error': showError}"
        #peoplePickerTemp>
      <span *ngIf="!hideSuggestion && !noResultFound && (resultData == undefined || resultData?.length == 0 || inputItem=='')" id="suggestionTextForPeoplePicker_id" [ngStyle]="{'margin-left': isVipException() ? '-30px':'10px'}"  class="search-caption">
        You can find based on alias, personnel number or last name, first name</span>
    </div>
    <!-- <span *ngIf="showError" class="error-text"><i>Please select employee</i></span> -->
    <div class="combobox-options" id="combo_options" *ngIf="!listHidden">
      <!-- <list-item *ngFor="let item of filteredList;let i = index" (click)="selectItem(i)" [ngClass]="{'selected': i===selectedIndex}">{{item}}</list-item> -->
      <list-item *ngFor="let item of resultData;let i = index" class="BGC"
        (click)="selectItem(item.name, item.personnelNumber, item.value)" [ngClass]="{'selected': i===selectedIndex}">
        <div class="flex-container user-data">
          <div class="profile-image p-0" style="float: left;margin-top: 10px;">
            <img style="height: 40px;" src="../../../assets/images/profile_icon.png">
          </div>
          <div class="c-80 p-0" style="float: right;margin-top: 10px;">
            <span class="user_name" [innerHTML]="item.name |highlight:inputItem"></span>
            <div>
              <span class="dotgreen" *ngIf="item?.employeeStatusDesc == 'Active'"></span>
              <span class="dotred" *ngIf="item?.employeeStatusDesc == 'Retiree'"></span>
              <span class="dotgrey" *ngIf="item?.employeeStatusDesc == 'Withdrawn'"></span>
              <span class="user-info"><span>{{item.employeeGroupDesc}}</span> -
                <span>{{item.employeeStatusDesc}}</span> </span> / <span class="user-info">{{item.jobLevelText}}</span>
            </div>
            <div><span class="user-info">WBS: {{item.masterCostCenterNumber}}</span><span
                *ngIf="item?.facilityName != ''"> / </span>
              <!-- <span
                class="user-info">{{item.firmFunctionDesc}}</span> / <span
                class="user-info">{{item.serviceLineDesc}}</span> /  -->
              <span class="user-info">{{item.facilityName}}</span>
            </div>
          </div>
        </div>
      </list-item>
    </div>
    <div class="combobox-options" id="combo_options" *ngIf="listHidden && this.searchedText.length > 3 && noResultFound && hasNoResult">
      <list-item class="no-record">
        <p>No records found.</p>
      </list-item>
    </div>
  </div>
<button id="insufficientData" [hidden]="true" data-toggle="modal" data-target="#insufficientDataModal">Open
    Modal</button>
  
  <div class="modal fade" id="insufficientDataModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div style="width:50%;position: absolute;left: 35%;" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Device Wipe</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            DCD cannot create tickets for withdrawn employees.  You will need to create this ticket through Service Now.
        </div>
        <div class="modal-footer">
          <button type="button" id="btn_modelClose" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <!-- <button (click)="conformDelete()" type="button" class="btn btn-primary">Yes</button> -->
        </div>
      </div>
    </div>
  </div>







<div class="modal" id="myModalhistory" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static"
    data-keyboard="false" style="z-index: 100000 !important;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <form [formGroup]="wipeform" (ngSubmit)="submit($event)">
                <div class="" style="padding:20px 16px 0 15px">
                    <div class="row">
                        <div class="col-md-9">
                            <label>Choose Action</label>
                            <div>
                                <label class="custom-control fill-checkbox">
                                    <input type="radio" checked id="IsWiped" value="WIPED" name="iswiped"
                                        formControlName="iswiped" class="form-control fill-control-input"
                                        (click)="checkwipe()">
                                    <span class="fill-control-indicator" style="border-radius:8px !important"
                                        id="allvalueinfo"></span>
                                    <span class="violation-text">WIPE</span>
                                </label>
                                <label class="custom-control fill-checkbox">
                                    <input type="radio" id="IsNotWiped" value="NOTWIPED" name="iswiped"
                                        formControlName="iswiped" class="form-control fill-control-input"
                                        (click)="checkwipe()">
                                    <span class="fill-control-indicator" style="border-radius:8px !important"></span>
                                    <span class="violation-text">NOT WIPED</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                style="margin-top:0px">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>

                </div>
                <hr>
                <div class="modal-body" *ngIf="iswipeselected">
                    <label>Assignment Group for the Service Now Requests: *</label>
                    <div class="form-group">
                        <select class="form-control" formControlName="assignmentGroup">
                            <option hidden value="" disabled selected>Select Option</option>
                            <option *ngFor="let data of assignmentGroupModeldata" [value]="data.groupName">{{data.groupName}}</option>
                        </select>
                    </div>
                    <label>Reason for Wipe: *</label>
                    <div class="form-group">

                        <select class="form-control" formControlName="reasonforwipe">
                            <option hidden value="" disabled selected>Select Option</option>
                            <option *ngFor="let data of reasonforwiping" [value]="data.name">{{data.name}}</option>
                        </select>
                    </div>
                    <div style="margin-top: 15px;">
                        <label> MTN Status: *</label>
                        <div class="form-group">

                            <select formControlName="mtnstatus" class="form-control">
                                <option hidden value="" disabled selected>Select Option</option>
                                <option *ngFor="let data of mtnstatusvalues" [value]="data.name">{{data.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div style="margin-top: 15px;">
                        <label> Employee's Personal Email Address: </label>
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="email" />
                        </div>
                    </div>
                    <div style="margin-top: 15px;">
                        <label> Personal Device: *</label>
                        <div class="form-group">

                            <select formControlName="personaldevice" class="form-control">
                                <option hidden value="" disabled selected>Select Option</option>
                                <option *ngFor="let data of ispersonaldevice" [value]="data.name">{{data.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div style="margin-top: 15px;">
                        <label>Remote Wipe Sent: *</label>
                        <div class="form-group">

                            <select formControlName="remotewipesent" class="form-control">
                                <option hidden value="" disabled selected>Select Option</option>
                                <option *ngFor="let data of isremotewipesent" [value]="data.name">{{data.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div style="margin-top: 15px;">
                        <label class="comment-text" for="emp">Comment *</label>

                        <div class="form-group">
                            <textarea id="commentDataIDFromCommentComp" rows="2" cols="50" class="form-control"
                                formControlName="comment" placeholder="Add Comment"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-body" *ngIf="isnotwipeselected">
                    <label>Assignment Group for the Service Now Requests: *</label>
                    <div class="form-group">
                        <select class="form-control" formControlName="assignmentGroup">
                            <option hidden value="" disabled selected>Select Option</option>
                            <option *ngFor="let data of assignmentGroupModeldata" [value]="data.groupName">{{data.groupName}}</option>
                        </select>
                    </div>
                    <label>Reason for Not Wiping Data: *</label>
                    <div class="form-group">
                        <select class="form-control" formControlName="reasonfornotwiping">
                            <option hidden value="" disabled selected>Select Option</option>
                            <option *ngFor="let data of reasonfornotwipingValues" [value]="data.name">{{data.name}}</option>
                        </select>
                    </div>
                    <div style="margin-top: 15px;">
                        <label>MTN Status: *</label>
                        <div class="form-group">

                            <select formControlName="notwipedmtnstatus" class="form-control">
                                <option hidden value="" disabled selected>Select Option</option>
                                <option *ngFor="let data of mtnstatusvalues" [value]="data.name">{{data.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div style="margin-top: 15px;">
                        <label>Employee's Personal Email Address: </label>
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="notwipedemail" />
                        </div>
                    </div>
                    <div style="margin-top: 15px;">
                        <label>Personal Device: *</label>
                        <div class="form-group">
                            <select formControlName="notwipedpersonaldevice" class="form-control">
                                <option hidden value="" disabled selected>Select Option</option>
                                <option *ngFor="let data of ispersonaldevice" [value]="data.name">{{data.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    
                    <div style="margin-top: 15px;">
                        <label class="comment-text" for="emp">Comment *</label>

                        <div class="form-group">
                            <textarea id="commentDataIDFromCommentComp" rows="2" cols="50" class="form-control"
                                formControlName="notwipedcomment" placeholder="Add Comment"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-default notify-carrier-cancel closeDevice-wipe" data-dismiss="modal" aria-label="Close" >Cancel</button>
                    <button type="button" class="btn btn-primary" [disabled]="!wipeform.valid" (click)="DeviceWipeSubmit()">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ConfirmDialogueComponent } from '../_shared-component/confirm-dialogue/confirm-dialogue.component';
import { NotExistModalComponent } from '../_shared-component/not-exist-modal/not-exist-modal.component';
import { SessionTimeoutComponent } from '../_shared-component/session-timeout/session-timeout.component';
import { SuccessMessageDialogueComponent } from '../_shared-component/success-message-dialogue/success-message-dialogue.component';
import { UnsuccessMessageDialogueComponent } from '../_shared-component/unsuccess-message-dialogue/unsuccess-message-dialogue.component';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private ngbModal: NgbModal) { }
  confirm(
    prompt = 'Really?', title = 'Confirm'
  ): Observable<boolean> {
    const modal = this.ngbModal.open(
      ConfirmDialogueComponent, { backdrop: 'static' });

    modal.componentInstance.prompt = prompt;
    modal.componentInstance.title = title;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }
  update(
    message: string, title = 'Message'
  ): Observable<boolean> {
    const modal = this.ngbModal.open(
      ConfirmDialogueComponent, { backdrop: 'static' });

    modal.componentInstance.message = message;
    modal.componentInstance.title = title;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }
  message(
    message: string, title = 'Message'
  ): Observable<boolean> {
    const modal = this.ngbModal.open(
      SuccessMessageDialogueComponent, { backdrop: 'static' });

    modal.componentInstance.message = message;
    modal.componentInstance.title = title;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  notExist(
    message: string, title = 'Message'
  ): Observable<boolean> {
    const modal = this.ngbModal.open(
      NotExistModalComponent, { backdrop: 'static' });

    modal.componentInstance.message = message;
    modal.componentInstance.title = title;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }
  aleradyExist(message: string, title = 'Message'
  ): Observable<boolean> {
    const modal = this.ngbModal.open(
      UnsuccessMessageDialogueComponent, { backdrop: 'static' });

    modal.componentInstance.message = message;
    modal.componentInstance.title = title;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
    
  }

  sessionTimeOut(
    message: string, title = 'Message'
  ): Observable<boolean> {
    const modal = this.ngbModal.open(
      SessionTimeoutComponent, { backdrop: 'static' });

    modal.componentInstance.message = message;
    modal.componentInstance.title = title;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  } 
}



<section class="employee-profile-main-comp">
    <div class="row" style="margin-top:-22px" id="hide_toggle_emp_childs">
        <div class="col-md-12 text-right">
            <ng-template #tipContentMobile>Mobile</ng-template>
            <span class="icon-Mobile_phone_POS" [ngbTooltip]="tipContentMobile" placement="bottom" (click)="mobileChange()">
            </span>
            <span class="checkbox switcher">
                <label class="toggle-label">
                    <input type="checkbox" id="toggleValue_Laptop" name="togglevalue"
                        [(ngModel)]="toggleValueForMobileLaptop" (click)="toggleForMobile(toggleValueForMobileLaptop)">
                    <span><small></small></span>
                </label>
            </span>
            <ng-template #tipContentLaptop>Laptop</ng-template>
            <span class="icon-Laptop_monitor_display_POS" [ngbTooltip]="tipContentLaptop" placement="bottom" (click)="laptopChange()">
            </span>
        </div>
    </div>
    <div class="row">
        <!-- <app-side-nav (emitShowEvent)="openSideNav($event)" (emitHideEvent)="closeSideNav($event)"></app-side-nav> -->
        <div class="side-filter-nav col-md-4">
            <!-- <app-side-filter-nav></app-side-filter-nav> -->
            <app-empsidenav id="tableCol" [personnelNumber]="personnelNumber" [notificationCount]="notificationCount"
                [commentCount]="commentCount" [laptopCommentCount]="laptopCommentCount"
                [violationCount]="violationCount" [exceptionCount]="exceptionCount"
                [notificationDetails]="notificationDetails" [isEligible]="isEligible" [actionType]="actionType"
                [inventoryEmpView]="inventoryEmpView" (emitIMIE)="openImei($event)" (emitMTN)="openMtn($event)"
                [employeeDetails]="employeeDetails" [violationDetails]="violationDetails"
                [exceptionDetails]="exceptionDetails" [historyDataByInc]="historyDataByInc"
                [historyDataByReq]="historyDataByReq" [transferDetails]="transferDetails"
                [loaHistoryDetails]="loaHistoryDetails" [userName]="userName"
                [exceptionDataForLaptop]="exceptionDataForLaptop" [laptopEmpViolationData]="laptopEmpViolationData"
                [laptopEmpCommentsData]="laptopEmpCommentsData" [laptopNotificationsData]="laptopNotificationsData"
                [toggleValueForMobileLaptop]="toggleValueForMobileLaptop"></app-empsidenav>
        </div>
        <div class="filtered-data-employee-profile col-md-8">
            <div *ngIf="mtngridinformation || imeigridinformation" class="col-md-4"
                style="margin-left:-30px;cursor: pointer;">
                <div (click)="back()">
                    <img src="assets/images/collapse arrows.svg"><span class="hidedata-text"
                        style="font-size:12px;font-weight:600;color:#56585a">Back</span>
                </div>
            </div>
            <app-emptiles *ngIf="!mtngridinformation && !imeigridinformation"
                (emitHideToggleEmpChild)="hideToggleEmpChild($event)"
                (emitEventtileClose)="closetilefilternav($event)" [personnelNumber]="personnelNumber"
                (emitEventtileOpen)="opentilefilternav($event)" id="tilecol" [employeeDetails]="employeeDetails"
                [inventoryEmpView]="inventoryEmpView" [billingEmpView]="billingEmpView" [compEmpView]="compEmpView"
                [ordersEmpView]="ordersEmpView" [violationDetails]="violationDetails"
                [exceptionDetails]="exceptionDetails" [billingDetails]="billingDetails"
                [laptopTileData]="laptopTileData" [toggleValueForMobileLaptop]="toggleValueForMobileLaptop">
            </app-emptiles>
            <!-- <router-outlet></router-outlet>-->

            <app-mtn *ngIf="mtngridinformation" [selectedMTN]="selectedMTN"></app-mtn>
            <app-imei *ngIf="imeigridinformation" [selectedIMEI]="selectedIMEI"></app-imei>
        </div>
    </div>
</section>
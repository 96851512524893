import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComplianceRule } from 'src/app/_models/laptop-compliance/complianceRule.model';
import { LaptopComplianceSearchReq } from 'src/app/_models/laptop-compliance/complianceSearchResult.model';
import { IncidentTicketCreation } from 'src/app/_models/laptop-compliance/incidentTicketCreation.model';
import { InsertLaptopEmail } from 'src/app/_models/laptop-compliance/insertLaptopEmail.model';
import { LaptopModels } from 'src/app/_models/laptop-compliance/laptop-models.model';
import { LaptopCommentsResponse } from 'src/app/_models/laptop-compliance/laptopComment.model';
import { LaptopExceptionReq, LaptopExceptionResponse } from 'src/app/_models/laptop-compliance/laptopException.model';
import { LaptopNotificationResponse } from 'src/app/_models/laptop-compliance/laptopNotification.model';
import { NotifyUserForLaptop } from 'src/app/_models/laptop-compliance/laptopNotifyUser.model';
import { ViolationResponse } from 'src/app/_models/laptop-compliance/laptopViolation.model';
import { AppConstants } from 'src/app/_utils/app-constants';
import { DataContextService } from '../datacontext.service';
import { OSVersion } from 'src/app/_models/OSVersion.model';
import { InsertODCReportDetails } from 'src/app/_models/insertODCReportDetails.model';
import { SaveComplianceNotificationModel } from 'src/app/_models/laptop-compliance/saveComplianceNotification.model';
import { DisconnectUserResponse } from 'src/app/_models/laptop-compliance/disconnectUser.model';

@Injectable({
  providedIn: 'root'
})
export class LaptopComplianceService {

  searchCriteria: LaptopComplianceSearchReq;
  totalItemsForGrid: number = 10;
  totalCountForLaptopCompliance: number = 0;
  laptopCompliancesearchResultsList: any;
  apiurl: string = localStorage.getItem('apiurl');
  apiurlv2: string = localStorage.getItem("apiurlv2");
  complianceRules: ComplianceRule[];

  constructor(private http: HttpClient, private _datacontextService: DataContextService) { }
  getComplianceRules() {
    return this._datacontextService.getData<ComplianceRule[]>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetComplianceRules)
      .pipe(map(data => {
        this.complianceRules = data;
        return data;
      }));
  }
  getLaptopComplianceSearchResult(obj: LaptopComplianceSearchReq): Observable<any[]> {
    this.searchCriteria = obj;
    return this._datacontextService.postData<any>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetLaptopComplianceSearchResult, obj).pipe(map(data => {
      this.laptopCompliancesearchResultsList = data.laptopComplianceResult.map(d => ({ ...d, isHighlighter: false }));
      this.totalCountForLaptopCompliance = data.totalRecord;
      return data;
    }));
  }
  getLaptopComments(PersonnelNbr, SerialNumber, ComplianceViolationID) {
    return this._datacontextService.getData<LaptopCommentsResponse[]>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetLaptopComments + '?PersonnelNbr=' + PersonnelNbr + '&SerialNumber=' + SerialNumber + '&ComplianceViolationID=' + ComplianceViolationID).pipe(map(data => data));
  }

  AddLaptopComments(obj: LaptopCommentsResponse): Observable<any> {
    return this._datacontextService.postData<any>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.AddLaptopComments, obj)
      .pipe(map(data => {
        return data;
      }));

  }
  getComplianceIssues(PersonID) {
    return this._datacontextService.getData<ViolationResponse[]>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetComplianceIssues + '?PersonID=' + PersonID).pipe(map(data => data));
  }
  getComplianceNotificationDetails(ComplianceViolationID, EmployeeEmail) {
    return this._datacontextService.getData<LaptopNotificationResponse[]>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetComplianceNotificationDetails + '?ComplianceViolationID=' + ComplianceViolationID + '&EmployeeEmail=' + EmployeeEmail).pipe(map(data => data));
  }
  getComplianceExceptions(PersonID, ComplianceViolationID) {
    return this._datacontextService.getData<LaptopExceptionResponse[]>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetComplianceExceptions + '?PersonID=' + PersonID + '&ComplianceViolationID=' + ComplianceViolationID).pipe(map(data => data));
  }
  addLaptopException(obj: LaptopExceptionReq): Observable<string> {
    return this._datacontextService.postData<string>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.AddLaptopException, obj)
      .pipe(map(data => {
        return data;
      }));
  }
  getNotificationTemplateDetails(ViolationsList) {
    return this._datacontextService.getData<NotifyUserForLaptop[]>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetNotificationTemplateDetails + '?ViolationsList=' + ViolationsList).pipe(map(data => data));
  }
  saveComplianceNotification(obj: SaveComplianceNotificationModel) {
    return this._datacontextService.postData<any>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.SaveComplianceNotification, obj).pipe(map(data => data));
  }
  insertEmailToSchedule(obj: InsertLaptopEmail): Observable<string> {
    return this._datacontextService.postData<string>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.InsertEmailToSchedule, obj)
      .pipe(map(data => {
        return data;
      }));
  }
  disconnectUser(obj : DisconnectUserResponse) : Observable<any> {
    return this._datacontextService.postData<any>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.DisconnectUser, obj).pipe(map(data => {
      return data;
    }));
  }
  CreateIncidentWithCI(obj: IncidentTicketCreation) {
    return this._datacontextService.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.CreateIncidentWithCI, obj).pipe(map(data => {
      return data;
    }));
  }
  getAllLaptopModels() {
    return this._datacontextService.getData<LaptopModels[]>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetAllLaptopModels).pipe(map(data => data));
  }
  getAllOSVersion() {
    return this._datacontextService.getData<OSVersion[]>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetAllOSVersion).pipe(map(data => data));
  }
  getODCLaptopComments(PersonnelNbr, SerialNumber, ComplianceViolationID) {
    return this._datacontextService.getData<LaptopCommentsResponse[]>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetODCLaptopComments + '?PersonnelNbr=' + PersonnelNbr + '&SerialNumber=' + SerialNumber + '&ComplianceViolationID=' + ComplianceViolationID).pipe(map(data => data));
  }
  getODCComplianceIssues(PersonID) {
    return this._datacontextService.getData<ViolationResponse[]>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetODCComplianceIssues + '?PersonID=' + PersonID).pipe(map(data => data));
  }
  getODCComplianceExceptions(PersonID, ComplianceViolationID) {
    return this._datacontextService.getData<LaptopExceptionResponse[]>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetODCComplianceExceptions + '?PersonID=' + PersonID + '&ComplianceViolationID=' + ComplianceViolationID).pipe(map(data => data));
  }
  addODCLaptopException(obj: LaptopExceptionReq): Observable<string> {
    return this._datacontextService.postData<string>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.AddODCLaptopException, obj)
      .pipe(map(data => {
        return data;
      }));
  }
  InsertODCReportDetailsLaptop(obj: InsertODCReportDetails) {
    return this._datacontextService.postData<boolean>(this.apiurlv2+AppConstants.GPA_ENDPOINTS.Reports.InsertODCReportDetailsLaptop ,obj).pipe(map(data => data));
  }
  UpdateViolationIncident(obj: { complianceViolationID: number; ticketNumber: string }) {
    return this._datacontextService.postData<string>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.UpdateViolationIncident, obj).pipe(map(data => {
      return data;
    }));
  }
  getODCLaptopComplianceSearchResult(obj: LaptopComplianceSearchReq): Observable<any[]> {
    this.searchCriteria = obj;
    return this._datacontextService.postData<any>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetODCLaptopComplianceSearchResult, obj).pipe(map(data => {
      this.laptopCompliancesearchResultsList = (data.laptopComplianceResult || []).map(d => ({ ...d, isHighlighter: false }));
      this.totalCountForLaptopCompliance = data.totalRecord;
      return data;
    }));
  }
}

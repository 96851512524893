<section class="employee-profile-exception-comp">
    <div class="row history-tabs">
      <ul class="col-md-12 nav nav-tabs" role="tablist">
        <li class="col-md-6 nav-item">
          <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Mobile Exceptions List: {{exceptionData.length}}
          </a>
        </li>
        <li class="col-md-6 nav-item">
          <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Laptop Exceptions List: {{exceptionDataForLaptop.length}}
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <div class="tab-pane active" id="tabs-1" role="tabpanel">
        <div *ngIf="exceptionData">
          <!-- <div *ngIf="exceptionData.length !== undefined && exceptionData.length !== 0 ">
            <div class="mt-4 row">
              <div class="offset-md-0 past-exception-text">
                <p class="past-comments">Exceptions List:</p>
  
              </div>
            </div>
          </div> -->
  
          <div class="emp-comment-scroll tab-violation-laptop" *ngIf="exceptionData?.length > 0">
            <div class="card" *ngFor="let exceptiondata of exceptionData;let i=index">
              <div [ngClass]="(i%2===0)?'card-header':'card-body'" *ngIf="exceptiondata?.vipPersonneNbr == undefined">
                <label class="issue-text">Exception Category</label>
                <p class="issue-data">{{exceptiondata.ruletypedesc}}</p>
                <label class="issue-text">Violation Type</label>
                <p class="issue-data">{{exceptiondata.category}}</p>              
                <label class="issue-text">Expiration Date</label>
                <p class="issue-data">{{exceptiondata.dateEnd|date}}</p>
                <label class="issue-text">Exception Status</label>
                <p class="issue-data">{{exceptiondata.exceptionStatus}}</p>
                <label class="issue-text">Comments</label>
                <p class="issue-data">{{exceptiondata.commentText}}</p>
                <label class="issue-text">Date/Time</label>
                <p class="issue-data">{{exceptiondata.addedDate | date: "MMM d, y, h:mm:ss a"}}</p>
                <label class="issue-text">Added By</label>
                <p class="issue-data">{{exceptiondata.addedBy}}</p>
              </div>
              <div [ngClass]="(i%2===0)?'card-header':'card-body'" *ngIf="exceptiondata?.vipPersonneNbr != undefined">
                <label class="issue-text">Exception Category</label>
                <p class="issue-data">{{exceptiondata.exceptionType}}</p>
                <label class="issue-text">Exception Status</label>
                <p class="issue-data">{{exceptiondata.exceptionStatus}}</p>
                <label class="issue-text">Comments</label>
                <p class="issue-data">{{exceptiondata?.commentText}}</p>
                <label class="issue-text">Date/Time</label>
                <p class="issue-data">{{exceptiondata?.dateCreated | date: "MMM d, y, h:mm:ss a"}}</p>
                <label class="issue-text">Added By</label>
                <p class="issue-data">{{exceptiondata.adminName}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="notification-data"
          *ngIf="exceptionData === null || exceptionData === undefined || exceptionData.length === 0">
          <p class="no-notifications">No Records Found</p>
        </div>
      </div>
      <div class="tab-pane" id="tabs-2" role="tabpanel">
        <div *ngIf="exceptionDataForLaptop">
          <!-- <div *ngIf="exceptionDataForLaptop.length !== undefined && exceptionDataForLaptop.length !== 0 ">
            <div class="mt-4 row">
              <div class="offset-md-0 past-exception-text">
                <p class="past-comments">Exceptions List:</p>
  
              </div>
            </div>
          </div> -->
  
          <div class="emp-comment-scroll tab-violation-laptop" *ngIf="exceptionDataForLaptop?.length > 0">
            <div class="card" *ngFor="let exceptionDataForLaptop of exceptionDataForLaptop;let i=index">
              <div [ngClass]="(i%2===0)?'card-header':'card-body'" *ngIf="exceptionDataForLaptop?.vipPersonneNbr == undefined">
                <label class="issue-text">Exception Category</label>
                <p class="issue-data">{{exceptionDataForLaptop.category}}</p>
                <label class="issue-text">Violation Type</label>
                <p class="issue-data">{{exceptionDataForLaptop.complianceRuleDesc}}</p>
                <label class="issue-text">Expiration Date</label>
                <p class="issue-data">{{exceptionDataForLaptop.dateEnd|date}}</p>
                <label class="issue-text">Exception Status</label>
                <p class="issue-data">{{exceptionDataForLaptop.exceptionStatus}}</p>
                <label class="issue-text">Comments</label>
                <p class="issue-data">{{exceptionDataForLaptop.commentText}}</p>
                <label class="issue-text">Date/Time</label>
                <p class="issue-data">{{exceptionDataForLaptop.addedDate | date: "MMM d, y, h:mm:ss a"}}</p>
                <label class="issue-text">Added By</label>
                <p class="issue-data">{{exceptionDataForLaptop.addedBy}}</p>             
              </div>
              <div [ngClass]="(i%2===0)?'card-header':'card-body'" *ngIf="exceptionDataForLaptop?.vipPersonneNbr != undefined">
                <label class="issue-text">Exception Category</label>
                <p class="issue-data">{{exceptionDataForLaptop.category}}</p>
                <label class="issue-text">Exception Status</label>
                <p class="issue-data">{{exceptionDataForLaptop.exceptionStatus}}</p>
                <label class="issue-text">Comments</label>
                <p class="issue-data">{{exceptionDataForLaptop?.commentText}}</p>
                <label class="issue-text">Date/Time</label>
                <p class="issue-data">{{exceptionDataForLaptop?.dateCreated | date: "MMM d, y, h:mm:ss a"}}</p>
                <label class="issue-text">Added By</label>
                <p class="issue-data">{{exceptionDataForLaptop.adminName}}</p>
               
              </div>
            </div>
          </div>
        </div>
  
        <div class="notification-data"
          *ngIf="exceptionDataForLaptop === null || exceptionDataForLaptop === undefined || exceptionDataForLaptop.length === 0">
          <p class="no-notifications">No Records Found</p>
        </div>
      </div>
    </div>
  </section>
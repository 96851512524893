import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMyDate, IMyOptions } from 'mydatepicker';
import { AssignDevice } from 'src/app/_models/inventory/AssignDevice.model';
import { AssignmentGroupModel } from 'src/app/_models/inventory/assignmentgroupmodels';
import { CategoryModel } from 'src/app/_models/inventory/category.model';
import { InsertEmailScheduleInventory } from 'src/app/_models/inventory/InventoryNotification.model';
import { MTNList } from 'src/app/_models/inventory/MTNList';
import { Office } from 'src/app/_models/inventory/Office.model';
import { ServiceNowLookup } from 'src/app/_models/inventory/ServiceNowLookup.model';
import { ServicenowTicketDetails } from 'src/app/_models/inventory/servicenowticketdetails.model';
import { SubCategoryModel } from 'src/app/_models/inventory/subcategory.model';
import { UserTicketDetailsModel } from 'src/app/_models/inventory/userticketdetailsmodel';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
import { InventoryService } from 'src/app/_services/inventory/inventory.service';
import { ModalService } from 'src/app/_services/modal.service';
import { GlobalVariables } from 'src/app/_utils/global-variables';
import $ from 'jquery';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-assigndevice',
  templateUrl: './assigndevice.component.html',
  styleUrls: ['./assigndevice.component.scss']
})
export class AssigndeviceComponent implements OnInit {
  toplaceholder: string = "Expected Return";
  selectedDate: IMyDate = { year: 0, month: 0, day: 0 };
  myDatePickerOptions: IMyOptions = {
    dateFormat: 'mm/dd/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: true,
  };
  toDateSelected: boolean;
  toDateSelectedMust: boolean;
  responseTicketNo: string = '';
  loggedinUser: string;
  loggedinUserName: string;
  locationdata: any;
  statusdata: any;
  Devicelocationdata: any;
  DeviceStatusdata: any;
  MtnListdata: MTNList[];
  carrier: any;
  searchRowData: any;
  deviceName: any;
  imei: any;
  location: any;
  office: any;
  devicetype: any;
  assignDevice: AssignDevice;
  assignDeviceObject: AssignDevice;
  multiassignDeviceObject: AssignDevice[] = [];
  assignDeviceoutput: string;
  currentAction: string;
  FromAction: string;
  ActionFromDetails: string;
  EmployeeAlias: string;
  EmpFacilityCode: string;
  EmpFacilityName: string;
  toDeviceLocation: any;
  toDeviceLocationName: string;
  toOfficeLocation: string;
  OfficeLocationList: Office[];
  Devicecatelogdata: any;
  ddlRequestStateVal: string;
  ddlRequestItemVal: string;
  notifyCarrierSelected: boolean = false;
  searchTONameVal: string = '';
  searchEmployeeAlias: string = '';
  searchEmployeePersonnelNbr: string = '';
  searchEmployeeAlias2: string = '';
  searchEmployeePersonnelNbr2: string = '';
  shippingTrackingVal: string = '';
  ddlCarrierVal: string = '';
  ddlRetailStoreVal: string = '';
  ddlDeivceStatusVal: any = '';
  ddlDeviceAssetStatusVal: any = '';
  replacedDeviceSelected: boolean = false;
  ticketNumberVal: string;
  ticketForVal: any;
  ticketTypeVal: string;
  ddlStateVal: string;
  ddlCategoryVal: string;
  ddlSubCategoryVal: string;
  ddlAssignmentGroupVal: string;
  ddlMTNVal: string;
  ddlDeviceReturnedToVal: string;
  descriptionVal: string;
  notesVal: string = '';
  showTicketGrid: boolean;
  ticketGridData: UserTicketDetailsModel[];
  personIDOrTicketNumber: string;
  snowAssignedTo: string;
  ticketStatus: string;
  IsCreateTicket: boolean;
  serviceNowTicketDetail: ServicenowTicketDetails;
  isMTNDropdown: boolean;
  PeoplePicActiveEmployeeEmailAlias: string = '';
  printflag: boolean = false;
  newdate: Date;
  currentdate: string;
  IsLoanerAsset: boolean = false;
  loanerAssetRowCnt: number;
  isOfficeLocationDisable: boolean = false;
  fromplaceholder: string = 'MM/DD/YYYY';
  setReturnDate: boolean = false;
  rowTicketNo: string;
  rowTicketPrevComments: string;
  isNotifyWhileSubmit: boolean = false;
  ddlReasonForReturnVal: string;
  txtDTStillFuncVal: string;
  txtDeviceModelTypeVal: string;
  txtImeiEsnHexaNumberVal: string;
  txtDTMobileNbrVal: string;
  txtSNCarrierVal: string;
  ddlShippedVal: string;
  txtMTNMobileNbrVal: string;
  txtSNModelVal: string;
  txtShippedDeviceCarrierVal: string;
  txtSNTrackingVal: string;
  txtDesiredRepDeviceVal: string;
  txtShippedAddRepDeviceVal: string;
  txtESNMEIDIMEVal: string;
  txtServiceAccSuspentVal: string;
  txtRepModelVal: string;
  txtRepESNMEIDIMEIVal: string;
  txtRepMTNVal: string;
  txtOrdDateVal: string;
  txtWLNbmAssociateDeviceVal: string;
  ddlReasonForWipeVal: string;
  ddlMtnStatusVal: string;
  txtEmpPersonelEmailAddrVal: string;
  ddlWipeStatusVal: string = '';
  txtKDLoggedPTSIncidentVal: string = '';
  MTNSelectedVal: string = '';
  ServiceDeskLookup: ServiceNowLookup[] = [];
  serviceNowChecklistItems: any[] = [];


  assetStatusItems: any[] = [
    { assetStatusID: 1, mobileAssetStatus: "Assigned" },
    { assetStatusID: 2, mobileAssetStatus: "Loaner" },
    { assetStatusID: 3, mobileAssetStatus: "Training" },
    { assetStatusID: 4, mobileAssetStatus: "Test" }
  ]
  requeststate: any[] = [
    { name: "Open" },
    { name: "Work In Progress" },
    { name: "Closed Complete" },
    { name: "Closed Incomplete" }
  ];
  stateitems: any[] = [
    { name: "New" },
    { name: "Active" },
    { name: "Awaiting User" },
    { name: "Awaiting vendor" },
    { name: "Awaiting Problem" },
    { name: "Awaiting Internal" },
    { name: "Resolved" },
    { name: "Cancelled" }
  ];
  reasonForWipedata: any[] = [

    { name: "Separation" },
    { name: "Upgrade" }
  ];
  mtnStatusdata: any[] = [

    { name: "Cancel the MTN" },
    { name: "Keep MTN active (i.e. device upgrade)" },
    { name: "Release MTN to user's ownership" }
  ];
  wipeStatusdata: any[] = [

    { name: "Wiped/Reset to factory defaults" },
    { name: "Not wiped due to OGC permanent hold" },
    { name: "Not wiped due to SDHD 10 day hold" },
    { name: "Not wiped due to physical damage" },
    { name: "Not wiped due to software issue" }
  ];
  reasonForReturn: any[] = [
    { name: "Termination" },
    { name: "Upgrade/Replacement" },
    { name: "Low Usage" }
  ];
  ShippedData: any[] = [
    { name: "TSS" },
    { name: "Hermitage" },
    { name: "Recellular" }
  ];

  categoryModeldata: CategoryModel[];
  subCategoryModeldata: SubCategoryModel[];
  assignmentGroupModeldata: AssignmentGroupModel[];

  //Display flags
  DeviceSectionVisibleFlg: boolean;
  notifyCarrierFlg: boolean;
  toEmployeeFlg: boolean;
  toTechroomFlg: boolean;
  toLostOrStolenFlg: boolean;
  toInTransitToCarrierFlg: boolean;
  toInTransitToTechroomFlg: boolean;
  toInTransitToRecyleFlg: boolean;
  newReqOptionSelected: boolean;
  newIncOptionSelected: boolean;
  snowOptionSelectedVal: string;
  newOptionSelected: boolean;
  existingOptionSelected: boolean;

  newReqFromEToTRPDA: boolean = false;

  isTicketRowSelected: boolean;
  isTicketRowInc: boolean;
  isTicketRowReq: boolean;
  selectdTicketNo: string;
  isMultiSelectAvailable: Boolean = false;
  selectedAliasName: any;
  selectedSNowAliasName: any;
  messageResult: boolean;
  showPrint: boolean;

  //service now checklist flags
  snowReqItemVisible: boolean = false;
  snowReqReasonForWipe: boolean = false;
  snowReqMTNStatus: boolean = false;
  snowReqEmailAddress: boolean = false;
  snowReqWipeStatus: boolean = false;
  snowAssignToVisible: boolean = false;
  snowAssignGrpVisible: boolean = false;
  snowReqEmpPersonelEmailAddr: boolean = false;
  snowReqKDLoggedPTSIncident: boolean = false;
  snowReqReasonForReturn: boolean = false;
  snowReqShippedTo: boolean = false;
  snowReqDeviceModelType: boolean = false;
  snowReqImeiEsnHexaNumber: boolean = false;
  snowReqDTMobileNbr: boolean = false;
  snowReqDTStillFunc: boolean = false;
  snowReqSNCarrier: boolean = false;
  snowReqMTNMobileNbr: boolean = false;
  snowReqSNModel: boolean = false;
  snowReqESNMEIDIME: boolean = false;
  snowReqServiceAccSuspent: boolean = false;
  snowReqRepModel: boolean = false;
  snowReqRepESNMEIDIMEI: boolean = false;
  snowReqRepMTN: boolean = false;
  snowReqOrdDate: boolean = false;
  snowReqtWLNbmAssociateDevice: boolean = false;
  snowReqShippedDeviceCarrier: boolean = false;
  snowReqSNTracking: boolean = false;
  snowReqDesiredRepDevice: boolean = false;
  snowReqShippedAddRepDevice: boolean = false;
  loggedInUserNameVal: string = GlobalVariables.loggedInUserName;
  Role: string = '';
  @Input() rowData: AssignDevice;
  @Input() multiRowData: AssignDevice[];
  @Input() sourceItem: string;
  @Output() AssignmentChanged = new EventEmitter();
  notifyUserHeader: boolean;
  notifyCarrierHeader: boolean;
  selectedItem: any;
  violationIds: any;
  arrayCollectionList: any[] = [];
  bodyHtmlValue: string;
  saveEmailRepoForNotifyUser: string;
  notifyUserObj: any = [];
  isUSIRegion: any = null;
  // notifyCarrierForm: FormGroup;
  insertEmailSchedule = new InsertEmailScheduleInventory();
  enableNCLinck: boolean = false;
  constructor(
    private inventoryservice: InventoryService, public datepipe: DatePipe, private modalService: ModalService, private _empService: EmpProfileService
  ) { 
    this.loggedinUser = localStorage.getItem('loggedInUserName').split('@')[0];
    this.loggedinUserName = localStorage.getItem('LoggedInDisplayName');
    this.Role = localStorage.getItem('UserRole');
  }

  ngOnInit(): void {

    this.DeviceSectionVisibleFlg = false;
    this.notifyCarrierFlg = false;
    this.toEmployeeFlg = false;
    this.toTechroomFlg = false;
    this.toLostOrStolenFlg = false;
    this.toInTransitToCarrierFlg = false;
    this.toInTransitToTechroomFlg = false;
    this.toInTransitToRecyleFlg = false;
    this.newIncOptionSelected = false;
    this.newReqOptionSelected = false;
    this.newOptionSelected = false;
    this.existingOptionSelected = false;
    this.snowReqEmailAddress = false;
    this.snowReqItemVisible = false;
    this.snowReqMTNStatus = false;
    this.snowReqReasonForWipe = false;
    this.snowReqWipeStatus = false;
    this.snowAssignGrpVisible = false;
    this.snowAssignToVisible = false;
    this.searchEmployeePersonnelNbr = '';
    this.newdate = new Date();
    this.currentdate = this.datepipe.transform(this.newdate, 'MM-dd-yyyy');

  }

  ngOnChanges() {

    this.isMultiSelectAvailable = this.inventoryservice.getIsMultiSelect();
    if (this.rowData != undefined && this.rowData != null) {
      this.isUSIRegion = this.rowData['deviceRegion']
      this.inventoryservice
        .getDeviceLocationsByFromLocation(this.rowData.deviceLocationID)
        .subscribe(locationdata => {

          if (this.isMultiSelectAvailable) {
            this.Devicelocationdata = locationdata.filter(function (node) {
              return node.deviceLocationDesc == "In Transit to Recycle";
            });
          }
          else {
            this.Devicelocationdata = locationdata;
          }
          // console.log("locationdata", locationdata);
        });


      if (this.DeviceStatusdata == undefined) {
        this.inventoryservice
          .getallDeviceStatus()
          .subscribe(statusdata => {
            this.DeviceStatusdata = statusdata;

          });
      }

      this.GetCarrier();
      this.DeviceSectionVisibleFlg = false;
      this.notifyCarrierFlg = false;
      this.toEmployeeFlg = false;
      this.toTechroomFlg = false;
      this.toLostOrStolenFlg = false;
      this.toInTransitToCarrierFlg = false;
      this.toInTransitToTechroomFlg = false;
      this.toInTransitToRecyleFlg = false;
      this.newIncOptionSelected = false;
      this.newReqOptionSelected = false;
      this.newOptionSelected = false;
      this.existingOptionSelected = false;
      this.snowOptionSelectedVal = "none";
      this.toDeviceLocation = '';
      this.toOfficeLocation = '';
      this.ddlCarrierVal = '';
      this.ddlRetailStoreVal = '';
      this.ddlDeivceStatusVal = this.rowData.deviceStatusID;
      this.ddlDeviceAssetStatusVal = this.rowData.mobileAssetStatusID;
      this.replacedDeviceSelected = this.rowData.isReplacedDevice;
      this.ddlRequestStateVal = '';
      this.ddlRequestItemVal = '';
      this.ddlStateVal = '';
      this.ddlCategoryVal = '';
      this.ddlSubCategoryVal = '';
      this.ddlAssignmentGroupVal = '';
      this.ddlDeviceReturnedToVal = '';
      this.isMTNDropdown = false;
      this.EmpFacilityCode = '';
      this.ddlMTNVal = '';
      this.ddlReasonForWipeVal = '';

      // if (this.rowData.mobileAssetStatusID == 2) {
      //   this.loanerAssetRowCnt = 0;
      //   this.IsLoanerAsset = this.rowData.hasExpectedReturn;
      //   let d: Date = this.rowData.expectedReturn;
      // this.selectedDate = {year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()};
      // }
      this.ResetAssignDeviceView();
    }
  }
  toDeviceLocationChange() {
    let id = this.toDeviceLocation;
    this.toDeviceLocationName = this.Devicelocationdata.filter(function (node) {
      return node.deviceLocationID == id;
    })[0].deviceLocationDesc;
    this.RefreshDispaly();
    this.ServiceNowRefreshDisplay();
    this.Devicecatelogdata = '';
    this.LoadCatalogData();
  }
  mtnChange(e, row) {
    row.mtn = e.currentTarget.selectedOptions[0].value;
    this.MTNSelectedVal = row.mtn;
    if (row.mtn == "Wifi Only")
      row.mobileAssetStatusID = 2;
    else
      row.mobileAssetStatusID = 1;
  }
  deviceStatusChange(e, row) {
    row.deviceStatusID = e.currentTarget.selectedOptions[0].value;
  }
  assetStatusChange(e, row, i) {
    row.mobileAssetStatusID = e.currentTarget.selectedOptions[0].value;
    // console.log("Mobile Asset Status ID" + row.mobileAssetStatusID);
    this.loanerAssetRowCnt = i;
    if (row.mobileAssetStatusID == 2) {
      this.IsLoanerAsset = true;

    }
    else {
      this.IsLoanerAsset = false;
      row.hasExpectedReturn = false;
      row.expectedReturnString = '';
    }
  }
  isReplacedChange(e, row) {
    row.isReplacedDevice = e.currentTarget.checked;
  }
  hasExpectedReturnChange(e, row) {
    row.hasExpectedReturn = e.currentTarget.checked;
    if (!row.hasExpectedReturn)
      row.expectedReturn = new Date("0001-01-01T00:00:00");
  }
  onDateChangedTo(e, row) {
    row.expectedReturn = e.formatted;
    this.fn_DateChangedEvent(e);
  }
  fn_DateChangedEvent(event) {
    this.toDateSelected = false;
    this.toDateSelectedMust = false;
    let toDateVal = event.formatted;

    if ((event.formatted !== null) && (event.formatted !== undefined)) {
      if ((toDateVal !== null) && (toDateVal !== undefined) && (toDateVal !== '')) {

        let todateTxt = new Date(event.formatted);
        let fromdateTxt = new Date();
        if ((fromdateTxt >= todateTxt)) {
          //$('#SubmitInventory').css('pointer-events', 'none');
          document.getElementById('SubmitInventory').style.pointerEvents = 'none';
          this.toDateSelected = true;
          this.toDateSelectedMust = false;
        } else {
          //$('#SubmitInventory').css('pointer-events', '');
          document.getElementById('SubmitInventory').style.pointerEvents = '';
          this.toDateSelected = false;
          this.toDateSelectedMust = false;
        }
      }
      else if ((toDateVal == '')) {
        //$('#SubmitInventory').css('pointer-events', 'none');
        document.getElementById('SubmitInventory').style.pointerEvents = 'none';
        this.toDateSelected = false;
        this.toDateSelectedMust = true;
      } else {
        //$('#SubmitInventory').css('pointer-events', '');
        document.getElementById('SubmitInventory').style.pointerEvents = '';
        this.toDateSelected = false;
        this.toDateSelectedMust = false;
      }
    }
  }

  RefreshDispaly() {
    if (this.toDeviceLocationName == "Tech Room" && (this.currentAction == "Employee" || (this.currentAction == "In Transit to Tech Room" && !this.isMultiSelectAvailable))) {
      this.printflag = true;
    }
    else {
      this.printflag = false;
    }

    this.notifyCarrierSelected = false;
    this.searchTONameVal = '';
    this.shippingTrackingVal = '';
    //this.shippingTrackingVal = this.rowData.shippingTrackingNbr;

    switch (this.toDeviceLocationName) {
      case "Tech Room": {
        this.DeviceSectionVisibleFlg = true;
        this.toTechroomFlg = true;
        this.toEmployeeFlg = false;
        this.toLostOrStolenFlg = false;
        this.toInTransitToCarrierFlg = false;
        this.toInTransitToTechroomFlg = false;
        this.toInTransitToRecyleFlg = false;
        this.GetOfficeLocationData();

        break;
      }
      case "Employee": {
        this.DeviceSectionVisibleFlg = true;
        this.toTechroomFlg = false;
        this.toEmployeeFlg = true;
        this.toLostOrStolenFlg = false;
        this.toInTransitToCarrierFlg = false;
        this.toInTransitToTechroomFlg = false;
        this.toInTransitToRecyleFlg = false;

        break;
      }
      case "Lost/Stolen": {
        this.DeviceSectionVisibleFlg = true;
        this.toTechroomFlg = false;
        this.toEmployeeFlg = false;
        this.toLostOrStolenFlg = true;
        this.toInTransitToCarrierFlg = false;
        this.toInTransitToTechroomFlg = false;
        this.toInTransitToRecyleFlg = false;
        this.notifyCarrierSelected = true;
        break;
      }
      case "In Transit to Vendor": {
        this.DeviceSectionVisibleFlg = true;
        this.toTechroomFlg = false;
        this.toInTransitToCarrierFlg = true;
        this.toInTransitToTechroomFlg = false;
        this.toInTransitToRecyleFlg = false;
        this.toEmployeeFlg = false;
        this.toLostOrStolenFlg = false;
        this.LoadFacilityCodeInfo(this.loggedinUser);
        break;
      }
      case "In Transit to Tech Room": {
        this.DeviceSectionVisibleFlg = true;
        this.toTechroomFlg = false;
        this.toInTransitToCarrierFlg = false;
        this.toEmployeeFlg = false;
        this.toLostOrStolenFlg = false;
        this.toInTransitToTechroomFlg = true;
        this.toInTransitToRecyleFlg = false;
        this.GetOfficeLocationData();
        if (this.currentAction == "Tech Room") {
          //TODO show barcode
          this.printflag = true;
        }
        else {
          this.printflag = false;
        }
        break;
      }
      case "In Transit to Recycle": {
        this.DeviceSectionVisibleFlg = true;
        this.toTechroomFlg = false;
        this.toInTransitToCarrierFlg = false;
        this.toEmployeeFlg = false;
        this.toLostOrStolenFlg = false;
        this.toInTransitToTechroomFlg = false;
        this.toInTransitToRecyleFlg = true;
        break;
      }
    }
    //this.ResetAssignDeviceView();
    this.snowOptionSelectedVal = "none";
    this.ServiceNowOptSelection(this.snowOptionSelectedVal);
  }

  GetOfficeLocationData() {
    let fc = this.rowData.facilityCode;
    this.isOfficeLocationDisable = false;
    this.inventoryservice
      .getEmployeeFacilitiesByRegion(this.loggedinUser, this.Role == 'NMC', true)
      .subscribe(data => {
        this.OfficeLocationList = data;
        if (fc != '') {
          if (this.currentAction == "In Transit to Tech Room" && this.toDeviceLocationName == "Tech Room") {
            this.OfficeLocationList = this.OfficeLocationList.filter(function (a) {
              return a.facilityCode.indexOf(fc) != -1;
            });
            this.toOfficeLocation = this.OfficeLocationList.length > 0 ? this.OfficeLocationList[0].facilityCode : '';
            this.isOfficeLocationDisable = true;
          }
          else {
            if (this.currentAction == "Employee" && this.toDeviceLocationName == "Tech Room") {
            }
            else {
              this.OfficeLocationList = this.OfficeLocationList.filter(function (a) {
                return a.facilityCode.indexOf(fc) == -1;
              });
            }
          }
        }
      });

    if (this.OfficeLocationList == null ||
      this.OfficeLocationList.length == 0) {
      this.toOfficeLocation = '';
    }
  }

  ResetAssignDeviceView() {
    this.ActionFromDetails = null;
    this.currentAction = this.rowData.currentStatus;
    this.FromAction = this.rowData.currentStatus;
    this.toDeviceLocation = '';

    switch (this.currentAction) {
      case "Tech Room": {
        this.ActionFromDetails = this.rowData.facilityName;
        if (this.isMultiSelectAvailable) {
          this.ActionFromDetails = '';
        }
        break;
      }
      case "Employee": {
        this.EmployeeAlias =
          this.rowData.alias !== "" ? this.rowData.alias : this.loggedinUser;
        if (this.rowData.firstName !== null && this.rowData.firstName !== "") {
          this.ActionFromDetails =
            this.rowData.lastName + ", " + this.rowData.firstName;
        }
        if (
          this.rowData.facilityName === null ||
          this.rowData.facilityName === ""
        ) {

        } else {
          this.EmpFacilityName = this.rowData.facilityName;
        }
        break;
      }
      case "In Transit to Tech Room": {
        if (
          this.rowData.facilityName === null ||
          this.rowData.facilityName === ""
        ) {
          this.LoadFacilityCodeInfo(this.loggedinUser);
          //this.ActionFromDetails = this.EmpFacilityName;
          this.ActionFromDetails = '';

        } else {
          //this.ActionFromDetails = this.rowData.facilityName;
          this.ActionFromDetails = '';
        }
        break;
      }
    }
  }
  LoadFacilityCodeInfo(alias: string) {
    this.inventoryservice
      .getEmployeeFacility(alias)
      .subscribe(data => {
        this.EmpFacilityName = data.split("|")[0];
        this.EmpFacilityCode = data.split("|")[1];
      },
        err => {
          // console.log("ErrorLoadFacilityCodeInfo", err);
        });
  }

  ServiceNowRefreshDisplay() {
    this.snowReqEmailAddress = false;
    this.snowReqItemVisible = false;
    this.snowReqMTNStatus = false;
    this.snowReqReasonForWipe = false;
    this.snowReqWipeStatus = false;
    this.snowAssignGrpVisible = false;
    this.snowAssignToVisible = false;
    this.snowReqEmpPersonelEmailAddr = false;
    this.snowReqKDLoggedPTSIncident = false;
    this.snowReqReasonForReturn = false;
    this.snowReqShippedTo = false;
    this.snowReqDeviceModelType = false;
    this.snowReqImeiEsnHexaNumber = false;
    this.snowReqDTMobileNbr = false;
    this.snowReqDTStillFunc = false;
    this.snowReqSNCarrier = false;
    this.snowReqMTNMobileNbr = false;
    this.snowReqSNModel = false;
    this.snowReqESNMEIDIME = false;
    this.snowReqServiceAccSuspent = false;
    this.snowReqRepModel = false;
    this.snowReqRepESNMEIDIMEI = false;
    this.snowReqRepMTN = false;
    this.snowReqOrdDate = false;
    this.snowReqtWLNbmAssociateDevice = false;
    this.snowReqShippedDeviceCarrier = false;
    this.snowReqSNTracking = false;
    this.snowReqDesiredRepDevice = false;
    this.snowReqShippedAddRepDevice = false;

    switch (this.toDeviceLocationName) {
      case "Tech Room": {
        break;
      }
      case "Employee": {
        this.snowReqItemVisible = true;
        break;
      }
      case "Lost/Stolen": {
        break;
      }
      case "In Transit to Vendor": {
        break;
      }
      case "In Transit to Tech Room": {
        break;
      }
      case "In Transit to Recycle": {
        break;
      }
    }
  }
  OnIncidentAreaChange(e) {

    if (this.ServiceDeskLookup != null && this.ServiceDeskLookup.length > 0) {
      //for (var k = 0; k < this.ServiceDeskLookup.length; k++) {
      var ServiceDeskchecklist = this.ServiceDeskLookup[0].serviceDeskchecklist;
      var mtnval = this.multiRowData[0].mtn;
      var deviceIDval = this.multiRowData[0].deviceID;
      var deviceModelval = this.multiRowData[0].deviceModelDescription;
      var deviceTypeval = this.multiRowData[0].deviceTypeDesc;
      var carrierval = this.multiRowData[0].carrier;
      var itemVal = this.ddlRequestItemVal;
      // if (this.toDeviceLocationName == 'Lost/Stolen') {        
      $(".dvLSDAutoGenCheckList").empty();
      $('.dvLSDAutoGenCheckList').each(function () {
        for (var i = 0; i < ServiceDeskchecklist.length; i++) {
          var mandatory = '';
          var supText = '';
          if (ServiceDeskchecklist[i].isMandatory) {
            mandatory = ' required';
            supText = ' <sup>*</sup>';
          }
          if (ServiceDeskchecklist[i].controlType == 'DropDown' && ServiceDeskchecklist[i].catalogItemDesc == itemVal) {
            var arrDropDownValues = ServiceDeskchecklist[i].possibleValues.split('|');
            if (arrDropDownValues[0] != 'Recycling') {
              //var options = '<div class="form-group"><div class="row ml-2"><div class="col-md-12"> <label for="sel1" class="audit-type ">' + '</label>';
              var options = '<div class="form-group"><div class="row ml-2"><div class="col-md-12"> <label for="sel1" class="audit-type ">' + ServiceDeskchecklist[i].checkListText + supText + '</label>';
              options += '<select  class="form-control" id=' + ServiceDeskchecklist[i].checkListID + '  ' + mandatory + '><option hidden value="" disabled selected>Select Option</option>';

              var length = arrDropDownValues.length;
              for (var l = 0; l < length; l++) {
                options = options + '<option>' + arrDropDownValues[l].toString() + '</option>';
              }
              options = options + '</select>';
              var ddGenerate = options + '</div></div></div>';
              $(this).append(ddGenerate);
            }
            if (ServiceDeskchecklist[i].isCarrier) {
              $('#' + ServiceDeskchecklist[i].checkListID + ' option:contains(' + carrierval + ')').attr('selected', true);
            }
          }
          else {
            if (ServiceDeskchecklist[i].controlType == 'Textbox' && ServiceDeskchecklist[i].catalogItemDesc == itemVal) {
              // && ServiceDeskchecklist[i].catalogItemDesc == this.ddlRequestItemVal) {
              var val = '';
              if (ServiceDeskchecklist[i].isMTN) {
                if (mtnval != '')
                  val = mtnval;
              }
              if (ServiceDeskchecklist[i].isDeviceID) {
                if (deviceIDval != '')
                  val = deviceIDval;
              }
              if (ServiceDeskchecklist[i].isDeviceModel) {
                if (deviceModelval != '') {
                  val = deviceModelval;
                }
              }
              if (ServiceDeskchecklist[i].isDeviceType) {
                if (deviceTypeval != '') {
                  val = deviceTypeval;
                }
              }
              if (ServiceDeskchecklist[i].isCarrier) {
                val = carrierval;
              }
              var options = '<div class="form-group"><div class="row ml-2"><div class="col-md-12"> <label for="sel1" class="audit-type ">' + ServiceDeskchecklist[i].checkListText + supText + '</label>';
              options += '<input type="text"  class="form-control" ' + mandatory + ' id=' + ServiceDeskchecklist[i].checkListID + ' >'
              var ddGenerate = options + '</div></div></div>';
              $(this).append(ddGenerate);
              $('#' + ServiceDeskchecklist[i].checkListID).val(val);
            }
          }
        }
      });

      // }
    }
  }
  getRDPDAChecklistValues() {
    this.serviceNowChecklistItems = [{ checkListText: 'Reason for Wipe', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.ddlReasonForWipeVal },
    { checkListText: 'Reason for Not Wiping Data', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: '' },
    { checkListText: 'MTN Status', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.ddlMtnStatusVal },
    { checkListText: 'Personal Device?', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: '' },
    { checkListText: 'Remote Wipe Sent?', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: '' },
    { checkListText: 'Wipe Status', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.ddlWipeStatusVal },
    { checkListText: 'Employee’s Personal Email Address', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.txtEmpPersonelEmailAddrVal },
    { checkListText: 'Mobile Telephone Number', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.multiRowData[0].mtn },
    { checkListText: 'Wireless Carrier', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.multiRowData[0].carrier },
    { checkListText: 'IMEI or ESN Hex Number', catalogItemDesc: 'Cross-Functional.Hardware.Mobile Email Device.Wipe', givenValue: this.multiRowData[0].deviceID }];

  }
  getAutoChecklistValues() {
    if (this.ServiceDeskLookup != null) {

      if (this.ServiceDeskLookup.length > 0) {
        this.serviceNowChecklistItems = this.ServiceDeskLookup[0].serviceDeskchecklist;
      }

      if (this.serviceNowChecklistItems != null) {
        for (var i = 0; i < this.serviceNowChecklistItems.length; i++) {
          var CheckListID = this.serviceNowChecklistItems[i].checkListID;
          if (this.serviceNowChecklistItems[i].controlType == 'DropDown' &&
            this.serviceNowChecklistItems[i].catalogItemDesc == this.ddlRequestItemVal) {
            var ddlSelectText = $('#' + CheckListID + '>option:selected').text();
            //  alert(ddlSelectText);
            if (ddlSelectText == 'Select Option') {
              ddlSelectText = 'NA';
            }
            this.serviceNowChecklistItems[i].givenValue = ddlSelectText;
          }
          else if (this.serviceNowChecklistItems[i].controlType == 'Textbox' && this.serviceNowChecklistItems[i].catalogItemDesc == this.ddlRequestItemVal) {
            var txtSelectText = $('#' + CheckListID).val();
            if (txtSelectText == '') {
              txtSelectText = 'NA';
            }
            this.serviceNowChecklistItems[i].givenValue = txtSelectText;
          }
        }
      }
    }

  }
  ServiceNowOptSelection(opt: string) {
    this.isTicketRowSelected = false;
    this.ticketForVal = '';
    this.ticketNumberVal = '';
    this.ticketGridData = null;
    this.showTicketGrid = false;
    this.isTicketRowInc = false;
    this.isTicketRowReq = false;
    switch (opt) {
      case "none": {
        this.newOptionSelected = false;
        this.existingOptionSelected = false;
        this.newReqOptionSelected = false;
        this.newIncOptionSelected = false;
        //this.styleObjNone["background-color"] = "#00A3E0";
        break;
      }
      case "existing": {
        this.newOptionSelected = false;
        this.existingOptionSelected = true;
        this.newReqOptionSelected = false;
        this.newIncOptionSelected = false;
        this.snowAssignedTo = this.loggedinUserName;
        this.newReqFromEToTRPDA = false;
        this.LoadCatalogData();

        this.LoadAssignmentGroupData();
        this.LoadCategoryData();

        break;
      }
      case "new": {

        this.newOptionSelected = true;
        this.existingOptionSelected = false;
        this.newReqOptionSelected = true;
        this.newIncOptionSelected = false;
        this.ticketTypeVal = "request";
        this.snowAssignedTo = this.loggedinUserName;
        this.ddlRequestStateVal = '';
        this.ddlRequestItemVal = '';
        this.ddlStateVal = '';
        this.ddlCategoryVal = '';
        this.ddlSubCategoryVal = '';
        this.ddlAssignmentGroupVal = '';
        this.descriptionVal = '';
        this.ddlReasonForWipeVal = '';
        this.ddlMtnStatusVal = '';
        this.txtEmpPersonelEmailAddrVal = '';
        this.ddlWipeStatusVal = '';
        this.txtKDLoggedPTSIncidentVal = '';
        this.ddlReasonForReturnVal = '';
        this.ddlShippedVal = '';
        this.txtDeviceModelTypeVal = '';
        this.txtImeiEsnHexaNumberVal = '';
        this.txtDTMobileNbrVal = '';
        this.txtDTStillFuncVal = '';
        this.txtSNCarrierVal = '';
        this.txtMTNMobileNbrVal = '';
        this.txtSNModelVal = '';
        this.txtServiceAccSuspentVal = '';
        this.txtRepModelVal = '';
        this.txtRepESNMEIDIMEIVal = '';
        this.txtRepMTNVal = '';
        this.txtOrdDateVal = '';
        this.txtWLNbmAssociateDeviceVal = '';
        this.txtShippedDeviceCarrierVal = '';
        this.txtSNTrackingVal = '';
        this.txtDesiredRepDeviceVal = '';
        this.txtShippedAddRepDeviceVal = '';
        this.txtESNMEIDIMEVal = '';

        this.LoadCatalogData();
        this.LoadAssignmentGroupData();
        this.LoadCategoryData();
        if (this.currentAction == "Employee" && this.toDeviceLocationName == "Tech Room" && this.rowData.deviceTypeDesc == "PDA" && this.newOptionSelected) {
          this.newReqFromEToTRPDA = true;
        }
        else {
          this.newReqFromEToTRPDA = false;
        }
        this.LoadServiceNowLookup();
        this.ServiceNowRefreshDisplay();
        break;
      }
      case "request": {
        this.newReqOptionSelected = true;
        this.newIncOptionSelected = false;


        break;
      }
      case "incident": {
        this.newReqOptionSelected = false;
        this.newIncOptionSelected = true;
        this.newReqFromEToTRPDA = false;
        break;
      }
    }
  }

  LoadServiceNowLookup() {

    this.inventoryservice
      .GetIncidentAreaandCheckListBasedOnAssignment(this.currentAction, this.toDeviceLocationName,
        // 'iOS',
        //   'PDA' 
        this.rowData.deviceOS, this.rowData.deviceTypeDesc
      )
      .subscribe(data => {
        this.ServiceDeskLookup = data;
      });
  }

  LoadAssignmentGroupData() {
    if (this.assignmentGroupModeldata === undefined ||
      this.assignmentGroupModeldata === null ||
      this.assignmentGroupModeldata.length == 0) {
      this.inventoryservice
        .getAllAssignmentgroupItems(this.loggedinUser)
        .subscribe(data => {
          this.assignmentGroupModeldata = data;
        });
    }
  }

  LoadCategoryData() {
    if (this.categoryModeldata === undefined ||
      this.categoryModeldata === null ||
      this.categoryModeldata.length == 0) {
      this.inventoryservice.getAllCategory().subscribe(data => {
        this.categoryModeldata = data;
      });
    }
  }

  LoadCatalogData() {

    if (this.rowData.deviceOS == undefined || this.rowData.deviceOS == null) {
      this.rowData.deviceOS = '';
    }
    if (this.rowData.deviceTypeDesc == undefined || this.rowData.deviceTypeDesc == null) {
      this.rowData.deviceTypeDesc = '';
    }
    // if(this.rowData.deviceType=null ){ this.rowData.deviceType = this.rowData.deviceType;}

    if (this.Devicecatelogdata === undefined || this.Devicecatelogdata === null || this.Devicecatelogdata.length == 0) {
      this.inventoryservice
        .getAllCatalogItmes(this.currentAction, this.toDeviceLocationName, this.rowData.deviceOS, this.rowData.deviceTypeDesc)
        .subscribe(catlogdata => {
          this.Devicecatelogdata = catlogdata;
        });
      // console.log('allinputparmeters', this.currentAction, this.toDeviceLocationName, this.rowData.deviceOS, this.rowData.deviceTypeDesc);
    }
  }

  GetMTNListForEmployee() {
    this.MtnListdata = [];
    this.inventoryservice
      .getMTNList(this.searchEmployeePersonnelNbr, this.rowData.deviceTypeDesc, this.rowData.deviceID) //TODO
      .subscribe(data => {
        this.MtnListdata = data;
        if (this.MtnListdata != null && this.MtnListdata.length > 0) {
          this.PeoplePicActiveEmployeeEmailAlias = this.MtnListdata[0].activeEmployeeAlias;
          this.rowData.mtn = this.MtnListdata[0].mobileTelephoneNbr;
        }
        else {
         if(this.rowData.isWifiOnly){
            this.PeoplePicActiveEmployeeEmailAlias = this.selectedAliasName;
          }
          else{
            this.PeoplePicActiveEmployeeEmailAlias = '';
          }
          this.rowData.mtn = '';
        }
        this.multiRowData[0].mobileAssetStatusID = 1;
      });

  }

  GetCarrier() {
    this.inventoryservice.getAllCarrier().subscribe(data => {
      //this.carrier = data;
      this.carrier = data.filter((val) => val.isActive == true);
    });
  }
  SubmitAssignedDevice() {
    var submitBtn = <HTMLInputElement>document.getElementById('SubmitInventory');
    this.multiassignDeviceObject = [];
    let personnelNbr;
    for (let assRowData of this.multiRowData) {
      personnelNbr = assRowData.personnelNbr;
      this.assignDeviceObject = new AssignDevice();
      this.assignDeviceObject.deviceID = assRowData.deviceID;
      this.assignDeviceObject.deviceLocationID = this.toDeviceLocation == '' ? null : this.toDeviceLocation;
      this.assignDeviceObject.deviceStatusID = assRowData.deviceStatusID;
      this.assignDeviceObject.serviceDescIncident = this.selectdTicketNo == undefined || this.selectdTicketNo == '' ? null : this.selectdTicketNo;
      this.assignDeviceObject.shippingTrackingNbr = this.shippingTrackingVal == undefined || this.shippingTrackingVal == '' ? null : this.shippingTrackingVal;
      //get only mtn for non-wifi devices
      if(assRowData.isWifiOnly)
      {
        this.assignDeviceObject.mtn == '' ; 
      }
      if(!assRowData.isWifiOnly)
      {
        this.assignDeviceObject.mtn = assRowData.mtn == '' ? null : assRowData.mtn;
      }
      this.assignDeviceObject.deviceType = assRowData.deviceTypeDesc;
      this.assignDeviceObject.mobileAssetStatusID = assRowData.mobileAssetStatusID;
      this.assignDeviceObject.hasExpectedReturn = assRowData.hasExpectedReturn;
      if (assRowData.mobileAssetStatusID == 2 && assRowData.hasExpectedReturn) {
        this.assignDeviceObject.expectedReturn = assRowData.expectedReturn;
      }
      if (this.toDeviceLocationName == "Employee") {
        this.assignDeviceObject.personnelNbr = this.searchEmployeePersonnelNbr;//TODO
        if (this.currentAction == "Employee") {
          this.assignDeviceObject.deviceTransactionTypeID = 11;
        }
        else {
          this.assignDeviceObject.deviceTransactionTypeID = 1;
        }
        this.assignDeviceObject.facilityCode = this.EmpFacilityCode;
      }
      if (this.toDeviceLocationName == "Tech Room") {
        this.assignDeviceObject.personnelNbr = null;
        this.assignDeviceObject.deviceTransactionTypeID = 2;
        this.assignDeviceObject.facilityCode = this.toOfficeLocation == '' ? null : this.toOfficeLocation;

      }
      if (this.toDeviceLocationName == "In Transit to Tech Room") {
        this.assignDeviceObject.personnelNbr = null;
        this.assignDeviceObject.deviceTransactionTypeID = 3;
        this.assignDeviceObject.facilityCode = this.toOfficeLocation == '' ? null : this.toOfficeLocation;
        this.assignDeviceObject.serviceDescIncident = null;
      }
      if (this.toDeviceLocationName == "In Transit to Vendor") {
        this.assignDeviceObject.personnelNbr = null;
        this.assignDeviceObject.deviceTransactionTypeID = 6;
        this.assignDeviceObject.facilityCode = this.EmpFacilityCode;
      }
      if (this.toDeviceLocationName == "Lost/Stolen") {
        this.assignDeviceObject.personnelNbr = null;
        this.assignDeviceObject.deviceTransactionTypeID = 8;
        this.assignDeviceObject.facilityCode = null;
        this.assignDeviceObject.shippingTrackingNbr = null;
      }
      if (this.toDeviceLocationName == "In Transit to Recycle") {
        this.assignDeviceObject.personnelNbr = null;
        this.assignDeviceObject.deviceTransactionTypeID = 5;
        this.assignDeviceObject.facilityCode = null;
      }
      this.assignDeviceObject.tssRep = this.loggedinUser;


      this.assignDeviceObject.sim = assRowData.sim == null || assRowData.sim == '' ? null : assRowData.sim;
      this.assignDeviceObject.carrier = assRowData.carrier;
      this.assignDeviceObject.carrierStore = this.ddlCarrierVal == undefined || this.ddlCarrierVal == '' ? null : this.ddlCarrierVal;
      this.assignDeviceObject.isReplacedDevice = assRowData.isReplacedDevice;
      //this.assignDeviceObject.mobileAssetStatusID = this.rowData.mobileAssetStatusID;
      this.assignDeviceObject.createOrModifiedBy = this.loggedinUser;
      if (this.isMultiSelectAvailable) {
        this.multiassignDeviceObject.push(this.assignDeviceObject);
      }
    }
    if (this.isMultiSelectAvailable) {
      this.inventoryservice
        .SubmitMultiAssignedDevice(this.multiassignDeviceObject)
        .subscribe((data) => {
          this.assignDeviceoutput = data[0]; /// TODO: have to use this output
          this.ticketStatus = 'Assigned';
          if (this.snowOptionSelectedVal != 'none') {
            this.SubmitSDTicket();
          }
          else {
            if (this.sourceItem == "EMPProfile") {
              this.AssignmentChanged.emit(true);
            }
            else {
              this.inventoryservice.refreshSearchInformation();

              // this.ShowMessage();
            }
            this.ticketStatus = 'Assigned';
            this.ShowMessage();
          }

          
        },
          (err) => {
            // console.log("err", err);

            this.ticketStatus = 'Failed';
            submitBtn.disabled = true;	
          });
    }
    else {
      this.inventoryservice
        .SubmitAssignedDevice(this.assignDeviceObject)
        .subscribe((data) => {
          this.inventoryservice.UpdateComplianceViolationFromAssignDevice(this.assignDeviceObject.deviceID, personnelNbr).subscribe(x => {
            console.log(x);
          });
          this.assignDeviceoutput = data; /// TODO: have to use this output

          this.ticketStatus = 'Assigned';
          if (this.snowOptionSelectedVal != 'none') {
            this.SubmitSDTicket();
          }
          else {
            if (this.sourceItem == "EMPProfile") {
              this.AssignmentChanged.emit(true);
            }
            else {
              this.inventoryservice.refreshSearchInformation();

              // this.ShowMessage();
            }
            this.ticketStatus = 'Assigned';
            this.ShowMessage();
          }
        },
          (err) => {
            // console.log("err", err);
            // this.ticketStatus = 'Failed';
            submitBtn.disabled = true;	
            // this.ShowMessage();
          });
    }

  }
  categoryChange() {
    let catName = this.ddlCategoryVal;
    let catid = this.categoryModeldata.filter(function (node) {
      return node.categoryName == catName;
    })[0].catId;
    this.inventoryservice
      .getAllSubcategory(catid)
      .subscribe(data => {
        this.subCategoryModeldata = data;
      });
  }
  ticketRowSelected(row: UserTicketDetailsModel) {
    this.rowTicketNo = row.ticketNumber;
    // console.log("Ticket No: " + JSON.stringify(row));
    if (row != undefined) {
      this.isTicketRowSelected = true;
      //this.snowAssignedTo = row.assignee_To;
      this.snowAssignedTo = row.affectedEndUser;
      this.ddlAssignmentGroupVal = row.assignment_group;
      this.descriptionVal = row.description;
      this.notesVal = row.commentsAndWorkNotes;
      this.selectdTicketNo = row.ticketNumber;
      if (row.ticketNumber.indexOf('INC') >= 0) {
        this.ddlStateVal = row.ticketStatus == null ? '' : row.ticketStatus;
        this.ddlCategoryVal = row.category;
        this.ddlSubCategoryVal = row.subCategory;
        this.isTicketRowInc = true;
        this.isTicketRowReq = false;

      }
      if (row.ticketNumber.indexOf('REQ') >= 0) {
        this.ddlRequestStateVal = row.ticketStatus == null || row.ticketStatus == undefined ? '' : row.ticketStatus;
        this.ddlRequestItemVal = row.reqCatalogItemName == null ? '' : row.reqCatalogItemName;
        this.isTicketRowInc = false;
        this.isTicketRowReq = true;
      }
    }
  }
  trimValue(event) { event.target.value = event.target.value.trim(); }
  goClick() {
    this.personIDOrTicketNumber =
      this.ticketForVal != undefined && this.ticketForVal != ""
        ? this.ticketForVal
        : this.ticketNumberVal != undefined && this.ticketNumberVal != ""
          ? this.ticketNumberVal
          : "";

    if (this.personIDOrTicketNumber != "") {
      this.inventoryservice
        .getTicketDetialsByPersonalOrTicketNumber(this.personIDOrTicketNumber)
        .subscribe(personalOrTicketNumberdata => {
          this.ticketGridData = personalOrTicketNumberdata;
          // console.log("Text Text " + personalOrTicketNumberdata);
          this.showTicketGrid =
            this.ticketGridData != undefined &&
              this.ticketGridData != null &&
              this.ticketGridData.length > 0
              ? true
              : false;
          // console.log(
          //   "ticketGridData",
          //   this.ticketGridData
          // );
          if (!this.showTicketGrid) {
            this.modalService.notExist(
              'No incidents found.'
            ).pipe(
              take(1) // take() manages unsubscription for us
            ).subscribe(result => {
              // console.log({ messageResult: result });
              this.messageResult = result;
            });
            // alert("no incidents found");
          }
        });
    }
  }
  
  showPrintPage() {
    let dsid = this.rowData.deviceStatusID;
    var dstus = this.DeviceStatusdata.filter(function (node) {
      return node.deviceStatusID == dsid;
    });
    this.rowData.deviceStatus = dstus.length > 0 ? dstus[0].deviceStatusDesc : '';
    // console.log("rowdata", this.rowData);
    this.showPrint = true;
  }
  cancelPrintPage() {
    this.showPrint = false;
  }
  private ShowMessage() {

    switch (this.ticketStatus) {
      case 'AssignedUpdated':
        // alert("Device assigned and Ticket updated successfully.");
        this.modalService.message(
          'Yes! Mobile device association got updated successfully'
        ).pipe(
          take(1) // take() manages unsubscription for us
        ).subscribe(result => {
          // console.log({ messageResult: result });
          this.messageResult = result;
        });

        break;
      case 'Assigned':
        this.modalService.message(
          'Device assigned successfully.'
        ).pipe(
          take(1) // take() manages unsubscription for us
        ).subscribe(result => {
          // console.log({ messageResult: result });
          this.messageResult = result;
        });
        // alert("Device assigned successfully.");
        break;
      case 'Failed':
        this.modalService.notExist(
          'Device assigned successfully.Could not generate Ticket.'
        ).pipe(
          take(1) // take() manages unsubscription for us
        ).subscribe(result => {
          // console.log({ messageResult: result });
          this.messageResult = result;
        });
        // alert("Device assigned successfully.Could not generate Ticket.");
        break;
      case 'TicketCreated':
        if (this.IsCreateTicket) {
          this.modalService.message(
            "Device assigned and Ticket # " + this.responseTicketNo + " is created successfully."
          ).pipe(
            take(1) // take() manages unsubscription for us
          ).subscribe(result => {
            // console.log({ messageResult: result });
            this.messageResult = result;
          });
        }
        // alert("Device assigned and Ticket # " + this.ticketStatus + " is created successfully.");
        else {
          this.modalService.message(
            'Device assigned successfully.'
          ).pipe(
            take(1) // take() manages unsubscription for us
          ).subscribe(result => {
            // console.log({ messageResult: result });
            this.messageResult = result;
          });
        }

      // alert("Device assigned successfully.");
    }

    document.getElementById("btnCancel").click();
  }
  SubmitSDTicket() {
    this.serviceNowTicketDetail = new ServicenowTicketDetails();
    var prevComments = this.rowTicketPrevComments != undefined && this.rowTicketPrevComments != '' ? "-----*****----- " + this.rowTicketPrevComments : '';

    if (this.newReqFromEToTRPDA) {
      this.serviceNowTicketDetail.itemName = 'Cross-Functional.Hardware.Mobile Email Device.Wipe';
      this.serviceNowTicketDetail.callSource = 'Walk-Up';

    }
    else {
      this.serviceNowTicketDetail.itemName = this.ddlRequestItemVal == '' ? null : this.ddlRequestItemVal;
      this.serviceNowTicketDetail.callSource = null;
    }

    if (this.currentAction != 'In Transit to Tech Room' && !this.newReqFromEToTRPDA) {
      this.getAutoChecklistValues();
    }

    if (this.newReqFromEToTRPDA) {
      this.getRDPDAChecklistValues();
    }

    var snowChecklistarrSize: number = this.serviceNowChecklistItems.length * 2;
    var strCheckList: string[] = [];

    var j = 0;
    for (var i = 0; i <= this.serviceNowChecklistItems.length - 1; i++) {
      if (this.serviceNowTicketDetail.itemName == this.serviceNowChecklistItems[i].catalogItemDesc) {
        strCheckList[j] = this.serviceNowChecklistItems[i].checkListText;
        j++;
        if (this.newReqFromEToTRPDA) {
          if (this.serviceNowChecklistItems[i].givenValue == "NA")
            strCheckList[j] = "";
          else
            strCheckList[j] = this.serviceNowChecklistItems[i].givenValue;
        }
        else
          strCheckList[j] = this.serviceNowChecklistItems[i].givenValue;

        j++;
      }
    }

    if (this.currentAction == "Employee" || this.toDeviceLocationName == "Employee") {

      this.serviceNowTicketDetail.affectedEndUser = this.toDeviceLocationName == "Employee" ? (this.PeoplePicActiveEmployeeEmailAlias != null ? this.PeoplePicActiveEmployeeEmailAlias : this.rowData.activeEmployeeAlias) : this.rowData.activeEmployeeAlias;
    }

    if (this.currentAction != "Employee" && this.toDeviceLocationName != "Employee") {

      this.serviceNowTicketDetail.affectedEndUser = this.loggedinUser;
    }

    let comments = '';

    if (this.currentAction != "Employee") {
      if(!this.rowData.isWifiOnly)
      {
        comments = this.rowData.deviceID + ', '+this.rowData.serialNumber + ', ' + this.rowData.deviceModelDescription + ', ';
        comments = comments + this.rowData.carrier == '' || this.rowData.carrier == null ? 'NA' : this.rowData.carrier;
        comments = comments + this.descriptionVal;
      }
      if(this.rowData.isWifiOnly)
      {
        comments = this.rowData.deviceID + ', ' + this.rowData.deviceModelDescription + ', ';
        comments = comments + this.rowData.carrier == '' || this.rowData.carrier == null ? 'NA' : this.rowData.carrier;
        comments = comments + this.descriptionVal;
      }
    }

    this.serviceNowTicketDetail.assignee = this.loggedinUser;
    this.serviceNowTicketDetail.description = comments;
    this.serviceNowTicketDetail.group = this.ddlAssignmentGroupVal == '' ? null : this.ddlAssignmentGroupVal;
    this.serviceNowTicketDetail.priority = null;

    if (this.currentAction == "Employee" && this.toDeviceLocationName == "Tech Room") {
      if(!this.rowData.isWifiOnly)
      {
        comments = 'Mobile Device has been returned to TSS - ' + (this.rowData.carrier == '' || this.rowData.carrier == undefined ? 'NA' : this.rowData.carrier) + ', ';
        comments = comments + this.rowData.deviceID + ', '+ this.rowData.serialNumber + ', ' + (this.rowData.mtn == '' || this.rowData.mtn == undefined ? 'NA' : this.rowData.mtn) + ', ';
        comments = comments + this.rowData.deviceModelDescription; 
      }
      if(this.rowData.isWifiOnly)
      {
        comments = 'Mobile Device has been returned to TSS - ' + (this.rowData.carrier == '' || this.rowData.carrier == undefined ? 'NA' : this.rowData.carrier) + ', ';
        comments = comments + this.rowData.deviceID + ', ' + (this.rowData.mtn == '' || this.rowData.mtn == undefined ? 'NA' : this.rowData.mtn) + ', ';
        comments = comments + this.rowData.deviceModelDescription; 
      }    
    }

    if (this.ticketTypeVal == 'incident') {

      // if (this.rowData.deviceType == 'PDA') {
      comments = comments != '' ? comments + ' - ' + this.descriptionVal : this.descriptionVal;
      comments = comments != '' ? comments + ' - ' + this.notesVal : this.notesVal;
      // }
      this.serviceNowTicketDetail.comments = comments;
      this.serviceNowTicketDetail.description = comments; //this.descriptionVal == '' ? null : this.descriptionVal;
      this.serviceNowTicketDetail.summary = this.descriptionVal == '' ? null : this.descriptionVal;
      this.serviceNowTicketDetail.shortDescription = comments;//this.descriptionVal == '' ? null : this.descriptionVal;
    }
    // else {
    //   this.serviceNowTicketDetail.description = comments; 
    //   this.serviceNowTicketDetail.summary = comments;
    //   this.serviceNowTicketDetail.shortDescription = comments;
    // }
    if (this.toDeviceLocationName == "Lost/Stolen") {
        if(!this.rowData.isWifiOnly)
        {
        comments = 'Mobile Device has been Lost/ Stolen - ' + (this.rowData.carrier == '' || this.rowData.carrier == undefined ? 'NA' : this.rowData.carrier) + ', ';  
        comments = comments + this.rowData.deviceID + ', ' + this.rowData.serialNumber + ', ' + (this.rowData.mtn == '' || this.rowData.mtn == undefined ? 'NA' : this.rowData.mtn) + ', ';
        }
        if(this.rowData.isWifiOnly)
        {
          comments = 'Mobile Device has been Lost/ Stolen - ' + (this.rowData.carrier == '' || this.rowData.carrier == undefined ? 'NA' : this.rowData.carrier) + ', ';  
        comments = comments + this.rowData.deviceID + ', ' + (this.rowData.mtn == '' || this.rowData.mtn == undefined ? 'NA' : this.rowData.mtn) + ', ';
        }
    }

    if (this.ticketTypeVal == 'request') {
      if (this.rowData.deviceTypeDesc == 'PDA') {
        for (var i = 0; i <= this.serviceNowChecklistItems.length - 1; i++) {
          if ((this.serviceNowChecklistItems[i].checkListText == "Reason for Wipe") || (this.serviceNowChecklistItems[i].checkListText == "MTN Status") || (this.serviceNowChecklistItems[i].checkListText == "Wipe Status") || (this.serviceNowChecklistItems[i].checkListText == "Employee’s Personal Email Address")) {
            comments = comments + ', ' + this.serviceNowChecklistItems[i].givenValue;
          }
        }
        //comments = comments.substring(0, comments.length - 2);

      }
      comments = comments != '' ? comments + ' - ' + this.descriptionVal : this.descriptionVal;


      if (this.rowData.deviceTypeDesc == 'PDA') {
        //this.toDeviceLocationName
        //
      }
      if (this.currentAction == 'Tech Room') {
        comments = '';
        if(!this.rowData.isWifiOnly)
        {
        comments = comments + (this.rowData.deviceID == '' || this.rowData.deviceID == null ? 'NA' : this.rowData.deviceID)+ ', '+
                               (this.rowData.serialNumber == '' || this.rowData.serialNumber == null ? 'NA' : this.rowData.serialNumber)+ ', ';
        
                               comments = comments + (this.rowData.deviceModelDescription == '' || this.rowData.deviceModelDescription == null ? ' NA' : ' ' + this.rowData.deviceModelDescription);
                               comments = comments + (this.rowData.carrier == '' || this.rowData.carrier == null ? ' NA' : ' ' + this.rowData.carrier);
                               comments = comments != '' ? comments + ' - ' + this.descriptionVal : this.descriptionVal                       
        }
        if(this.rowData.isWifiOnly)
        {
        comments = comments + (this.rowData.deviceID == '' || this.rowData.deviceID == null ? 'NA' : this.rowData.deviceID)+', ';
        comments = comments + (this.rowData.deviceModelDescription == '' || this.rowData.deviceModelDescription == null ? ' NA' : ' ' + this.rowData.deviceModelDescription);
        comments = comments + (this.rowData.carrier == '' || this.rowData.carrier == null ? ' NA' : ' ' + this.rowData.carrier);
        comments = comments != '' ? comments + ' - ' + this.descriptionVal : this.descriptionVal
        }
      }

      //comments = comments != '' ? comments + ' - ' + this.notesVal : this.notesVal;
      this.serviceNowTicketDetail.checkListProperties = strCheckList;
      //this.serviceNowTicketDetail.special_Instructions = "once check the cart";
      this.serviceNowTicketDetail.comments = comments;
      this.serviceNowTicketDetail.description = comments;
      this.serviceNowTicketDetail.summary = comments;
      this.serviceNowTicketDetail.shortDescription = comments;
    }


    if (this.newReqOptionSelected || (this.isTicketRowSelected && this.existingOptionSelected && this.isTicketRowReq)) {
      this.serviceNowTicketDetail.status = this.ddlRequestStateVal == '' ? null : this.ddlRequestStateVal;
      comments = comments != '' ? comments + ' - ' + this.descriptionVal : this.descriptionVal;
      if (this.isTicketRowSelected && this.existingOptionSelected && this.isTicketRowReq) {
        comments = comments != '' ? comments + ' - ' + this.notesVal : this.notesVal;
      }
      this.serviceNowTicketDetail.comments = comments;
    }

    if (this.isTicketRowSelected && this.existingOptionSelected) {
      this.serviceNowTicketDetail.comments = this.descriptionVal + ' - ' + this.notesVal;
    }

    if (this.newIncOptionSelected || (this.isTicketRowSelected && this.existingOptionSelected && this.isTicketRowInc)) {
      this.serviceNowTicketDetail.status = this.ddlStateVal == '' ? null : this.ddlStateVal;
      this.serviceNowTicketDetail.category = this.ddlCategoryVal == '' ? null : this.ddlCategoryVal;
      this.serviceNowTicketDetail.subCategory = this.ddlSubCategoryVal == '' ? null : this.ddlSubCategoryVal;
    }

    this.serviceNowTicketDetail.requestedFor = this.serviceNowTicketDetail.affectedEndUser;
    this.serviceNowTicketDetail.dueDate = this.currentdate;

    if (this.isMultiSelectAvailable && this.newOptionSelected) {
      this.serviceNowTicketDetail.comments = '';
      this.serviceNowTicketDetail.description = this.descriptionVal;
      this.serviceNowTicketDetail.shortDescription = this.descriptionVal;
      if (this.newReqOptionSelected) {
        strCheckList[0] = "Device Returned To:";
        strCheckList[1] = this.ddlDeviceReturnedToVal;
        this.serviceNowTicketDetail.checkListProperties = strCheckList;
      }
    }

    if (this.newReqOptionSelected) {
      // console.log("reqdata", this.serviceNowTicketDetail);
      this.serviceNowTicketDetail.incidentArea = 'Assign Device';
      if(this.serviceNowTicketDetail.requestedFor && this.serviceNowTicketDetail.group){
      this.inventoryservice
        .createRequestNUpdateState(this.serviceNowTicketDetail)
        .subscribe(data => {
          // console.log("data", data);
          this.responseTicketNo = data;
          this.ticketStatus = 'TicketCreated';
          //TODO: call assign device update method by passing data(servicenownumber), this.assignDeviceoutput(mdhnumber) to UpdateTicketNumToMobileDeviceHistory 
          if (this.responseTicketNo != null || this.assignDeviceoutput != null) {
            this.UpdateTicketNumToMobileDeviceHistory(this.assignDeviceoutput, this.responseTicketNo);
          }
          this.IsCreateTicket = true;
          this.ShowMessage();
          if (this.sourceItem == "EMPProfile") {
            this.AssignmentChanged.emit(true);
          }
          else {
            this.inventoryservice.refreshSearchInformation();
          }

        },
          (err) => {
            // console.log("err", err);
            // this.ticketStatus = 'Failed';
            // this.ShowMessage();
            // if (this.sourceItem == "EMPProfile") {
            //   this.AssignmentChanged.emit(true);
            // }
            // else {
            this.inventoryservice.refreshSearchInformation();
          });
        }else{
          document.getElementById('insufficientData').click();
        }
    }

    if (this.newIncOptionSelected) {
      this.serviceNowTicketDetail.incidentArea = 'Assign Device';
      this.serviceNowTicketDetail.callerId = this.serviceNowTicketDetail.affectedEndUser;
      this.serviceNowTicketDetail.incidentArea = this.ddlRequestStateVal;
      // console.log("incident para", this.serviceNowTicketDetail);
      this.inventoryservice
        .createIncidentTicketWithGroupAssignment(this.serviceNowTicketDetail)
        .subscribe(data => {
          this.responseTicketNo = data;
          // console.log("Incident is created successfully" + data);
          this.ticketStatus = 'TicketCreated';
          //TODO: call assign device update method by passing data(servicenownumber), this.assignDeviceoutput(mdhnumber) to UpdateTicketNumToMobileDeviceHistory 
          if (this.responseTicketNo != null || this.assignDeviceoutput != null) {
            this.UpdateTicketNumToMobileDeviceHistory(this.assignDeviceoutput, this.responseTicketNo);
          }
          this.IsCreateTicket = true;

          this.ShowMessage();
          if (this.sourceItem == "EMPProfile") {
            this.AssignmentChanged.emit(true);
          }
          else {
            this.inventoryservice.refreshSearchInformation();
          }

        },
          (err) => {
            // console.log("err", err);
            this.ticketStatus = 'Failed';

            this.ShowMessage();
            if (this.sourceItem == "EMPProfile") {
              this.AssignmentChanged.emit(true);
            }
            else {
              this.inventoryservice.refreshSearchInformation();
            }
          });
    }
    if ((this.isTicketRowSelected && this.existingOptionSelected && this.isTicketRowReq)
      || (this.isTicketRowSelected && this.existingOptionSelected && this.isTicketRowInc)) {
      this.serviceNowTicketDetail.ticketNumber = this.rowTicketNo;
      //this.serviceNowTicketDetail.status = (this.ddlStateVal ! = ''? status)
      this.serviceNowTicketDetail.comments = this.serviceNowTicketDetail.comments + prevComments;
      // alert(JSON.stringify(this.serviceNowTicketDetail));
      // alert(JSON.stringify(this.assignDeviceoutput));
      this.inventoryservice
        .updateTicketStatus(this.serviceNowTicketDetail)
        .subscribe(data => {
          // this.responseTicketNo = data;
          //TODO: call assign device update method by passing data(servicenownumber), this.assignDeviceoutput(mdhnumber) to UpdateTicketNumToMobileDeviceHistory 
          if (data && this.assignDeviceoutput != null) {
            this.UpdateTicketNumToMobileDeviceHistory(this.assignDeviceoutput, this.serviceNowTicketDetail.ticketNumber);
          }
        });
    }


  }

  UpdateTicketNumToMobileDeviceHistory(mobDeviceHisId: string, ticketNumber: string) {
    // console.log(mobDeviceHisId + "," + ticketNumber);
    var updateResult = false;
    this.inventoryservice
      .updateTicketNumToMobileDeviceHistory(mobDeviceHisId, ticketNumber)
      .subscribe(data => {
        updateResult = data;
        if (updateResult && !this.IsCreateTicket) {
          this.modalService.message("Device assigned and Ticket updated successfully.").pipe(take(1)).subscribe(result => {
            // console.log({ messageResult: result });
            this.messageResult = result;
          });
          if (this.sourceItem == "EMPProfile") {
            this.AssignmentChanged.emit(true);
          }
          else {
            this.inventoryservice.refreshSearchInformation();
          }
          document.getElementById("btnCancel").click();
        }
        // console.log("Ticket and Assigned Device History save successfully in Inventory", data);

      });
  }

  selectedAlias(alias) {
    this.selectedAliasName = alias;
  }
  selectedAlias2(alias) {
    this.selectedSNowAliasName = alias;
  }
  inputItemEventEmit(event) {
    // console.log('people-picker result data from assign-device', event);
    if (event != null && event.length > 0) {
      this.searchEmployeeAlias = event[0].emailAlias;
      this.searchEmployeePersonnelNbr = event[0].personnelNumber;
      if ((this.currentAction == "Tech Room" && this.toDeviceLocationName == "Employee")
        || (this.currentAction == "Employee" && this.toDeviceLocationName == "Employee")) {
        this.GetMTNListForEmployee();
        this.isMTNDropdown = true;
      }
      else {
        this.isMTNDropdown = false;
      }

      this.LoadFacilityCodeInfo(this.searchEmployeeAlias);//TODO

    }
  }
  inputItemEventEmit2(event) {
    // console.log('people-picker result data from service now section', event);
    if (event != null && event.length > 0) {
      this.searchEmployeeAlias2 = event[0].emailAlias;
      this.searchEmployeePersonnelNbr2 = event[0].personnelNumber;
      this.ticketForVal = this.searchEmployeeAlias2;
      // if ((this.currentAction == "Tech Room" && this.toDeviceLocationName == "Employee")
      //   || (this.currentAction == "Employee" && this.toDeviceLocationName == "Employee")) {
      //   this.GetMTNListForEmployee();
      //   this.isMTNDropdown = true;
      // }
      // else {
      //   this.isMTNDropdown = false;
      // }

      // this.LoadFacilityCodeInfo(this.searchEmployeeAlias);//TODO

    }
  }
  empDetails(event) {
    // console.log('people-picker result data from assign-device', event);
  }
  empDetails2(event) {
    // console.log('people-picker result data from service now', event);
  }
  fn_notifyCarrier(rowObj) {

    this.notifyUserHeader = false;
    this.notifyCarrierHeader = true;
    if (rowObj != null && rowObj != undefined && rowObj != "") {
      //$('#dataTargetNotifyCarrier').attr('data-target', '#notifyUserID');
      // $('#dataTargetNotifyCarrier').click();
      document.getElementById("dataTargetNotifyCarrier").click();
      this.fn_GetInventoryNotifyCarrierDetails(rowObj);
    }
  }

  fn_GetInventoryNotifyCarrierDetails(rowObj) {
    let carrierCode = (rowObj.carrier == "AT&T") ? "ATT" : rowObj.carrier;
    this.notifyUserObj = null;
    this.inventoryservice.GetInventoryNotifyCarrierDetails(carrierCode).subscribe((notifyCarrierData) => {
      if (notifyCarrierData != null && notifyCarrierData != undefined ) {
        this.notifyUserObj = notifyCarrierData;
        if (this.notifyUserObj.templateBody != null && this.notifyUserObj.templateBody != "" && this.notifyUserObj.templateBody != undefined) {
          this.bodyHtmlValue = unescape(this.notifyUserObj.templateBody.replace("{IMEI}", rowObj.deviceID));
        }
        // this.fn_SendCarrierNotification();
      }
      else{
        this.notifyUserObj = null;
      }
    });
  }
  closeNotifyUser() {
    // $(".modal-backdrop").remove();
    // $("#applyToFilterDelinquency").click();
    document.querySelector('.modal-backdrop').remove();
    document.getElementById("applyToFilterDelinquency").click();
  }
  SubmitAssignDevice() {

    if (this.toLostOrStolenFlg) {
      if ($('input:checkbox[name="chkNotifyCarrier"]').is(':checked')) {
        this.modalService.confirm(
          "Are you sure you want to Notify Carrier?"
        ).subscribe(data => {
          if (data) {
            console.log('SubmitAssignDevice-', data)
            this.fn_SendCarrierNotification();
            this.SubmitAssignedDevice();
          }
        });
      } else {
        this.SubmitAssignedDevice();
      }
    }
    else {
      this.SubmitAssignedDevice();
      // if (this.snowOptionSelectedVal != 'none') {
      //   this.SubmitSDTicket();
      // }
    }


    //updateTicketNumToMobileDeviceHistory TODO


  }
  fn_SendCarrierNotification() {
    this.insertEmailSchedule.emailrepid = 0;
    this.insertEmailSchedule.toaddress = this.notifyUserObj.emailAddress;
    this.insertEmailSchedule.fromaddress = this.notifyUserObj.mailFrom;//this.mailBodyOfEmail.mailFrom;//readonly
    this.insertEmailSchedule.ccaddress = this.notifyUserObj.cc;
    this.insertEmailSchedule.bccaddress = this.notifyUserObj.bcc;
    this.insertEmailSchedule.subject = this.notifyUserObj.templateSubject;//this.mailBodyOfEmail.mailSubject;//readonly
    this.insertEmailSchedule.body = unescape(this.bodyHtmlValue);//readonly
    this.insertEmailSchedule.modulename = 'Inventory';
    this.insertEmailSchedule.createdate = new Date();
    this.insertEmailSchedule.status = true;
    this.insertEmailSchedule.createdby = this.loggedinUser;
    this.insertEmailSchedule.sentdate = new Date();
    this.insertEmailSchedule.batchId = 0;
    this.inventoryservice.InsertEmailToSchedule(this.insertEmailSchedule).subscribe((data) => {
      this.modalService.message(
        'Notification(s) sent successfully'
      ).pipe(
        take(1)
      ).subscribe(result => {
        //$(".modal-backdrop").remove();
        //$("#applyToFilterDelinquency").click();
        $('#notifyUserID').hide("modal");

        document.querySelector('.modal-backdrop').remove();
        document.getElementById("applyToFilterDelinquency").click();
      });
    });
  }

  checkValidation(f) {
    let empSearchValidation = false;
    let mtnValidation = false;
    let existingValidation = false;
    if(this.rowData.isWifiOnly)
    {
      if (this.toEmployeeFlg && this.searchEmployeePersonnelNbr.length == 0) {
          empSearchValidation = true;
        }
      return !f.form.valid || empSearchValidation || mtnValidation ;
    }
   else 
   {
        if (this.toEmployeeFlg && this.searchEmployeePersonnelNbr.length == 0) {
          empSearchValidation = true;
        }
        if (this.PeoplePicActiveEmployeeEmailAlias.length == 0) {
          mtnValidation = true;
        }
        if (!this.toEmployeeFlg ) {
          empSearchValidation = false;
          mtnValidation = false;
        }
        
        if (this.existingOptionSelected && !this.isTicketRowSelected) {
          existingValidation = true;
      }
      return !f.form.valid || empSearchValidation || mtnValidation || existingValidation || !this.IsValidExpectedReturn;
    }
  }

  fn_onClickNCCheckBox(event) {
    if ((event.target.checked)) {
      this.enableNCLinck = true;
    } else {
      this.enableNCLinck = false;
    }
  }

  public get IsValidExpectedReturn(): boolean {
    if (this.multiRowData[0].mobileAssetStatusID == 2)
      return this.multiRowData[0].hasExpectedReturn && this.multiRowData[0].expectedReturn.toString() != '' && new Date(this.multiRowData[0].expectedReturn).getFullYear() != 1;
    else {
      this.multiRowData[0].hasExpectedReturn = false;
      this.multiRowData[0].expectedReturn = new Date("0001-01-01T00:00:00");
      return true;
    }
  }
}

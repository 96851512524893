export class AssignDevice {
  deviceID: string;
  deviceLocationID: number;
  deviceStatusID: number;
  deviceStatus: string;
  personnelNbr: string;
  serviceDescIncident: string;
  shippingTrackingNbr: string;
  sim: string;
  isPowerCordReturned: boolean;
  isDeviceWiped: boolean;
  deviceWipeReasonID: number;
  facilityCode: string;
  carrier: string;
  createOrModifiedBy: string;
  deviceTransactionTypeID: number;
  mtn: string;
  deviceType: string;
  deviceTypeDesc: string;
  isReplacedDevice: boolean;
  carrierStore: string;
  mobileDeviceHistoryID: number;
  mobileAssetStatusID: number;
  deviceModelDescription: string;
  status: string;
  imsi: string;
  msid: string;
  mobileNumber: string;
  facilityName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  alias: string;
  preferredName: string;
  legalName: string;
  currentStatus: string;
  accountStatus: string;
  imeI_ESN_MEID: string;
  isEmployeeActive: boolean;
  deviceOS: string;
  deviceRegion: number;
  activeEmployeeAlias: string;
  activePersonnelNbr: string;
  employeeStatusDesc: string;
  isPRExceptionGranted: number;
  isPersonnel: number;
  assignedTo: string;
  tssRep: string;
  activationDate: string;
  adminTransactionComments: string;
  deviceName: string;
  expectedReturn: Date;  
  hasExpectedReturn: boolean;
  expectedReturnString: string;
  size: string;
  color: string;
  serialNumber: string;
  vendor: string;
  isWifiOnly : boolean;
}

export class ADConstants {
  RECYCLE_LABEL = 'Recycle';
  IN_TRANSIT_TO_RECYCLE = 'In Transit to Recycle';
}

export class AssignDeviceBatch{
  Devices: AssignDevice[];
}

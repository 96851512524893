import { Component, OnInit } from '@angular/core';
declare var _satellite: any;
declare var window: any;
@Component({
  selector: 'app-empmtn',
  templateUrl: './empmtn.component.html',
  styleUrls: ['./empmtn.component.scss']
})
export class EmpmtnComponent implements OnInit {

  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];

  constructor() { }
  
totalnumber=5;
  ngOnInit() {
    this.fn_DatalayerPageLoad();

  }
  collection: any[] = [
    {
      // id: 1,
      info: {
        name: "praveen",
        alias: "dnesley",
        devicemodel: "Apple iphone 11",
        syncdate: "12 May 2019",
        isSelected: false
      }
    },
    {
      // id: 5,
      info: {
        name: "Hensley,Dillon1",
        alias: "dnesley",
        devicemodel: "Apple iphone 11",
        syncdate: "12 May 2019",
        isSelected: false
      }
    },
    {
      // id: 2,
      info: {
        name: "test2",
        alias: "jo",
        devicemodel: "Apple iphone 11",
        syncdate: "12 May 2019",
        isSelected: false
      }
    },

  ];
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee MTN Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

}

import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { LoggedInUserModulesService } from '../_services/login.service';


@Directive({
  selector: '[copyToClipboard]'
})
export class CopyToClipboardDirective implements OnInit {
  @Input('copiedString') copiedString: string;
  @ViewChild('copiedAlert',{static:false}) copiedAlert: ElementRef;
  private _copyElement: HTMLElement;
  constructor(
    private _el: ElementRef,
    private _clipboard: Clipboard,
    private _renderer: Renderer2,
    private loginService: LoggedInUserModulesService
  ) { }

  @HostListener('mouseenter') onMouseEnter() {
    this._createCopyElement();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this._removeCopyIcon();
  }

  ngOnInit(): void {
  }

  private _onClick(copiedElement : HTMLElement, event: Event) {
    event.stopPropagation();
    const element = this.loginService.copyElementRef.nativeElement;

    const alertXaxis = copiedElement.getBoundingClientRect().left - 50;
    const alertYaxis = copiedElement.getBoundingClientRect().top - 90;
    if(element) {
      this._renderer.setStyle(element, 'transform', `translateX(${alertXaxis}px) translateY(${alertYaxis}px)`);
      this._renderer.setStyle(element, 'display', 'block');

      setTimeout(() => {
        this._renderer.setStyle(element, 'display', 'none');
      }, 1000);
    }
    this._clipboard.copy(this.copiedString);
  }

  private _createCopyElement(): void {
    this._copyElement = this._renderer.createElement('i');
    this._renderer.addClass(this._copyElement, 'fa');
    this._renderer.addClass(this._copyElement, 'fa-clone');
    this._renderer.addClass(this._copyElement, 'copy-clipboard');
    
    this._renderer.appendChild(this._el.nativeElement, this._copyElement);

    this._renderer.listen(this._copyElement, 'click', () => this._onClick(this._copyElement, event));
  }

  private _removeCopyIcon(): void {
    this._renderer.removeChild(this._el.nativeElement, this._copyElement);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DataContextService } from './datacontext.service';
import { UploadActivityDetails } from '../_models/UploadActivityDetails.model';
import { AppConstants } from '../_utils/app-constants';


@Injectable({
  providedIn: 'root'
})
export class UploadActivityService {
  // uploadactivityDetails: UploadActivityDetails;
  uploadActivityLogData = new BehaviorSubject<any[]>([]);
  apiurl:string = localStorage.getItem('apiurl');
  constructor(private http: HttpClient, private _datacontextService: DataContextService) { }
  getUploadActivityDetailsInv() {
    return this._datacontextService.getData<UploadActivityDetails[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Inventory.GetUploadActivityDetailsInv).pipe(map(data => data));
  }
  getUploadActivityDetailsOrders() {
    return this._datacontextService.getData<UploadActivityDetails[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Orders.GetUploadActivityDetailsOrders).pipe(map(data => data));
  }
  getUploadActivityDetailsCore() {
    return this._datacontextService.getData<UploadActivityDetails[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.CoreService.GetUploadActivityDetailsCore).pipe(map(data => data));
  }
  getUploadActivityDetailsBill() {
    return this._datacontextService.getData<UploadActivityDetails[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.MBR.GetUploadActivityDetailsBill).pipe(map(data => data));
  }
  getUploadActivityDetailsCompliance() {
    return this._datacontextService.getData<UploadActivityDetails[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Compliance.GetUploadActivityDetailsCompliance).pipe(map(data => data));
  }
}

export class ServicenowTicketDetails {
    callSource: string;
    priority: string;
    status: string;
    assignee: string;
    group: string;
    ticketNumber: string;
    incidentArea: string;
    description: string;
    affectedEndUser: string;
    openDate: string;
    summary: string;
    id: string;
    comments: string;
    impact: string;
    urgency: string;
    checkListProperties:string[];
    category: string;
    subCategory: string;
    callerId: string;
    configItem: string;
    dueDate: string;
    requestedFor: string;
    shortDescription: string;
    special_Instructions: string;
    itemName: string;
    WipeOption:string;
}
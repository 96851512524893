export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public  apiurl = '';
  public  apiurlv2 = '';
  public  tenant ='';
  public  clientid='';
  public  weburl='';
  public  environment='';

  // Whether or not to enable debug mode
  public static enableDebug = true;

  constructor() {
  }

}

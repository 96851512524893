import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InventoryEmployeeDetails } from 'src/app/_models/employee-profile/InventoryEmployeeDetails';
import { MTNCancelledSuspendStatus } from 'src/app/_models/employee-profile/MTNCancelledSuspendStatus';
import { AssignDevice } from 'src/app/_models/inventory/AssignDevice.model';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
import { InventoryService } from 'src/app/_services/inventory/inventory.service';

declare var _satellite: any;
declare var window: any;

@Component({
  selector: 'app-empinventory',
  templateUrl: './empinventory.component.html',
  styleUrls: ['./empinventory.component.scss']
})
export class EmpinventoryComponent implements OnInit {

  fromComp: string = "INV_SEARCH"
  Role: string = '';
  currentInventory: InventoryEmployeeDetails[];
  previousInventory: InventoryEmployeeDetails[];
  @Input() personnelNumber: string;
  selectedMTN: string = '';
  selectedIMEI: string = '';
  pendingReturn: boolean = false;
  pendingWipe: boolean = false;
  totalnumbercurrent = 0;
  totalnumberprevious = 0;
  assigndevicebutton: boolean = false;
  showAssignDevice: boolean = false;
  showWipeDevice: boolean = false;
  multiassignDeviceData: any;
  assignDeviceData: AssignDevice;
  @Output() emitIMIE = new EventEmitter<any>();
  @Output() emitMTN = new EventEmitter<any>();
  @Output() backToTails = new EventEmitter<any>();
  mtngridinformation: boolean = false;
  imeigridinformation: boolean = false;
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];
  isUSI:string='';
  MtnStatusDetails: MTNCancelledSuspendStatus[];

  constructor(private _empService: EmpProfileService, private inventoryservice: InventoryService) {
    this.Role = localStorage.getItem('UserRole');
  }

  ngOnInit() {
    // $('.pane--table2').scroll(function () {
    //   $('.pane--table2 table').width($('.pane--table2').width() + $('.pane--table2').scrollLeft());
    // });
    this.GetEmployeeInventoryDetails(this.personnelNumber);
    //this.fn_DatalayerPageLoad();
  }
  GetEmployeeInventoryDetails(personnelno: string) {
    return this._empService.GetEmployeeInventoryDetails(personnelno).subscribe(
      (data) => {
        // let mtnList = '';
        // if (data)
        // {
        //   data.forEach((x,index)=>{
        //     if(x['mobileTelephoneNbr'].length > 0)
        //     {
        //       if (mtnList.length <= 0) mtnList = x['mobileTelephoneNbr'];
        //       else  mtnList = mtnList + ',' + x['mobileTelephoneNbr'];
        //     }
        //   })
        // }        
        // this._empService.GetMTNCancelledSuspendStatus(mtnList).subscribe(
        //   (data) => {            
        //     this.MtnStatusDetails = data;
        //     this.currentInventory.forEach((x,index)=>{
        //         this.MtnStatusDetails.forEach((y,index)=>{                  
        //           if(x['mobileTelephoneNbr']== y['mtn'])
        //           {                    
        //             x['isMarkedForCancellation'] = y['isMarkedForCancellation'];
        //             x['isMarkedForSuspend'] = y['isMarkedForSuspend'];                    
        //           }                
        //         });            
        //     })
        //   });
        this.currentInventory = data.filter(x => x.isHistory == '0');
        this.totalnumbercurrent = this.currentInventory.length;
        this.previousInventory = data.filter(x => x.isHistory != '0');
        // console.log('previousinventyory', this.previousInventory);
        this.totalnumberprevious = this.previousInventory.length;
        let arrPR = data.filter(x => x.isUSI == "US");
        let arrPW = data.filter(x => x.isUSI != "US");

        if (arrPR.length > 0) {
          this.pendingReturn = true;
        }
        if (arrPW.length > 0) {
          this.pendingWipe = true;
        }
      }
    )
  }
  deviceAssignmentChanged(event) {
    if (event) {
      this.GetEmployeeInventoryDetails(this.personnelNumber);
    }
  }
  ReturnInventory(row: InventoryEmployeeDetails) {

    this.inventoryservice
      .GetAssignedDeviceData(row.mobileDeviceID, this.personnelNumber)
      .subscribe(data => {
        this.multiassignDeviceData = [];
        this.assignDeviceData = data[0];
        this.multiassignDeviceData.push(data[0]);
        this.assigndevicebutton = true;
        // console.log("multiassignDeviceData", this.multiassignDeviceData);
        this.showAssignDevice = true;
        // this.spinnerService.showLoaderForGridData = false;
      });
  }
  WipeInventory(row: InventoryEmployeeDetails) {
    this.inventoryservice
      .GetAssignedDeviceData(row.mobileDeviceID, this.personnelNumber)
      .subscribe(data => {
        this.multiassignDeviceData = [];
        this.assignDeviceData = data[0];
        this.multiassignDeviceData.push(data[0]);
        this.assigndevicebutton = true;
        // console.log("multiassignDeviceData", this.multiassignDeviceData);
        this.showWipeDevice = true;

        // console.log('wipeshowdevicebutton', this.showWipeDevice);
        // this.spinnerService.showLoaderForGridData = false;
      });
      this.isUSI = row.isUSI;
  }
  imeimethod(imei) {
    this.selectedIMEI = imei;
    // this.emitIMIE.emit(imei);
    this.imeigridinformation = true;
  }
  mtnmethod(mtn) {
    this.selectedMTN = mtn;
    this.mtngridinformation = true;
    // this.emitMTN.emit(mtn);
  }
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee Inventory Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }
  backToInventory(){
    this.imeigridinformation = false;
    this.mtngridinformation = false;
  }
  backtoSearch() {
    this.backToTails.emit()
    this.imeigridinformation = true;
    this.mtngridinformation = true;
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompIntuneSync } from 'src/app/_models/employee-profile/CompIntuneSync';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
declare var _satellite: any;
declare var window: any;
import $ from 'jquery';
@Component({
  selector: 'app-empintune',
  templateUrl: './empintune.component.html',
  styleUrls: ['./empintune.component.scss']
})
export class EmpintuneComponent implements OnInit {

  constructor(private _empService:EmpProfileService){}

  @Input()personnelNumber:string;
  intuneDetails:CompIntuneSync[];
  totalnumber = 0;
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];
  @Output() backToTails = new EventEmitter<any>();
  @Input() isPageLoad;
 
   backtoSearch() {
     this.backToTails.emit()
     this.isPageLoad= false;
   }
 
  ngOnInit() {
    $('.pane--table2').scroll(function () {
      $('.pane--table2 table').width($('.pane--table2').width() + $('.pane--table2').scrollLeft());
    });
    this.GetIntuneSyncDetails(this.personnelNumber);
    //this.fn_DatalayerPageLoad();
  }
  GetIntuneSyncDetails(personnelno:string)
  {
    this._empService.GetIntuneSyncDetails(personnelno).subscribe(
      (data) => {this.intuneDetails = data;
        this.totalnumber =this.intuneDetails.length;
      }
    )
  }
  collection: any[] = [
    {
      // id: 1,
      info: {
        name: "praveen",
        alias: "dnesley",
        devicemodel: "Apple iphone 11",
        syncdate: "12 May 2019",
        isSelected: false
      }
    },
    {
      // id: 5,
      info: {
        name: "Hensley,Dillon1",
        alias: "dnesley",
        devicemodel: "Apple iphone 11",
        syncdate: "12 May 2019",
        isSelected: false
      }
    },
    {
      // id: 2,
      info: {
        name: "test2",
        alias: "jo",
        devicemodel: "Apple iphone 11",
        syncdate: "12 May 2019",
        isSelected: false
      }
    },

  ];
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee Intune Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PeoplePickerComponent } from './people-picker/people-picker.component';
import { NgxPrintModule } from 'ngx-print';
import { OrderModule } from 'ngx-order-pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MyDatePickerModule } from 'mydatepicker';
import { HighlightSearch } from 'src/app/_pipes/highlight.pipe';
import { EmployeeProfileComponent } from './employee-profile/employee-profile.component';
import { EmpsidenavComponent } from './employee-profile/empsidenav/empsidenav.component';
import { EmptilesComponent } from './employee-profile/emptiles/emptiles.component';
import { DeviceWipeComponent } from './employee-profile/device-wipe/device-wipe.component';
import { EmpintuneComponent } from './employee-profile/empintune/empintune.component';
import { EmpinventoryComponent } from './employee-profile/empinventory/empinventory.component';
import { EmpmbrComponent } from './employee-profile/empmbr/empmbr.component';
import { EmpmtnComponent } from './employee-profile/empmtn/empmtn.component';
import { EmpordersComponent } from './employee-profile/emporders/emporders.component';
import { EmpviolationsComponent } from './employee-profile/empviolations/empviolations.component';
import { LaptopEmpinventoryComponent } from './employee-profile/laptop-empinventory/laptop-empinventory.component';
import { LaptopEmpviolationComponent } from './employee-profile/laptop-empviolation/laptop-empviolation.component';
import { EmpIncHistoryComponent } from './employee-profile/empsidenav/emp-inc-history/emp-inc-history.component';
import { EmpcommentsComponent } from './employee-profile/empsidenav/empcomments/empcomments.component';
import { EmpgridoverlayComponent } from './employee-profile/empsidenav/empgridoverlay/empgridoverlay.component';
import { ExceptionsComponent } from './employee-profile/empsidenav/exceptions/exceptions.component';
import { LoaHistoryComponent } from './employee-profile/empsidenav/loa-history/loa-history.component';
import { NotificationsComponent } from './employee-profile/empsidenav/notifications/notifications.component';
import { TransfersComponent } from './employee-profile/empsidenav/transfers/transfers.component';
import { ViolationsComponent } from './employee-profile/empsidenav/violations/violations.component';
import { SearchPipe } from 'src/app/_pipes/search.pipe';
import { MbrSearchPipe } from 'src/app/_pipes/mbr-search.pipe';
import { ModalService } from 'src/app/_services/modal.service';
import { SafePipe } from 'src/app/_pipes/safe.pipe';
import { UploadActivityService } from 'src/app/_services/uploadactivitylog.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { SideFilterNavComponent } from './side-filter-nav/side-filter-nav.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UploadActivityLogComponent } from './upload-activity-log/upload-activity-log.component';
import { ReportsComponent } from './reports/reports.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ImeiComponent } from './imei/imei.component';
import { MtnComponent } from './mtn/mtn.component';
import { FilterPipe } from 'src/app/_pipes/filter.pipe';
import { LaptopDeviceHistoryComponent } from './laptop-device-history/laptop-device-history.component';
import { AssigndeviceComponent } from '../mobile/inventory/assigndevice/assigndevice.component';
import { CopyToClipboardDirective } from 'src/app/_utils/copy-to-clipboard.directive';

@NgModule({
  declarations: [
    PeoplePickerComponent,
    HighlightSearch,
    EmployeeProfileComponent,
    EmpsidenavComponent,
    EmptilesComponent,
    DeviceWipeComponent,
    EmpintuneComponent,
    EmpinventoryComponent,
    EmpmbrComponent,
    EmpmtnComponent,
    EmpordersComponent,
    EmpviolationsComponent,
    LaptopEmpinventoryComponent,
    LaptopEmpviolationComponent,
    EmpIncHistoryComponent,
    EmpcommentsComponent,
    EmpgridoverlayComponent,
    ExceptionsComponent,
    LoaHistoryComponent,
    NotificationsComponent,
    TransfersComponent,
    ViolationsComponent,
    SafePipe,
    SearchPipe,
    FilterPipe,
    SideFilterNavComponent,
    UploadActivityLogComponent,
    ReportsComponent,
    ImeiComponent,
    MtnComponent,
    MbrSearchPipe,
    LaptopDeviceHistoryComponent,
    AssigndeviceComponent,
    CopyToClipboardDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxBarcodeModule,
    NgxPrintModule,
    ReactiveFormsModule,
    OrderModule,
    NgbModule,
    NgbModalModule, 
    MyDatePickerModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
    PeoplePickerComponent,
    HighlightSearch,
    EmployeeProfileComponent,
    EmpsidenavComponent,
    EmptilesComponent,
    DeviceWipeComponent,
    EmpintuneComponent,
    EmpinventoryComponent,
    EmpmbrComponent,
    EmpmtnComponent,
    EmpordersComponent,
    EmpviolationsComponent,
    LaptopEmpinventoryComponent,
    LaptopEmpviolationComponent,
    EmpIncHistoryComponent,
    EmpcommentsComponent,
    EmpgridoverlayComponent,
    ExceptionsComponent,
    LoaHistoryComponent,
    NotificationsComponent,
    TransfersComponent,
    ViolationsComponent,
    SafePipe,
    SearchPipe,
    FilterPipe,
    DatePipe,
    SideFilterNavComponent,
    UploadActivityLogComponent,
    ReportsComponent,
    MtnComponent,
    ImeiComponent,
    MbrSearchPipe,
    LaptopDeviceHistoryComponent,
    AssigndeviceComponent,
    CopyToClipboardDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers:[
    HighlightSearch,
    MbrSearchPipe,
    ModalService,
    SearchPipe,FilterPipe,SafePipe,DatePipe, ReportsService, UploadActivityService
  ]
})
export class SharedModule { }

import { HttpHeaders } from "@angular/common/http";
import { FormControl } from "@angular/forms";
import { of } from "rxjs";

declare var _satellite: any;
declare var window: any;

export class GlobalVariables {
  public static get loggedInEmailId(): string { return localStorage.getItem('loggedInUserName');}
  public static get loggedInUserName(): string { return localStorage.getItem('loggedInUserName') ? localStorage.getItem('loggedInUserName').split('@')[0] : undefined; }
  public static get userRole(): string { return localStorage.getItem('UserRole'); }
  public static httpJSONHeader = new HttpHeaders().set('content-type', 'application/json');
  public static fn_DatalayerPageLoad(pageName: string) {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': pageName,
        'userID': GlobalVariables.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

  public static fn_DatalayerOnClicks(pageName: string, Onclicktext) {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': pageName,
        'userID': GlobalVariables.loggedInUserName,
        'onclick': Onclicktext
      }
    };
    _satellite.track('onclicks');
  }

  public static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return of(isValid ? null : { "whitespace": true });
  }

}
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-unsuccess-message-dialogue',
  templateUrl: './unsuccess-message-dialogue.component.html',
  styleUrls: ['./unsuccess-message-dialogue.component.scss']
})
export class UnsuccessMessageDialogueComponent implements OnInit {

  title: string;
  message: string;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}

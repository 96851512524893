import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private tableGridData = new BehaviorSubject('');
  totalGridData = this.tableGridData.asObservable();

  constructor() { }

  postTableGridData(grid: any) {
    this.tableGridData.next(grid);
  
  }

}

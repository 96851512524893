import { Component, Input, OnInit } from '@angular/core';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
declare var _satellite: any;
declare var window: any;
@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.scss']
})
export class TransfersComponent implements OnInit {

  @Input() rowObj: string;
  @Input() transferDetails = [];
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];

  constructor(private _empService: EmpProfileService) { }

  ngOnInit() {
    //this.fn_DatalayerPageLoad();
  }
  ngOnChanges() {
    // this.GetCoreTransferDetails();
  }
  GetCoreTransferDetails() {
    let personnelNumber = this.rowObj;
    this._empService.GetCoreTransferDetails(personnelNumber).subscribe(
      (data) => {
        // console.log("GetCoreTransferDetails",data);
        
          this.transferDetails = data;
        }
      
    )
  }
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee Transfers Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

}

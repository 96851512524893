<!-- main app container -->
<div class="sidenav-filter-panel" [ngStyle]="{'display': showFiltersPanel===true ? 'none':'block'}">
    <div class="card-body order-sidenav-cardbody">
      <form [formGroup]="registerForm" (ngSubmit)="applyToFilterGridData($event)">
        <div class="form-row">
          <div class="form-group col">
            <label for="sel1" class="audit-type">Audit Type <sup>*</sup></label>
            <select id="audittypeval" formControlName="audittype" *ngIf="data" [(ngModel)]="auditSelectedVal" (change)="auditValue(auditSelectedVal)" class="form-control audittype-select">
              <option value="" hidden selected>Select Option</option>
              <option *ngFor="let data of complianceModel.complianceIssue">{{data.complianceIssueDesc}}</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col">
            <label for="sel2" class="audit-type">Notifications Sent</label>
            <select formControlName="notificationsent" class="form-control input-lg audittype-select" *ngIf="data"
              [(ngModel)]="notificationsSentSelectedVal" (click)="notificationsValue(notificationsSentSelectedVal)">
              <option value="" hidden selected>Select Option</option>
              <!-- <option *ngFor="let issueid of notificationValues">{{issueid.value}}</option> -->
              <option *ngFor="let issueid of notificationValues[auditSelectedVal]">{{issueid.value}}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
            <label class="control-label" for="Country">Region</label>
            <select id="selectedOfficeName" formControlName="regionval" *ngIf="complianceModel.compFacility" [(ngModel)]="selectedCountry" (change)="regionValueData()" class="form-control input-lg" id="DDLRegionID">
               <option value="0" hidden selected>Select Option</option>
               <option value="0">All</option>
              <option *ngFor="let country of regionData" value= {{country.facilityRegionCode}}>{{country.facilityRegionMediumText}}</option>
            </select>
          </div>
        <div *ngIf="showLastHeartBeatDate">
        <label for="sel1" class="audit-type">Last Heartbeat Date</label>
        <div class="form-group date-picker">
          <div class="row selected-date">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 my-date-picker-from">
              <my-date-picker [options]="myDatePickerOptions" (dateChanged)="onDateChangedFrom($event)"
                [selDate]="fselectedDate" [placeholder]="fromplaceholder" formControlName="datepickerfromval" class="fromDateClass">
              </my-date-picker>
            </div>
            <!-- <div class="col-md-12 p-0" *ngIf="fromDateSelected">
                <div class="fromdate-err-msg">To Date must be greater than From Date</div>
              </div> -->
              <div class="col-md-12 p-0" *ngIf="fromDateSelected">
                  <div class="fromdate-err-msg">Please select From Date</div>
                </div>
  
          </div>
          <div class="row selected-date">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 my-date-picker-to">
              <my-date-picker [options]="myDatePickerOptions" (dateChanged)="onDateChangedTo($event)"
                [selDate]="tselectedDate" [placeholder]="toplaceholder" formControlName="datepickertoval" class="toDateClass"></my-date-picker>
            </div>
            <div class="col-md-12 p-0" *ngIf="toDateSelected">
              <div class="todate-err-msg">To Date must be greater than From Date</div>
            </div>
            <div class="col-md-12 p-0" *ngIf="toDateSelectedMust">
                <div class="todate-err-msg">Please select To Date</div>
              </div>
            <div class="col-md-12 p-0" *ngIf="fromToDateSelected">
                <div class="todate-err-msg">Please select both From date and To date</div>
              </div>
          </div>
        </div>
      </div>
  
        <div *ngIf="filtersdata">
          <div class="form-row filter-data" *ngIf="showEmployeeFilter">
            <div class="form-group col">
              <div class="row">
                <div class="col-md-10">
                  <label class="audit-type" for="emp">Employee</label>
                </div>
                <div class="col-md-2">
                  <span class="close-icon" (click)="closeEmployeeDiv($event)"><i class="fa fa-close"></i></span>
                </div>
              </div>
              <input type="text" class="form-control" id="employeefiltervalue" formControlName="selectedemployeefilterval"
                placeholder="Personnel No., Name or Alias">
            </div>
          </div>
          <div class="form-row filter-data" *ngIf="showEmployeeStatusFilter">
            <div class="form-group col">
              <div class="row">
                <div class="col-md-10">
  
                  <label class="audit-type" for="empstatus">Employee Status</label>
                </div>
                <div class="col-md-2">
                  <span class="close-icon" (click)="closeEmployeeStatusDiv($event)"><i class="fa fa-close"></i></span>
                </div>
              </div>
              <select formControlName="employeestatusdropdown" class="form-control employeestatusdropdown"
                [(ngModel)]="empStatusDropdownVal" (click)="empDropdownValue(empStatusDropdownVal)">
                <!-- <option value="">Select Option</option> -->
                <option value="" hidden selected>Select Option</option>
               <option value="">All</option>
                <option *ngFor="let empStatusData of empStatusData">{{empStatusData.employeeStatusDesc}}</option>
              </select>
            </div>
          </div>
          <div class="form-row filter-data" *ngIf="showAssetStatusFilter">
            <div class="form-group col">
              <div class="row">
                <div class="col-md-10">
  
                  <label class="audit-type" for="assetstatus">Asset Status</label>
                </div>
                <div class="col-md-2">
                  <span class="close-icon" (click)="closeAssetStatusDiv($event)"><i class="fa fa-close"></i></span>
                </div>
              </div>
              <select formControlName="assetStatusdropdown" class="form-control assetStatusdropdown">
                <!-- <option value="">Select Option</option> -->
                <option value="" hidden selected>Select Option</option>
               <option value="">All</option>
                <option *ngFor="let assetStatusData of assetStatusList" value="{{assetStatusData.AssetStatusID}}">{{assetStatusData.AssetStatusDesc}}</option>
              </select>
            </div>
          </div>
          <div class="form-row filter-data" *ngIf="showMtnFilter">
            <div class="form-group col">
              <div class="row">
                <div class="col-md-10">
                  <label class="audit-type" for="mtnval" id="mtn-cancel">MTN</label>
                </div>
                <div class="col-md-2">
                  <span class="close-icon" (click)="closeMtnDiv($event)"><i class="fa fa-close"></i></span>
                </div>
              </div>
              <input type="text" class="form-control" placeholder="Enter MTN" id="mtnval" formControlName="selectedmtnfilterval">
            </div>
          </div>
          <div class="form-row filter-data" *ngIf="showDeviceTypeFilter">
            <div class="form-group col">
              <div class="row">
                <div class="col-md-10">
                  <label class="audit-type" for="mtnval" id="mtn-cancel">Device Type</label>
                </div>
                <div class="col-md-2">
                  <span class="close-icon" (click)="closeDeviceTypeDiv($event)"><i class="fa fa-close"></i></span>
                </div>
              </div>
              <select id="DDLDeviceTypeID" formControlName="selecteddevicetypefilterval" class="form-control selecteddevicetypefilterval"
                [(ngModel)]="deviceTypeDropdownVal" (click)="deviceTypeDropdownValue(deviceTypeDropdownVal)">
                <!-- <option value="">Select Option</option> -->
                <option value="" hidden selected>Select Option</option>
                <option value="">All</option>
                <option *ngFor="let devicemodel of deviceTypeDetails" value="{{devicemodel.deviceTypeDesc}}">{{devicemodel.devicetype}}</option>
              </select>
              <!-- <input type="text" class="form-control" id="mtnval" formControlName="selecteddevicetypefilterval"> -->
            </div>
          </div>
          <div class="form-row filter-data" *ngIf="showOfficeFilter">
            <div class="form-group col">
              <div class="row">
                <div class="col-md-10">
                  <label class="audit-type" for="mtnval" id="mtn-cancel">Office</label>
                </div>
                <div class="col-md-2">
                  <span class="close-icon" (click)="closeOfficeDiv($event)"><i class="fa fa-close"></i></span>
                </div>
              </div>
              <!-- <select formControlName="selectedofficefilterval" class="form-control selectedofficefilterval"
                [(ngModel)]="officeDropdownVal" (click)="officeDropdownValue(officeDropdownVal)">
                <option value="">Select Option</option>
                <option>test</option>
              </select> -->
              <select *ngIf="states" formControlName="statesData" [(ngModel)]="selectedState" class="form-control input-lg DDLOfficeTypeClass" id="state">
                  <!-- <option value="">Select Option</option> -->
                  <!-- <option hidden value="" disabled selected>Select Option</option> -->
                  <!-- <option value="">All</option> -->
                  <option value="" hidden selected>Select Option</option>
               <option value="">All</option>
                <option *ngFor="let statesData of officeDataBasedRegion" value= {{statesData.facilityCode}}>{{statesData.facilityName}}</option>
              </select>
            </div>
          </div>
                 
          <!-- <div class="form-group">
              <label class="control-label" for="States">States:</label>
              <select *ngIf="states" [(ngModel)]="selectedState" [ngModelOptions]="{standalone: true}" class="form-control input-lg" id="state">
                  <option value="0">Select State</option>
                <option *ngFor="let state of states" value= {{state.id}}>{{state.name}}</option>
              </select>
            </div> -->
          <!-- <div class="form-row filter-data" *ngIf="showMtnStatusFilter">
            <div class="form-group col">
              <div class="row">
                <div class="col-md-10">
                  <label class="audit-type" for="mtnstatus">MTN Status</label>
                </div>
                <div class="col-md-2">
                  <span class="close-icon" (click)="closeMtnStatusDiv($event)"><i class="fa fa-close"></i></span>
                </div>
              </div>
              <select formControlName="mtntatusdropdown" class="form-control mtnstatusdropdown">
                <option value="">Select Option</option>
                <option *ngFor="let mtnStatusDetails of mtnStatusModel">{{mtnStatusDetails.mtnStatus}}</option>
              </select>
            </div>
          </div> -->
          <div class="form-row filter-data" *ngIf="showJoblevelGroupFilter">
            <div class="form-group col">
              <div class="row">
                <div class="col-md-10">
                  <label class="audit-type" for="joblevelgroup">Job Level Group</label>
                </div>
                <div class="col-md-2">
                  <span class="close-icon" (click)="closeJoblevelDiv($event)"><i class="fa fa-close"></i></span>
                </div>
              </div>
              <select id="DDLJobLevelGroupID" formControlName="joblevelgroupdropdown" class="form-control joblevelgroupsdropdown">
                <!-- <option value="">Select Option</option>
                <option>All</option> -->
                <option value="" hidden selected>Select Option</option>
                <option value="All">All</option>
                <option>Manager</option>
                <option>Non-PPD</option>
                <option>PPD</option>
                <option>Senior Manager</option>
                <option>Staff</option>
              </select>
            </div>
          </div>
          <div class="form-row filter-data" *ngIf="showViolationAgeFilter && audittypeMdm">
            <div class="form-group col">
              <div class="row">
                <div class="col-md-10">
                  <label class="audit-type" for="joblevelgroup">Violation Age</label>
                </div>
                <div class="col-md-2">
                  <span class="close-icon" (click)="closeViolationDiv($event)"><i class="fa fa-close"></i></span>
                </div>
              </div>
              <select id="DDLViolationAgeID" formControlName="violationagedropdown" class="form-control violationagedropdown" (change)="violationAge($event)">
                <option value="">Select Option</option>
                <!-- <option value="" hidden selected>Select Option</option> -->
                <option value="1">15 Days</option>
                <option value="2">30 Days</option>
                <option value="3">45 Days</option>
                <option value="4">60 Days</option>
                <option value="5">75+ Days</option>
              </select>
            </div>
          </div>
          <!-- <div class="form-row" *ngIf="showRegionFilter">
            <div class="form-group col">
              <div class="row">
                <div class="col-md-10">
                  <label class="audit-type" for="region">Region</label>
                </div>
                <div class="col-md-2">
                  <span class="close-icon" (click)="closeRegionDiv($event)"><i class="fa fa-close"></i></span>
                </div>
              </div>
              <select formControlName="regiondropdown" class="form-control regiondropdown">
                <option value="">Select Option</option>
                <option>Active</option>
              </select>
            </div>
          </div> -->
          <div class="form-row filter-data" *ngIf="showLoaStatusFilter">
            <div class="form-group col">
              <div class="row">
                <div class="col-md-10">
                  <label class="audit-type" for="loastatus">LOA Status</label>
                </div>
                <!-- <div class="col-md-2">
                  <span class="close-icon" (click)="closeLoaDiv($event)"><i class="fa fa-close"></i></span>
                </div> -->
              </div>
              <select id="DDLLoaStatusID" formControlName="loastatusdropdown" class="form-control loastatusdropdown">
                <!-- <option value="">Select Option</option>
                <option>All</option> -->
                <option value="" hidden selected>Select Option</option>
                <option value="All">All</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
          </div>
          <div *ngIf="showLoaDateFilter" class="filter-data">
            <div class="row">
              <div class="col-md-10">
                <label for="sel1" class="audit-type">LOA Date</label>
              </div>
              <div class="col-md-2">
                <span class="close-icon" (click)="closeLoaDateDiv($event)"><i class="fa fa-close"></i></span>
              </div>
            </div>
            <div class="form-group date-picker">
              <div class="row selected-date">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 my-date-picker-from">
                  <my-date-picker [options]="myDatePickerOptions" (dateChanged)="loaOnDateChangedFrom($event)"
                    [selDate]="selectedDate" [placeholder]="fromplaceholder"
                    formControlName="selectedloadatepickerfromval"></my-date-picker>
                </div>
              </div>
              <div class="row selected-date">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 my-date-picker-to">
                  <my-date-picker [options]="myDatePickerOptions" (dateChanged)="loaOnDateChangedTo($event)"
                    [selDate]="selectedDate" [placeholder]="toplaceholder" formControlName="selectedloadatepickertoval">
                  </my-date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
            <label for="carrierVendor" class="audit-type">Carrier</label>
            <ng-multiselect-dropdown formControlName="carrier" name="carrier" id="carrierVendor"   [placeholder]="'Select Option'"
            [settings]="dropdownSettings" [data]="DeviceModel12" 
                [(ngModel)]="carrierSelectedList"   (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
        </div>
        <div class="form-group">
          <div *ngIf="addFiltersData" class="extrafilters">
            <div class="labelwithcheckbox col-md-12 col-lg-12">
              <label class="custom-control fill-checkbox">
                <input type="checkbox" id="employeecheck" class="form-control fill-control-input"
                  formControlName="employeefilterval">
                <span class="fill-control-indicator"></span>
                <span class="violation-text">Employee</span>
              </label>
            </div>
            <div class="col-md-12 col-lg-12">
              <label class="custom-control fill-checkbox">
                <input type="checkbox" class="fill-control-input" formControlName="employeestatusfilterval">
                <span class="fill-control-indicator"></span>
                <span class="violation-text">Employee Status</span>
              </label>
            </div>
            <div class="col-md-12 col-lg-12">
              <label class="custom-control fill-checkbox">
                <input type="checkbox" class="fill-control-input" placeholder="Enter MTN" formControlName="mtnfilterval">
                <span class="fill-control-indicator"></span>
                <span class="violation-text">MTN</span>
              </label>
            </div>
            <div class="col-md-12 col-lg-12">
              <label class="custom-control fill-checkbox">
                <input type="checkbox" class="fill-control-input" formControlName="devicetypefilterval">
                <span class="fill-control-indicator"></span>
                <span class="violation-text">Device Type</span>
              </label>
            </div>
            <div class="col-md-12 col-lg-12">
              <label class="custom-control fill-checkbox">
                <input type="checkbox" class="fill-control-input" formControlName="officefilterval">
                <span class="fill-control-indicator"></span>
                <span class="violation-text">Office</span>
              </label>
            </div>
            <!-- <div class="col-md-12 col-lg-12">
              <label class="custom-control fill-checkbox">
                <input type="checkbox" class="fill-control-input" formControlName="mtnstatusfilterval">
                <span class="fill-control-indicator"></span>
                <span class="violation-text">MTN Status</span>
              </label>
            </div> -->
            <div class="col-md-12 col-lg-12">
              <label class="custom-control fill-checkbox">
                <input type="checkbox" class="fill-control-input" formControlName="joblevelfilterval">
                <span class="fill-control-indicator"></span>
                <span class="violation-text">Job Level Group</span>
              </label>
            </div>
            <div class="col-md-12 col-lg-12" *ngIf="audittypeMdm">
              <label class="custom-control fill-checkbox">
                <input type="checkbox" class="fill-control-input" formControlName="violationagefilterval">
                <span class="fill-control-indicator"></span>
                <span class="violation-text">Violation Age</span>
              </label>
            </div>
            <div class="col-md-12 col-lg-12" *ngIf="showAssetStatusFilterLabel">
              <label class="custom-control fill-checkbox">
                <input type="checkbox" class="fill-control-input" formControlName="assetstatusfilterval">
                <span class="fill-control-indicator"></span>
                <span class="violation-text">Asset Status</span>
              </label>
            </div>
            <!-- <div class="col-md-12 col-lg-12">
              <label class="custom-control fill-checkbox">
                <input type="checkbox" class="fill-control-input" formControlName="regionfilterval">
                <span class="fill-control-indicator"></span>
                <span class="violation-text">Region</span>
              </label>
            </div> -->
            <!-- <div class="col-md-12 col-lg-12">
              <label class="custom-control fill-checkbox">
                <input type="checkbox" class="fill-control-input" formControlName="loastatusfilterval">
                <span class="fill-control-indicator"></span>
                <span class="violation-text">LOA Status</span>
              </label>
            </div> -->
            <!-- <div class="col-md-12 col-lg-12">
              <label class="custom-control fill-checkbox">
                <input type="checkbox" class="fill-control-input" formControlName="loadatefilterval">
                <span class="fill-control-indicator"></span>
                <span class="violation-text">LOA Date</span>
              </label>
            </div> -->
            <div class="extra-filters-buttons col-md-12">
              <div class="button-panel">
                <button id="btnCancelID" type="button" class="btn btn-white" (click)="cancelAddFilters()">Cancel</button>
                <button type="submit" class="btn btn-white-add" (click)="addData($event)"><span
                    class="add-text">Add</span></button>
              </div>
            </div>
          </div>
        </div>
       
        <div id="txtAddFilterID" class="add-filters-part row" (click)="addFilters()">
            <div *ngIf="!showAddFiltersDiv">
          <i class="fa fa-plus"></i><span class="addfilter-text">Add filters</span>
        </div>
        </div>
        <div class="text-right">
          <button class="btn btn-default clear-all resetClass" type="button" (click)="onReset()"
            [disabled]="!registerForm.valid && (!fromDateSelected && !toDateSelected)">Clear all</button>
          <button id="applyToFilterCompliance" #applyToFilterCompliance class="btn btn-primary mr-1 apply" [disabled]="!registerForm.valid && (!fromDateSelected && !toDateSelected)">Apply</button>
        </div>
        <div class="row border-for-filters-panel">
          
        </div>
        <div class="row hide-filters-div">
          <div class="offset-md-6 hide-filters-panel" (click)="closeNavFilter($event)">
            <img src="assets/images/collapse arrows.svg"><span class="hidedata-text">Hide filters panel</span>
          </div>
        </div>
      </form>
  
  
    </div>
  </div>
  <div class="filters-panel"  [ngStyle]="{'display': showFiltersPanel===true ? 'block':'none'}">
    <p (click)="filtersForOrders($event,registerForm)" class="show-filter-panel">Filters for Compliances<span
        class="open-filter-nav-icon"><img src="assets/images/arrow left 16.svg"></span><span style="visibility: hidden;">test</span></p>
  </div>
  
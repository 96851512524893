import { Component, Input, OnInit } from '@angular/core';
import { LoaHistory } from 'src/app/_models/employee-profile/loaHistory.model';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
declare var _satellite: any;
declare var window: any;
@Component({
  selector: 'app-loa-history',
  templateUrl: './loa-history.component.html',
  styleUrls: ['./loa-history.component.scss']
})
export class LoaHistoryComponent implements OnInit {

  @Input() rowObj: string;
  @Input() loaHistoryDetails: LoaHistory[];
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];

  constructor(private _empService: EmpProfileService) { }

  ngOnInit() {
  }
  ngOnChanges() {
    // this.GetLoaHistoryDetails();
    this.fn_DatalayerPageLoad();

  }
  GetLoaHistoryDetails() {
    let personnelNumber = this.rowObj;
    this._empService.GetLOADetails(personnelNumber).subscribe(
      (data) => {
        // console.log("GetLoaHistoryDetails",data);
          this.loaHistoryDetails = data;
        }
    )
  }
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "LOA History Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

}

import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { LoggedInUserModulesService } from "./login.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: LoggedInUserModulesService,
    private router: Router
  ) {}

  public canActivate(): Observable<boolean> {
    return this.authService.user$.pipe(
      map(_ => {
        if (_ && _.roleID > 0) {
          return true;
        }
        this.router.navigate(['unauthorized']);
        return false;
      }),
      take(1)
    );
  }
}
<div *ngIf="isPageLoad"
class="col-md-4" style="margin-left:-30px;cursor: pointer;">
<div (click)="backtoSearch()">
    <img src="assets/images/collapse arrows.svg"><span class="hidedata-text"
        style="font-size:12px;color: #212529;">Back</span>
</div>
</div>
<div *ngIf="isPageLoad">
<div class="row" *ngFor="let mbrBlowOut of resultset">
    <!-- <div class="col-md-1"></div> -->
    <!-- <div class="col-md-12 ml-4" *ngIf="isGridDataNotAvailable">
        <p class="compliance-text">No history found!</p>
    </div> -->
    <div>
        <div class="mtntable">
            <div class="row">
                <div class="col-md-8 intune-padding">
                    <p class="compliance-text-mbr-tile">{{mbrBlowOut[0].carrier}}</p>
                </div>


            </div>
            <div class="row pane pane--table2" style="overflow-x:auto;">
                <table class="table">

                    <thead class="">


                        <tr>
                            <th></th>
                            <th class="t-3" style="min-width:130px">
                                Feature details</th>
                            <th class="t-3" style="min-width:145px">
                                Wireless Number </th>
                            <th class="t-3" style="min-width:170px">
                                Total Current Charges</th>
                            <th class="t-3" style="min-width:180px">
                                Monthly Access Charges</th>
                            <th class="t-3" style="min-width:140px">
                                Airtime Charges</th>
                            <th class="t-3" style="min-width:300px">Total Data Usage Charges(Excluding Roaming)
                            </th>
                            <th class="t-3" style="min-width:170px">Total Messaging Charges</th>
                            <th class="t-3" style="min-width:170px">
                                Total Roaming Charges</th>
                            <th class="t-3" style="min-width:172px">
                                Total Equipment Charges</th>
                            <th class="t-3" style="min-width:170px">
                                Roaming Airtime Charges</th>
                                <th class="t-3" style="min-width:196px">
                                    Total Voice Minutes of Used</th>
                                    <th class="t-3" style="min-width:196px">
                                        Total KB Data Usage</th>
                        </tr>
                    </thead>
                    <!-- <tbody *ngIf="isGridDataNotAvailable">
                        <tr>
                            <td colspan="15" style="text-align: center">
                                <p class="compliance-text">No history found!</p>
                            </td>
                        </tr>
                    </tbody> -->
                    <tbody *ngFor="let row of mbrBlowOut">
                         <tr>
                            <td style="cursor: pointer;">
                                <div class="collapse-arrow" (click)="firstcollapsefiltericon(row)">
                                    <a data-toggle="collapse" [ngClass]="row == childId?'iconDown':'iconUp'"
                                        href="#collapseFilterfirst">
                                        <span class="icon">
                                            <img src="../../../../assets/images/arrow down 16.svg">
                                        </span>

                                    </a>


                                </div>


                            </td>
                            <td style="padding-top:7px;padding-bottom:9px;white-space: nowrap;
                            width: 150px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;" data-toggle="tooltip" title="{{row.billCycle}}">



                                {{row.billCycle | date:'mediumDate'}}<span data-toggle="tooltip" title="{{row.billCycle}}"></span>
                            </td>

                            <!-- <td  style="padding-top:8px;padding-bottom:22px">
                                        {{x.info.alias}}</td> -->

                           <td style="padding-top:8px;padding-bottom:22px" 
                           copyToClipboard copiedString="{{row.wirelessNumber}}">
                                {{row.wirelessNumber}}</td>
                            <!-- <td  data-toggle="tooltip" title="{{x.info.devicemodel}}"
                                style="width:200px;padding-top:5px;padding-bottom:22px;border-top:0px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block;">
                                {{x.info.devicemodel}}<span data-toggle="tooltip" title="{{x.info.devicemodel}}"
                                    ></span></td> -->
                            <td data-toggle="tooltip" title="{{row.totalCurrentCharges}}"
                                style="padding-top:5px;padding-bottom:22px;border-top:0px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block;">
                                {{row.totalCurrentCharges | number :'1.0-2'}}
                                <span data-toggle="tooltip" title="{{row.totalCurrentCharges}}"></span></td>
                            <td style="padding-top:5px;padding-bottom:22px">
                                <!-- {{row.monthlyAccessCharges.length>0?row.monthlyAccessCharges:'--'} -->
                                {{row.monthlyAccessCharges| number :'1.0-2'}}
                            </td>
                            <td style="padding-top:5px;padding-bottom:22px">
                                <!-- {{row.airtimeCharges.length>0?row.airtimeCharges:'--'}} -->
                                {{row.airtimeCharges| number :'1.0-2'}}
                            </td>
                            <td style="padding-top:5px;padding-bottom:22px">
                                <!-- {{row.totalDataUsageChargesExcludingRoaming.length>0?row.totalDataUsageChargesExcludingRoaming:'--'}} -->
                                {{row.totalDataUsageChargesExcludingRoaming| number :'1.0-2'}}
                            </td>
                            <td style="padding-top:5px;padding-bottom:22px">
                                <!-- {{row.totalMessagingCharges.length>0?row.totalMessagingCharges:'--' }} -->
                                {{row.totalMessagingCharges | number :'1.0-2'}}
                            </td>

                            <td style="padding-top:5px;padding-bottom:22px">
                                <!-- {{row.totalRoamingChargesExcludingVSCRoaming.length>0? row.totalRoamingChargesExcludingVSCRoaming:'--'}} -->
                                {{row.totalRoamingChargesExcludingVSCRoaming | number :'1.0-2'}}
                            </td>
                            <td style="padding-top:5px;padding-bottom:22px">
                                <!-- {{row.totalEquipmentCharges.length>0?row.totalEquipmentCharges:'--'}} -->
                                {{row.totalEquipmentCharges | number :'1.0-2'}}
                            </td>
                            <td style="padding-top:5px;padding-bottom:22px">
                                {{row.roamingAirtimeCharges | number :'1.0-2'}}</td>
                                <td style="padding-top:5px;padding-bottom:22px">
                                    {{row.totalVoiceMinutesofUse | number:'1.0-2'}}</td>
                                <td style="padding-top:5px;padding-bottom:22px">
                                    <!--{{row.totalKBDataUsage.length>0?row.totalKBDataUsage:'--'}}-->
                                    {{row.totalKBDataUsage | number :'1.0-2'}}
                            </td>
                        </tr>

                        <tr class="collapse" *ngIf="row == childId" id="collapseFilterfirst">
                            <td [attr.colspan]="11">
                                <table class="table" id="customers">
                                    <thead style="line-height:5px;background-color: #e6e6e6;">
                                        <tr>
                                            <th></th>
                                            <th style="min-width:161px;padding-bottom:22px;padding-left:22px">Item
                                                category</th>
                                            <th style="min-width:130px;padding-bottom:22px;padding-left:20px">
                                                Allowance</th>
                                            <th style="min-width:787px;padding-bottom:22px;padding-left:20px">Item
                                                description</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background-color: #f6f6f6;" *ngIf="row.mbrItemDetails!=null && row.mbrItemDetails.length>0">
                                        <tr *ngFor="let y of row.mbrItemDetails">
                                            <td>

                                            </td>
                                            <td style="padding-top:7px;padding-bottom:9px;white-space: nowrap;padding-left:22px;
                                             width: 150px;
                                             overflow: hidden;
                                             text-overflow: ellipsis;
                                             display: block;" data-toggle="tooltip"
                                                title="{{y.category}}">
                                                {{y.category.length>0?y.category:'--'}}<span data-toggle="tooltip"
                                                    title="{{y.category}}"></span>
                                            </td>

                                            <td style="padding-top:8px;padding-bottom:22px;padding-left:22px">
                                                {{y.allowance.length>0?y.allowance:'--'}}</td>
                                            <td data-toggle="tooltip" title="{{y.description}}"
                                                style="padding-top:5px;padding-left:22px;padding-bottom:22px;border-top:0px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block;">
                                                {{y.description.length>0?y.description:'--'}}
                                            </td>
                                        </tr>


                                    </tbody>
                                    <tfoot style="background-color: #f6f6f6;" *ngIf="row.mbrItemDetails==null"> 
                                        <tr>
                                            <td colspan="4" style="text-align: left;">
                                              <b><p class="no-data">No Data Available</p></b>
                                            </td>
                                          </tr>
                                    </tfoot>
                                </table>
                                <table class="table" id="plans">
                                    <thead style="line-height:5px;background-color: #e6e6e6;">
                                        <tr>
                                            <th></th>
                                            <th style="min-width:161px;padding-bottom:22px;padding-left:22px">
                                                Feature Code</th>
                                            <th style="min-width:130px;padding-bottom:22px;padding-left:20px">
                                                Feature Name</th>
                                            <th style="min-width:787px;padding-bottom:22px;padding-left:20px">
                                                Feature Charges</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background-color: #f6f6f6;" *ngIf="row.mbrPlanDetails!=null && row.mbrPlanDetails.length>0">
                                        <tr *ngFor="let y of row.mbrPlanDetails">
                                            <td>

                                            </td>
                                            <td style="padding-top:7px;padding-bottom:9px;white-space: nowrap;padding-left:22px;
                                             width: 150px;
                                             overflow: hidden;
                                             text-overflow: ellipsis;
                                             display: block;" data-toggle="tooltip"
                                                title="{{y.category}}"
                                                copyToClipboard copiedString="{{y.category}}"  
                                                >
                                                {{y.featureCode.length>0?y.featureCode:'--'}}<span data-toggle="tooltip"
                                                    title="{{y.category}}"></span>
                                            </td>

                                            <td style="padding-top:8px;padding-bottom:22px;padding-left:22px"
                                                copyToClipboard copiedString="{{y.featureName}}"  
                                            >
                                                {{y.featureName.length>0?y.featureName:'--'}}</td>
                                            <td data-toggle="tooltip" title="{{y.description}}"
                                                style="padding-top:5px;padding-left:22px;padding-bottom:22px;border-top:0px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block;">
                                                {{y.featureCharges.length>0?y.featureCharges:'--'}}
                                            </td>
                                        </tr>


                                    </tbody>
                                    <tfoot style="background-color: #f6f6f6;" *ngIf="row.mbrPlanDetails==null"> 
                                        <tr>
                                            <td colspan="4" style="text-align: left;">
                                              <b><p class="no-data">No Data Available</p></b>
                                            </td>
                                          </tr>
                                    </tfoot>
                                </table>
                                
                            </td>
                        </tr>






                        <!-- </tr> -->


                    </tbody>


                </table>

            </div>

        </div>


    </div>

</div>

</div>
<!-- <app-imei *ngIf="ShowIMEI" [selectedIMEI]="selectedIMEI"></app-imei> -->
<!-- <div *ngIf="spinnerService.showLoaderForGridData">
            <div class="loading">Loading&#8230;</div>
      </div> -->
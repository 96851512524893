export class InsertReportDetails {
    reportType: string;
    requestedUserAlias: string;
    requestedUserEmail: string;
    paramRegionCode: string;
    paramOfficeCode: string;
    paramStartDate: string;
    paramEndDate: string;
    paramEmployeeAlias?: string;

    filterType:string;

    paramDeviceReplacementCount?: string;
    personnelNumber : string;
}
<div *ngIf="showMtn && showBackButon"
    class="col-md-4" style="margin-left:-30px;cursor: pointer;">
    <div (click)="backtoSearch()" >
        <img src="assets/images/collapse arrows.svg"><span class="hidedata-text"
            style="font-size:12px;color: #212529;">Back</span>
    </div>
</div>




<section *ngIf="showMtn" class="mtn-component">
    <div class="row" *ngIf="ShowGrid">
        <!-- <div class="col-md-1"></div> -->
        <div class="col-md-12 ml-4" *ngIf="isGridDataNotAvailable">
            <p class="compliance-text">No history found!</p>
        </div>
        <div *ngIf="!isGridDataNotAvailable">
            <div class="mtntable">
                <div class="row">
                    <div class="col-md-11">
                        <p class="compliance-text-mtn">Mobile Telephone Number</p>
                    </div>
                    <div class="col-md-1 mt-2">
                        <div class="add-filters-part row" (click)="addFilters()">
                            <button class="icon-Cog_settings_POS">
                            </button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive" id="responsivetable">
                    <table class="table" id="customers">

                        <thead class="">
                            <tr>
                                <th *ngIf="devicenamefilter"
                                    style="min-width:200px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'deviceModelName'"
                                    (click)="setOrder('deviceModelName')">Device Name <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                                <th *ngIf="mobilenumbercheck"
                                    style="min-width:240px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'mobileTelephoneNbr'"
                                    (click)="setOrder('mobileTelephoneNbr')">Mobile Telephone Number <img src="assets/images/sorting.svg"
                                    class="img-cursor">
                                </th>
                                <th *ngIf="assignedtocheck"
                                    style="min-width:350px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'assignedTo'"
                                    (click)="setOrder('assignedTo')">Assigned To <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                                <th *ngIf="isPersonalcheck"
                                    style="min-width:170px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'isPersonalMTN'"
                                    (click)="setOrder('isPersonalMTN')">Is Personal MTN <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                                <th *ngIf="carriercheck" style="min-width:160px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'currentCarrier'"
                                (click)="setOrder('currentCarrier')">
                                    Carrier <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                                <th *ngIf="imeicheck" style="min-width:155px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'mobileDeviceID'"
                                (click)="setOrder('mobileDeviceID')">
                                    IMEI/ ESN <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                                <th *ngIf="devicelocationcheck"
                                    style="min-width:170px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'deviceLocation'"
                                    (click)="setOrder('deviceLocation')">Device Location <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                                <th *ngIf="devicestatuscheck"
                                    style="min-width:170px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'deviceStatus'"
                                    (click)="setOrder('deviceStatus')">Device Status <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                                <th *ngIf="accountstatuscheck"
                                    style="min-width:170px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'accountStatus'"
                                    (click)="setOrder('accountStatus')">Account Status <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                                <th *ngIf="modifiedcheck" style="min-width:160px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'modifiedDateString'"
                                (click)="setOrder('modifiedDateString')">
                                    Modified Date <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                                <th *ngIf="modifiedbycheck"
                                    style="min-width:200px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'modifiedBy'"
                                    (click)="setOrder('modifiedBy')">Modified By <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                                <th *ngIf="activationdatecheck"
                                    style="min-width:160px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'activationDateString'"
                                    (click)="setOrder('activationDateString')">Activation Date <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                                <th *ngIf="contractstartdatecheck"
                                    style="min-width:180px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'contractStartDateString'"
                                    (click)="setOrder('contractStartDateString')">Contract start date <img src="assets/images/sorting.svg"
                                    class="img-cursor">
                                </th>
                                <th *ngIf="contractenddatecheck"
                                    style="min-width:180px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'contractEndDateString'"
                                    (click)="setOrder('contractEndDateString')">Contract end date <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                                <th *ngIf="distinctioncheck"
                                    style="min-width:200px;padding-top:10px;padding-bottom:14px" [class.active]="order === 'personnelNbr'"
                                    (click)="setOrder('personnelNbr')">Personnel Number <img src="assets/images/sorting.svg"
                                    class="img-cursor"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let mtn of Mtnmodel | orderBy: order:reverse:true; let i = index">
                            
                               <td *ngIf="devicenamefilter" style="padding-top:10px;padding-bottom:9px;
                                width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: block;cursor: pointer;border: none;" data-toggle="tooltip"
                                    title="{{mtn.deviceModelName}}">{{mtn.deviceModelName}}<span data-toggle="tooltip"
                                        title="{{mtn.deviceModelName}}" *ngIf="devicenamefilter"></span></td>
                                <td *ngIf="mobilenumbercheck"
                                    style="min-width:240px;padding-top:10px;padding-bottom:14px">
                                    {{mtn.mobileTelephoneNbr}}</td>
                                <td *ngIf="assignedtocheck" data-toggle="tooltip" title="{{mtn.assignedTo}}"
                                    style="width:350px;padding-top:10px;padding-bottom:14px;border-top:0px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block;cursor: pointer;">
                                    {{mtn.assignedTo}}<span data-toggle="tooltip" title="{{mtn.assignedTo}}"
                                        *ngIf="assignedtocheck"></span></td>
                                <td *ngIf="isPersonalcheck"
                                    style="min-width:170px;padding-top:10px;padding-bottom:14px">
                                    {{mtn.isPersonalMTN ? 'Yes' : 'No'}}</td>
                                <td *ngIf="carriercheck" style="min-width:160px;padding-top:10px;padding-bottom:14px">
                                    {{mtn.currentCarrier}}</td>
                                <td *ngIf="imeicheck" style="min-width:155px;padding-top:10px;padding-bottom:14px"><a
                                        class="popups" name="imei" id="imei"
                                        (click)="imeimethod(mtn.mobileDeviceID)">{{ mtn.mobileDeviceID }}</a></td>
                                <td *ngIf="devicelocationcheck" style="width:100px;padding-top:10px;padding-bottom:14px;white-space: nowrap;
                                width: 170px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: block;cursor: pointer;border-top:0px;" data-toggle="tooltip"
                                    title="{{mtn.deviceLocation}}">{{mtn.deviceLocation}}<span data-toggle="tooltip"
                                        title="{{mtn.deviceLocation}}" *ngIf="devicelocationcheck"></span></td>
                                <td *ngIf="devicestatuscheck"
                                    style="min-width:170px;padding-top:10px;padding-bottom:14px">{{mtn.deviceStatus}}
                                </td>
                                <td *ngIf="accountstatuscheck"
                                    style="min-width:170px;padding-top:10px;padding-bottom:14px">{{mtn.accountStatus}}
                                </td>
                                <td *ngIf="modifiedcheck" style="min-width:160px;padding-top:10px;padding-bottom:14px">
                                    {{mtn.modifiedDateString}}</td>
                                <td *ngIf="modifiedbycheck" data-toggle="tooltip" style="padding-top:10px;padding-bottom:14px;white-space:nowrap;
                                width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: block;cursor: pointer;border-top:0px;" title="{{mtn.modifiedBy}}">
                                    {{mtn.modifiedBy}}
                                    <span data-toggle="tooltip" title="{{mtn.modifiedBy}}"
                                        *ngIf="modifiedbycheck"></span>

                                </td>
                                <td *ngIf="activationdatecheck"
                                    style="min-width:160px;padding-top:10px;padding-bottom:14px">
                                    {{mtn.activationDateString}}</td>
                                <td *ngIf="contractstartdatecheck"
                                    style="min-width:180px;padding-top:10px;padding-bottom:14px">
                                    {{mtn.contractStartDateString}}</td>
                                <td *ngIf="contractenddatecheck"
                                    style="min-width:180px;padding-top:10px;padding-bottom:14px">
                                    {{mtn.contractEndDateString}}</td>
                                <td *ngIf="distinctioncheck" data-toggle="tooltip" style="padding-top:10px;padding-bottom:14px;white-space:nowrap;
                                width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: block;cursor: pointer;border-top:0px;" title="{{mtn.personnelNbr}}">
                                    {{mtn.personnelNbr}}
                                    <span data-toggle="tooltip" title="{{mtn.personnelNbr}}"
                                        *ngIf="distinctioncheck"></span>
                                </td>

                            </tr>
                            <tr>


                        </tbody>
                    </table>
                </div>

            </div>
            <div class="row">
                <div class="offset-md-8 col-md-4">

                    <div *ngIf="filterdata" class="extrafilters">

                        <div class="filtercolumns">
                            <p class="custom">Customize table</p>
                            <div class="labelwithcheckbox col-md-7">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="devicecheck" name="devicecheck"
                                        class="form-control fill-control-input" [(ngModel)]="devicefilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Device</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-10">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="mobilenumbercheck" name="mobilenumbercheck"
                                        class="form-control fill-control-input" [(ngModel)]="mobilenumberfilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Mobile Telephone Number</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-7">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="assignedtocheck" name="assignedtocheck"
                                        class="form-control fill-control-input" [(ngModel)]="assignedtofilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Assigned To</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-8">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="isPersonalcheck" name="isPersonalcheck"
                                        class="form-control fill-control-input" [(ngModel)]="ispersonalfilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Is Personal MTN</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-7">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="carriercheck" name="carriercheck"
                                        class="form-control fill-control-input" [(ngModel)]="carrierfilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Carrier</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-7">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="imeinocheck" name="imeinocheck"
                                        class="form-control fill-control-input" [(ngModel)]="imeinofilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">IMEI/ESN</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-7">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="devicelocationcheck" name="devicelocationcheck"
                                        class="form-control fill-control-input" [(ngModel)]="devicelocationfilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Device Location</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-7">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="deviceStatuscheck" name="deviceStatuscheck"
                                        class="form-control fill-control-input" [(ngModel)]="devicestatusfilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Device Status</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-7">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="accountstatuscheck" name="accountstatuscheck"
                                        class="form-control fill-control-input" [(ngModel)]="accountstatusfilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Account Status</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-7">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="modifiedfiltercheck" name="modifiedfiltercheck"
                                        class="form-control fill-control-input" [(ngModel)]="modifiedfilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Modified</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-7">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="modifiedbyfiltercheck" name="modifiedbyfiltercheck"
                                        class="form-control fill-control-input" [(ngModel)]="modifiedbyfilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Modified By</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-7">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="activationdatecheck" name="activationdatecheck"
                                        class="form-control fill-control-input" [(ngModel)]="activationdatefilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Activation Date</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-8">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="contractstartdatecheck" name="contractstartdatecheck"
                                        class="form-control fill-control-input" [(ngModel)]="contractstartdatefilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Contract Start Date</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-8">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="contractenddatecheck" name="contractenddatecheck"
                                        class="form-control fill-control-input" [(ngModel)]="contractenddatefilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Contract End Date</span>
                                </label>
                            </div>
                            <div class="labelwithcheckbox col-md-8">
                                <label class="custom-control fill-checkbox">
                                    <input type="checkbox" id="distinctioncheck" name="distinctioncheck"
                                        class="form-control fill-control-input" [(ngModel)]="distinctionfilter">
                                    <span class="fill-control-indicator"></span>
                                    <span class="violation-text">Personnel Number</span>
                                </label>
                            </div>
                            <div class="extra-filters-buttons col-md-12">
                                <div class="button-panel">
                                    <button type="button" class="btn clear-all"
                                        (click)="cancelAddFilters()">Cancel</button>
                                    <button type="submit" class="btn btn-primary apply mt-1"
                                        (click)="addData($event)"><span class="add-text">Apply</span></button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <app-imei *ngIf="ShowIMEI" [selectedIMEI]="selectedIMEI"></app-imei>
    <div *ngIf="showLoaderForGridData">
        <div class="loading">Loading&#8230;</div>
    </div>
</section>
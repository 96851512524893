import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComplianceExceptions } from 'src/app/_models/employee-profile/ComplianceExceptions';
import { ComplianceViolations } from 'src/app/_models/employee-profile/ComplianceViolations';
import { CoreEmployeeView } from 'src/app/_models/employee-profile/CoreEmployeeView';
import { EmpIncidentHistory } from 'src/app/_models/employee-profile/EmpIncidentHistory';
import { LoaHistory } from 'src/app/_models/employee-profile/loaHistory.model';
import { LaptopCommentsResponse } from 'src/app/_models/laptop-compliance/laptopComment.model';
import { LaptopExceptionResponse } from 'src/app/_models/laptop-compliance/laptopException.model';
import { LaptopNotificationResponse } from 'src/app/_models/laptop-compliance/laptopNotification.model';
import { ViolationResponse } from 'src/app/_models/laptop-compliance/laptopViolation.model';
declare var _satellite: any;
declare var window: any;
@Component({
  selector: 'app-empgridoverlay',
  templateUrl: './empgridoverlay.component.html',
  styleUrls: ['./empgridoverlay.component.scss']
})
export class EmpgridoverlayComponent implements OnInit {

  @Input() rowObj: any;
  notificationData: any;
  sideOverlay: boolean = false;
  @Input() actionTypeValue: any = 0;
  @Input() commentCount: any = 0;
  @Input() laptopCommentCount: any = 0;
  @Input() notificationCount: any = 0;
  @Input() violationCount: any = 0;
  @Input() exceptionCount: any = 0;
  @Input() LegalName: string;
  @Input() violationDetails: ComplianceViolations[];
  @Input() exceptionDetails: ComplianceExceptions[];
  @Input() exceptionDataForLaptop: LaptopExceptionResponse[];
  @Input() notificationDetails: any[];
  @Output() emitt_CommentCount = new EventEmitter<number>();
  @Output() emitt_CommentCount_laptop = new EventEmitter<number>();
  @Input() laptopNotificationsData: any;
  @Input() laptopEmpViolationData: ViolationResponse[];
  @Input() rightSideNavWidth:string;
  @Input() loaHistoryDetails: LoaHistory[];
  @Input() transferDetails: [];
  @Input() userName: any;
  @Input() historyDataByInc: EmpIncidentHistory[];
  @Input() historyDataByReq: EmpIncidentHistory[];
  @Input() transferCount: number = 0;
  @Input() loaHistoryCount: number = 0;
  @Input() TotalTicketsCount: number = 0;
  @Input() toggleValueForMobileLaptop:boolean;
  @Input() employeeDetails:CoreEmployeeView;
  @Input() laptopEmpCommentsData: LaptopCommentsResponse[];
  IsResolve: boolean = false;
  showCommentsData: boolean = false;
  showNotificationsData: boolean = false;
  showExceptionsData: boolean = false;
  showViolationsData: boolean = false;
  showTransfersData: boolean = false;
  showHistoryData: boolean = false;

  countNumber: any;

  submitEventFromCommonComponent: any;
  submitEventFromExceptionComponent: any;
  showLoaHistoryData: boolean;

  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];

  constructor() { }


  ngOnInit() {
    // // console.log('this.rowObj in grid-overlay',this.rowObj);
    document.getElementById("RightSidenav").style.width = "30%";
    this.sideOverlay = true;
    //this.fn_DatalayerPageLoad();
  }
  notificationEventValue(event) {
    alert(event);
  }
  submitEventFromCommonComp(event) {
    this.submitEventFromCommonComponent = event;
  }
  submitEventFromExceptionComp(event) {
    this.submitEventFromExceptionComponent = event;
  }

  closeRightSideNav() {
    document.getElementById("RightSidenav").style.width = "0";
    this.emitt_CommentCount.emit(this.commentCount);
    this.emitt_CommentCount_laptop.emit(this.laptopCommentCount);
  }

  ngOnChanges() {
    // console.log(this.exceptionDetails);
    // if (this.rowObj != null && this.rowObj != undefined) {
    //   this.commentCount = this.rowObj.commentsCount;
    //   this.exceptionCount = this.rowObj.exceptionCount;
    //   this.notificationCount = this.rowObj.notificationCount;

    // }    
    this.fn_NavigateChildComponent(this.actionTypeValue);

  }

  fn_NavigateChildComponent(actionValue) {
    this.showCommentsData = false;

    this.showNotificationsData = false;
    this.showViolationsData = false;
    this.showExceptionsData = false;
    this.showTransfersData = false;
    this.showHistoryData = false;
    this.showLoaHistoryData = false;

    if (actionValue == 1) {
      this.showNotificationsData = true;
    } else if (actionValue == 2) {
      this.showViolationsData = true;
    } else if (actionValue == 3) {
      this.showCommentsData = true;
    } else if (actionValue == 4) {
      this.showExceptionsData = true;
    } else if (actionValue == 5) {
      this.showTransfersData = true;
    } else if (actionValue == 6) {
      this.showHistoryData = true;
    } else if (actionValue == 7) {
      this.showLoaHistoryData = true;
    }

  }

  fn_Emitt_CommentCount(value) {
    if (value != null && value != undefined) {
      this.commentCount = value;
    }
  }
  fn_Emitt_CommentCount_laptop(value) {
    if (value != null && value != undefined) {
      this.laptopCommentCount = value;
    }
  }

  fn_Emitt_ExceptionCount(value) {
    if (value != null && value != undefined) {
      this.exceptionCount = value;
    }
  }


  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee Overlay Grid Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

}

<section class="employee-profile-notification-comp">
    <div class="row history-tabs">
        <ul class="col-md-12 nav nav-tabs" role="tablist">
            <li class="col-md-6 nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Mobile Notifications: {{notificationDetails?.length}}
                </a>
            </li>
            <li class="col-md-6 nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Laptop Notifications: {{laptopNotificationsData?.laptopExceptionResult?.length}}
                </a>
            </li>
        </ul>
    </div>
    <div class="tab-content">
        <div class="tab-pane active" id="tabs-1" role="tabpanel">
            <div
                *ngIf="notificationDetails !== null && notificationDetails !== undefined && notificationDetails.length !== 0">
                <!-- <div class="row">
                    <div class="ml-4 past-comment-text">
                        <p class="past-comments">Notification Details</p>
                    </div>
                </div> -->
                <div class="emp-comment-scroll tab-violation-laptop">
                    <div class="card" *ngFor="let notificationresult of notificationDetails;let i=index">
                        <div [ngClass]="(i%2===0)?'card-header':'card-body'">
                            <div class="issue">
                                <label class="issue-text">Subject</label>
                                <p class="issue-data">{{notificationresult.emailSubject}}</p>
                                <label class="issue-text">Date/ Time</label>
                                <p class="issue-data">{{notificationresult.dateCreated | date:'medium'}}</p>
                                <label class="issue-text">Added By</label>
                                <p class="issue-data" *ngIf="notificationresult?.addedBy == ''">
                                    {{notificationresult.createdBy}}</p>
                                <p class="issue-data" *ngIf="notificationresult?.addedBy != ''">
                                    {{notificationresult.addedBy}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="notificationDetails === null || notificationDetails === undefined || notificationDetails.length === 0"
                class="notification-data">
                <p class="no-notifications">No Records Found</p>
            </div>
        </div>
        <div class="tab-pane" id="tabs-2" role="tabpanel">
            <div
                *ngIf="laptopNotificationsData.laptopExceptionResult !== null && laptopNotificationsData.laptopExceptionResult !== undefined && laptopNotificationsData.laptopExceptionResult.length !== 0">
                <!-- <div class="row">
                    <div class="ml-4 past-comment-text">
                        <p class="past-comments">Notification Details</p>
                    </div>
                </div> -->
                <div class="emp-comment-scroll tab-violation-laptop">
                    <div class="card" *ngFor="let notificationLaptopData of laptopNotificationsData.laptopExceptionResult;let i=index">
                        <div [ngClass]="(i%2===0)?'card-header':'card-body'">
                            <div class="issue">
                                <label class="issue-text">Subject</label>
                                <p class="issue-data">{{notificationLaptopData.emailSubject}}</p>
                                <label class="issue-text">Date/ Time</label>
                                <p class="issue-data">{{notificationLaptopData.dateCreated | date:'medium'}}</p>
                                <label class="issue-text">Added By</label>
                                <p class="issue-data" *ngIf="notificationLaptopData?.createdBy == ''">
                                    {{notificationLaptopData.createdBy}}</p>
                                <p class="issue-data" *ngIf="notificationLaptopData?.createdBy != ''">
                                    {{notificationLaptopData.createdBy}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="laptopNotificationsData.laptopExceptionResult === null || laptopNotificationsData.laptopExceptionResult === undefined || laptopNotificationsData.laptopExceptionResult.length === 0"
                class="notification-data">
                <p class="no-notifications">No Records Found</p>
            </div>
        </div>
    </div>
    </section>
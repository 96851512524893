
<button id="insufficientData" [hidden]="true" data-toggle="modal" data-target="#insufficientDataModal">Open
  Modal</button>

<div class="modal fade" id="insufficientDataModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div style="width:70%;position: absolute;left: 30%;" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Inventory Assign Device</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Inadequate Data to create a ticket in Service Now.
      </div>
      <div class="modal-footer">
        <button type="button" id="btn_modelClose" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <!-- <button (click)="conformDelete()" type="button" class="btn btn-primary">Yes</button> -->
      </div>
    </div>
  </div>
</div>



<div class="modal" id="myModal" style="overflow-y: auto;" [ngClass]="showPrint?'showModalOpacity':'hideModelOpacity'">
  <div class="modal-dialog">
    <div class="modal-content assignDevice-content">

      <!-- Modal Header -->
      <div class="modal-header assignDevice-header ml-2">
        <h4 class="modal-title" style="font-size:20px">Assign Device</h4>
        <button id="btn_close" type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <form name="assform" id="assform" (ngSubmit)="f.form.valid" #f="ngForm" novalidate method="POST"
        style="margin-left:auto;margin-right:auto">
        <div class="modal-body modelbody">
          <!-- <div class="headermain">
            <div class="row mobileinfo">
              <div class="col-md-2">
                <img src="assets/images/device.svg"
                  style="width:70px;height:70px;margin-right:15px;">
              </div>
              <div class="col-md-6">
                <h4>{{rowData.deviceModelDescription}}</h4>
                <p>
                  IMEI:<span class="imeino">{{rowData.deviceID}}</span></p>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div> -->
          <div class="mt-4">
            <img src="assets/images/from.svg">
            <label for="ritema" class="ml-3 to">From</label>
          </div>

          <div class="row ml-2">
            <div class="col-md-2 ml-3">
              <p class="location-text">Location:</p>

            </div>
            <div class="col-md-6 p-0">
              <div><span class="imeino">{{rowData?.currentStatus != undefined ? rowData?.currentStatus : ''}}</span>
              </div>
              <span class="imeino">{{ActionFromDetails}}</span>
            </div>
          </div>

          <div class="radio-item">
            <img src="assets/images/to.svg"><span class="ml-3 to">To</span>
          </div>

          <div class="form-group ml-3">
            <label for="sel1" class="audit-type ml-4 mt-2">Device Location</label>
            <div class="row ml-2">
              <div class="col-md-12">

                <select class="form-control" id="devicelocaation" name="devicelocaation" [(ngModel)]="toDeviceLocation"
                  #devicelocaation="ngModel" (change)="toDeviceLocationChange()">
                  <option hidden value="" disabled selected>Select Option</option>
                  <option *ngFor="let location of Devicelocationdata" value="{{location.deviceLocationID}}">
                    {{location.deviceLocationDesc}}</option>
                </select>

              </div>
            </div>
          </div>
          <div *ngIf="DeviceSectionVisibleFlg">
            <div class="form-group  ml-3" *ngIf="toTechroomFlg || toInTransitToTechroomFlg ">
              <label for="sel1" class="audit-type ml-4 mt-2">Office Location <sup>*</sup></label>
              <div class="row ml-2">
                <div class="col-md-12">

                  <select class="form-control" id="officeLocation" name="officeLocation"
                    [disabled]="isOfficeLocationDisable" [(ngModel)]="toOfficeLocation" #officeLocation="ngModel"
                    [ngClass]="{ 'is-invalid': f.submitted && officeLocation.invalid }" required>
                    <option hidden value="" disabled selected>Select Option</option>
                    <option *ngFor="let location of OfficeLocationList" value="{{location.facilityCode}}">
                      {{location.facilityName}}</option>
                  </select>

                </div>
              </div>
            </div>
            <div class="col-md-12 form-group ml-3" *ngIf="(toLostOrStolenFlg && isUSIRegion !== 1 && (multiRowData != undefined && multiRowData.length > 0 && multiRowData[0].carrier != 'Sprint'))">
              <label class="custom-control fill-checkbox ml-2">
                <input (click)="fn_onClickNCCheckBox($event)" type="checkbox" name="chkNotifyCarrier"
                  id="chkNotifyCarrier" class="form-control fill-control-input">
                <span class="fill-control-indicator">
                </span>
                <span class="imeino">Notify Carrier</span> &nbsp;&nbsp;
                <a *ngIf="enableNCLinck" href="javascript:void(0)" (click)="fn_notifyCarrier(rowData)"
                  style="color:#0056b3;font-weight:bold"><u>Preview</u></a>
              </label>
              <button hidden="hidden" data-toggle="modal" type="button" class="btn" id="dataTargetNotifyCarrier"
                data-target="#notifyUserID">
                Notify Carrier
              </button>
            </div>
            <div class="form-group  ml-3 has-search" *ngIf="toEmployeeFlg">
              <label for="sel1" class="audit-type ml-4 mt-2">Search</label>

              <div class="row ml-2">
                <div class="col-md-12">
                  <!-- <input type="text" class="form-control" name="searchEmployeeDevice" id="searchEmployeeDevice"
                    (blur)="SearchBlur()" placeholder="Enter Name" [(ngModel)]="searchTONameVal"> -->
                  <app-people-picker (empDetails)="empDetails($event)"  (selectedAlias)="selectedAlias($event)"
                    (inputItemEventEmit)="inputItemEventEmit($event)"></app-people-picker>
                  <!-- {{auditSelectedVal}} -->
                </div>
              </div>
            </div>
            <div class="form-group mt-3 ml-3"
              *ngIf="toInTransitToCarrierFlg || toInTransitToTechroomFlg || toInTransitToRecyleFlg || (currentAction == 'In Transit to Tech Room' && toDeviceLocationName == 'Tech Room')">
              <label for="sel1" class="audit-type ml-4 mt-2">Shipping Tracking No <sup>*</sup></label>
              <div class="row ml-2">
                <div class="col-md-12">
                  <input type="text" class="form-control" placeholder="Type Shipping Tracking No"
                    [(ngModel)]="shippingTrackingVal" name="shippingTracking" id="shippingTracking"
                    #shippingTracking="ngModel" [ngClass]="{ 'is-invalid': f.submitted && shippingTracking.invalid }"
                    (keyup)="trimValue($event)" required>
                  <!-- {{auditSelectedVal}} -->
                </div>
              </div>
            </div>

            <div class="form-group ml-3" *ngIf="toInTransitToCarrierFlg">
              <div class="row ml-2">
                <div class="col-md-6">
                  <label for="sel1" class="audit-type  mt-2">Carrier <sup>*</sup></label>

                  <select class="form-control" id="ddlCarrier" name="ddlCarrier" [(ngModel)]="ddlCarrierVal"
                    #ddlCarrier="ngModel" [ngClass]="{ 'is-invalid': f.submitted && ddlCarrier.invalid }" required>
                    <option hidden value="" disabled selected>Select Option</option>
                    <option *ngFor="let allcarrier of carrier">{{allcarrier.carrierName}}</option>
                  </select>

                </div>
                <div class="col-md-6">
                  <label for="sel1" class="audit-type  mt-2">Retail Store</label>

                  <select class="form-control" id="retailStore" name="retailStore" [(ngModel)]="ddlRetailStoreVal">
                    <option hidden value="" disabled selected>Select Option</option>
                    <option>Apple Store</option>
                    <option>Local AT&T Store</option>
                    <option>Local Sprint Store</option>
                    <option>Local T-Mobile Store</option>
                    <option>Local Verizon Store</option>
                    <option>USI Store</option>
                  </select>

                </div>

              </div>
            </div>

            <div class="row">
              <!-- <div class="col-md-2">
                      <img src="assets/images/device.svg"><span class="to">Device</span>
                    </div> -->
              <div class="col-md-3 mt-4">
                <img src="assets/images/device.svg">
                <label for="ritema" class="ml-3 to">Device</label>
              </div>
              <div class="col-md-5 mt-4"></div>
              <div id="print" class="col-md-4 mt-4" *ngIf="printflag" (click)="showPrintPage()">
                <a data-toggle="modal" data-target="#printModel" data-backdrop="static"  data-keyboard="false">
                  <img src="assets/images/printer.png">
                  <label class="ml-2 to-blue">Print Barcode</label>
                </a>

              </div>
            </div>
            <div class="jumbotron device-section" *ngFor="let assRowData of multiRowData; let i = index">
              <div class="form-group">

                <div class="row">
                  <div class="col-md-6">
                    <p class="location-text">IMEI/ ESN:<span class="imeino ml-2"  *ngIf="assRowData.isWifiOnly==false">{{assRowData?.deviceID}}</span></p>
                  </div>
                  <div class="col-md-6">
                    <p class="location-text">Device Model:<span
                        class="imeino ml-2">{{assRowData?.deviceModelDescription}}</span></p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <p class="location-text">SIM:<span class="imeino ml-2">{{assRowData?.sim}}</span></p>
                  </div>
                  <div class="col-md-6">
                    <p class="location-text">Device Type:<span class="imeino ml-2">{{assRowData?.deviceType}}</span></p>
                  </div>
                </div>
                <div class="row" *ngIf="toTechroomFlg || toLostOrStolenFlg || toEmployeeFlg">
                  <div class="col-md-6">
                    <p class="location-text">Carrier:<span class="imeino ml-2">{{assRowData?.carrier}}</span></p>
                  </div>
                  <div class="col-md-6">
                    <p class="location-text">Account Status:<span
                        class="imeino ml-2">{{assRowData?.accountStatus}}</span></p>
                  </div>
                </div>
                <!---1949727 bug fix start -->
                <div class="row">
                  <div class="col-md-6">
                  <p class="location-text">Serial Number:<span
                    class="imeino ml-2">{{assRowData?.serialNumber}}</span>
                   </p>
                   </div>
                </div>
                 <!---1949727 bug fix end -->
                <div class="row">
                  <div class="col-md-6" *ngIf="toTechroomFlg || toLostOrStolenFlg || toEmployeeFlg">
                    <div *ngIf="isMTNDropdown && !assRowData?.vendor" ><label for="sel1" class="audit-type">MTN</label>
                      <select class="form-control" id="ddlMTN{{i}}" name="ddlMTN{{i}}" [value]="assRowData?.mtn"
                        (change)="mtnChange($event, assRowData)">
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let mtn of MtnListdata">{{mtn.mobileTelephoneNbr}}</option>

                      </select> </div>
                    <div *ngIf="!isMTNDropdown && !assRowData?.vendor">
                      <p>MTN:<span class="imeino ml-2">{{assRowData?.mtn}}</span></p>
                    </div>

                  </div>
                  <div class="col-md-6" *ngIf="toEmployeeFlg">
                    <label for="sel1" class="audit-type">Shipping Tracking No</label>
                    <input type="text" class="form-control" placeholder="Type Shipping Tracking No"
                      [(ngModel)]="shippingTrackingVal" name="shippingTracking" id="shippingTracking"
                      (keyup)="trimValue($event)">
                  </div>

                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label for="sel1" class="audit-type mt-2">Device Status</label>
                    <select class="form-control" id="deviceStatus{{i}}" name="deviceStatus{{i}}"
                      [value]="assRowData?.deviceStatusID" (change)="deviceStatusChange($event, assRowData)">
                      <option hidden value="" disabled selected>Select Option</option>
                      <option *ngFor="let status of DeviceStatusdata" value="{{status.deviceStatusID}}">
                        {{status.deviceStatusDesc}}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6"><label for="sel1" class="audit-type mt-2">Asset Status</label>
                    <select class="form-control" id="deviceAssetStatus{{i}}" name="deviceAssetStatus{{i}}"
                      [value]="assRowData?.mobileAssetStatusID" (change)="assetStatusChange($event, assRowData, i)" [disabled] ="assRowData?.mobileAssetStatusID === 2">
                      <option hidden value="" disabled selected>Select Option</option>
                      <option *ngFor="let status of assetStatusItems" value="{{status.assetStatusID}}" [disabled]="status.assetStatusID == 2 && assRowData?.mtn !== 'Wifi Only' ">
                        {{status.mobileAssetStatus}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row" *ngIf="assRowData?.mobileAssetStatusID == 2">
                  <div class="col-md-6">&nbsp;</div>
                  <div class="col-md-6 expected-date-of-return">
                    <label class="custom-control fill-checkbox">
                      <input type="checkbox" [checked]="assRowData?.hasExpectedReturn"
                        (change)="hasExpectedReturnChange($event, assRowData)" name="chkhasExpectedReturn{{i}}"
                        id="chkhasExpectedReturn{{i}}" class="form-control fill-control-input">
                      <span class="fill-control-indicator"></span>
                      <span class="imeino">Expected return <sup *ngIf="assRowData?.hasExpectedReturn">*</sup></span>
                    </label>
                    <div class="form-group date-picker" *ngIf="assRowData?.hasExpectedReturn">
                      <div class="row selected-date">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 my-date-picker-from">

                          <my-date-picker [options]="myDatePickerOptions" id="datePickerForReturn{{i}}"
                            (dateChanged)="onDateChangedTo($event, assRowData)"
                            [selDate]="assRowData?.expectedReturnString" [placeholder]="toplaceholder"
                            class="toDateClass">
                          </my-date-picker>
                        </div>
                        <div class="col-md-12 p-0" *ngIf="toDateSelected">
                          <div class="todate-err-msg">Date must be greater than today</div>
                        </div>
                        <div class="col-md-12 p-0" *ngIf="toDateSelectedMust">
                          <div class="todate-err-msg">Please select Expected Return Date</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label class="custom-control fill-checkbox">
                      <input type="checkbox" [checked]="assRowData?.isReplacedDevice"
                        (change)="isReplacedChange($event, assRowData)" name="chkIsReplaced{{i}}"
                        id="chkIsReplaced{{i}}" class="form-control fill-control-input">
                      <span class="fill-control-indicator"></span>
                      <span class="imeino">Replaced Device</span>
                    </label>
                  </div>
                  <!-- <div class="col-md-6" *ngIf="IsLoanerAsset">
                    <label class="custom-control fill-checkbox">
                      <input type="checkbox" [checked]="setReturnDate"
                        class="form-control fill-control-input">
                      <span class="fill-control-indicator"></span>
                      <span class="imeino">Set Return Date</span>
                    </label>
                  </div> -->
                </div>
              </div>
            </div>


            <!-- Service Now Block-->
            <div *ngIf="currentAction != 'In Transit to Tech Room'">
              <div class="radio-item">
                <img src="assets/images/service now.svg"><span class="ml-2 to">Service now</span>
              </div>


              <div class="form-group ml-3">


                <div class="col-md-8 ml-4 mt-2">

                  <div class="row">

                    <!-- <label class="radio-inline existing">
                    <input type="radio" name="optradio" value="none" [(ngModel)]="snowOptionSelectedVal"
                      (click)="ServiceNowOptSelection('none')">
                    <span class="ml-2">None</span>

                  </label> 
                <label class="radio-inline existing">
                    <input type="radio" name="optradio" value="existing" [(ngModel)]="snowOptionSelectedVal"
                      (click)="ServiceNowOptSelection('existing')"><span class="ml-2">Existing</span>

                  </label>
                  <label class="radio-inline existing">
                    <input type="radio" name="optradio" value="new" [(ngModel)]="snowOptionSelectedVal"
                      (click)="ServiceNowOptSelection('new')"><span class="ml-2">New</span>
                  </label>
                -->
                    <div class="col-md-4 box1"
                      [ngStyle]="{'border': '1px', 'border-style': 'solid', 'border-color': '#D0D0CE', 'padding-top': '4px'}">
                      <label class="custom-control fill-radio">
                        <input type="radio" name="optradio" id="optradio" value="none"
                          [(ngModel)]="snowOptionSelectedVal" class="fill-control-input"
                          (click)="ServiceNowOptSelection('none')">
                        <span class="fill-control-indicator"></span>
                        <span class="imeino">None</span>

                      </label>
                    </div>
                    <div class="col-md-4 box1"
                      [ngStyle]="{'border': '1px', 'border-style': 'solid', 'border-color': '#D0D0CE', 'padding-top': '4px'}">
                      <label class="custom-control fill-radio">
                        <input type="radio" name="optradio" id="optradio" value="existing"
                          [(ngModel)]="snowOptionSelectedVal" class="fill-control-input"
                          (click)="ServiceNowOptSelection('existing')">
                        <span class="fill-control-indicator"></span>
                        <span class="imeino">Existing</span>

                      </label>
                    </div>
                    <div class="col-md-4 box1"
                      [ngStyle]="{'border': '1px', 'border-style': 'solid', 'border-color': '#D0D0CE', 'padding-top': '4px'}">
                      <label class="custom-control fill-radio">
                        <input type="radio" name="optradio" id="optradio" value="new"
                          [(ngModel)]="snowOptionSelectedVal" class="fill-control-input"
                          (click)="ServiceNowOptSelection('new')">
                        <span class="fill-control-indicator"></span>
                        <span class="imeino">New</span>

                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="newOptionSelected || existingOptionSelected" class=" ml-3">
                <div class="form-group has-search" *ngIf="existingOptionSelected">
                  <label for="sel1" class="audit-type ml-4">Search</label>

                  <div class="row ml-2">
                    <div class="col-md-12"><span class="fa fa-search form-control-feedback"></span>
                      <input type="text" class="form-control" placeholder="Enter Ticket No" name="ticketNumberVal"
                        id="ticketNumberVal" [(ngModel)]="ticketNumberVal">
                      <!-- {{auditSelectedVal}} -->
                    </div>
                    <p style="margin-top: 14px;text-align: center;" class="mt-2 location-text">OR</p>
                    <div class="col-md-12"><span class="fa fa-search form-control-feedback"></span>
                      <!-- <input type="text" class="form-control" placeholder="Affected End User" [(ngModel)]="ticketForVal"
                        id="ticketForVal" name="ticketForVal"> -->
                      <app-people-picker (empDetails)="empDetails2($event)"  (selectedAlias)="selectedAlias2($event)"
                        (inputItemEventEmit)="inputItemEventEmit2($event)"></app-people-picker>
                      <!-- {{auditSelectedVal}} -->
                    </div>
                    <div style="margin-top:13px" class="col-md-2">
                      <button type="button" class="btn btn-success" (click)="goClick()">Go</button>
                    </div>
                  </div>
                </div>
                <div class="form-group ticketGrid  ml-2 col-md-12" *ngIf="showTicketGrid">
                  <div class="col-md-12" style="border: 1px solid;">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Ticket Number</th>
                          <th>Ticket State</th>
                          <th>Open Date</th>
                          <th>Summary</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let ticket of ticketGridData" (click)="ticketRowSelected(ticket)">
                          <td>{{ticket.ticketNumber}}</td>
                          <td>{{ticket.ticketStatus}}</td>
                          <td>{{ticket.sys_Created_On_Dt | date}}</td>
                          <td>{{ticket.short_Description}}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="form-group" *ngIf="newReqOptionSelected || newIncOptionSelected">
                  <label class="audit-type ml-4">Would you like to create</label>
                  <div class="row ml-2">


                    <div class="col-md-10">
                      <label class="radio-inline ">
                        <input type="radio" name="rbtnTicketType" checked [(ngModel)]="ticketTypeVal" value="request"
                          (click)="ServiceNowOptSelection('request')">
                        <span class="ml-2">Request</span>
                      </label>

                      <label class="radio-inline ml-4">
                        <input type="radio" name="rbtnTicketType" [(ngModel)]="ticketTypeVal" value="incident"
                          (click)="ServiceNowOptSelection('incident')">
                        <span class="ml-2">Incident</span>
                      </label>
                    </div>

                  </div>
                </div>
                <div class="mt-4 form-group " *ngIf="isTicketRowInc || isTicketRowReq">
                  <div class="row ml-2">
                    <div class="col-md-6">
                      <label for="sel1" class="audit-type" style="font-weight: bold;">Service Now ticket </label>

                    </div>
                  </div>
                </div>

                <div class="form-group"
                  *ngIf="newReqOptionSelected || (isTicketRowSelected && existingOptionSelected && isTicketRowReq)">
                  <div class="row ml-2">
                    <div class="col-md-12">
                      <label for="sel1" class="audit-type">Request State <sup>*</sup></label>

                      <select class="form-control" id="ddlRequestState" name="ddlRequestState"
                        [(ngModel)]="ddlRequestStateVal" required>
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let request of requeststate">{{request.name}}</option>

                      </select>
                    </div>
                    <!-- <div class="col-md-6" *ngIf="!newReqFromEToTRPDA">
                      <label for="sel1" class="audit-type ">Item</label>

                      <select class="form-control" id="ddlReqItem" name="ddlReqItem" [(ngModel)]="ddlRequestItemVal"
                        required *ngIf="!isTicketRowReq" (change)="OnIncidentAreaChange($event)">
                        <option value="">Select Option</option>
                        <option *ngFor="let item of Devicecatelogdata">{{item.catalogItemDesc}}</option>
                      </select>
                      <p class="location-text"> <span class="imeino" *ngIf="isTicketRowReq">{{ddlRequestItemVal}}</span></p>
                    </div> -->

                  </div>
                </div>
                <div class="form-group"
                  *ngIf="newReqOptionSelected || (isTicketRowSelected && existingOptionSelected && isTicketRowReq)">
                  <div class="row ml-2">
                    <div class="col-md-12" *ngIf="!newReqFromEToTRPDA">
                      <label for="sel1" class="audit-type ">Item</label>

                      <select class="form-control" id="ddlReqItem" name="ddlReqItem" [(ngModel)]="ddlRequestItemVal"
                        required *ngIf="!isTicketRowReq" (change)="OnIncidentAreaChange($event)">
                        <option value="">Select Option</option>
                        <option *ngFor="let item of Devicecatelogdata">{{item.catalogItemDesc}}</option>
                      </select>
                      <p class="location-text"> <span class="imeino" *ngIf="isTicketRowReq">{{ddlRequestItemVal}}</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="form-group"
                  *ngIf="newIncOptionSelected || (isTicketRowSelected && existingOptionSelected && isTicketRowInc)">

                  <div class="row ml-2">

                    <div class="col-md-6">
                      <label for="sel1" class="audit-type">State</label>
                      <select class="form-control" id="ddlState" name="ddlState" [(ngModel)]="ddlStateVal" required>
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let state of stateitems">{{state.name}}</option>

                      </select>
                    </div>
                    <div class="col-md-6"></div>
                  </div>
                </div>

                <div class="form-group "
                  *ngIf="newIncOptionSelected || (isTicketRowSelected && existingOptionSelected && isTicketRowInc)">
                  <div class="row ml-2">
                    <div class="col-md-6">
                      <label for="sel1" class="audit-type" *ngIf="!isTicketRowInc">Category</label>
                      <p class="location-text" *ngIf="isTicketRowInc">Category:<span
                          class="imeino ml-2">{{ddlCategoryVal}}</span></p>
                      <select class="form-control" id="ddlCategory" *ngIf="!isTicketRowInc" name="ddlCategory"
                        [(ngModel)]="ddlCategoryVal" (change)="categoryChange()" required>
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let cat of categoryModeldata">{{cat.categoryName}}</option>

                      </select>
                      <!-- <p class="location-text"> <span class="imeino" *ngIf="isTicketRowInc">{{ddlCategoryVal}}</span></p> -->

                    </div>
                    <div class="col-md-6">
                      <label for="sel1" class="audit-type " *ngIf="!isTicketRowInc">Sub Category</label>
                      <p class="location-text" *ngIf="isTicketRowInc">Sub Category:<span
                          class="imeino ml-2">{{ddlSubCategoryVal}}</span></p>
                      <select class="form-control" id="ddlSubCategory" name="ddlSubCategory"
                        [(ngModel)]="ddlSubCategoryVal" required *ngIf="!isTicketRowInc">
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let sub of subCategoryModeldata">{{sub.subCategory}}</option>

                      </select>
                      <!-- <p class="location-text"> <span class="imeino" *ngIf="isTicketRowInc">{{ddlSubCategoryVal}}</span></p> -->
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row ml-2"
                    *ngIf="(isTicketRowSelected && existingOptionSelected) || newReqOptionSelected || newIncOptionSelected">
                    <div class="col-md-12">
                      <p class="location-text">Assigned To:<span class="imeino ml-2">{{snowAssignedTo}}</span></p>
                    </div>
                  </div>
                </div>
                <div class="form-group "
                  *ngIf="(isTicketRowSelected && existingOptionSelected) || newReqOptionSelected || newIncOptionSelected">
                  <div class="row ml-2">

                    <div class="col-md-12">
                      <label for="sel1" class="audit-type" *ngIf="!isTicketRowInc && !isTicketRowReq">Assignment
                        Group</label>
                      <p class="location-text" *ngIf="isTicketRowInc || isTicketRowReq">Assignment Group:<span
                          class="imeino ml-2">{{ddlAssignmentGroupVal}}</span></p>

                      <!-- <label for="sel1" class="audit-type ">Assignment Group</label> -->
                      <select class="form-control" id="ddlAssignmentGrp" name="ddlAssignmentGrp"
                        [(ngModel)]="ddlAssignmentGroupVal" *ngIf="!isTicketRowInc && !isTicketRowReq">
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let assignment of assignmentGroupModeldata">{{assignment.groupName}}
                        </option>
                      </select>
                      <!-- <p class="location-text"> <span class="imeino" *ngIf="isTicketRowInc || isTicketRowReq">{{ddlAssignmentGroupVal}}</span>
                      </p> -->
                    </div>

                  </div>
                </div>

                <div id="dvLSDAutoGenCheckList" class="form-group dvLSDAutoGenCheckList" *ngIf="newReqOptionSelected">


                </div>

                <div class="form-group" *ngIf="newReqFromEToTRPDA">
                  <div class="row ml-2">
                    <div class="col-md-12">
                      <label for="sel1" class="audit-type ">Reason for Wipe <sup>*</sup></label>
                      <select class="form-control" id="ddlReasonForWipe" name="ddlReasonForWipe"
                        [(ngModel)]="ddlReasonForWipeVal" required>
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let item of reasonForWipedata" value={{item.name}}>{{item.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group" *ngIf="newReqFromEToTRPDA">
                  <div class="row ml-2">
                    <div class="col-md-12">
                      <label for="sel1" class="audit-type ">MTN Status <sup>*</sup></label>
                      <select class="form-control" id="ddlMtnStatus" name="ddlMtnStatus" [(ngModel)]="ddlMtnStatusVal"
                        required>
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let mtnStatus of mtnStatusdata" value={{mtnStatus.name}}>{{mtnStatus.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group" *ngIf="newReqFromEToTRPDA">
                  <div class="row ml-2">
                    <div class="col-md-12">
                      <label for="sel1" class="audit-type ">Employee's Personal Email Address</label>
                      <input type="text" class="form-control" id="txtEmpPersonelEmailAddr"
                        name="txtEmpPersonelEmailAddr" [(ngModel)]="txtEmpPersonelEmailAddrVal">
                    </div>
                  </div>
                </div>
                <div class="form-group" *ngIf="newReqFromEToTRPDA">
                  <div class="row ml-2">
                    <div class="col-md-12">
                      <label for="sel1" class="audit-type ">Wipe Status <sup>*</sup></label>
                      <select class="form-control" id="ddlWipeStatus" name="ddlWipeStatus"
                        [(ngModel)]="ddlWipeStatusVal" required>
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let wipeStatus of wipeStatusdata" value={{wipeStatus.name}}>{{wipeStatus.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- 
                <div class="form-group">
                    <div class="row ml-2"
                        *ngIf="newReqOptionSelected">
                      <div class="col-md-12">
                        <label for="sel1" class="audit-type ">Reason for Wipe</label>
                        <select class="form-control" id="ddlReasonForWipe" name="ddlReasonForWipe" [(ngModel)]="ddlReasonForWipeVal"
                          required>
                          <option hidden value="" disabled selected>Select Option</option>
                          <option *ngFor="let item of reasonForWipedata" value={{item.name}}>{{item.name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group"
                  *ngIf="newReqOptionSelected">
                  <div class="row ml-2">
                    <div class="col-md-12">
                      <label for="sel1" class="audit-type ">MTN Status</label>
                      <select class="form-control" id="ddlMtnStatus" name="ddlMtnStatus" [(ngModel)]="ddlMtnStatusVal"
                        required >
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let mtnStatus of mtnStatusdata" value={{mtnStatus.name}}>{{mtnStatus.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group"
                *ngIf="newReqOptionSelected">
                <div class="row ml-2">
                  <div class="col-md-12">
                    <label for="sel1" class="audit-type ">Employee's Personal Email Address</label>
                    <input type="text" class="form-control" id="txtEmpPersonelEmailAddr" name="txtEmpPersonelEmailAddr" [(ngModel)]="txtEmpPersonelEmailAddrVal"
                      required>
                  </div>
                </div>
                </div>
                <div class="form-group"
                *ngIf="newReqOptionSelected">
                <div class="row ml-2">
                  <div class="col-md-12">
                    <label for="sel1" class="audit-type ">Wipe Status</label>
                    <select class="form-control" id="ddlWipeStatus" name="ddlWipeStatus" [(ngModel)]="ddlWipeStatusVal"
                        required>
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let wipeStatus of wipeStatusdata" value={{wipeStatus.name}}>{{wipeStatus.name}}</option>
                      </select>
                  </div>
                </div>
                </div>
                <div class="form-group"
                *ngIf="newReqOptionSelected">
                <div class="row ml-2">
                  <div class="col-md-12">
                    <label for="sel1" class="audit-type">Have you followed KD # 421388 and logged a PTS incident?</label>
                    <input type="text" class="form-control" id="txtKDLoggedPTSIncident" name="txtKDLoggedPTSIncident" [(ngModel)]="txtKDLoggedPTSIncidentVal"
                      required>
                  </div>
                </div>
                </div>
                <div class="form-group"
                *ngIf="newReqOptionSelected">
                <div class="row ml-2">
                  <div class="col-md-12">
                    <label for="sel1" class="audit-type">Reason For Return</label>
                    <select class="form-control" id="ddlWipeStatus" name="ddlReasonForReturn" [(ngModel)]="ddlReasonForReturnVal"
                        required >
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let reForRet of reasonForReturn" value={{reForRet.name}}>{{reForRet.name}}</option>
                      </select>
                  </div>
                </div>
                </div>
                <div class="form-group"
                *ngIf="newReqOptionSelected">
                <div class="row ml-2">
                  <div class="col-md-12">
                    <label for="sel1" class="audit-type">Shipped to *</label>
                    <select class="form-control" id="ddlShipped" name="ddlShipped" [(ngModel)]="ddlShippedVal"
                        required>
                        <option hidden value="" disabled selected>Select Option</option>
                        <option *ngFor="let shipped of ShippedData" value={{shipped.name}}>{{shipped.name}}</option>
                      </select>
                  </div>
                </div>
                </div>
                <div class="form-group"
                *ngIf="newReqOptionSelected">
                <div class="row ml-2">
                  <div class="col-md-12">
                    <label for="sel1" class="audit-type">Device/model/type</label>
                    <input type="text" class="form-control" id="txtDeviceModelType" name="txtDeviceModelType" [(ngModel)]="txtDeviceModelTypeVal"
                      required *ngIf="!isTicketRowReq">
                  </div>
                </div>
                </div>
                <div class="form-group"
                *ngIf="newReqOptionSelected">
                <div class="row ml-2">
                  <div class="col-md-12">
                    <label for="sel1" class="audit-type">IMEI or ESN Hex Number</label>
                    <input type="text" class="form-control" id="txtImeiEsnHexaNumber" name="txtImeiEsnHexaNumber" [(ngModel)]="txtImeiEsnHexaNumberVal"
                      required>
                  </div>
                </div>
                </div>
                <div class="form-group"
                *ngIf="newReqOptionSelected">
                <div class="row ml-2">
                  <div class="col-md-12">
                    <label for="sel1" class="audit-type">Air Card/ Mifi Mobile Number</label>
                    <input type="text" class="form-control" id="txtDTMobileNbr" name="txtDTMobileNbr" [(ngModel)]="txtDTMobileNbrVal"
                      required>
                  </div>
                </div>
                </div>
                <div class="form-group"
                *ngIf="newReqOptionSelected">
                <div class="row ml-2">
                  <div class="col-md-12">
                    <label for="sel1" class="audit-type">Is the Air Card / MiFi still functional?</label>
                    <input type="text" class="form-control" id="txtDTStillFunc" name="txtDTStillFunc" [(ngModel)]="txtDTStillFuncVal"
                      required>
                  </div>
                </div>
                </div>
                <div class="form-group"
                *ngIf="newReqOptionSelected">
                <div class="row ml-2">
                  <div class="col-md-12">
                    <label for="sel1" class="audit-type">Carrier</label>
                    <input type="text" class="form-control" id="txtSNCarrier" name="txtSNCarrier" [(ngModel)]="txtSNCarrierVal"
                      required>
                  </div>
                </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-12">
                   <label for="sel1" class="audit-type">MTN (Mobile Telephone Number)</label>
                   <input type="text" class="form-control" id="txtMTNMobileNbr" name="txtMTNMobileNbr" [(ngModel)]="txtMTNMobileNbrVal"
                     required>
                 </div>
               </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-12">
                   <label for="sel1" class="audit-type">Model</label>
                   <input type="text" class="form-control" id="txtSNModel" name="txtSNModel" [(ngModel)]="txtSNModelVal"
                     required >
                 </div>
               </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-6">
                   <label for="sel1" class="audit-type">ESN/MEID/IME</label>
                   <input type="text" class="form-control" id="txtESNMEIDIME" name="txtESNMEIDIME" [(ngModel)]="txtESNMEIDIMEVal"
                     required>
                 </div>
               </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-12">
                   <label for="sel1" class="audit-type">Has the service account been suspended?</label>
                   <input type="text" class="form-control" id="txtServiceAccSuspent" name="txtServiceAccSuspent" [(ngModel)]="txtServiceAccSuspentVal"
                     required>
                 </div>
               </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-12">
                   <label for="sel1" class="audit-type">Replacement Model</label>
                   <input type="text" class="form-control" id="txtRepModel" name="txtRepModel" [(ngModel)]="txtRepModelVal"
                     required>
                 </div>
               </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-12">
                   <label for="sel1" class="audit-type">Replacement ESN/MEID/IMEI</label>
                   <input type="text" class="form-control" id="txtRepESNMEIDIMEI" name="txtRepESNMEIDIMEI" [(ngModel)]="txtRepESNMEIDIMEIVal"
                     required>
                 </div>
               </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-12">
                   <label for="sel1" class="audit-type">Replacement MTN:</label>
                   <input type="text" class="form-control" id="txtRepMTN" name="txtRepMTN" [(ngModel)]="txtRepMTNVal"
                     required>
                 </div>
               </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-12">
                   <label for="sel1" class="audit-type">Order Date</label>
                   <input type="text" class="form-control" id="txtOrdDate" name="txtOrdDate" [(ngModel)]="txtOrdDateVal"
                     required>
                 </div>
               </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-12">
                   <label for="sel1" class="audit-type">Wireless number associated with device</label>
                  
                   <input type="text" class="form-control" id="txtWLNbmAssociateDevice" name="txtWLNbmAssociateDevice" [(ngModel)]="txtWLNbmAssociateDeviceVal"
                     required *ngIf="!isTicketRowReq">
                   <p class="location-text"> <span class="imeino" *ngIf="isTicketRowReq">{{txtWLNbmAssociateDeviceVal}}</span></p>
                 </div>
               </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-12">
                   <label for="sel1" class="audit-type">Has the device been shipped back to carrier</label>
                   <input type="text" class="form-control" id="txtShippedDeviceCarrier" name="txtShippedDeviceCarrier" [(ngModel)]="txtShippedDeviceCarrierVal"
                     required>
                 </div>
               </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-12">
                   <label for="sel1" class="audit-type">If yes, what is the tracking #</label>
                   <input type="text" class="form-control" id="txtSNTracking" name="txtSNTracking" [(ngModel)]="txtSNTrackingVal"
                     required>
                 </div>
               </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-12">
                   <label for="sel1" class="audit-type">Desired replacement deivce</label>
                   <input type="text" class="form-control" id="txtDesiredRepDevice" name="txtDesiredRepDevice" [(ngModel)]="txtDesiredRepDeviceVal"
                     required>
                 </div>
               </div>
               </div>
               <div class="form-group"
               *ngIf="newReqOptionSelected">
               <div class="row ml-2">
                 <div class="col-md-12">
                   <label for="sel1" class="audit-type">Shipping address for replacement device</label>
                   <input type="text" class="form-control" id="txtShippedAddRepDevice" name="txtShippedAddRepDevice" [(ngModel)]="txtShippedAddRepDeviceVal"
                     required>
                 </div>
               </div>
               </div> -->





                <!-- Device Returned To -->
                <div class="form-group "
                  *ngIf="newReqOptionSelected && toDeviceLocationName == 'In Transit to Recycle'">
                  <div class="row ml-2">

                    <div class="col-md-6">
                      <label for="sel1" class="audit-type ">Device returned to</label>
                      <select class="form-control" id="ddlDeviceReturnedTo" name="ddlDeviceReturnedTo"
                        [(ngModel)]="ddlDeviceReturnedToVal" required>
                        <option hidden value="" disabled selected>Select Option</option>
                        <option value="Recycling">Recycling</option>

                      </select>

                    </div>

                  </div>
                </div>
                <div class="form-group"
                  *ngIf="(isTicketRowSelected && existingOptionSelected) || newReqOptionSelected || newIncOptionSelected">
                  <div class="row ml-2">
                    <div class="col-md-12">
                      <label class="audit-type">Description <sup>*</sup></label>
                      <textarea rows="2" cols="50" placeholder="Type your description here" class="form-control"
                        [(ngModel)]="descriptionVal" name="txtDescription" id="txtDescription" required
                        [readonly]="isTicketRowInc || isTicketRowReq"></textarea>
                    </div>
                  </div>
                </div>
                <div class="form-group" *ngIf="(isTicketRowSelected && existingOptionSelected)">
                  <div class="row ml-2">
                    <div class="col-md-12">
                      <label class="audit-type">Status Notes</label>
                      <textarea rows="2" cols="50" placeholder="Type your notes here" class="form-control"
                        [(ngModel)]="notesVal" name="txtNotes" id="txtNotes" required></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer text-right">
              <div class="col-md-12">
                <div class="button-panel">
                  <button type="button" id="btnCancel" class="btn btn-white" data-dismiss="modal">Cancel</button>
                  <button type="submit" class="btn btn-white-add" [disabled]="checkValidation(f) && !rowData?.vendor"
                    (click)="SubmitAssignDevice()" id="SubmitInventory"><span class="add-text">Submit</span></button>
                </div>
              </div>
              <!-- <button type="button" class="btn-light" data-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-default" data-dismiss="modal"
                (click)="SubmitAssignedDevice()">Submit</button> [disabled]="(!f.form.valid)"-->
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>

<!-- Model Print -->
<div class="modal" id="printModel">
  <div class="modal-dialog">
    <div class="modal-content barcode-printer-content">
      <div class="modal-header barcode-printer-modalheader ml-2">
        <div class="col-md-10">
          <h4 class="modal-title heading-center">Print Barcode</h4>
        </div>
        <div class="col-md-2 close-right">
          <button id="btn_close" type="button" class="close" data-dismiss="modal"
            (click)="cancelPrintPage()">&times;</button>
        </div>
      </div>
      <div class="modal-body barcode-printer-modalbody">

        <div fxLayout="column">
          <div id="divPrintContent" class="print-content">
            <span class="print-content-currentdate">{{currentdate}}</span>
            <ngx-barcode [bc-value]="rowData?.deviceID"></ngx-barcode>
            <div class="barcode-string">
              <p class="location-text">{{rowData?.deviceID}}</p>
              <div class="row">
                <b class="carrier-text">Carrier:</b><span class="carrier-text">{{rowData?.carrier}}</span>
              </div>
              <div class="row">
                <b class="carrier-text">Device Model:</b><span
                  class="carrier-text">{{rowData?.deviceModelDescription}}</span>
              </div>
              <div class="row">
                <b class="carrier-text">Device Size:</b><span class="carrier-text">{{rowData?.size}}</span>
              </div>
              <div class="row">
                <b class="carrier-text">Device Color:</b><span class="carrier-text">{{rowData?.color}}</span>
              </div>
              <div class="row">
                <b class="carrier-text">Device Type:</b><span class="carrier-text">{{rowData?.deviceType}}</span>
              </div>
              <div class="row">
                <b class="carrier-text">Device Status:</b><span class="carrier-text">{{rowData?.deviceStatus}}</span>
              </div>
            </div>
          </div>
          <div class="btn-print-group text-right">
            <button type="button" id="btnCancel" class="btn btn-white" data-dismiss="modal"
              (click)="cancelPrintPage()">Cancel</button>
            <button printSectionId="divPrintContent" ngxPrint class="btn btn-white-print">Print</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="notifyUserID" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="false">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <h3 *ngIf="notifyUserHeader" class="modal-title" id="exampleModalLabel">Notify User</h3>
        <h3 *ngIf="notifyCarrierHeader" class="modal-title" id="exampleModalLabel">Notify Carrier</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeNotifyUser()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form>
        <div class="modal-body">
          <div class="row form-group send-to-emp-details">
            <div class="col-md-4 p-0" id="previewDiv_0">
              <div class="group-info" id="preview_0">
                <div class="to-email">
                  <label class="employee-email">{{rowData?.carrier}}</label>
                  <!--<span><i class="fa fa-close"></i></span>-->
                </div>
              </div>
            </div>
          </div>
          <div class="form-group bcc-info" *ngIf="notifyUserObj !== null">
            <label><span class="bcc-text">Subject :</span><span
                class="bcc-sbject-info">{{notifyUserObj.templateSubject}}</span></label>
          </div>
          <div class="form-group bcc-info" *ngIf="notifyUserObj !== null">
            <label><span class="bcc-text">To :</span> <span
                class="bcc-sbject-info">{{notifyUserObj.emailAddress}}</span></label>
          </div>
          <div class="form-group bcc-info" *ngIf="notifyUserObj !== null">
            <label><span class="bcc-text">CC :</span> <span class="bcc-sbject-info">{{notifyUserObj.cc}}</span></label>
          </div>
          <div class="form-group bcc-info" *ngIf="notifyUserObj !== null">
            <label><span class="bcc-text">BCC :</span> <span
                class="bcc-sbject-info">{{notifyUserObj.bcc}}</span></label>
          </div>
          <div class="form-group" *ngIf="notifyUserObj !== null">
            <div [innerHTML]="bodyHtmlValue" id="ProcessSite"></div>
          </div>
          <div class="form-group" *ngIf="notifyUserObj === null">
            <p style="color: black; font-size: 15px;">Preview couldn't be generated as no notifications would be sent for the selected criteria </p>
          </div>
        </div>
        <div class="modal-footer border-top-0 d-flex justify-content-right" *ngIf="notifyUserObj !== null">
          <button hidden="hidden" class="btn btn-default notify-carrier-cancel" data-dismiss="modal" aria-label="Close"
            (click)="closeNotifyUser()">Cancel</button>
          <button hidden="hidden" type="button" class="btn btn-primary notify-carrier-submit"
            (click)="fn_SendCarrierNotification()">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>

export class GridData{
  complianceIssue:string;
  lastSyncStartDate:string;
  lastSyncEndDate:string;
  location:string;
  notificationSent:string;
  currentPage:number;
  itemsPerPage:number;
  sortOrder:string;
  totalNoOfRecords:number;
  loA_Flag:string;
  loA_DateBegin:string;
  loA_DateEnd:string;
  mobileTelephoneNbr:string;
  employeeInfo:string;
  empStatus:string;
  assetStatus:string;
  violationAge:string;
  jobLevelGroup:string;
  mtnStatus:string;
  deviceType:string;
  FacilityCode : string;
  Carrier: string;
}
export class Region {
  id: number;
  name: string;
}
export class Office {
  id: number;
  countryid: number;
  name: string;
}

//   export class Employee {
//     careerLevel: string;
//     companyCode: string;
//     groupCode: string;
//     groupDesc: string;
//     firmFunction: string;
//     hrRegion: string;
//     isExpat: boolean;
//     isPPD: boolean;
//     loaIndicator: string;
//     isInpatORLTA: boolean;
//     jobLevelCode: string;
//     jobLevelText: string;
//     jobLevelAbbreviation: string;
//     loaEndDate: Date;
//     loaStartDate: Date;
//     loAtype: string;
//     hireDate: Date;
//     terminatedDate: Date;
//     masterCostCenterNumber: string;
//     masterCostCenterDesc: string;
//     region: string;
//     serviceAreaCode: string;
//     serviceAreaDesc: string;
//     serviceLineCode: string;
//     serviceLineDesc: string;
//     subGroupCode: string;
//     subGroupDesc: string;
//     employeeStatus: number;
//     isEligible: boolean;
//     isVIPLeader: number;
//     isEligibleForDevice: boolean;
//     personnelNumber: string;
//     isCurrentPersonnelNumber: boolean;
//     isIntern: boolean;
//     isEmployeeAtHomeOfficeWithPW: boolean;
//     facilityName: string;
//     costCenterLeader: string;
//     lastDayWorked: Date;
//     deviceMTN: string;
//     employeeStatusString: string;
//     isVoluntaryCancel: boolean;
//     isMarkedForSuspend: string;
//     isMarkedForCancellation: string;
//     isPermanentOrdering: boolean;
//     vipException: boolean;
//     lastDayWorkedString: string;
//     loaEndDateString: string;
//     loaStartDateString: string;
//     terminatedDateString: string;
//     hireDateString: string;
//     separationType: string;
//     facilityState: string;
//     facilityPostalCode: string;
//     employeeCommentCount: string;
//     adminAssistant: string;
//     empStatus: string;
//     accessTypeDescription: string;
//     actionTypeID: string;
//     firstName: string;
//     lastName: string;
//     middleName: string;
//     alias: string;
//     personId: string;
//     priorName: string;
//     preferredName: string;
//     legalName: string;
//     email: string;
//     personalEmail: string;
//     fullName: string;
// }

// export class ServerSync {
//     syncType: number;
//     lastSyncDate: Date;
//     agent: string;
//     device: string;
//     mtn: string;
//     isPersonalMTN: boolean;
//     besLastSynchDate: Date;
//     easLastSynchDate: Date;
//     besReportUploadDate: Date;
//     easReportUploadDate: Date;
//     besPolicyAppliedDate: Date;
//     easPolicyAppliedDate: Date;
//     besPolicyUpdatedTime: Date;
//     easPolicyUpdatedTime: Date;
//     besMobileNo: string;
//     easMobileNo: string;
//     besDevice: string;
//     easDevice: string;
//     besUserAgent: string;
//     easUserAgent: string;
//     besDevicePolicyApplied: string;
//     easDevicePolicyApplied: string;
//     intuneLastHeartbeat: Date;
//     intunePhoneNumber: string;
//     intuneDeviceModel: string;
//     intuneIMEI: string;
//     intuneLastPolicyRequest: Date;
//     intuneOperatingSystem: string;
//     intuneClientVersion: string;
//     intuneSerialNumber: string;
//     complianceStatus: string;
//     intuneSubscriberCarrierNetwork: string;
//     intuneLastHeartbeatString: string;
//     intuneLastPolicyRequestString: string;
//     userName: string;
//     fullName: string;
//     besDeviceIdentifier: string;
//     easDeviceIdentifier: string;
//     besDeviceModel: string;
//     easDeviceModel: string;
//     besNetworkType: string;
//     easNetworkType: string;
//     besimei: string;
//     easimei: string;
//     besOperatingSystem: string;
//     easOperatingSystem: string;
//     besLastSynchString: string;
//     easLastSynchString: string;
//     besReportUploadString: string;
//     easReportUploadString: string;
//     lastSyncDateString: string;
//     easPolicyUpdatedTimeString: string;
//     besPolicyUpdatedTimeString: string;
//     easPolicyAppliedDateString: string;
//     besPolicyAppliedDateString: string;
// }

// export class PrException {
//     mobileDeviceID: string;
//     model: string;
//     createdDate: Date;
//     createdBy: string;
//     dateBegin: Date;
//     dateEnd: Date;
//     dateEndString: string;
// }

// export class GridResponse {
//     Employee: Employee;
//     LastNotifiedDate: Date;
//     ExceptionCount: number;
//     NotificationCount: number;
//     CommentsCount: number;
//     ComplianceIssueCount: number;
//     TotalResultCount: string;
//     MaxNotifications: number;
//     UsersNoOfNotification: number;
//     ExceptionSubCategoryDesc: string;
//     IsMaxNotification: string;
//     ResolvedViolationCount: number;
//     HasOverride: number;
//     carrierCode: string;
//     LastNotifiedDateString: string;
//     ComplianceIssue: string;
//     Violation: number;
//     ServerSync: ServerSync;
//     IsExempted: boolean;
//     ViolationID: number;
//     IsCleared: boolean;
//     IsActive: boolean;
//     SdTicketNumber: string;
//     Reason: string;
//     ComplianceRuleDesc: string;
//     UserAgent: string;
//     DeviceType: string;
//     AddedBy: string;
//     DeviceManufacturer: string;
//     DeviceModelName: string;
//     MobileDeviceID: string;
//     DateEnd: Date;
//     DateBegin: Date;
//     DateCreated: Date;
//     LastSyncDate: Date;
//     PrException: PrException;
//     ViolationString: string;
//     DateEndString: string;
//     DateBeginString: string;
//     LastSyncDateString: string;
// }

  
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IMyOptions, IMyDate } from 'mydatepicker';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReportsService } from 'src/app/_services/reports.service';
import { ReportCategory } from 'src/app/_models/reportCategory.model';
import { Region } from 'src/app/_models/region.model';
import { FacilityByRegion } from 'src/app/_models/facilityByRegion.model';
import { ReportSubCategory } from 'src/app/_models/reportSubCategory.model';
import { GetFullInventoryReport } from 'src/app/_models/getFullInventoryReport.model';
import { InventoryReconHistReport } from 'src/app/_models/inventoryReconHisReport.model';
import { OrderReportRes } from 'src/app/_models/orderReportRes.model';
import { OrderReportReq } from 'src/app/_models/oderReport.model';
import { ModalService } from 'src/app/_services/modal.service';
import { take } from 'rxjs/operators';
import { InsertReportDetails } from 'src/app/_models/insertReportDetails.model';
import { Observable, observable, forkJoin } from 'rxjs';
import { ReportGeneratingStatus } from 'src/app/_models/reportGeneratingStatus.mdel';
import { GlobalVariables } from 'src/app/_utils/global-variables';

declare var _satellite: any;
declare var window: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  @ViewChild('reportform', { static: false }) reportform: NgForm;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  reportCategoryData: any;
  regionData: any;
  facilityByRegion: FacilityByRegion[];
  reportSubCategory: ReportSubCategory[];
  showTypeData: boolean;
  showReportDateData: boolean;
  showRegionData: boolean;
  showOfficeData: boolean;

  showRecycleReassignemtData: boolean;
  minimumDeviceCount: any;

  orderReportReq = new OrderReportReq();
  insertReportDetails = new InsertReportDetails();
  fullInventoryReport: GetFullInventoryReport[];
  selectedOffice: any;
  inventoryReconcileHistoryReport: InventoryReconHistReport[];
  inventoryReconcileReport: InventoryReconHistReport[];
  selectedMinimumDeviceReplacementCount: string = '';
  fromDateEvent: any;
  toDateEvent: any;
  dataForExcel: ReportSubCategory;
  selFromDate: any;
  selToDate: any;
  ordersReportData: OrderReportRes[];
  loggedInUserName = localStorage.getItem('loggedInUserName');
  selectedOfficeName: any;
  showEmpAlias: any;
  showEmpPesonnelNo: any;
  showDeviceReplacementCountData: any;
  showEmployeeData: any;
  insertReportDataStatus: boolean;
  messageResult: boolean;
  generateReportStatus: ReportGeneratingStatus[];
  showReportStatusDiv: boolean;
  reportStatusResultSet: ReportGeneratingStatus[];
  constructor(private formbuilder: FormBuilder, private reportsService: ReportsService, private modalService: ModalService) { }
  toDate: any;
  reportsform: FormGroup;
  employeetype: boolean = false;
  regionflag: boolean = false;
  employeesallflag: boolean = false;
  employeealias: boolean = false;
  exportexcel: boolean = false;
  officeflag: boolean = false;
  officedatainfo;
  selectedRegion: any = "";
  selectedReassignemtData: any = "";
  empAlias = '';
  // Leadershipreportsdata;
  reportsinfo;
  regioninfo;
  mindevicereplacementinfo;
  mindevicereplacemnetflag: boolean = false;
  // Inventoryreportsdata;
  // datarange:string;
  peoplepickerinfo: boolean = false;
  private fromplaceholder: string = 'From';
  private toplaceholder: string = 'To';
  selectedTypeReport: string = '';
  dateflag: boolean = false;
  categoryinfo;
  reportinfo;
  // Complaincereportsdata;

  syncDateTo: any;
  toDateSelected: boolean;
  fromToDateSelected: boolean;
  toDateSelectedMust: boolean;
  selectedEmployeeAlias: any;
  isEmployeeSelected: boolean = false;
  selectedSubCategory: string = '';
  selectedDateFrom: any;//= new Date();
  selectedDateTo: any; //= new Date();
  syncDateFrom: any;
  // datarange:string;
  // toDate: any;
  fromDateSelected: boolean;
  fromDate: any;
  PNEmpty: boolean = false;
  showPeoplePickerData: boolean = false;
  public myDatePickerOptions: IMyOptions = {
    dateFormat: 'mm/dd/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: true,
  };
  selDate: any;
  selDateFrom: any;

  fn_DateChangedEvent(event, type) {
    this.toDateSelected = false;
    this.fromDateSelected = false;
    this.toDateSelectedMust = false;
    let toDateVal = this.selToDate;//$('.toDateClass').find('.selection').val();
    let fromDateVal = this.selFromDate;//$('.fromDateClass').find('.selection').val();
    if (type == 1) {
      fromDateVal = this.fromDate.formatted;
    } else {
      toDateVal = this.toDate.formatted;
    }
    if ((event.formatted !== null) && (event.formatted !== undefined)) {
      if ((toDateVal !== null) && (toDateVal !== undefined) && (toDateVal !== '') && ((fromDateVal !== ''))) {
        let fromdateTxt = new Date(this.fromDate.formatted);
        if (this.toDate) {
          var todateTxt = new Date(this.toDate.formatted);
        }
        // console.log("Diff", (fromdateTxt > todateTxt));
        if ((fromdateTxt >= todateTxt)) {
          document.getElementById('generate_Report').style.pointerEvents = 'none';
          this.toDateSelected = true;
          this.fromDateSelected = false;
          this.toDateSelectedMust = false;
          this.fromToDateSelected = false;
        } else {
          document.getElementById('generate_Report').style.pointerEvents = '';
          this.toDateSelected = false;
          this.fromDateSelected = false;
          this.toDateSelectedMust = false;
          this.fromToDateSelected = false;
        }
      } else if ((fromDateVal == '') && (toDateVal == '')) {
        this.toDateSelected = false;
        this.fromDateSelected = false;
        this.toDateSelectedMust = false;
        this.fromToDateSelected = true;
        document.getElementById('generate_Report').style.pointerEvents = 'none';
      } else if ((fromDateVal == '')) {
        this.toDateSelected = false;
        this.fromDateSelected = true;
        this.toDateSelectedMust = false;
        this.fromToDateSelected = false;
        document.getElementById('generate_Report').style.pointerEvents = 'none';
      }
      else if ((toDateVal == '')) {
        document.getElementById('generate_Report').style.pointerEvents = 'none';
        this.toDateSelected = false;
        this.fromDateSelected = false;
        this.toDateSelectedMust = true;
        this.fromToDateSelected = false;
      } else {
        document.getElementById('generate_Report').style.pointerEvents = '';
        this.toDateSelected = false;
        this.fromDateSelected = false;
        this.toDateSelectedMust = false;
        this.fromToDateSelected = false;
      }
    }
  }
  onDateChangedFrom(event) {
    this.fromDate = event;
    this.selFromDate = event.formatted;
    this.fn_DateChangedEvent(event, 1);
    this.syncDateFrom = event.formatted;

    if (this.selectedTypeReport == 'End-user Device Replacement Report') {
      let from: Date = new Date(this.selFromDate);
      this.insertReportDetails.paramStartDate = from.getDate() + '/' + (from.getMonth() + 1) + '/' + from.getFullYear();
      let to: Date = new Date(this.selToDate);
      this.insertReportDetails.paramEndDate = to.getDate() + '/' + (to.getMonth() + 1) + '/' + to.getFullYear();

      this.reportsService.getDeviceReplacementCounts(this.insertReportDetails).subscribe((data) => {
        this.minimumDeviceCount = data;
      });
    }

  }
  onDateChangedTo(event) {
    this.toDate = event;
    this.selToDate = event.formatted;
    this.fn_DateChangedEvent(event, 2);
    this.syncDateTo = event.formatted;

    if (this.selectedTypeReport == 'End-user Device Replacement Report') {
      let from: Date = new Date(this.selFromDate);
      this.insertReportDetails.paramStartDate = from.getDate() + '/' + (from.getMonth() + 1) + '/' + from.getFullYear();
      let to: Date = new Date(this.selToDate);
      this.insertReportDetails.paramEndDate = to.getDate() + '/' + (to.getMonth() + 1) + '/' + to.getFullYear();

      this.reportsService.getDeviceReplacementCounts(this.insertReportDetails).subscribe((data) => {
        this.minimumDeviceCount = data;
      });
    }
  }
  substractDays(date, days) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - days,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  }
  ngOnInit() {
    this.reportsService.reportData.subscribe((data) => {
      // this.reportStatusResultSet = data;
    });
    // let d: Date = new Date();
    // this.selDate = {
    //   year: d.getFullYear(),
    //   month: d.getMonth() + 1,
    //   day: d.getDate()
    // };
    // this.selToDate = this.selDate.day + '/' + this.selDate.month + '/' + this.selDate.year;
    // // this.selDateFrom = {
    // //   year: d.getFullYear(),
    // //   month: d.getMonth() + 1,
    // //   day: d.getDate() - 7
    // // };
    // this.selDateFrom = this.substractDays(d,7).toLocaleDateString();//d.setDate(d.getDate() - 7);
    // // console.log('date',this.selDateFrom);
    // this.selFromDate = this.selDateFrom;
    // this.selFromDate = this.selDateFrom.day + '/' + this.selDateFrom.month + '/' + this.selDateFrom.year;
    // // console.log('seldate from', this.selDateFrom.day + '/' + this.selDateFrom.month + '/' + this.selDateFrom.year)
    // // console.log(this.selDate);
    this.resetDate();
    this.reportsService.getReportCategory().subscribe((data) => {
      this.reportCategoryData = data;
      // console.log('reportCategoryData', this.reportCategoryData);
    });
    this.reportsService.getRegionData().subscribe((data) => {
      this.regionData = data;
    });
    this.reportsform = this.formbuilder.group({
      categoryinfo: ['', Validators.required],
      reportinfo: ['', Validators.required],
      datepickerfromval: ['', Validators.required],
      datepickertoval: ['', Validators.required],
      employeeealias: ['', Validators.required],
      personnelNumber: ['', Validators.required],
      officedata: ['', Validators.required],
      mindevicereplacementdata: [''],
      reportsinfo: [''],
      regioninfo: [''],
      mindevicereplacement: [''],
      mindevicereplacementinfo: [''],
      employeetype: ['all'],

      recycleReassignemtData: [''],

      selectedEmployeeAlias: [],

    });
    this.reportsform.patchValue({ "regioninfo": '' });
    this.reportsform.patchValue({ "officedata": '' });
  }
  ngOnChanges() {
    this.reportsform = this.formbuilder.group({
      categoryinfo: ['', Validators.required],
      reportinfo: ['', Validators.required],
      datepickerfromval: ['', Validators.required],
      datepickertoval: ['', Validators.required],
      employeeealias: ['', Validators.required],
      personnelNumber: ['', Validators.required],
      officedata: ['', Validators.required],
      mindevicereplacementdata: [''],
      reportsinfo: [''],
      regioninfo: [''],
      mindevicereplacement: [''],
      mindevicereplacementinfo: [''],
      employeetype: ['all'],

      recycleReassignemtData: [''],

      selectedEmployeeAlias: [],

    });
    this.reportsform.patchValue({ "regioninfo": '' });
    this.reportsform.patchValue({ "officedata": '' });
  }

  selectmethod() {
    this.peoplepickerinfo = true;
  }
  selectedDay: string = '';

  categorymethod(event) {
    this.PNEmpty = false;
    if (this.selectedSubCategory == '') {
      this.showRegionData = false;
      this.showOfficeData = false;
      this.showReportDateData = false;
      this.showTypeData = false;
      this.showEmpAlias = false;
      this.showEmpPesonnelNo = false;
      this.showDeviceReplacementCountData = false;
      this.showRecycleReassignemtData = false;

    }
    else if (this.selectedSubCategory == 'Leadership Reports') {
      this.showReportDateData = false;
      this.showRegionData = false;
      this.showOfficeData = false;
      this.showEmpAlias = false;
      this.showEmpPesonnelNo = false;
      this.showDeviceReplacementCountData = false;
      this.showRecycleReassignemtData = false;
    } else if (this.selectedSubCategory == 'Inventory Reports') {
      this.showRegionData = false;
      this.showOfficeData = false;
      this.showReportDateData = false;
      this.showEmpAlias = false;
      this.showEmpPesonnelNo = false;
      this.showDeviceReplacementCountData = false;
      this.showRecycleReassignemtData = false;
    } else if (this.selectedSubCategory == 'Compliance Reports') {
      this.showRegionData = false;
      this.showOfficeData = false;
      this.showReportDateData = false;
      this.showEmpAlias = false;
      this.showEmpPesonnelNo = false;
      this.showDeviceReplacementCountData = false;
      this.showRecycleReassignemtData = false;
    }

    this.reportsform.patchValue({ "reportinfo": '' });
    this.selectedOffice = '';
    this.selectedRegion = '';
    document.getElementById('generate_Report').setAttribute('disabled', 'true');
    this.reportsService.getReportSubCategory(this.selectedSubCategory).subscribe((data) => {
      if (data) {
        this.showTypeData = true;
      }
      this.reportSubCategory = data;
    });
  }
  selectedAlias(alias) { // kvn_1
    this.reportsform.patchValue({
      employeeealias: alias
    })
    console.log(this.reportsform)
    //    document.getElementById('generate_Report').removeAttribute('disabled');
    document.getElementById('generate_Report').removeAttribute('disabled');
    if (alias.length > 0) {
      this.isEmployeeSelected = true;
      if (this.selectedEmployeeAlias != undefined && this.selectedEmployeeAlias.length > 0) {
        this.selectedEmployeeAlias.forEach((val) => {
          if (val == 'All') {
            this.selectedEmployeeAlias = [];
            this.selectedEmployeeAlias.push(alias);
          }
          else {
            var isAliasAvailableInList = this.selectedEmployeeAlias.filter(x => x == alias);
            if (isAliasAvailableInList != undefined && isAliasAvailableInList.length == 0) {
              this.selectedEmployeeAlias.push(alias);
            }
          }
        });
      }
      else {
        this.selectedEmployeeAlias = [];
        this.selectedEmployeeAlias.push(alias);
      }
    }
  }

  closeSelectedImei(value, index) {
    if (value != 'All') {
      this.selectedEmployeeAlias.splice(index, 1);
      if (this.selectedEmployeeAlias.length == 0) {
        //this.selectedEmployeeAlias.push('All');
        this.isEmployeeSelected = false;
      }
    }
  }

  reportmethodinfo() {
    this.resetDate();
    this.showReportDateData = false;
    this.facilityByRegion = [];
    this.selectedOffice = '';
    this.selectedRegion = '';
    this.selectedReassignemtData = '';

    this.showEmpAlias = false;
    this.showEmpPesonnelNo = false;
    this.showReportDateData = false;
    this.showRegionData = false
    this.showOfficeData = false;
    this.isEmployeeSelected = false;
    this.showDeviceReplacementCountData = false;
    this.showRecycleReassignemtData = false;

    if (this.selectedTypeReport == 'Orders Report') {
      this.showRegionData = false;
      this.showReportDateData = true;

      this.showRecycleReassignemtData = false;

      this.showDeviceReplacementCountData = false;

      if ((this.selectedSubCategory !== '') || (this.selectedSubCategory !== null) || (this.selectedSubCategory !== undefined)) {
       //     document.getElementById('generate_Report').removeAttribute('disabled');
        document.getElementById('generate_Report').removeAttribute('disabled');
      } else {
        document.getElementById('generate_Report').setAttribute('disabled', 'true');
      }
    } else if (this.selectedTypeReport == 'Inventory Reconciliation History Report') {
      document.getElementById('generate_Report').setAttribute('disabled', 'true');
      this.showReportDateData = true;
      this.showRegionData = true;
      this.showDeviceReplacementCountData = false;
      this.showOfficeData = true;
      this.showRecycleReassignemtData = false;
      if ((this.selectedSubCategory !== '') || (this.selectedSubCategory !== null) || (this.selectedSubCategory !== undefined)) {
        if ((this.selectedRegion !== '') && (this.selectedOffice !== '')) {

          //    document.getElementById('generate_Report').removeAttribute('disabled');
    document.getElementById('generate_Report').removeAttribute('disabled');
        }
      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
    } else if (this.selectedTypeReport == 'Inventory Report') {
      document.getElementById('generate_Report').setAttribute('disabled','true');
      if ((this.selectedSubCategory !== '') || (this.selectedSubCategory !== null) || (this.selectedSubCategory !== undefined)) {
       //     document.getElementById('generate_Report').removeAttribute('disabled');
    document.getElementById('generate_Report').removeAttribute('disabled');
      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
      this.showReportDateData = false;
      this.showRegionData = false;
      this.showDeviceReplacementCountData = false;
      this.showOfficeData = false;
    } else if (this.selectedTypeReport == 'End-user Device Replacement Report') {
      document.getElementById('generate_Report').setAttribute('disabled','true');
      if ((this.selectedSubCategory !== '') || (this.selectedSubCategory !== null) || (this.selectedSubCategory !== undefined)) {
       //     document.getElementById('generate_Report').removeAttribute('disabled');
    document.getElementById('generate_Report').removeAttribute('disabled');
      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
      this.showReportDateData = true;
      this.showRegionData = false;
      this.showOfficeData = false;
      this.showDeviceReplacementCountData = true;
      this.showEmpAlias = true;
      this.showEmpPesonnelNo = false;
      this.isEmployeeSelected = false;
      this.reportsform.patchValue({ "selectedEmployeeAlias": [] });
      this.reportsform.patchValue({ "mindevicereplacementdata": [''] });
      this.reportsform.patchValue({ "regioninfo": '' });
      this.reportsform.patchValue({ "officedata": '' });

      let from: Date = new Date(this.selFromDate);
      this.insertReportDetails.paramStartDate = from.getDate() + '/' + (from.getMonth() + 1) + '/' + from.getFullYear();
      let to: Date = new Date(this.selToDate);
      this.insertReportDetails.paramEndDate = to.getDate() + '/' + (to.getMonth() + 1) + '/' + to.getFullYear();

      this.reportsService.getDeviceReplacementCounts(this.insertReportDetails).subscribe((data) => {
        this.minimumDeviceCount = data;

      });

    } else if (this.selectedTypeReport == 'Recycle Reassignment Report') {
      document.getElementById('generate_Report').setAttribute('disabled','true');
      if ((this.selectedSubCategory !== '') || (this.selectedSubCategory !== null) || (this.selectedSubCategory !== undefined)) {
       //     document.getElementById('generate_Report').removeAttribute('disabled');
    document.getElementById('generate_Report').removeAttribute('disabled');
      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
      this.showReportDateData = true;
      this.showRegionData = false;
      this.showOfficeData = false;
      this.showDeviceReplacementCountData = false;
      this.showRecycleReassignemtData = true;
    }
    else if (this.selectedTypeReport == 'Order Vs Inventory Report') {
      document.getElementById('generate_Report').setAttribute('disabled','true');
      if ((this.selectedSubCategory !== '') || (this.selectedSubCategory !== null) || (this.selectedSubCategory !== undefined)) {
            document.getElementById('generate_Report').removeAttribute('disabled');
      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
      this.showReportDateData = true;
      this.showRegionData = false;
      this.showOfficeData = false;
      this.showDeviceReplacementCountData = false;
      this.showRecycleReassignemtData = false;
    }
    else if (this.selectedTypeReport == 'Inventory Reconciliation Report') {
      document.getElementById('generate_Report').setAttribute('disabled','true');
      this.showReportDateData = false;
      this.showRegionData = true;
      this.showDeviceReplacementCountData = false;
      this.showOfficeData = true;
      this.showRecycleReassignemtData = false;
      if ((this.selectedSubCategory !== '') || (this.selectedSubCategory !== null) || (this.selectedSubCategory !== undefined)) {
        if ((this.selectedRegion !== '0') && (this.selectedOffice !== '')) {

              document.getElementById('generate_Report').removeAttribute('disabled');
        }
        else {
          document.getElementById('generate_Report').setAttribute('disabled','true');
        }
      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
    }
    else if (this.selectedTypeReport == 'Mobility Program - Eligibility List Report') {
      this.showReportDateData = false;
      this.showRegionData = true;
      this.showDeviceReplacementCountData = false;
      this.showOfficeData = false;
      this.showRecycleReassignemtData = false;
      if ((this.selectedSubCategory !== '') || (this.selectedSubCategory !== null) || (this.selectedSubCategory !== undefined)) {
        if ((this.selectedRegion !== '0') && (this.selectedRegion !== '')) {

              document.getElementById('generate_Report').removeAttribute('disabled');
        }
        else {
          document.getElementById('generate_Report').setAttribute('disabled','true');
        }
      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
    }
    else if (this.selectedTypeReport == 'Laptop Compliance Report' || this.selectedTypeReport == 'Druva Profile History Report') {
      this.showReportDateData = false;
      this.showRegionData = false;
      this.showDeviceReplacementCountData = false;
      this.showOfficeData = false;
      this.showRecycleReassignemtData = false;
      if ((this.selectedSubCategory !== '') || (this.selectedSubCategory !== null) || (this.selectedSubCategory !== undefined)) {
            document.getElementById('generate_Report').removeAttribute('disabled');
      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
    } else if (this.selectedTypeReport === `Employee's Timeline Report`) { // kvn_1
      this.showEmpPesonnelNo = true;
      this.showEmpAlias = false;
      this.showRecycleReassignemtData = false;

      this.showDeviceReplacementCountData = false;

      if ((this.selectedSubCategory !== '') || (this.selectedSubCategory !== null) || (this.selectedSubCategory !== undefined)) {

            document.getElementById('generate_Report').removeAttribute('disabled');

      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
    } else if (this.selectedTypeReport === 'Enrollment Status Report') {
      this.showDeviceReplacementCountData = false;
          document.getElementById('generate_Report').removeAttribute('disabled');
    } else if (this.selectedTypeReport === 'Exceptions Report') {


      this.showDeviceReplacementCountData = false;
      console.log('final else', this.selectedTypeReport)

          document.getElementById('generate_Report').removeAttribute('disabled');
    }
    else if (this.selectedTypeReport == 'Laptop Disconnect Report') {
      this.showReportDateData = true;
      this.showRegionData = false;
      this.showDeviceReplacementCountData = false;
      this.showOfficeData = false;
      this.showRecycleReassignemtData = false;
      let d = new Date("01-01-2020");

      this.selDateFrom = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.selFromDate = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();

      if ((this.selectedSubCategory !== '') || (this.selectedSubCategory !== null) || (this.selectedSubCategory !== undefined)) {
            document.getElementById('generate_Report').removeAttribute('disabled');
      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
    } else if (this.selectedTypeReport == 'Lost/Stolen Report') {
      document.getElementById('generate_Report').setAttribute('disabled','true');
      if ((this.selectedSubCategory !== '') || (this.selectedSubCategory !== null) || (this.selectedSubCategory !== undefined)) {
            document.getElementById('generate_Report').removeAttribute('disabled');
      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
      this.showReportDateData = true;
      this.showRegionData = false;
      this.showOfficeData = false;
      this.showDeviceReplacementCountData = false;
      this.showRecycleReassignemtData = false;
    }

    this.categoryinfo = this.reportsform.controls["categoryinfo"].value;
    this.reportinfo = this.reportsform.controls["reportinfo"].value;
    this.regioninfo = this.reportsform.controls["regioninfo"].value;
    this.mindevicereplacementinfo = this.reportsform.controls["mindevicereplacement"].value;
    this.dateflag = false;
    this.employeealias = false;
    this.mindevicereplacemnetflag = false;
    this.officeflag = false;
    this.regionflag = false;
    this.exportexcel = false;
  }
  closemethod() {
    document.getElementById('reportActive').classList.remove('active');
    document.getElementById('generate_Report').style.pointerEvents = '';
    this.toDateSelected = false;
    this.fromDateSelected = false;
    this.toDateSelectedMust = false;
    this.fromToDateSelected = false;
    this.reportsform.patchValue({ "reportinfo": '' });
    this.employeetype = false;
    this.regionflag = false;
    this.employeesallflag = false;
    this.employeealias = false;
    this.exportexcel = false;
    this.officeflag = false;
    this.dateflag = false;
    this.peoplepickerinfo = false;
    this.showReportDateData = false;
    this.showTypeData = false;
    this.showRegionData = false;
    this.showOfficeData = false;
    this.showRecycleReassignemtData = false;
    this.showEmpAlias = false;
    this.showEmpPesonnelNo = false;
    this.showDeviceReplacementCountData = false;
    this.selectedEmployeeAlias = [];
    this.reportsform.patchValue({ "personnelNumber": '' });
    document.getElementById('categoryinfoo')["value"] = "";
    if (this.showTypeData) {
      document.getElementById('reportinfoo')["value"] = "";

    }
    if (this.showRegionData) {
      document.getElementById('regioninfoo')["value"] = "";
    }
    if (this.showEmpAlias) {
      document.getElementById('employealia')["value"] = "";
    }
    if (this.showDeviceReplacementCountData) {
      document.getElementById('mindevicere')["value"] = "";
    }
    if (this.showOfficeData) {
      document.getElementById('officedat')["value"] = "";
    }
    if (this.showEmployeeData) {
      document.getElementById('allcheck')["value"] = "";
    }
    if (this.showRecycleReassignemtData) {
      document.getElementById('RecycleReassignemtData')["value"] = "";
    }
  }
  regionmethodinfo(event) {
    if (this.selectedTypeReport == 'Mobility Program - Eligibility List Report') {
      if (this.selectedRegion !== 0) {
            document.getElementById('generate_Report').removeAttribute('disabled');
      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
    } else {
      if ((this.selectedRegion !== 0) && (this.selectedOffice !== '')) {
            document.getElementById('generate_Report').removeAttribute('disabled');
      } else {
        document.getElementById('generate_Report').setAttribute('disabled','true');
      }
      this.reportsform.patchValue({ "officedata": '' });
      this.facilityByRegion
      this.reportsService.getFacilityByRegion(this.selectedRegion).subscribe((data) => {
        this.facilityByRegion = data;
        // console.log('facilityByRegion', this.facilityByRegion);
      });
    }
  }
  officesData(event) {
    // console.log('office name', event);
    var e = document.getElementById("officedat");
    //this.selectedOfficeName = e.options[e.selectedIndex].text;
    if ((this.selectedRegion !== 0) && ((this.selectedOffice !== '') || (this.selectedOffice !== undefined))) {
          document.getElementById('generate_Report').removeAttribute('disabled');
    } else {
      document.getElementById('generate_Report').setAttribute('disabled','true');
    }
  }
  generateReport() {
    //Onclicks for Adobe
    var onClickTextName = "";
    if (this.selectedSubCategory == 'Inventory Reports') {
      onClickTextName = this.selectedTypeReport + ' - ' + 'Generate Report';
    } else {
      onClickTextName = this.selectedSubCategory + ' - ' + this.selectedTypeReport + ' - ' + 'Generate Report';
    }
    GlobalVariables.fn_DatalayerOnClicks('Reports Page', onClickTextName);
    //--

    this.getResultSetForGenerateReport();
    // let repDate = new Date().toLocaleDateString();
    this.insertReportDetails.reportType = this.selectedTypeReport;
    this.insertReportDetails.requestedUserAlias = this.loggedInUserName.split('@')[0];
    this.insertReportDetails.requestedUserEmail = this.loggedInUserName;
    if (this.selectedSubCategory == 'Inventory Reports' && this.selectedTypeReport == `Employee's Timeline Report`) {
      if (this.reportsform.get('personnelNumber').value === '') {
        this.PNEmpty = true;
        return;
      }
      else {
        this.PNEmpty = false;
        this.insertReportDetails.reportType = 'Employee Timeline Report';
        this.insertReportDetails.paramEmployeeAlias = this.reportsform.value.employeeealias;
        this.insertReportDetails.personnelNumber = this.reportsform.value.personnelNumber ;
        this.insertReportDetails.paramRegionCode = '';
        this.insertReportDetails.paramOfficeCode = '';
        this.insertReportDetails.paramStartDate = '';
        this.insertReportDetails.paramEndDate = '';
        this.insertReportDetails.filterType = '';
        this.reportsService.insertReportDetailsComp(this.insertReportDetails).subscribe((data) => {
          this.insertReportDataStatus = data;
          this.resetDate();
          if (this.insertReportDataStatus == true) {
            this.closeBtn.nativeElement.click();
            this.modalService.message(
              'Report generated successfully and will be sent as an email attachment in approx. 10 minutes.'
            ).pipe(
              take(1)
            ).subscribe(result => {
              // this.getResultSetForGenerateReport();
              this.messageResult = result;
              document.getElementById('dataTargetReportDetail').click();
            });
          } else {
            this.closeBtn.nativeElement.click();
            this.modalService.notExist(
              'No Records Found.'
            ).pipe(
              take(1) // take() manages unsubscription for us
            ).subscribe(result => {
              // this.getResultSetForGenerateReport();
              document.getElementById('dataTargetReportDetail').click();
              return false;
            });
          }
        });
      }
    }
    else if (this.selectedSubCategory == 'Inventory Reports') {
      if (this.selectedTypeReport == 'Inventory Report') {
        this.insertReportDetails.paramRegionCode = '';
        this.insertReportDetails.paramOfficeCode = '';
        this.insertReportDetails.paramStartDate = '';
        this.insertReportDetails.paramEndDate = '';
        this.insertReportDetails.filterType = '';
      } else if (this.selectedTypeReport == 'Inventory Reconciliation History Report') {
        this.insertReportDetails.paramRegionCode = this.selectedRegion;
        this.insertReportDetails.paramOfficeCode = this.selectedOffice;
        this.insertReportDetails.filterType = '';
        let from: Date = new Date(this.selFromDate);
        this.insertReportDetails.paramStartDate = from.getDate() + '/' + (from.getMonth() + 1) + '/' + from.getFullYear();
        let to: Date = new Date(this.selToDate);
        this.insertReportDetails.paramEndDate = to.getDate() + '/' + (to.getMonth() + 1) + '/' + to.getFullYear();
      } else if (this.selectedTypeReport == 'Recycle Reassignment Report') {
        this.insertReportDetails.paramRegionCode = '';
        this.insertReportDetails.paramOfficeCode = '';
        let from: Date = new Date(this.selFromDate);
        this.insertReportDetails.paramStartDate = from.getDate() + '/' + (from.getMonth() + 1) + '/' + from.getFullYear();
        let to: Date = new Date(this.selToDate);
        this.insertReportDetails.paramEndDate = to.getDate() + '/' + (to.getMonth() + 1) + '/' + to.getFullYear();
        this.insertReportDetails.filterType = this.reportsform.controls["recycleReassignemtData"].value;
      }
      else if (this.selectedTypeReport == 'Order Vs Inventory Report') {
        this.insertReportDetails.paramRegionCode = '';
        this.insertReportDetails.paramOfficeCode = '';
        let from: Date = new Date(this.selFromDate);
        this.insertReportDetails.paramStartDate = from.getDate() + '/' + (from.getMonth() + 1) + '/' + from.getFullYear();
        let to: Date = new Date(this.selToDate);
        this.insertReportDetails.paramEndDate = to.getDate() + '/' + (to.getMonth() + 1) + '/' + to.getFullYear();
        this.insertReportDetails.filterType = '';
      }
      else if (this.selectedTypeReport == 'Inventory Reconciliation Report') {
        this.insertReportDetails.paramRegionCode = this.selectedRegion;
        this.insertReportDetails.paramOfficeCode = this.selectedOffice;
        this.insertReportDetails.paramStartDate = '';
        this.insertReportDetails.paramEndDate = '';

        this.insertReportDetails.filterType = '';

      } else if (this.selectedTypeReport == 'End-user Device Replacement Report') {
        this.insertReportDetails.paramRegionCode = '';
        this.insertReportDetails.paramOfficeCode = '';
        this.insertReportDetails.paramEmployeeAlias = this.selectedEmployeeAlias != undefined ? this.selectedEmployeeAlias.toString() : '';
        let from: Date = new Date(this.selFromDate);
        this.insertReportDetails.paramStartDate = from.getDate() + '/' + (from.getMonth() + 1) + '/' + from.getFullYear();
        let to: Date = new Date(this.selToDate);
        this.insertReportDetails.paramEndDate = to.getDate() + '/' + (to.getMonth() + 1) + '/' + to.getFullYear();
        this.insertReportDetails.paramDeviceReplacementCount = this.selectedMinimumDeviceReplacementCount;
      }
      this.reportsService.insertReportDetails(this.insertReportDetails).subscribe((data) => {
        this.insertReportDataStatus = data;
        this.resetDate();
        this.showEmpAlias = false;
        if (this.insertReportDataStatus == true) {
          this.closeBtn.nativeElement.click();
          this.modalService.message(
            'Report generated successfully and will be sent as an email attachment in approx. 10 minutes.'
          ).pipe(
            take(1)
          ).subscribe(result => {
            this.getResultSetForGenerateReport();
            this.messageResult = result;
            document.getElementById('dataTargetReportDetail').click();
          });
        } else {
          this.closeBtn.nativeElement.click();
          this.modalService.notExist(
            'No Records Found.'
          ).pipe(
            take(1) // take() manages unsubscription for us
          ).subscribe(result => {
            this.getResultSetForGenerateReport();
            document.getElementById('dataTargetReportDetail').click();
            return false;
          });
        }
      });
    } else if (this.selectedSubCategory == 'Leadership Reports') {
      if (this.selectedTypeReport == 'Orders Report') {
        this.insertReportDetails.paramRegionCode = this.selectedRegion;
        this.insertReportDetails.paramOfficeCode = '';
        this.insertReportDetails.filterType = '';
        let from: Date = new Date(this.selFromDate);
        this.insertReportDetails.paramStartDate = from.getDate() + '/' + (from.getMonth() + 1) + '/' + from.getFullYear();
        let to: Date = new Date(this.selToDate);
        this.insertReportDetails.paramEndDate = to.getDate() + '/' + (to.getMonth() + 1) + '/' + to.getFullYear();
        this.reportsService.InsertReportDetailsOrder(this.insertReportDetails).subscribe((data) => {
          this.insertReportDataStatus = data;
          this.resetDate();
          if (this.insertReportDataStatus == true) {
            this.closeBtn.nativeElement.click();
            this.modalService.message(
              'Report generated successfully and will be sent as an email attachment in approx. 10 minutes.'
            ).pipe(
              take(1)
            ).subscribe(result => {
              this.getResultSetForGenerateReport();
              this.messageResult = result;
              document.getElementById('dataTargetReportDetail').click();
            });
          } else {
            this.closeBtn.nativeElement.click();
            this.modalService.notExist(
              'No Records Found.'
            ).pipe(
              take(1) // take() manages unsubscription for us
            ).subscribe(result => {
              this.getResultSetForGenerateReport();
              document.getElementById('dataTargetReportDetail').click();
              return false;
            });
          }
        });
      }
      else if (this.selectedTypeReport == 'Mobility Program - Eligibility List Report') {
        this.insertReportDetails.paramRegionCode = this.selectedRegion;
        this.insertReportDetails.paramOfficeCode = '';
        this.insertReportDetails.filterType = '';
        let from: Date = new Date(this.selFromDate);
        this.insertReportDetails.paramStartDate = from.getDate() + '/' + (from.getMonth() + 1) + '/' + from.getFullYear();
        let to: Date = new Date(this.selToDate);
        this.insertReportDetails.paramEndDate = to.getDate() + '/' + (to.getMonth() + 1) + '/' + to.getFullYear();
        this.reportsService.InsertReportDetailsCore(this.insertReportDetails).subscribe((data) => {
          this.insertReportDataStatus = data;
          this.resetDate();
          if (this.insertReportDataStatus == true) {
            this.closeBtn.nativeElement.click();
            this.modalService.message(
              'Report generated successfully and will be sent as an email attachment in approx. 10 minutes.'
            ).pipe(
              take(1)
            ).subscribe(result => {
              this.getResultSetForGenerateReport();
              this.messageResult = result;
              document.getElementById('dataTargetReportDetail').click();
            });
          } else {
            this.closeBtn.nativeElement.click();
            this.modalService.notExist(
              'No Records Found.'
            ).pipe(
              take(1) // take() manages unsubscription for us
            ).subscribe(result => {
              this.getResultSetForGenerateReport();
              document.getElementById('dataTargetReportDetail').click();
              return false;
            });
          }
        });
      } else if (this.selectedTypeReport == 'Laptop Compliance Report') {
        this.insertReportDetails.paramRegionCode = '';
        this.insertReportDetails.paramOfficeCode = '';
        this.insertReportDetails.paramStartDate = '';
        this.insertReportDetails.paramEndDate = '';
        this.reportsService.InsertReportDetailsLaptop(this.insertReportDetails).subscribe((data) => {
          this.resetDate();
          if (data == true) {
            this.closeBtn.nativeElement.click();
            this.modalService.message(
              'Report generated successfully and will be sent as an email attachment in approx. 10 minutes.'
            ).pipe(
              take(1)
            ).subscribe(result => {
              this.getResultSetForGenerateReport();
              this.messageResult = result;
              document.getElementById('dataTargetReportDetail').click();
            });
          } else {
            this.closeBtn.nativeElement.click();
            this.modalService.notExist(
              'No Records Found.'
            ).pipe(
              take(1) // take() manages unsubscription for us
            ).subscribe(result => {
              // this.getResultSetForGenerateReport();
              document.getElementById('dataTargetReportDetail').click();

              return false;
            });
          }
        });
      } else if (this.selectedTypeReport == 'Enrollment Status Report') {
        this.insertReportDetails.paramRegionCode = '';
        this.insertReportDetails.paramOfficeCode = '';
        this.insertReportDetails.paramStartDate = '';
        this.insertReportDetails.paramEndDate = '';
        this.insertReportDetails.filterType = '';
        this.reportsService.insertReportDetailsComp(this.insertReportDetails).subscribe((data) => {
          this.insertReportDataStatus = data;
          this.resetDate();
          this.showEmpAlias = false;
          if (this.insertReportDataStatus == true) {
            this.closeBtn.nativeElement.click();
            this.modalService.message(
              'Report generated successfully and will be sent as an email attachment in approx. 10 minutes.'
            ).pipe(
              take(1)
            ).subscribe(result => {
              this.getResultSetForGenerateReport();
              this.messageResult = result;
              document.getElementById('dataTargetReportDetail').click();
            });
          } else {
            this.closeBtn.nativeElement.click();
            this.modalService.notExist(
              'No Records Found.'
            ).pipe(
              take(1) // take() manages unsubscription for us
            ).subscribe(result => {
              this.getResultSetForGenerateReport();
              document.getElementById('dataTargetReportDetail').click();
              return false;
            });
          }
        });
      } else if (this.selectedTypeReport == 'Exceptions Report') {
        this.insertReportDetails.paramRegionCode = '';
        this.insertReportDetails.paramOfficeCode = '';
        this.insertReportDetails.paramStartDate = '';
        this.insertReportDetails.paramEndDate = '';
        this.insertReportDetails.filterType = '';
        this.reportsService.insertReportDetailsComp(this.insertReportDetails).subscribe((data) => {
          this.insertReportDataStatus = data;
          this.resetDate();
          this.showEmpAlias = false;
          if (this.insertReportDataStatus == true) {
            this.closeBtn.nativeElement.click();
            this.modalService.message(
              'Report generated successfully and will be sent as an email attachment in approx. 10 minutes.'
            ).pipe(
              take(1)
            ).subscribe(result => {
              // this.getResultSetForGenerateReport();
              this.messageResult = result;
              document.getElementById('dataTargetReportDetail').click();
            });
          } else {
            this.closeBtn.nativeElement.click();
            this.modalService.notExist(
              'No Records Found.'
            ).pipe(
              take(1) // take() manages unsubscription for us
            ).subscribe(result => {
              // this.getResultSetForGenerateReport();
              document.getElementById('dataTargetReportDetail').click();
              return false;
            });
          }
        });
      }
      else if (this.selectedTypeReport == 'Laptop Disconnect Report') {
        this.insertReportDetails.paramOfficeCode = '';
        this.insertReportDetails.paramRegionCode = '';
        this.insertReportDetails.filterType = '';
        let from: Date = new Date(this.selFromDate);
        this.insertReportDetails.paramStartDate = from.getFullYear() + '/' + (from.getMonth() + 1) + '/' + from.getDate()
        let to: Date = new Date(this.selToDate);
        this.insertReportDetails.paramEndDate = to.getFullYear() + '/' + (to.getMonth() + 1) + '/' + (to.getDate() + 1);
        this.reportsService.InsertReportDetailsLaptop(this.insertReportDetails).subscribe((data) => {
          this.resetDate();
          if (data == true) {
            this.closeBtn.nativeElement.click();
            this.modalService.message(
              'Report generated successfully and will be sent as an email attachment in approx. 10 minutes.'
            ).pipe(
              take(1)
            ).subscribe(result => {
              // this.getResultSetForGenerateReport();
              this.messageResult = result;
              document.getElementById('dataTargetReportDetail').click();
            });
          } else {
            this.closeBtn.nativeElement.click();
            this.modalService.notExist(
              'No Records Found.'
            ).pipe(
              take(1) // take() manages unsubscription for us
            ).subscribe(result => {
              // this.getResultSetForGenerateReport();
              document.getElementById('dataTargetReportDetail').click();

              return false;
            });
          }
        });
      }
      else if (this.selectedTypeReport == 'Druva Profile History Report') {
        this.insertReportDetails.paramRegionCode = '';
        this.insertReportDetails.paramOfficeCode = '';
        this.insertReportDetails.paramStartDate = '';
        this.insertReportDetails.paramEndDate = '';
        this.reportsService.InsertReportDetailsLaptop(this.insertReportDetails).subscribe((data) => {
          this.resetDate();
          if (data == true) {
            this.closeBtn.nativeElement.click();
            this.modalService.message(
              'Report generated successfully and will be sent as an email attachment in approx. 10 minutes.'
            ).pipe(
              take(1)
            ).subscribe(result => {
              // this.getResultSetForGenerateReport();
              this.messageResult = result;
              document.getElementById('dataTargetReportDetail').click();
            });
          } else {
            this.closeBtn.nativeElement.click();
            this.modalService.notExist(
              'No Records Found.'
            ).pipe(
              take(1) // take() manages unsubscription for us
            ).subscribe(result => {
              // this.getResultSetForGenerateReport();
              document.getElementById('dataTargetReportDetail').click();

              return false;
            });
          }
        });
      }
    } else if (this.selectedSubCategory == 'Compliance Reports') {
      if (this.selectedTypeReport == 'Lost/Stolen Report') {
        this.insertReportDetails.paramRegionCode = '';
        this.insertReportDetails.paramOfficeCode = '';
        let from: Date = new Date(this.selFromDate);
        this.insertReportDetails.paramStartDate = from.getDate() + '/' + (from.getMonth() + 1) + '/' + from.getFullYear();
        let to: Date = new Date(this.selToDate);
        this.insertReportDetails.paramEndDate = to.getDate() + '/' + (to.getMonth() + 1) + '/' + to.getFullYear();
      }

      this.reportsService.insertReportDetails(this.insertReportDetails).subscribe((data) => {
        this.insertReportDataStatus = data;
        this.resetDate();

        if (this.insertReportDataStatus == true) {
          this.closeBtn.nativeElement.click();
          this.modalService.message(
            'Report generated successfully and will be sent as an email attachment in approx. 10 minutes.'
          ).pipe(
            take(1)
          ).subscribe(result => {
            this.getResultSetForGenerateReport();
            this.messageResult = result;
            document.getElementById('dataTargetReportDetail').click();
          });
        } else {
          this.closeBtn.nativeElement.click();
          this.modalService.notExist(
            'No Records Found.'
          ).pipe(
            take(1) // take() manages unsubscription for us
          ).subscribe(result => {
            this.getResultSetForGenerateReport();
            document.getElementById('dataTargetReportDetail').click();
            return false;
          });
        }
      });
    }
  }
  resetDate() {
    let d: Date = new Date();
    this.selDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.selToDate = d.toLocaleDateString();
    this.selDateFrom = this.substractDays(d, 7).toLocaleDateString();//d.setDate(d.getDate() - 7);
    this.selFromDate = this.selDateFrom;
    if (this.toDate != undefined) {
      this.toDate.formatted = this.selToDate;
    }
    if (this.fromDate != undefined) {
      this.fromDate.formatted = this.selFromDate;
    }
    this.PNEmpty = false;
  }
  getResultSetForGenerateReport() {
    let response1 = this.reportsService.GetReportGeneratingStatus(this.loggedInUserName.split('@')[0]);
    let response2 = this.reportsService.GetReportGeneratingStatusOrder(this.loggedInUserName.split('@')[0]);
    let response3 = this.reportsService.GetReportGeneratingStatusCore(this.loggedInUserName.split('@')[0]);
    let response4 = this.reportsService.GetReportGeneratingStatusComp(this.loggedInUserName.split('@')[0]);
    forkJoin([response1, response2, response3, response4]).subscribe((data) => {
      let data3 = [...data[0], ...data[1], ...data[2], ...data[3]];
      this.resetDate();
      this.reportStatusResultSet = data3.sort(function (a, b) {
        let dateA = +new Date(a.reportReuestedDate), dateB = +new Date(b.reportReuestedDate)
        return dateB - dateA
      }
      );
      // console.log('result set from reports comp', this.reportStatusResultSet);
    });
  }

  OnPNChange() {
    this.PNEmpty = false;
  }  
}


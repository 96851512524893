import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, observable, Observer, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataContextService } from '../datacontext.service';
import { AddMbrFeatureCode, DeleteMBRFeatureCodeModel, MbrFeatureCode, UpdateMBRFeatureCodeModel } from 'src/app/_models/admin/MbrFeatureCode';
import { AppConstants } from 'src/app/_utils/app-constants';
import { LoggedInUserSubModules } from 'src/app/_models/admin/LoggedInSubModules.model';
import { DeviceType } from 'src/app/_models/admin/DeviceType.model';
import { DeviceSize } from 'src/app/_models/admin/DeviceSize.model';
import { DeviceColor } from 'src/app/_models/admin/DeviceColor.model';
import { DeviceImage } from 'src/app/_models/admin/DeviceImage.model';
import { DeviceLocation } from 'src/app/_models/admin/DeviceLocation.model';
import { DeviceOS } from 'src/app/_models/admin/DeviceOS.model';
import { DeviceModel } from 'src/app/_models/admin/DeviceModel.model';
import { Carrier } from 'src/app/_models/admin/Carrier.model';
import { DeviceManufacturer } from 'src/app/_models/admin/DeviceManufacture.model';
import { AdminMtn } from 'src/app/_models/admin/AdminMtn.model';
import { CarrierAuditDetails } from 'src/app/_models/admin/CarrierAuditDetails.model';
import { ExceptionViewModel } from 'src/app/_models/admin/ExceptionViewModel.model';
import { ExceptionCategory } from 'src/app/_models/admin/Exception category';
import { ExceptionSubCategory } from 'src/app/_models/admin/ExceptionSubCategory';
import { GetOverrideExecption } from 'src/app/_models/admin/GetOverrideExecption';
import { MbrThreshold } from 'src/app/_models/admin/MbrThreshold.model';
import { JobLevelGroup } from 'src/app/_models/admin/Joblevel.model';
import { DeviceMtnAssociationReassign } from 'src/app/_models/admin/Device-mtn.model';
import { MobileDeviceModel } from 'src/app/_models/admin/MobileDeviceModel.model';
import { DeviceOrigin } from 'src/app/_models/admin/DeviceOrigin.model';
import { DeviceStatus } from 'src/app/_models/admin/DeviceStatus.model ';
import { AdminUser } from 'src/app/_models/admin/Adminuser.model';
import { AllRoles } from 'src/app/_models/admin/Adminrole.model';
import { InsertUserRole } from 'src/app/_models/admin/insertadminuserrole.model';
import { AllUserRole } from 'src/app/_models/admin/Alluserrole.model';
import { InsertUpdateLaptopException } from 'src/app/_models/admin/InsertUpdateLaptopException.model';
import { LaptopBaselineSettings } from 'src/app/_models/admin/LaptopBaselineSettings.model';
import { AutoApprovalConfig } from 'src/app/_models/admin/AutoApprovalConfig.model';
import { EmployeeSubgroupCodes } from 'src/app/_models/admin/EmployeeSubgroupCodes.model';
import { ExcludeSubGroups } from 'src/app/_models/admin/ExcludeSubGroups.model';
import { InsertEmailScheduleInventory, InventoryNotification } from 'src/app/_models/inventory/InventoryNotification.model';
import { ODCName } from 'src/app/_models/admin/LaptopBaselineSettings.model';
@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public apiurl: string = localStorage.getItem('apiurl');
  apiurlv2 : string = localStorage.getItem("apiurlv2");
  private _httpHeadercommon: HttpHeaders;
  recallDeviceData: any;
  associationData: String;
  reAttach: String;
  reAssignData: String;
  orderedData:String;
  sortOrder: string;
  isSidenavOpen
  constructor(private _http: DataContextService,private _datacontextService: DataContextService) {
    this.isSidenavOpen = new Subject();
    this._httpHeadercommon = new HttpHeaders().set('content-type', 'application/json');
  }


  
  GetMBRThresholdFeatureCode(reqObj) {
    return this._http.getData<MbrFeatureCode[]>(`${this.apiurl}${AppConstants.GPA_ENDPOINTS.Admin.GetMBRThresholdFeatureCode}?ComplianceRuleID=${reqObj.roleId}&ComplianceParameterID=${reqObj.id}&CarrierCode=${reqObj.code}`);
  }
  SetMBRFeatureCode(reqObj: AddMbrFeatureCode) : Observable<string> {
    return this._http.postData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.SetMBRFeatureCode, reqObj);
  }
  DeleteMBRFeatureCode(obj:DeleteMBRFeatureCodeModel){
    return this._http.getData<any>(`${this.apiurl}${AppConstants.GPA_ENDPOINTS.Admin.DeleteMBRFeatureCode}?StandardFeatureID=${obj.StandardFeature_ID}&FeatureCode=${obj.FeatureCode}&LoggedInUser=${obj.LoggedInUser}`);
  }
  UpdateMBRFeatureCode(obj : UpdateMBRFeatureCodeModel){
    return this._http.getData<any>(`${this.apiurl}${AppConstants.GPA_ENDPOINTS.Admin.UpdateMBRFeatureCode}?StandardFeatureID=${obj.StandardFeature_ID}&ExistingFeatureCode=${obj.ExistingFeatureCode}&FeatureCode=${obj.FeatureCode}&ModifiedBy=${obj.ModifiedBy}`);
  }

  // GetAdminDashboardDeviceType(): Observable<DeviceType[]> {
  // return this._http.getData<DeviceType[]>(this.apiurl+AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceType);
  //}
  GetAdminDashboardDeviceType(): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceType);
  }
  GetLoggedInUserSubModules(useralias, menuName) {
    return this._http.getData<LoggedInUserSubModules[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetLoggedInUserSubModules + '?useralias=' + useralias + '&menuName=' + menuName).pipe(map(data => data));
  }
  InsertUpdateDeviceType(dt: DeviceType): Observable<boolean> {

    //let status=true;
    //alert(JSON.stringify(dt.isActive));
    let status = !dt.isActive;
    //alert(JSON.stringify(dt));
    let createdbyAlias = '';
    let modifiedbyAlias = '';
    if (dt.deviceTypeID === '0'){
      createdbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    else {
      modifiedbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    var body = {
      DeviceTypeID: dt.deviceTypeID, DeviceTypeDesc: dt.deviceTypeDesc,
      Devicetype: dt.deviceTypeDesc, SmallImageURL: dt.smallImageURL,
      LargeImageURL: dt.largeImageURL, 
      CreatedBy: createdbyAlias, //dt.CreatedBy,
      ModifiedBy: modifiedbyAlias, //dt.ModifiedBy,
      DateCreated: dt.dateCreated,
      IsActive: status
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.postData<boolean>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateAdminDashboardDeviceType, body);

  }
  GetAdminDashboardDeviceTypeByID(deviceTypeID: number): Observable<DeviceType> {
    return this._http.getData<DeviceType>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceTypeByID + '?DeviceTransactionTypeID=' + deviceTypeID);
  }
  GetAdminDashboardDeviceSize(deviceSizeID: number): Observable<any[]> {
    return this._http.getData<any[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceSize);
  }
  InsertUpdateDeviceSize(dt: DeviceSize): Observable<boolean> {
    //alert(JSON.stringify(dt));
    let status = !dt.isActive;
    let createdbyAlias = '';
    let modifiedbyAlias = '';
    if (dt.deviceSizeID === '0'){
      createdbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    else {
      modifiedbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    // if(+dt.DeviceSizeID===0){
    // status=true;
    // }else{
    //  status=!dt.IsActive;
    // }
    var body = {
      DeviceSizeID: (dt.deviceSizeID == null) ? 0 : dt.deviceSizeID,
      DeviceSizeDesc: dt.deviceSizeDesc,
      CreatedBy: createdbyAlias,//dt.CreatedBy,
      ModifiedBy: modifiedbyAlias,//dt.ModifiedBy,
      IsActive: status
    };
    return this._http.postData<boolean>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateAdminDashboardDeviceSize, body);

  }
  GetAdminDashboardDeviceColor(): Observable<DeviceColor[]> {
    return this._http.getData<DeviceColor[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceColors);
  }
  InsertUpdateDeviceColor(dt: DeviceColor): Observable<string> {
    let status = !dt.isActive;
    let createdbyAlias = '';
    let modifiedbyAlias = '';
    if (dt.deviceColorID === 0){
      createdbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    else {
      modifiedbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    //   if(dt.deviceColorID===0){
    //    status=true;
    //   }else{
    //  status=!dt.isActive;
    //   }
    //alert(JSON.stringify(dt));
    var body = {
      DeviceColorID: dt.deviceColorID,
      DeviceColorName: dt.deviceColorName,
      CreatedBy: createdbyAlias,//dt.createdBy,
      DateCreated: dt.dateCreated,
      ModifiedBy: modifiedbyAlias,//dt.modifiedBy,
      DateModified: dt.dateModified,
      IsActive: status
    };
    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateAdminDashboardDeviceColor, body);

  }
  GetAdminDashboardDeviceImage(): Observable<DeviceImage[]> {
    return this._http.getData<DeviceImage[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceImage);
  }
  InsertUpdateDeviceImage(dt: DeviceImage): Observable<string> {
    //alert(JSON.stringify(dt));
    var body = {
      DeviceImageID: dt.deviceImageID,
      DeviceImageFileName: dt.deviceImageFileName,
      DeviceImageBinary: dt.deviceImageBinary,
      IsActive: dt.isActive
    };
    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateAdminDashboardDeviceImage, body);

  }

  GetAdminDashboardDeviceLocation(locationid: string): Observable<DeviceLocation[]> {
    return this._http.getData<DeviceLocation[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceLocation + '?Locationid=' + locationid + '&userRole=""');
  }
  GetAdminDashboardDeviceOS(deviceOSID: number): Observable<DeviceOS[]> {
    return this._http.getData<DeviceOS[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceOS + '?DeviceOSID=' + deviceOSID);
  }
  InsertUpdateAdminDashboardDeviceOS(ds: DeviceOS): Observable<boolean> {
    let status = !ds.isActive;
    let createdbyAlias = '';
    let modifiedbyAlias = '';
    if (ds.deviceOSID === 0) {
      createdbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    else {
      modifiedbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    //     if(ds.DeviceOSID===0){
    //   status=true;
    // }else{
    //   status=!ds.isActive;
    // }
    var body = {
      DeviceOSID: ds.deviceOSID,
      DeviceOSDesc: ds.deviceOSDesc,
      CreatedBy: createdbyAlias,
      ModifiedBy: modifiedbyAlias,
      IsActive: status
    };
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.postData<boolean>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateAdminDashboardDeviceOS, body);

  }
  InsertUpdateAdminDashboardDeviceLocation(dL: DeviceLocation): Observable<string> {
    // alert(JSON.stringify(dL));
    let status = !dL.IsActive;
    var body = {
      DeviceLocationID: dL.DeviceLocationID,
      DeviceLocationDesc: dL.DeviceLocationDesc,
      CreatedBy: dL.CreatedBy,
      DateCreated: dL.DateCreated,
      ModifiedBy: dL.ModifiedBy,
      DateModified: dL.DateModified,
      IsActive: status
    };
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateAdminDashboardDeviceLocation, body);

  }
  GetAllDeviceOs(): Observable<DeviceOS[]> {
    return this._http.getData<DeviceOS[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAllDeviceOs);
  }

  GetAllLocations(): Observable<DeviceLocation[]> {
    return this._http.getData<DeviceLocation[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAllLocations);
  }
  InsertUpdateDeviceModel(dm: DeviceModel): Observable<string> {
    //alert(JSON.stringify(dm.status));
    let status = !dm.isActive;
    let createdbyAlias = '';
    let modifiedbyAlias = '';
    if (dm.id === 0) {
      createdbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    else {
      modifiedbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    //alert(JSON.stringify(status));
    // if(dm.id===0){
    //   status=true;
    // }else{
    //   status=!dm.status;
    // }
    let body = {
      ID: dm.id,
      DeviceModelName: dm.deviceModelName,
      //DeviceOS:dm.deviceOS,
      DeviceOSID: dm.deviceOSID,
      //  DeviceSizeID:dm.deviceSizeID,
      DeviceTypeID: dm.deviceTypeID,
      IsSupported: 0,
      CarrierCode: dm.carrierCode,
      ManufacturerId: dm.manufacturerId,
      IsActive: status,
      LargeImageURL: dm.largeImageURL,
      AlternateDeviceNames: [],
      CreatedBy: createdbyAlias, //dm.createdBy,
      ModifiedBy: modifiedbyAlias, //dm.modifiedBy,
      DeviceModelMappingListForUpdate: dm.alternatNames,
      Comment: dm.comment
      //IsActive:status
    };
    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateAdminDashboardDeviceModel, body);

  }
  GetAdminDashboardDeviceModel(deviceModelName: string): Observable<DeviceModel[]> {
    //alert(deviceModelName);
    return this._http.getData<DeviceModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashBoardAuditDeviceModel + '?devicemodelname=' + deviceModelName);
  }

  GetAllDeviceAlternateNames(deviceModelID: number): Observable<any[]> {
    return this._http.getData<any[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceAlternateNames + '?deviceModelID=' + deviceModelID);
  }

  GetAllVipException(deviceName: number): Observable<any[]> {
    return this._http.getData<any[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardVipException + deviceName)
      .pipe(map(data =>
        data.map(d =>
          ({
            personnelNbr: d.vipPersonneNbr,
            joblevelgroup: '',
            nameFirst: d.employee.firstName,
            nameLast: d.employee.lastName,
            complianceRuleDesc: '',
            ruleTypeDesc: '',
            overrideComment: d.employeeComments,
            firmfunctiondesc: d.firmFunctionDesc,
            dateEnd: null,
            complianceRuleTypeID: 0,
            complianceRuleID: 0,
            exceptionType: d.exceptionType,
            complienceOverrideID: 0,
            employeeAlias: d.employeeAlias,
            name: d.employee.name
          })
        )));
  }
  InsertUpdateVipLeader(bodytosend: any): Observable<string> {
    //alert('okk');
    let body = bodytosend;
    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateVIPException, body);
  }
  GetAdminDashboardCarrier(): Observable<Carrier[]> {
    return this._http.getData<Carrier[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardCarrier);
  }

  GetUSCarriers(): Observable<Carrier[]> {
    return this._http.getData<Carrier[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetUSCarrier + '?isUsCarrier=1');
  }


  InsertUpdateCarrier(carrier: Carrier): Observable<string> {
    //  alert(JSON.stringify(carrier));
    let status = !carrier.isActive;
    let body = {
      CarrierCode: carrier.carrierCode,
      CarrierName: carrier.carrierName,
      CreatedBy: localStorage.getItem('loggedInUserName').split('@')[0],
      ModifiedBy: localStorage.getItem('loggedInUserName').split('@')[0],
      Country: carrier.country,
      CarrierImage: carrier.carrierImage,
      IsActive: status,
      IsInUse: false



    }
    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateCarrier, body);
  }
  insertupdatedevicemanufacturer(dm): Observable<string> {
    //alert(dm.isActive);
    let status = !dm.isactive;
    let createdbyAlias = '';
    let modifiedbyAlias = '';
    if (dm.deviceManufacturerID === '0') {
      createdbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    else {
      modifiedbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    // alert(status);
    // if(+dm.deviceManufacturerID===0){
    //   status=true;
    // }else{
    //   status=!dm.isactive;
    // }
    var body = {
      DeviceManufacturerID: dm.deviceManufacturerID,
      Manufacturer: dm.manufacturer,
      SmallImageURL: dm.smallImageURL,
      LargeImageURL: dm.largeImageURL,
      CreatedDate: dm.createdDate,
      CreatedBy: createdbyAlias,//dm.createdBy,
      ModifiedBy: modifiedbyAlias,
      IsActive: status,
      IsInUse: status
    };
    //alert(JSON.stringify(body));

    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateAdminDashboardDeviceManufacturer, body);

  }
  GetAdminDashboardDeviceManufacturer(manufacturerId: string): Observable<DeviceManufacturer[]> {
    return this._http.getData<DeviceManufacturer[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceManufacturer + '?DeviceManufacturerID=' + manufacturerId);
  }

  GetAdminDashboardMtn(mobilno: string): Observable<AdminMtn[]> {
    return this._http.getData<AdminMtn[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardMTN + '?mobilenumber=' + mobilno);
  }

  InsertupdateAdminDashboardMtn(mtmModel: AdminMtn): Observable<boolean> {
    let createdbyAlias = '';
    let modifiedbyAlias = '';
    if (mtmModel.priviousMobileNo === '0') {
      createdbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    else {
      modifiedbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    }
    let body = {
      MobileTelephoneNbr: mtmModel.mobileTelephoneNbr,
      MTNActivationDate: mtmModel.mtnActivationDate,
      UserName: mtmModel.userName,
      MTNStatus: mtmModel.mtnStatus,
      AccountStatus: mtmModel.accountStatus,
      CurrentCarrierCode: mtmModel.currentCarrierCode,
      ContractEndDate: mtmModel.contractEndDate,
      ContractStartDate: mtmModel.contractStartDate,
      IsPrimaryNumber: mtmModel.isPrimaryNumber,
      IsPrimaryNumberCalculated: false,
      UpgradeEligibileDate: mtmModel.upgradeEligibileDate,
      IsPersonalMTN: mtmModel.isPersonalMTN,
      CreatedBy: createdbyAlias,
      ModifiedBy: modifiedbyAlias,
      IsEdited: mtmModel.isEdited,
      PreviousMobileNo: mtmModel.priviousMobileNo,
      Comments: mtmModel.comments,
      MobileAccountNo: mtmModel.mobileAccountNo,
      IsInUse: false
    };
    //// console.log('the Body IS '+JSON.stringify(body));

    return this._http.postData<boolean>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateAdminDashboardAdminDashboardMtn, body);

  }
  DeleteAdminDeviceType(deviceTypeID: number): Observable<string> {

    //alert(deviceTypeID);
    return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteAdminDashboardDeviceType + '?DeviceTypeID=' + deviceTypeID);
  }
  DeleteAdminDashboardDeviceOS(deviceos): Observable<string> {
    // alert(deviceos.deviceOSID);
    return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteAdminDashboardDeviceOS + '?DeviceOSID=' + deviceos.deviceOSID)
  }
  DeleteAdminDashboardDeviceSize(deviceSize): Observable<string> {
    //alert(deviceSize.deviceSizeID);
    return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteAdminDashboardDeviceSize + '?DeviceSizeID=' + deviceSize.deviceSizeID);
  }
  DeleteAdminDashboardDeviceColor(deviceColor): Observable<string> {
    return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteAdminDashboardDeviceColor + '?DeviceColorid=' + deviceColor.deviceColorID);
  }
  DeleteAdminDashboardDeviceManufacturer(devicemanufacturer): Observable<DeviceManufacturer[]> {
    //alert(devicemanufratuer.deviceManufacturerID);
    return this._http.getData<DeviceManufacturer[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteAdminDashboardDeviceManufacturer + '?manufacturerId=' + devicemanufacturer.deviceManufacturerID);
  }
  DeleteAdminDeviceModel(devicemodel): Observable<string> {
    return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteAdminDashBoardDeviceModel + '?deviceModelID=' + devicemodel.id);
  }
  DeleteAdminDashboardDeviceLocation(deviceLocationtoDelete): Observable<DeviceLocation[]> {
    // alert(deviceLocationtoDelete.deviceLocationID);
    return this._http.getData<DeviceLocation[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteAdminDashboardDeviceLocation + '?DeviceLocationID=' + deviceLocationtoDelete.deviceLocationID);
  }
  GetCarrierAuditDetails(): Observable<CarrierAuditDetails[]> {
    return this._http.getData<CarrierAuditDetails[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetCarrierAuditDetails
    );
  }
  InsertUpdateCarrierAuditDetails(dt: any): Observable<boolean> {
    //alert(JSON.stringify(dt));
    //var para = JSON.stringify(dt);
    var body = {
      CarrierCode: dt.carrierCode,
      DeviceTypeId: dt.deviceType,
      EligibilityDatePeriod: dt.eligibilityDatePeriod,
      ContractEndDatePeriod: dt.contractEndDatePeriod,
      IsActive: dt.isActive,
      LoggedInUser: localStorage.getItem('loggedInUserName').split('@')[0]

    };
    //alert("final data");
    //alert(JSON.stringify(body));
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this._http.postData<boolean>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateCarrierAuditDetails,
      body
    );
  }
  DeleteAdminDashboardCarrier(carrier: Carrier): Observable<Carrier[]> {
    return this._http.getData<Carrier[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteAdminDashboardCarrier + '?carriercode=' + carrier.carrierCode);
  }
  DeleteAdminDeviceModelMapping(deviceMappingId): Observable<string> {
    return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteAdminDeviceModelMapping + '?DeviceModelMappingID=' + deviceMappingId);
  }
  savependingreturnexception(ac): Observable<string> {
    //// console.log(ac);
    if (ac.ExceptionCatagoryID == 1 && (ac.ExceptionVioalationID == 141 || ac.ExceptionVioalationID == 184)) {
      return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.savependingreturnexception, ac);
    }

    else if (ac.ExceptionCatagoryID == 1 && (ac.ExceptionVioalationID != 141 || ac.ExceptionVioalationID != 184)) {

      return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertComplianceException, ac);
    }
    else if (ac.ExceptionCatagoryID == 5 || ac.ExceptionCatagoryID == 6 || ac.ExceptionCatagoryID == 4) {

      return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertMBRException, ac);
    }
    else {
      return Observable.create((observer: Observer<string>) => {
        observer.next("1")
      })
    }
  }

  GetAllBillingException(exceptionTypeID: number): Observable<ExceptionViewModel[]> {
    return this._http.getData<ExceptionViewModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAllBillingException + '?exceptionid=' + exceptionTypeID);
  }
  GetAllExceptionCategory(): Observable<ExceptionCategory[]> {
    return this._http.getData<ExceptionCategory[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAllExceptionCategory);
  }

  GetAllExceptionSubCategory(exceptionCategoryID: number): Observable<ExceptionSubCategory[]> {
    return this._http.getData<ExceptionSubCategory[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAllExceptionSubCategory + '?ExceptionCategoryID=' + exceptionCategoryID);
  }
  GetExceptionDetailsById(overrideid: number): Observable<GetOverrideExecption[]> {
    return this._http.getData<GetOverrideExecption[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetExceptionDetailsById + '?Overrideid=' + overrideid);
  }
  GetAllException(): Observable<ExceptionViewModel[]> {
    return this._http.getData<ExceptionViewModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAllException);
  }
  DeleteMobileException(ac): Observable<string> {
    let createdByAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    if (ac.exceptionType === 'Compliance' && ac.complianceRuleTypeID == 1 && (ac.complianceRuleID == 141 || ac.complianceRuleID == 184)) {
      return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteMobileException + '?ComplianceOverriedID=' + ac.complienceOverrideID + '&LoggedinUser=' +createdByAlias);
    }
    else if (ac.exceptionType === 'VIP') {
      //let createdByAlias = localStorage.getItem('loggedInUserName').split('@')[0];
      return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteVIPMobileexception + '?vipPersonalNbr=' + ac.personnelNbr + '&LoggedInUser=' +createdByAlias);
    }
    else if (ac.exceptionType === 'MBR' || ac.exceptionType === 'TnC' || ac.exceptionType === 'Deliquency') {
      return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteBillingException + '?ComplianceOverriedID=' + ac.complienceOverrideID+ '&loggedinUser=' +createdByAlias);
    }
    else {
      return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteComplianceException + '?ComplianceOverriedID=' + ac.complienceOverrideID+ '&LoggedinUser=' +createdByAlias);
    }
  }

  GetComplianceException(): Observable<any[]> {
    return this._http.getData<any[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetComplianceException)
      .pipe(map(data =>
        data.map(d =>
          ({
            personnelNbr: d['personnelNbr'],
            joblevelgroup: d['joblevelgroup'],
            nameFirst: d['name_First'],
            nameLast: d['name_Last'],
            complianceRuleDesc: d['complianceRuleDesc'],
            ruleTypeDesc: d['ruleTypeDesc'],
            overrideComment: d['overrideComment'],
            firmfunctiondesc: d['firmfunctiondesc'],
            dateEnd: d['dateEnd'],
            complianceRuleTypeID: d['complianceRuleTypeID'],
            complianceRuleID: d['complianceRuleID'],
            exceptionType: d['exceptionType'],
            complienceOverrideID: d['complianceOverrideID'],
            employeeAlias: d['employeeAlias'],
            name: d['name']
          })
        )))
  }
  GetAllRegion(): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAllRegion);
  }
  GetAllFirmFunction(regionID): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetFirmFunction + '?regionCode=' + regionID);
  }
  GetAllServiceArea(regionID, firmfunctioncode): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetServiceArea + '?firmFunctionCode=' + firmfunctioncode + '&regionCode=' + regionID);
  }
  GetAllServiceLine(regionID, firmfunctioncode, serviceAreaCode): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetServiceLine + '?ServiceAreaCode=' + serviceAreaCode + '&firmFunctionCode=' + firmfunctioncode + '&regionCode=' + regionID);
  }
GetRevokeEligible(regionCode, firmFunCode, svcAreaCode, svcLineCode, CostCenterCode, jobLevel, empSubGroup): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetRevokeEligible +
      '?regionCode=' + regionCode + '&firmFunCode=' + firmFunCode + '&svcAreaCode=' + svcAreaCode + '&svcLineCode=' + svcLineCode + '&CostCenterCode=' + CostCenterCode
      + '&jobLevel=' + jobLevel + '&empSubGroup=' + empSubGroup);
  }
GetRevokeEligibleGS(regionCode, firmFunCode, svcAreaCode, svcLineCode, CostCenterCode, jobLevel, empSubGroup, globalSearchKey): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetRevokeEligible +
      '?regionCode=' + regionCode + '&firmFunCode=' + firmFunCode + '&svcAreaCode=' + svcAreaCode + '&svcLineCode=' + svcLineCode + '&CostCenterCode=' + CostCenterCode
      + '&jobLevel=' + jobLevel + '&empSubGroup=' + empSubGroup + '&GlobalSearchKey=' + globalSearchKey);
  }
  SetRevokeEligible(revokeIDS): Observable<number> {
    let body = {
      RevokeEligibleValues: revokeIDS,
      CreatedBy: localStorage.getItem('loggedInUserName').split('@')[0],
      Iseligible: ''
    };
    return this._http.postData<number>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.SetRevokeEligible, body);
  }
  GetGrantEligible(regionCode, firmFunCode, svcAreaCode, svcLineCode, CostCenterCode, jobLevel, empSubGroup): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetGrantEligibility +
      '?regionCode=' + regionCode + '&firmFunCode=' + firmFunCode + '&svcAreaCode=' + svcAreaCode + '&svcLineCode=' + svcLineCode + '&CostCenterCode=' + CostCenterCode
      + '&jobLevel=' + jobLevel + '&empSubGroup=' + empSubGroup );
  }

  SetGrantEligible(revokeIDS): Observable<number> {
    let body = {
      RevokeEligibleValues: revokeIDS,
      CreatedBy: localStorage.getItem('loggedInUserName').split('@')[0],
      Iseligible: ''
    };
    return this._http.postData<number>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.SetGrantEligible, body);
  }
  GetEligibleHistory(regionCode): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetMobilityHistory +
      '?regionCode=' + regionCode);
  }
  GetMBRThresholdFilters(): Observable<MbrThreshold[]> {
    return this._http.getData<MbrThreshold[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetMBRThresholdFilters)
  }
  SetMBRComplianceParameter(mbrtype): Observable<boolean> {
    var body = {
      complianceParameterID: mbrtype.complianceParameterID,
      mbrViolationType: mbrtype.mbrViolationType,
      carrier: mbrtype.carrier,
      deviceType: mbrtype.deviceType,
      userType: mbrtype.userType,
      monthsUnderReview: mbrtype.monthsUnderReview,
      thresholdsDescription: mbrtype.thresholdsDescription,
      thresholdOperator: mbrtype.thresholdOperator,
      thresholdValue: mbrtype.thresholdValue,
      CreatedBy: mbrtype.CreatedBy
    };
    return this._http.postData<boolean>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.SetMBRComplianceParameter, body);
  }
  GetJobLevelGroup(): Observable<JobLevelGroup[]> {
    return this._http.getData<JobLevelGroup[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetAllUserTypes);
  }

  GetAdminDeviceModelForPaging(deviceModelName: string, pageNo: number, itemsperpage: number): Observable<any> {
    //alert(deviceModelName);
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDeviceModelForPaging + '?devicemodelname=' + deviceModelName + '&pageNo=' + pageNo + '&itemsperpage=' + itemsperpage);
  }
  CheckDeviceModelValidation(deviceModelName: string, carrierCode: string, editId: number): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.CheckDeviceModelValidation + '?deviceModelName=' + deviceModelName + '&carrierCode=' + carrierCode + '&editId=' + editId);
  }
 GetEligibleHistoryForPaging(regionCode, pageNo, itemsperpage, sortOrder): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetEligibilityHistoryForPaging +
      '?regionCode=' + regionCode + '&pageNo=' + pageNo + '&itemsperpage=' + itemsperpage + '&sortOrder=' + sortOrder);
  }
  GetEligibleHistoryForPagingGS(regionCode, pageNo, itemsperpage, sortOrder, globalSearchKey): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetEligibilityHistoryForPaging +
      '?regionCode=' + regionCode + '&pageNo=' + pageNo + '&itemsperpage=' + itemsperpage + '&sortOrder=' + sortOrder + '&GlobalSearchKey=' + globalSearchKey);
  }

  GetGrantEligibleForPaging(regionCode, firmFunCode, svcAreaCode, svcLineCode, CostCenterCode, jobLevel, empSubGroup, pageNo, itemsperpage, sortOrder): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetGrantEligibility +
      '?regionCode=' + regionCode + '&firmFunCode=' + firmFunCode + '&svcAreaCode=' + svcAreaCode + '&svcLineCode=' + svcLineCode + '&CostCenterCode=' + CostCenterCode
      + '&jobLevel=' + jobLevel + '&empSubGroup=' + empSubGroup + '&pageNo=' + pageNo + '&itemsperpage=' + itemsperpage + '&sortOrder=' + sortOrder);
  }
    GetGrantEligibleForPagingGS(regionCode, firmFunCode, svcAreaCode, svcLineCode, CostCenterCode, jobLevel, empSubGroup, pageNo, itemsperpage, sortOrder, globalSearchKey): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetGrantEligibility +
      '?regionCode=' + regionCode + '&firmFunCode=' + firmFunCode + '&svcAreaCode=' + svcAreaCode + '&svcLineCode=' + svcLineCode + '&CostCenterCode=' + CostCenterCode
      + '&jobLevel=' + jobLevel + '&empSubGroup=' + empSubGroup + '&pageNo=' + pageNo + '&itemsperpage=' + itemsperpage + '&sortOrder=' + sortOrder + '&GlobalSearchKey=' + globalSearchKey);
  }

  GetRevokeEligibleForPaging(regionCode, firmFunCode, svcAreaCode, svcLineCode, CostCenterCode, jobLevel, empSubGroup, pageNo, itemsperpage, sortOrder): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetRevokeEligible +
      '?regionCode=' + regionCode + '&firmFunCode=' + firmFunCode + '&svcAreaCode=' + svcAreaCode + '&svcLineCode=' + svcLineCode + '&CostCenterCode=' + CostCenterCode
      + '&jobLevel=' + jobLevel + '&empSubGroup=' + empSubGroup + '&pageNo=' + pageNo + '&itemsperpage=' + itemsperpage + '&sortOrder=' + sortOrder);
  }
  GetRevokeEligibleForPagingGS(regionCode, firmFunCode, svcAreaCode, svcLineCode, CostCenterCode, jobLevel, empSubGroup, pageNo, itemsperpage, sortOrder, globalSearchKey): Observable<any> {
    return this._http.getData<any>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetRevokeEligible +
      '?regionCode=' + regionCode + '&firmFunCode=' + firmFunCode + '&svcAreaCode=' + svcAreaCode + '&svcLineCode=' + svcLineCode + '&CostCenterCode=' + CostCenterCode
      + '&jobLevel=' + jobLevel + '&empSubGroup=' + empSubGroup + '&pageNo=' + pageNo + '&itemsperpage=' + itemsperpage + '&sortOrder=' + sortOrder+ '&GlobalSearchKey=' + globalSearchKey);
  }
  recallDevice(EmployeeAlias, MobileDeviceID, FeatureDesc): Observable<String> {
    const role = localStorage.getItem('UserRole')
    // let body={obj,LoggedUser:'',totalNoOfRecords:50}
    let header = new HttpHeaders().set('content-type', 'application/json;charset=utf-8;');
    return this._http.postData<String>(this.apiurl + "Inventory/AddUpdateAbilitytoReCallMobileDeviceID?EmployeeAlias=" + EmployeeAlias + "&MobileDeviceID=" + MobileDeviceID + "&FeatureDesc=" + FeatureDesc + "&UserRole=" + role, { responseType: 'text' }).pipe(map(data => {
      this.recallDeviceData = data;
      return data;
    })

    );

  }
  updateMobileId(ExistingMobileDeviceID, UpdatedMobileDeviceID, EmployeeAlias): Observable<String> {

    // let body={obj,LoggedUser:'',totalNoOfRecords:50}
    let header = new HttpHeaders().set('content-type', 'application/json;charset=utf-8;');
    return this._http.postData<String>(this.apiurl + "Inventory/InsertUpdateMobileDeviceAssociation?ExistingMobileDeviceID=" + ExistingMobileDeviceID + "&UpdatedMobileDeviceID=" + UpdatedMobileDeviceID + "&EmployeeAlias=" + EmployeeAlias, { headers: header }).pipe(map(data => {
      this.associationData = data;
      return data;
    })

    );

  }
  reAttachMobileDevice(EmployeeAlias, MobileDeviceID, MobileTelephoneNbr, ConfirmUpdate): Observable<String> {

    // let body={obj,LoggedUser:'',totalNoOfRecords:50}
    let header = new HttpHeaders().set('content-type', 'application/json;charset=utf-8;');
    return this._http.postData<String>(this.apiurl + "Inventory/AddUpdateAbilitytoReAttachMobileDeviceID?EmployeeAlias=" + EmployeeAlias + "&MobileDeviceID=" + MobileDeviceID + "&MobileTelephoneNbr=" + MobileTelephoneNbr + "&ConfirmUpdate=" + ConfirmUpdate, { headers: header }).pipe(map(data => {
      this.reAttach = data;
      return data;
    })

    );

  }
  reAssignMobileDevice(EmployeeAlias, MobileDeviceID, DeviceLocationID, AssignedAlias, FacilityCode): Observable<String> {
    const role = localStorage.getItem('UserRole');
    // let body={obj,LoggedUser:'',totalNoOfRecords:50}
    let header = new HttpHeaders().set('content-type', 'application/json;charset=utf-8;');
    return this._http.postData<String>(this.apiurl + "Inventory/AddUpdateAllowDeviceToReassign?EmployeeAlias=" + EmployeeAlias + "&MobileDeviceID=" + MobileDeviceID + "&DeviceLocationID=" + DeviceLocationID + "&AssignedAlias=" + AssignedAlias + "&FacilityCode=" + FacilityCode + "&UserRole=" + role, { headers: header }).pipe(map(data => {
      this.reAssignData = data;
      return data;
    })

    );

  }
  mobileDeviceAssociation(EmployeeAlias, MobileDeviceID, MobileTelephoneNbr, LoggedInUser): Observable<String> {
    const role = localStorage.getItem('UserRole')
    // let body={obj,LoggedUser:'',totalNoOfRecords:50}
    let header = new HttpHeaders().set('content-type', 'application/json;charset=utf-8;');
    return this._http.post<String>(this.apiurl + "Inventory/AddUpdateEmpMTNAssociation?EmployeeAlias=" + EmployeeAlias + "&MobileDeviceID=" + MobileDeviceID + "&MobileTelephoneNbr=" + MobileTelephoneNbr + "&LoggedInUser=" + LoggedInUser
    + "&UserRole=" + role
    ).pipe(map(data => {
      this.reAssignData = data;
      return data;
    })

    );

  }  

  FindOrderNumber(OrderNUmber): Observable<String[]> {
    let header = new HttpHeaders().set('content-type', 'application/json;charset=utf-8;');
    return this._http.postData<string[]>(this.apiurl + "Inventory/CheckOrderNumberExitsorNot?OrderNumber=" + OrderNUmber , { headers: header });
    
  }

  insertorupdateorder(EmployeeAlias, MobileDeviceID, LoggedInUser, OrderNbr, OrderDate): Observable<String> {
    const role = localStorage.getItem('UserRole')
    let header = new HttpHeaders().set('content-type', 'application/json;charset=utf-8;');
    return this._http.post<String>(this.apiurl + "Inventory/AddUpdateEmpOrder?EmployeeAlias=" + EmployeeAlias + "&MobileDeviceID=" + MobileDeviceID + "&LoggedInUser=" + LoggedInUser
    + "&UserRole=" + role + "&OrderNumber=" + OrderNbr + "&OrderDate=" + OrderDate 
    ).pipe(map(data => {
      this.orderedData = data;
      return data;
    })
    );

  }
  
  getDeviceLocationForReassign(Locationid, userRole) {
    return this._http
      .getData<DeviceMtnAssociationReassign[]>(this.apiurl + "Inventory/GetAdminDashboardDeviceLocation?Locationid=" + Locationid + "&userRole=" + userRole)
      .pipe(map(data => data));
  }
  getAllFacilitiesForReassign() {
    return this._http
      .getData<DeviceMtnAssociationReassign>(this.apiurl + "Inventory/GetAllFacilities")
      .pipe(map(data => data));
  }
  DeleteAdminDashboardMobileTelephoneNumber(mobileNumber): Observable<string> {
    return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteAdminDashboardMobileTelephoneNumber + '?mobiletelephonenumber=' + mobileNumber);
  }

  GetAdminDashboardMobileDevice(mobileDeviceID: string): Observable<MobileDeviceModel[]> {

    return this._http.getData<MobileDeviceModel[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardMobileDevice + '?MobileDeviceID=' + mobileDeviceID);
    // alert(JSON.stringify(result));

  }
  GetAllFacilityRegionWithDeviceOrigin(): Observable<DeviceOrigin[]> {
    return this._http.getData<DeviceOrigin[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAllFacilityRegionWithDeviceOrigin);
  }
  GetAdminDashboardDeviceStatus(devicestatusid: number): Observable<DeviceStatus[]> {
    return this._http.getData<DeviceStatus[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAdminDashboardDeviceStatus + '?deviceStatusID=' + devicestatusid);
  }
  DeleteAdminMobileDeviceMapping(mobileDeviceID): Observable<MobileDeviceModel> {
    // alert(JSON.stringify(mobileDeviceID));
    var res = this._http.getData<MobileDeviceModel>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteAdminMobileDeviceMapping + '?MobileDeviceId=' + mobileDeviceID);
    // alert(JSON.stringify(res));
    return res;
  }

  InsertUpdateIMEIAssociation(dm: MobileDeviceModel): Observable<string> {

    let body = {

      ExistingMobileDeviceID: dm.existingMobileDeviceId,
      NewMobileDeviceID: dm.newMobileDeviceId,
      MobileDeviceID: dm.mobileDeviceID,
      SerialNumber: dm.serialNumber,
      CarrierCode: dm.carrierCode,
      DeviceModelID: dm.deviceModelID,
      DeviceModel: dm.deviceModel,
      EmployeeAlias: localStorage.getItem('loggedInUserName').split('@')[0],
      IsPendingReturn: 0,
      EmployeeComments: dm.employeeComments,
      IsPersonnel: 0,
      IsPendingWipe: 0,
      DeviceOrigin: dm.deviceOrigin,
      DeviceColor: dm.deviceColorID,
      DeviceMemorySize: dm.deviceSizeDesc,
      IsBlacklisted: 0,
      IsExcluded: 0
    };
    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateIMEIAssociation, body);

  }
  UpdateAdminDashboardMobileDevice(dm: MobileDeviceModel): Observable<string> {

    let body = {

      MobileDeviceID: dm.mobileDeviceID,
      SerialNumber: dm.serialNumber,
      CarrierCode: dm.carrierCode,
      DeviceModelID: dm.deviceModelID,
      DeviceModel: dm.deviceModel,
      EmployeeAlias: localStorage.getItem('loggedInUserName').split('@')[0],
      // IsPendingReturn : 0,
      IsPendingReturn: dm.isPendingReturn,
      EmployeeComments: dm.employeeComments,
      //IsPersonnel :0,
      IsPersonnel: dm.isPersonnel,
      // IsPendingWipe :0,
      IsPendingWipe: dm.isPendingWipe,
      DeviceOrigin: dm.deviceOrigin,
      DeviceColor: dm.deviceColorID,
      DeviceMemorySize: dm.deviceSizeDesc,
      DeviceStatus: dm.deviceStatus,
      // IsBlacklisted:0,

      IsBlacklisted: dm.isBlacklisted,
      isExcludedDevice: dm.isExcludedDevice

    };

    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.UpdateAdminDashboardMobileDevice, body);

  }
  InsertAdminDashboardMobileDevice(dm: MobileDeviceModel): Observable<string> {
    // alert(JSON.stringify(dm));

    let body = {

      MobileDeviceID: dm.mobileDeviceID,
      SerialNumber: dm.serialNumber,
      CarrierCode: dm.carrierCode,
      DeviceModelID: dm.deviceModelID,
      DeviceModel: dm.deviceModel,
      EmployeeAlias: localStorage.getItem('loggedInUserName').split('@')[0],
      IsPendingReturn: dm.isPendingReturn,
      EmployeeComments: dm.employeeComments,
      IsPersonnel: dm.isPersonnel,
      IsPendingWipe: dm.isPendingWipe,
      DeviceOrigin: dm.deviceOrigin,
      DeviceColor: dm.deviceColorID,
      DeviceMemorySize: dm.deviceSizeDesc,
      IsBlacklisted: dm.isBlacklisted,
      DeviceStatus: dm.deviceStatus,
      isExcludedDevice: dm.isExcludedDevice
    };
    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertAdminDashboardMobileDevice, body);

  }


  GetAllAdminDashboardDeviceModelBinding(): Observable<any[]> {
    return this._http.getData<any[]>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.GetAllAdminDashboardDeviceModelBinding);
  }

  DeleteAdminDashboardCarrierAuditDetails(carrierAuditDetailsdelete): Observable<CarrierAuditDetails> {
    return this._http.getData<CarrierAuditDetails>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteAdminDashboardCarrierAuditDetails + '?CarrierCode=' + carrierAuditDetailsdelete.carrierCode + '&DeviceTypeId=' + carrierAuditDetailsdelete.deviceTypeId);
  }


  GetInventoryNotifyCarrierDetails(IMEINumber: string) {
    return this._http.getData<InventoryNotification>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.GetInventoryNotifyCarrierDetailsByIMEI + '?IMEINumber=' + IMEINumber
    );
  }
  InsertEmailToSchedule(obj: InsertEmailScheduleInventory) {
    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Inventory.InsertEmailToSchedule, obj)
      .pipe(map((data) => {
        return data;
      }
      ));
  }


  DeleteVipLeaderCompliance(personnelNbr: string): Observable<string> {
    let createdbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteVIPMobileexceptionCompliance + '?vipPersonalNbr=' + personnelNbr + '&LoggedInUser=' + createdbyAlias)
  }

  DeleteVipLeaderInventory(personnelNbr: string): Observable<string> {
    let createdbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteVIPMobileexceptionInventory + '?vipPersonalNbr=' + personnelNbr + '&LoggedInUser=' + createdbyAlias)
  }

  DeleteVipLeaderBilling(personnelNbr: string): Observable<string> {
    let createdbyAlias = localStorage.getItem('loggedInUserName').split('@')[0];
    return this._http.getData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.DeleteVIPMobileexceptionBilling + '?vipPersonalNbr=' + personnelNbr + '&LoggedInUser=' + createdbyAlias)
  }


  InsertUpdateVipLeaderInventory(bodytosend: any): Observable<string> {
    let body = bodytosend;
    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateVIPExceptionInventory, body);
  }
  InsertUpdateVipLeaderCompliance(bodytosend: any): Observable<string> {
    let body = bodytosend;
    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateVIPExceptionCompliance, body);
  }
  InsertUpdateVipLeaderBilling(bodytosend: any): Observable<string> {
    let body = bodytosend;
    return this._http.postData<string>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.InsertUpdateVIPExceptionBilling, body);
  }

  CheckAccountNumberExists(AccountNo, mtn): Observable<number> {
    return this._http.getData<number>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.CheckMobileAccountNo + '?AccountNo=' + AccountNo + '&mtn=' + mtn);
  }
  UpdateAdminDashboardRoleMenu(updateRoleMenu): Observable<number> {
    return this._http.postData<number>(this.apiurl + AppConstants.GPA_ENDPOINTS.Admin.UpdateAdminDashboardRoleMenu, updateRoleMenu);
  }

  GetAdminUserRolesHeader(): Observable<AdminUser[]> {
    return this._http.getData<AdminUser[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.GetAdminUserRolesHeader
    );
  }

  GetAllMenuRoles(): Observable<AllRoles[]> {
    return this._http.getData<AllRoles[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.GetAllMenuRoles
    );
  }
  InsertUpdateuserrole(insertUserRole: InsertUserRole): Observable<boolean> {
    return this._http.postData<boolean>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.Insertorupdateroleinfo, insertUserRole)
  }
  GetAdminUsertabledata(): Observable<AllUserRole[]> {
    return this._http.getData<AllUserRole[]>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.GetAdminUserRolesTableData
    );
  }

  DeleteUserRole(deleteUserRole: InsertUserRole): Observable<boolean> {
    return this._http.postData<boolean>(
      this.apiurl + AppConstants.GPA_ENDPOINTS.CoreService.DeleteUserRole, deleteUserRole)
  }
  InsertUpdateLaptopException(obj: InsertUpdateLaptopException): Observable<any> {
    return this._datacontextService.postData<any>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.InsertUpdateLaptopException, obj)
      .pipe(map(data => {
        return data;
      }));
  }
  GetLaptopBaselineSettings() {
      return this._datacontextService.getData<LaptopBaselineSettings[]>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.GetLaptopBaselineSettings).pipe(map(data => data));
  }
  UpdateLaptopBaselineSettings(obj: any ): Observable<any> {
    return this._datacontextService.postData<any>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.UpdateLaptopBaselineSettings ,obj ).pipe(map(data => data));
  }
  DeleteLaptopException(complianceOverrideID,deletedBy) {
    return this._datacontextService.getData<any>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.DeleteLaptopException + '?complianceOverrideID=' + complianceOverrideID+ '&deletedBy=' + deletedBy);
  } 
  GetAutoApprovalConfigSettings():Observable<AutoApprovalConfig[]> {
    return this._http.getData<AutoApprovalConfig[]>(this.apiurlv2 +AppConstants.GPA_ENDPOINTS.Admin.GetAutoApprovalConfigSettings);
  }

  GetEmployeeSubGroupCodes():Observable<EmployeeSubgroupCodes[]>{
    return this._http.getData<EmployeeSubgroupCodes[]>(this.apiurlv2 +AppConstants.GPA_ENDPOINTS.Admin.GetEmployeeSubGroupCodes);
  }

  GetExcludeEmpSubGroups():Observable<string>{
    let header = new HttpHeaders().set('responseType', 'text');
    return this._datacontextService.getPlainTextData(this.apiurlv2 +AppConstants.GPA_ENDPOINTS.Admin.GetExcludeEmpSubGroups);
  }

  SaveAutoApprovalConfigSettings(autoConfig:AutoApprovalConfig):Observable<boolean>{
    return this._http.postData<boolean>(this.apiurlv2 +AppConstants.GPA_ENDPOINTS.Admin.SaveAutoApprovalConfigSettings,autoConfig);
  }

  GetAutoApprovalSettingForCarrier(carrierCode: string):Observable<number>{
    return this._http.getData<number>(this.apiurlv2 +AppConstants.GPA_ENDPOINTS.Admin.GetAutoApprovalSettingForCarrier+'?CarrierCode='+carrierCode);
  }

  InsertExcludeEmpSubGrpToOrderSettings(excludeSubGroups:ExcludeSubGroups):Observable<boolean>{
    return this._http.postData<boolean>(this.apiurlv2 +AppConstants.GPA_ENDPOINTS.Admin.InsertExcludeEmpSubGrpToOrderSettings,excludeSubGroups);
  }
  DeleteJAMFVersion(agentVersionId, deletedBy) {
    return this._datacontextService.getData<any>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.DeleteJAMFVersion + '?agentVersionId=' + agentVersionId + '&modifiedBy=' + deletedBy);
  }
  DeleteCrowdStrikeVersion(agentVersionId, deletedBy) {
    return this._datacontextService.post<any>(this.apiurlv2 + AppConstants.GPA_ENDPOINTS.Laptop.DeleteCrowdStrikeVersion + '?agentVersionId=' + agentVersionId + '&modifiedBy=' + deletedBy);
  }
  DeleteODCJAMFVersion(agentVersionId, deletedBy) {
    return this._datacontextService.post<any>(`${this.apiurlv2}${AppConstants.GPA_ENDPOINTS.Laptop.DeleteODCJAMFVersion}?agentVersionId=${agentVersionId}&modifiedBy=${deletedBy}`);
  }
  DeleteODCCrowdStrikeVersion(agentVersionId, deletedBy) {
    return this._datacontextService.post<any>(`${this.apiurlv2}${AppConstants.GPA_ENDPOINTS.Laptop.DeleteODCCrowdStrikeVersion}?agentVersionId=${agentVersionId}&modifiedBy=${deletedBy}`);
  }
  GetODCNames(){
    return this._datacontextService.getData<ODCName[]>(`${this.apiurlv2}${AppConstants.GPA_ENDPOINTS.Laptop.GetODCNames}`);
  }

  GetODCLaptopBaselineSettings(odcId: number) {
    return this._datacontextService.getData<LaptopBaselineSettings[]>(`${this.apiurlv2}${AppConstants.GPA_ENDPOINTS.Laptop.GetODCLaptopBaselineSettings}?odcId=${odcId}`).pipe(map(data => data));
  }

  UpdateODCLaptopBaselineSettings(odcId: number, obj: any): Observable<any> {
    return this._datacontextService.postData<any>(`${this.apiurlv2}${AppConstants.GPA_ENDPOINTS.Laptop.UpdateODCLaptopBaselineSettings}?odcId=${odcId}`,obj).pipe(map(data => data));
  }
}

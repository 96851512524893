import { Component, OnInit, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import $ from 'jquery';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoggedInUserSubModules } from 'src/app/_models/admin/LoggedInSubModules.model';
import { LoggedInUserModules } from 'src/app/_models/getLoggeinUserModules';
import { ReportGeneratingStatus } from 'src/app/_models/reportGeneratingStatus.mdel';
import { AdminService } from 'src/app/_services/admin/admin.service';
import { FiltersMenuService } from 'src/app/_services/compliance/filters-menu.service';
import { LoggedInUserModulesService } from 'src/app/_services/login.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { UploadActivityService } from 'src/app/_services/uploadactivitylog.service';
import { GlobalVariables } from 'src/app/_utils/global-variables';

@Component({
  selector: 'app-side-nav-comp',
  templateUrl: './side-nav-comp.component.html',
  styleUrls: ['./side-nav-comp.component.scss']
})
export class SideNavCompComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() emitShowEvent = new EventEmitter<any>();
  @Output() emitHideEvent = new EventEmitter<any>();
  showCloseButton: boolean = false;
  showText: boolean = false;
  showCloseButtonFilter: boolean;
  showFiltersPanel: boolean;
  flag: boolean;
  reportFlag: boolean = false;
  UAFlag: boolean = false;
  compressButton: boolean;
  list: any[];
  loggedInUserModules: LoggedInUserModules[];
  loggedInUserSubModules: LoggedInUserSubModules[];
  userRole = localStorage.getItem('UserRole');
  reportStatusResultSet: ReportGeneratingStatus[];
  uploadactivityDetails = [];
  subModuleCount: number = 0;
  laptopComplianceDiv: boolean;
  laptopInventoryDiv: boolean;
  showAdminSettingsDiv: boolean;
  showSideNavComponent: boolean = false;
  mbZero: boolean = true;
  mbFirst: boolean = true;
  private readonly _destroying$ = new Subject<void>();
  // table_col = 'col-md-8'
  //loggedInUserNameVal: string = localStorage.getItem('loggedInUserName').split('@')[0];

  constructor(private loggedInUserModulesService: LoggedInUserModulesService, private adminService: AdminService, private router: Router, private reportsService: ReportsService,
    private uploadactivityService: UploadActivityService, private filtersMenuService: FiltersMenuService) {

  }

  ngOnInit() {
    
    combineLatest([this.loggedInUserModulesService.isAuthenticated$,this.loggedInUserModulesService.isAuthorized$]).pipe(takeUntil(this._destroying$)).subscribe(([authenticated, authorized]) => {
      this.showSideNavComponent = (authenticated && authorized);
      if (authenticated) {
        this.loggedInUserModulesService.GetLoggedInUserModules(GlobalVariables.loggedInUserName).subscribe((data) => {  
          if (data.length > 0) {
            this.adminService.GetLoggedInUserSubModules(GlobalVariables.loggedInUserName, 'Admin Settings').subscribe((x) => {
              this.subModuleCount = x.length;
              this.loggedInUserSubModules = x;
              //$('#mb_0_id').removeClass('mb-0');
              //$('#mb_0_id').addClass('mb-0-data');  
              //$('#mb_0_id1').removeClass('mb-0');
              //$('#mb_0_id1').addClass('mb-0-data');
              this.mbZero = false;
              this.mbFirst = false;
              if (this.loggedInUserModules.some(person => person.displayName === "Admin Settings")) {
                let index = this.loggedInUserModules.findIndex(x => x.displayName === "Admin Settings");
                this.loggedInUserModules.splice(index, 1);
                this.showAdminSettingsDiv = true;
              }
              if (this.subModuleCount == 0) {
                this.showAdminSettingsDiv = false;
                // if (this.loggedInUserModules.some(person => person.displayName === "Admin Settings")) {
                //   let index = this.loggedInUserModules.findIndex(x => x.displayName === "Admin Settings");
                //   this.loggedInUserModules.splice(index, 1);
  
                // }
              } else {
                localStorage.setItem('loggedInUserSubModules', JSON.stringify(this.loggedInUserSubModules));
              }
            });
            
            this.loggedInUserModules = data;
            localStorage.setItem('loggedInUserModules', JSON.stringify(this.loggedInUserModules));
            if (this.loggedInUserModules.some(person => person.displayName === "Reports")) {
              let index = this.loggedInUserModules.findIndex(x => x.displayName === "Reports");
              this.loggedInUserModules.splice(index, 1);
              this.reportFlag = true;

            }
            if (this.loggedInUserModules.some(person => person.displayName === "Upload Activity Log")) {
              let index = this.loggedInUserModules.findIndex(x => x.displayName === "Upload Activity Log");
              this.loggedInUserModules.splice(index, 1);
              this.UAFlag = true;

            }
            if (this.loggedInUserModules.some(person => person.displayName === "Laptop Compliance")) {
              let index = this.loggedInUserModules.findIndex(x => x.displayName === "Laptop Compliance");
              this.loggedInUserModules.splice(index, 1);
              this.laptopComplianceDiv = true;
            }
            if (this.loggedInUserModules.some(person => person.displayName === "Laptop Inventory")) {
              let index = this.loggedInUserModules.findIndex(x => x.displayName === "Laptop Inventory");
              this.loggedInUserModules.splice(index, 1);
              this.laptopInventoryDiv = true;
            }
            console.log('this.loggedInUserModules', this.loggedInUserModules);
          }
          // else {
          //   $('#header_component_hide').hide();
          //   $('#sidenav_component_hide').hide();
          //   this.router.navigate(['unauthorized']);

          // }

          // window.location.reload(true);
        });
        this.loggedInUserModulesService.GetLoggedInUser(GlobalVariables.loggedInUserName).subscribe((data) => {
          if (data == null || data == undefined) {

            this.loggedInUserModulesService.Unauthorized('', true);

          }
          else if (data != null && data.roleName == 'CallCenter') {
            // $('#sidenav_component_hide').hide();
          }
          else if (data != null && data.roleName != 'SysAdmin' && data.roleName != 'NMC') {
            this.userRole = data.roleName;
            // this.flag = true;
          }
          else {
            this.userRole = data.roleName;
          }

        });
      } else {
        //this.router.navigate(['unauthorized']);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngAfterViewInit() {
    // $('#mb_0_id').removeClass('mb-0');
    // $('#mb_0_id').addClass('mb-0-data');
    // $('#mb_0_id1').removeClass('mb-0');
    // $('#mb_0_id1').addClass('mb-0-data');
  }
  selectedModuleName(event) {
    console.log('selected module name', event);
    this.filtersMenuService.selectedMainModuleName = event;

  }
  get result() {
    return this.list.filter(item => item.checked);
  }
  openNav(event) {
    //$('#mb_0_id').addClass('mb-0');
    //$('#mb_0_id').removeClass('mb-0-data');
    //$('#mb_0_id1').addClass('mb-0');
    //$('#mb_0_id1').removeClass('mb-0-data');
    this.mbZero = true;
    this.mbFirst = true;
    this.emitShowEvent.emit(event);
    this.compressButton = true;
    this.showText = true;
    this.showCloseButton = true;
  }

  closeNav(event) {
    //$('#mb_0_id').removeClass('mb-0');
    //$('#mb_0_id').addClass('mb-0-data');
    //$('#mb_0_id1').removeClass('mb-0');
    //$('#mb_0_id1').addClass('mb-0-data');
    this.mbZero = false;
    this.mbFirst = false;
    // this.table_col = 'col-md-8';
    this.emitHideEvent.emit(event);
    this.compressButton = false;
    this.showText = false;
    this.showCloseButton = false;
  }
  closeNavFilter() {
    // this.table_col = 'col-md-8';
    this.showFiltersPanel = true;

  }
  filtersForOrders() {
    // this.table_col = 'col-md-10';
    this.showFiltersPanel = false;
  }
  reportsDetails() {
    $("#generate_Report").attr("disabled", true);
    $('#myModalreports').insertAfter($('body'));
    $('#dataTargetReportDetail').attr('data-target', '#myModalreports');
    $('#reportActive').addClass('active');
    this.getResultSetForGenerateReport();
  }
  getResultSetForGenerateReport() {
    let response1 = this.reportsService.GetReportGeneratingStatus(GlobalVariables.loggedInUserName);
    let response2 = this.reportsService.GetReportGeneratingStatusOrder(GlobalVariables.loggedInUserName);
    let response3 = this.reportsService.GetReportGeneratingStatusCore(GlobalVariables.loggedInUserName);
    let response4 = this.reportsService.GetReportGeneratingStatusComp(GlobalVariables.loggedInUserName);
    forkJoin([response1, response2, response3, response4]).subscribe((data) => {
      let data3 = [...data[0], ...data[1], ...data[2], ...data[3]];
      this.reportStatusResultSet = data3;
      this.reportsService.reportData.next(data3.sort(function (a, b) {
        let dateA = +new Date(a.reportReuestedDate), dateB = +new Date(b.reportReuestedDate)
        return dateB - dateA
      }
      ));
      // console.log('result set from side-nav', this.reportStatusResultSet);
    });
  }

  uploadDetails() {
    $('#uploadactivitymodal').insertAfter($('body'));
    $('#uploadactivitymodall').attr('data-target', '#uploadactivitymodal');
    $('#uploadactiveModel').addClass('active');
    this.GetUploadActivityDetails();
  }

  GetUploadActivityDetails() {
    let response1 = this.uploadactivityService.getUploadActivityDetailsInv();
    let response2 = this.uploadactivityService.getUploadActivityDetailsOrders();
    let response3 = this.uploadactivityService.getUploadActivityDetailsCore();
    let response4 = this.uploadactivityService.getUploadActivityDetailsBill();
    let response5 = this.uploadactivityService.getUploadActivityDetailsCompliance();
    forkJoin([response1, response2, response3, response4, response5]).subscribe((data) => {
      let data5 = [...data[0], ...data[1], ...data[2], ...data[3], ...data[4]];
      this.uploadactivityDetails = data5;
      // console.log('side nav ' + this.uploadactivityDetails);
      this.uploadactivityService.uploadActivityLogData.next(data5);
    });
  }
}



 <div *ngIf="isPageLoad"
 class="col-md-4" style="margin-left:-30px;cursor: pointer;">
 <div (click)="backtoSearch()">
     <img src="assets/images/collapse arrows.svg"><span class="hidedata-text"
         style="font-size:12px;color: #212529;">Back</span>
 </div>
</div>



<div *ngIf="isPageLoad">
    <div class="mtntable">
        <div class="row">
            <div class="col-md-8 order-tile-details">
                <p class="compliance-text">Orders: {{totalnumber}}</p>
            </div>


        </div>
        <div class="row search-filter">

            <div class="pane pane--table2">
                <table class="table-style">

                    <thead>


                        <tr>
                            <th style="min-width:130px;">
                                Device</th>
                            <th style="min-width:180px;">
                                Order No</th>
                            <th style="min-width:125px;">
                                Mobile No</th>
                            <th style="min-width:125px;">
                                Carrier</th>
                            <th style="min-width:200px;">
                                Tracking No</th>
                            <th style="min-width:130px;">Created date
                            </th>
                            <th style="min-width:130px;">Order status</th>

                        </tr>
                    </thead>
                    <!-- <tbody *ngIf="isGridDataNotAvailable">
                        <tr>
                            <td colspan="15" style="text-align: center">
                                <p class="compliance-text">No history found!</p>
                            </td>
                        </tr>
                    </tbody> -->
                    <tbody>
                        <tr *ngFor="let row of orderDetails;let i=index">
                            <td class="t-{{i}}" style="white-space: nowrap;
                            width: 130px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;cursor: pointer;" data-toggle="tooltip" title="{{row.deviceModel}}">
                                {{ row.deviceModel }}</td>

                            <td style="min-width:180px;" copyToClipboard copiedString="{{row.orderNbr}}">
                                {{row.orderNbr.length>0?row.orderNbr:'--'}}</td>
                            <td style="min-width:125px;" copyToClipboard copiedString="{{row.mobileTelephoneNbr}}">
                                {{row.mobileTelephoneNbr.length>0?row.mobileTelephoneNbr:'--'}}</td>
                            <td style="min-width:125px;">
                                <span data-toggle="tooltip"
                                data-placement="top" title="{{row.carrierCode.length > 0 ? row.carrierCode : '--'}}" 
                                style="white-space: nowrap;
                                width: 100px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: block;cursor: pointer;">{{row.carrierCode.length>0?row.carrierCode:'--'}}</span>
                            </td>
                            <td style="white-space: nowrap;
                            width: 200px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;cursor: pointer;"> <span data-toggle="tooltip"
                                data-placement="top" title="{{row.trackingNbr.length>0?row.trackingNbr:'--'}}">
                                {{row.trackingNbr.length>0?row.trackingNbr:'--'}}</span></td>
                            <td style="min-width:130px;">
                                {{(row.orderCreateDate != '0001-01-01T00:00:00') ? (row.orderCreateDate | date) : '--' }}
                            </td>
                            <td style="min-width:130px;">
                                {{row.orderStatus.length>0?row.orderStatus:'--'}}</td>
                        </tr>
                        <tr>


                    </tbody>
                </table>
            </div>

        </div>
    </div>


</div>
import { Component, Input, OnInit } from '@angular/core';
import { CoreEmployeeView } from 'src/app/_models/employee-profile/CoreEmployeeView';
import { LaptopNotificationResponse } from 'src/app/_models/laptop-compliance/laptopNotification.model';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
import { LaptopComplianceService } from 'src/app/_services/laptop-compliance/laptop-compliance.service';
declare var _satellite: any;
declare var window: any;
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  // @Input() notificationDetails: Notifications[];
  @Input() notificationDetails: any = [];
  @Input() employeeDetails:CoreEmployeeView;
  @Input() laptopNotificationsData: any;
  // notificationDetails = [];
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];

  constructor(private _empService: EmpProfileService, private laptopComplianceService: LaptopComplianceService,) { }

  ngOnInit() {
  // this.fn_DatalayerPageLoad();
  }
  ngOnChanges() {
  }
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee Notifications Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

}

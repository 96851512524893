<div class="row">
    <!-- <div>
        <app-side-nav (emitShowEvent)="openSideNav($event)" (emitHideEvent)="closeSideNav($event)"></app-side-nav>
    </div> -->
    <my-spinner></my-spinner>
    <div [ngClass]="sidenav_col" class="side-common-nav" [style.width.px]="showWidthInPx ? '60' : ''">
        <!-- <app-sidecommon-nav (emitShowEvent)="openSideNav($event)" (emitHideEvent)="closeSideNav($event)"></app-sidecommon-nav> -->
        <app-side-nav-comp (emitShowEvent)="openSideNav($event)" (emitHideEvent)="closeSideNav($event)"></app-side-nav-comp>
    </div>
    <div [ngClass]="router_col" class="mtop-8">
        <router-outlet></router-outlet>
        <div class="cookies-footer" *ngIf="showFooter" [ngClass]="!showWidthInPx ? router_col :' '" 
        [ngStyle]="!showWidthInPx ? {'margin-right': '0'}:{'width': 'calc(100% - 45px)'}">
            <a class="" target="_blank" href="https://cookienotice.deloitte.com">Cookies</a>
        </div>
    </div>
</div>
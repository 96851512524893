<section class="side-common-nav-comp" id="sidenav_component_hide" *ngIf="showSideNavComponent">

        <div class="row" class="sidebar">
                <div [ngClass]="showCloseButton ? 'test':'test1'">
                        <div class="accordion" id="accordionExample">
                                <div class="card" *ngIf="loggedInUserModules?.length>0 || UAFlag">
                                        <div (mouseover)="openNav($event)" class="card-head" id="headingOne">
                                                <h2 [ngClass]="mbZero ? 'mb-0' : 'mb-0-data'" id="mb_0_id">
                                                        <a role="button" data-toggle="collapse"
                                                                data-target="#collapseOne" aria-expanded="true"
                                                                aria-controls="collapseOne">
                                                                <span class="icon-Mobile_phone_POS">
                                                                        <p class="showtextForMobile" *ngIf="showText">
                                                                                Mobile</p>
                                                                </span>
                                                        </a>
                                                </h2>
                                        </div>
                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                                data-parent="#accordionExample">
                                                <div class="card-body">
                                                        <a routerLinkActive="active"
                                                                *ngFor="let userModules of loggedInUserModules"
                                                                routerLink="{{userModules.navigationURL}}">
                                                                <div (mouseover)="openNav($event)">
                                                                        <div class="active-item">
                                                                                <span *ngIf="userModules.displayName == 'Orders'"
                                                                                        class="icon-Clipboard_POS"></span>
                                                                                <span *ngIf="userModules.displayName == 'Compliance'"
                                                                                        class="icon-Bookmark_POS"></span>
                                                                                <span *ngIf="userModules.displayName == 'Delinquencies'"
                                                                                        class="icon-Gavel_POS"></span>
                                                                                <span *ngIf="userModules.displayName == 'Inventory'"
                                                                                        class="icon-Bullseye_target_goal_POS"></span>
                                                                                <span *ngIf="userModules.displayName == 'Reconciliation & Shipment'"
                                                                                        class="icon-File_edit_POS"></span>
                                                                                <span *ngIf="userModules.displayName == 'Monthly Bill Review'"
                                                                                        class="icon-Calender_POS"></span>
                                                                                <span *ngIf="userModules.displayName == 'Admin Settings'"
                                                                                        class="icon-Cog_settings_POS"></span>
                                                                                <span *ngIf="userModules.displayName == 'Terms and Cancellation'"
                                                                                        class="icon-No_entry_POS"></span>
                                                                                <span class="sidenav-text"
                                                                                        (click)="selectedModuleName(userModules.displayName)">
                                                                                        <p class="showtext"
                                                                                                *ngIf="showText">
                                                                                                {{userModules.displayName}}
                                                                                        </p>
                                                                                </span>
                                                                        </div>
                                                                </div>
                                                        </a>
                                                        <a id="uploadactiveModel" *ngIf="UAFlag">
                                                                <div class="row reports-data"
                                                                        style="cursor: pointer;margin-left: 0;"
                                                                        id="uploadactivitymodall" data-toggle="modal"
                                                                        (click)="uploadDetails()"
                                                                        data-backdrop="static" data-keyboard="false">
                                                                        <div class="active-item"
                                                                                (mouseover)="openNav($event)">
                                                                                <img class="report-img" style="margin: -10px 0px 0 0 !important;
                    height: 28px !important;" src="assets/images/uploadact.png" /><span class="sidenav-text">
                                                                                        <p class="showtext" *ngIf="showText">
                                                                                                Upload Activity Log</p>
                                                                                        <div>
                                                                                                <div style="position: relative;">
                                                                                                        <app-upload-activity-log>
                                                                                                        </app-upload-activity-log>
                                                                                                </div>
                                                                                        </div>
                                                                                </span>
                                                                        </div>
                                                                </div>
                                                        </a>
                                                </div>
                                        </div>
                                </div>
                                <div class="card" *ngIf="laptopInventoryDiv || laptopComplianceDiv">
                                        <div (mouseover)="openNav($event)" class="card-head" id="headingTwo">
                                                <h2 [ngClass]="mbFirst ? 'mb-0' : 'mb-0-data'" id="mb_0_id1">
                                                        <a class="collapsed" role="button" data-toggle="collapse"
                                                                data-target="#collapseTwoForLaptopSideNav"
                                                                aria-expanded="false"
                                                                aria-controls="collapseTwoForLaptopSideNav">
                                                                <span class="icon-Laptop_monitor_display_POS">
                                                                        <p class="showtextForMobile" *ngIf="showText">
                                                                                Laptop</p>
                                                                </span>
                                                        </a>
                                                </h2>
                                        </div>
                                        <div id="collapseTwoForLaptopSideNav" class="collapse"
                                                aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                <div class="card-body">
                                                        <a routerLinkActive="active" routerLink="/laptop-compliance"
                                                                *ngIf="laptopComplianceDiv"
                                                                (click)="selectedModuleName('LaptopCompliance')">
                                                                <div class="row" style="margin-left: 0px;">
                                                                        <div class="active-item">
                                                                                <span
                                                                                        class="icon-Bookmark_POS"></span><span
                                                                                        class="sidenav-text">
                                                                                        <p class="showtext"
                                                                                                *ngIf="showText">
                                                                                                Compliance
                                                                                        </p>
                                                                                </span>
                                                                        </div>
                                                                </div>
                                                        </a>
                                                        <a routerLinkActive="active" routerLink="/laptop-inventory"
                                                                *ngIf="laptopInventoryDiv"
                                                                (click)="selectedModuleName('LaptopInventory')">
                                                                <div class="row" style="margin-left: 0px;">
                                                                        <div class="active-item">
                                                                                <span
                                                                                        class="icon-Bullseye_target_goal_POS"></span><span
                                                                                        class="sidenav-text">
                                                                                        <p class="showtext"
                                                                                                *ngIf="showText">
                                                                                                Inventory
                                                                                        </p>
                                                                                </span>
                                                                        </div>
                                                                </div>
                                                        </a>
                                                </div>
                                        </div>
                                </div>
                                <a id="reportActive" *ngIf="reportFlag">
                                        <div class="row reports-data" style="cursor: pointer;margin-left: 0;"
                                                id="dataTargetReportDetail" data-toggle="modal"
                                                (click)="reportsDetails()" data-backdrop="static"
                                                data-keyboard="false">
                                                <div class="active-item" (mouseover)="openNav($event)">
                                                        <img class="report-img" style="margin: -7px 0px 0px -2px !important;;
height: 30px !important;"  src="assets/images/Picture_report.png" /><span class="sidenav-text">
                                                                <p class="showtextForMobile" *ngIf="showText">
                                                                        Reports</p>
                                                                <div>
                                                                        <div style="position: relative;">
                                                                                <app-reports>
                                                                                </app-reports>
                                                                        </div>
                                                                </div>
                                                        </span>
                                                </div>
                                        </div>
                                </a>
                                <a (mouseover)="openNav($event)" routerLinkActive="active" *ngIf="showAdminSettingsDiv"
                                        routerLink="/admin">
                                        <div class="row" style="margin-left: 0px;">
                                                <div class="active-item">
                                                        <span class="icon-Cog_settings_POS"></span><span
                                                                class="sidenav-text">
                                                                <p class="showtextForMobile" *ngIf="showText">
                                                                        Admin Settings
                                                                </p>
                                                        </span>
                                                </div>
                                        </div>
                                </a>
                        </div>
                </div>
        </div>
        <div class="expand-collapse-icons" [ngClass]="showCloseButton ? 'test':'test1'">
                <span *ngIf="!compressButton" (click)="openNav($event)">
                        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </span>

                <span *ngIf="compressButton" (click)="closeNav($event)">
                        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                </span>
        </div>
</section>
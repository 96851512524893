import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LaptopInventoryDetails } from 'src/app/_models/employee-profile/laptop-invdetails.model';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';

@Component({
  selector: 'app-laptop-empinventory',
  templateUrl: './laptop-empinventory.component.html',
  styleUrls: ['./laptop-empinventory.component.scss']
})
export class LaptopEmpinventoryComponent implements OnInit {

  @Output() backToTails = new EventEmitter<any>();
  @Input() personnelNumber: string;
  inventoryDetailsForLaptop: LaptopInventoryDetails[];
  inventoryDetailsForLaptopCurrentDevices: LaptopInventoryDetails[];
  inventoryDetailsForLaptopPreviousDevices: LaptopInventoryDetails[];
  constructor(private _empService: EmpProfileService) { }

  ngOnInit() {
    this.getLaptopInventoryDetails(this.personnelNumber);
  }
  backtoSearch() {
    this.backToTails.emit();
  }
  getLaptopInventoryDetails(personnelNumber) {
    this._empService.getLaptopInventoryDetailsByPersonnelNbr(personnelNumber).subscribe((data) => {
      this.inventoryDetailsForLaptop = data;
      this.inventoryDetailsForLaptopCurrentDevices = this.inventoryDetailsForLaptop.filter(x => x.isCurrentDevice == 1);
      this.inventoryDetailsForLaptopPreviousDevices = this.inventoryDetailsForLaptop.filter(x => x.isCurrentDevice == 0);
      console.log('laptop-emp inventory details',this.inventoryDetailsForLaptop);
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataContextService } from './datacontext.service';
import { EmployeeDetails, PeoplePicker } from '../_models/peoplePicker.model';

@Injectable({
  providedIn: 'root'
})
export class PeoplePickerService {
  apiurl: string = localStorage.getItem('apiurl');
  baseUrl = this.apiurl;//environment.appEndpoints.CoreApi;
  backTOSearch : BehaviorSubject<any>;
  constructor(private http: DataContextService) { 
    this.backTOSearch = new BehaviorSubject(null);
  }
  getPeoplePickerData(SearchParam) {
    return this.http
      .getData<PeoplePicker[]>(`${this.baseUrl}CoreService/GetEmployeeSearchForPeoplePicker?SearchParam=` + SearchParam)
      .pipe(map(data => data));
  }
  getEmployeeDetails(SearchPersonnelNbr) {
    return this.http.getData<EmployeeDetails[]>(`${this.baseUrl}CoreService/GetEmployeeDetails?SearchPersonnelNbr=` + SearchPersonnelNbr)
      .pipe(map(data => data));
  }
  GetUserTicketsListByPersonalOrTicketNumber(alias) {
    return this.http.getData<any[]>(`${this.baseUrl}CoreService/GetUserTicketsListByPersonalOrTicketNumber?personalOrTicketNumber=` + alias)
      .pipe(map(data => data));
  }
  // GetLoggedInUser(alias) {
  //   return this.http.get<any>(`${this.baseUrl}/CoreService/GetLoggedInUser?useralias=` + alias)
  //     .pipe(map(data => data));
  // }
}

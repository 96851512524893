import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedInUserModulesService } from 'src/app/_services/login.service';
import { PeoplePickerService } from 'src/app/_services/people-picker.service';
import {takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppConfig } from 'src/app/_utils/app-config/app-config.service';
import $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  loggedInUserName: any = localStorage.getItem('loggedInUserName');
  displayName: string = '';
  loggedInUser: string = '';
  showHeader: boolean= false;
  environmentName: string;
  private readonly _destroying$ = new Subject<void>();
  constructor(private router: Router,private loginsvc : LoggedInUserModulesService,  public service: PeoplePickerService) { 
    
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit(): void {
    this.loginsvc.isAuthorized$.pipe(takeUntil(this._destroying$)).subscribe((data:any) => {
      this.showHeader = data;
    })
    if(this.loggedInUserName != ""  || this.loggedInUserName != null){
      let username = localStorage.getItem('loggedInUserName').split('@')[0];
      this.loginsvc.GetLoggedInUser(username).subscribe((data) => {

        if (data.roleID == 0) {
         
          this.loginsvc.Unauthorized('', true);

        } else if (data != null && data.roleName == 'CallCenter') {
          
          $('#reportActive').hide();
          localStorage.setItem('UserRole', data.roleName);

        } else {
          this.displayName = `${data.lastName}, ${data.firstName}`;
          localStorage.setItem('UserRole', data.roleName);
          this.environmentName = AppConfig.settings.env.displayName;
        }
        
      });
    }
  }

  goToHome(){
    const isLocation = window.location.pathname === '/';
    this.service.backTOSearch.next(isLocation)
    this.router.navigate(['']);
  }

}

<div *ngIf="isPageLoad" class="col-md-4" style="margin-left:-30px;cursor: pointer;">
    <div (click)="backtoSearch()">
        <img src="assets/images/collapse arrows.svg"><span class="hidedata-text"
            style="font-size:12px;color: #212529;">Back</span>
    </div>
</div>

<div *ngIf="isPageLoad" class="mtntable">
    <div class="row">
        <div class="col-md-8 intune-padding">
            <p class="compliance-text">Violations: {{totalnumber}}</p>
        </div>
    </div>
    <div class="row search-filter">

        <div class="pane pane--table2">
            <table class="table-style">
                <thead>
                    <tr class="tabrow">
                        <th style="min-width: 200px;">Violation</th>
                        <th style="min-width: 120px;">Since</th>
                        <th style="min-width: 150px;">Exception Status</th>
                        <th style="min-width: 205px;">Date of Exception Granted</th>
                        <th style="min-width: 135px;">Expiration Date</th>
                        <th style="min-width: 300px;">Added By</th>
                        <th style="min-width: 300px;">Comments</th>
                    </tr>
                </thead>
                <tbody *ngIf="totalnumber>0">
                    <tr *ngFor="let row of violationDetails ;let i=index">
                        <td class="t-{{i}}" style="white-space: nowrap;
                                    width: 200px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: block;cursor: pointer;" data-toggle="tooltip"
                            title="{{row.complianceRuleDesc}}">{{ row.complianceRuleDesc }}</td>

                        <td style="min-width: 120px;">
                            {{(row.dateCreated != '0001-01-01T00:00:00') ? (row.dateCreated | date) : '--' }}</td>


                        <!-- <td [attr.colspan]="3" class="textAlign" *ngIf="row.hasOverride !== 0">
                                test
                                <table class="test ">
                                    <tr>
                                        <td  style="min-width: 100px;">{{row.hasOverride ? 'Yes':'No'}}</td>

                                        <td *ngIf="row.hasOverride !== 0" style="min-width: 205px;">
                                            {{(row.dateCreated !== '0001-01-01T00:00:00') ? (row.dateCreated  | date) : '--' }}</td>
                                        <td *ngIf="row.hasOverride !== 0" style="min-width: 130px;">{{(row.dateEnd !== '0001-01-01T00:00:00') ? (row.dateEnd   | date) : '--' }}</td>
                                        <td *ngIf="row.hasOverride !== 0" style="min-width: 80px;">{{row.createdBy}}</td>
                                    </tr>
                               
                                    <tr>
                                        <td [attr.colspan]="4" style="width:410px">
                                            {{row.overrideComment}}
                                        </td>
                                    </tr>
                                </table>
                            </td> -->

                        <!-- <td >
                                <table class="table-style-inner" >
                                    <tr> -->
                        <td style="min-width: 150px;">{{row.hasOverride == 1 ? 'Yet to Expire': row.hasOverride == 2 ? 'Expired' : row.hasOverride == 3 ? 'Deleted' : 'No'}}</td>
                        <td style="min-width: 205px;" *ngIf="row.hasOverride === 0">--</td>
                        <td style="min-width: 205px;" *ngIf="row.hasOverride !== 0">
                            {{(row.dateBegin !== '0001-01-01T00:00:00') ? (row.dateBegin  | date) : '--' }}</td>
                        <td style="min-width: 135px;">
                            {{(row.dateEnd !== '0001-01-01T00:00:00') ? (row.dateEnd   | date) : '--' }}</td>
                        <td style="white-space: nowrap;
                                        width: 300px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: block;cursor: pointer;" *ngIf="row.createdBy.length>0"><a
                                data-toggle="tooltip" data-placement="top" title="{{row.createdBy}}"
                                click="showEmployeeProfile(row.personnelNbr)">{{row.createdBy}}</a></td>
                        <td style="min-width: 300px;" *ngIf="row.createdBy.length === 0">--</td>
                        <!-- <td style="min-width: 300px;" >{{row.overrideComment}}</td> -->

                        <td style="min-width: 300px;overflow: hidden;
                                        text-overflow: ellipsis;
                                       cursor: pointer;">
                            <a data-toggle="tooltip" data-placement="top"
                                title="{{row.overrideComment}}">{{row.overrideComment.length > 0 ? row.overrideComment : '--'}}</a>
                        </td>
                        <!-- </tr>
                                </table>
                            </td> -->



                    </tr>
                </tbody>
                <tfoot *ngIf="totalnumber === 0">
                    <tr>
                        <td colspan="15" style="text-align: center;">
                            <b>
                                <p class="no-data">No Data Available</p>
                            </b>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
<app-employee-profile *ngIf="employeeinformation" [personnelNumber]="createdByPersonnelNo"></app-employee-profile>
import { Injectable } from '@angular/core';

@Injectable()
export class JwtService {
    constructor() { }

    public urlBase64Decode(str: string): string {
        let output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                throw new Error('Illegal base64url string!');
        }

        try {
            return this.b64DecodeUnicode(output);
        } catch (err) {
            return atob(output);
        }
    }

    private b64DecodeUnicode(str: any) {
        return decodeURIComponent(
            atob(str).replace(/(.)/g, (m, p) => {
                let code = p
                    .charCodeAt(0)
                    .toString(16)
                    .toUpperCase();
                if (code.length < 2) {
                    code = '0' + code;
                }
                return '%' + code;
            })
        );
    }

    public decodeToken(token: string): any {
        if (token === null) {
            return null;
        }

        const parts = token.split('.');

        if (parts.length !== 3) {
            throw new Error(
                'The inspected token does not appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more.'
            );
        }

        const decoded = this.urlBase64Decode(parts[1]);
        if (!decoded) {
            throw new Error('Cannot decode the token.');
        }

        return JSON.parse(decoded);
    }

    /**
   * Checks if JWT Token Expired
   *  @param threshold Threshold in `seconds`
   */
    public isTokenExpired(token: string, threshold = 5): boolean {
        const payload = this.decodeToken(token);
        if (!payload || !payload.exp) {
            return false;
        }
        const instant = Math.floor(new Date().getTime() / 1000);
        return payload.exp < instant + threshold;
    }
}

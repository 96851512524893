import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoggedInUserModulesService } from 'src/app/_services/login.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {
  openSideNavEvent: any;
  closeSideNavEvent: any;
  router_col = 'col-lg-11 col-md-11 col-sm-11 col-xs-11';
  sidenav_col = '';
  showWidthInPx: boolean = true;
  showFooter: boolean= false;
  employees: any[] = [
    { ID: 1, Name: 'Ramakanta', Gender: 'Male', IsActive: true, Image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH8AAAB/CAMAAADxY+0hAAAAYFBMVEX///9KwES35LVEvz5wy2xHv0E9vTY4vDBBvjr4/fhgx1vk9OPt+e3I6sb1+/Xp9+id25pZxFSp3qe/575oyWTQ7c8yvClSw0yH0oOi3KCY2ZWS14+D03/U7tOw4a52zXL9Wb07AAADSUlEQVRoge2af5OCIBCGI1wk/H1mVl7d9/+Wp3dSVIBAKtMM7z8316gPLMuyLGw2QUFBQUEmumz1uiyLbxDFOlHULInPMCC9AGcL4uMpfN+AeLEGTPd+UQtkyATfNwAt0gCz3i9mgYyY4vsGkNkbYN77RSxgOva3BszrAxnY4fsGgGsD0mv3Ez3JyvhjA/DzR366azpJT74P5CWq2tP/W/AscvhO9PhtS51YpqLtVoe/UreumgvoVY1vDgvTBx2UK2RuOcncBChX8L/ICniEyJei+3u8Ch9HcgMU7Rrm7wegLaT8ep3u9waopfztanx5DAj8wDcXYEpIv1zA8MduhXyf3y/J7HjKkqQoiiTJTkcGFk14l49jVl4eU4n0cq5i4/ff4kO8z3aS13dZZLA9eptPmDqxy5jRCvYGH2ipWj0H5aVJAuPOx5U8dN9VVwZfceVTJl+5RBVsMoN05VP24ne79PWnyQY48vEjPm/KqGpRW0Vl8+ATOzb1ISc+VKLxkzOLCYZBmPTxQMzri0rvhG58Krhe2sHDdrj/rxMCUq0fASc+KYXv41cAFbOaUhsHXPjA7mP8JY1zEN/z2pzpRsCFL1SVOlXnyPn2TBbPy8fRvfdq2wqZvS6Xd+DfCyq1rmfxzQd0BrDn36d+ql3nAbiX7DQe4MC/OX+nn1qk4w+eNV+z5gMvKyeT9VceiJoZ+w+MBxf9xEZCmEjVA2DNx8fxEf28/m8q94Cj+nO2fHriRtU5/79iXl44KT3Fmk/4tJo0vzAAtfJZez53qsggueGRKlHayt7+nD+xsA6CivPnsz+MK//OoEIB7Riqivn8H4383Ig/ToBC+cjH9d/3+Pv2f9/z33f88x3/fa9/vtd/hG+ZrZ/8x3f+J6T/XvJf7/m/uP85e9j/eN//Pe5/4dUEBBbd/z7u/3PJ/l+ogSyx/3+uf5Rr1z/k9Z92tfqPvP6Vr1f/8l7/817/7F+drP+afORz699IV//fr1H/H96Pq3Pzcv7BVjr/GAR/5z/1eP5T/53/WJweffz513sK/MCXyPf9B9/3P3zff/F9/8f7/Sff97+833/zfv9Pcf9xLk3ff9zI73/OIqP7n0FBQUFBQUFBQUFL6he/SkOzBiBU7wAAAABJRU5ErkJggg==' },
    { ID: 2, Name: 'Jayanti', Gender: 'Female', IsActive: false, Image: null },
    { ID: 3, Name: 'Nelson', Gender: 'Male', IsActive: true, Image: null },
    { ID: 4, Name: 'Manoja', Gender: 'Female', IsActive: false, Image: null },
    { ID: 5, Name: 'Willson', Gender: 'Male', IsActive: true, Image: null },
    { ID: 5, Name: 'Willson', Gender: 'Female', IsActive: true, Image: null }];
  filteredEmployees: any[] = [];
  employee: any[] = [];
  private readonly _destroying$ = new Subject<void>();

  constructor(private loginsvc : LoggedInUserModulesService) { }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit() {
    this.loginsvc.isAuthorized$.pipe(takeUntil(this._destroying$)).subscribe((data:any) => {
      this.showFooter = data;
    })
  }

  openSideNav(event: any) {
    this.openSideNavEvent = event;
    this.showWidthInPx = false;
    this.sidenav_col = 'col-lg-2 col-md-2';
    this.router_col = 'col-lg-10 col-md-10 col-sm-10 col-xs-10';
  }

  closeSideNav(event: any) {
    this.closeSideNavEvent = event;
    this.showWidthInPx = true;
    this.sidenav_col = '';
    this.router_col = 'col-lg-11 col-md-11 col-sm-11 col-xs-11';
  }
  
  filterEmployees(event: any) {
    this.filteredEmployees = [];
    for (let i = 0; i < this.employees.length; i++) {
      let employee = this.employees[i];
      if (employee.Name.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredEmployees.push(employee);
      }
      //alert(JSON.stringify(this.filteredBrands));
    }
  }

}

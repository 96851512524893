import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
import { ComplianceViolations } from 'src/app/_models/employee-profile/ComplianceViolations';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
declare var _satellite: any;
declare var window: any;
@Component({
  selector: 'app-empviolations',
  templateUrl: './empviolations.component.html',
  styleUrls: ['./empviolations.component.scss']
})
export class EmpviolationsComponent implements OnInit {

  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];

  constructor(private orderPipe: OrderPipe, private _empService: EmpProfileService) {
    this.sortedCollection = orderPipe.transform(this.violationDetails, "complianceRuleDesc");
  }
  totalnumber: number = 0;
  @Input() personnelNumber: string;
  @Input() violationDetails: ComplianceViolations[];
  gridcolumnflag: boolean = false;
  employeeinformation: boolean = false;
  createdByPersonnelNo: string;
  order: string = 'complianceRuleDesc';
  reverse: boolean = false;
  sortedCollection: any[];
  
  @Output() backToTails = new EventEmitter<any>();
  @Input() isPageLoad;

  backtoSearch() {
    this.backToTails.emit()
    this.isPageLoad= false;
  }

  ngOnInit() {
    // $('.pane--table2').scroll(function () {
    //   $('.pane--table2').find('.table-style').width($('.pane--table2').width() + $('.pane--table2').scrollLeft());
    // });
    //this.personnelNumber = '220550';
    // this.GetInvViolationDetails(this.personnelNumber);
    // this.GetCompViolationDetails(this.personnelNumber);
    // this.GetMBRViolationDetails(this.personnelNumber);
    //this.totalnumber = this.violationDetails;
    this.totalnumber = this.violationDetails.length;
    //this.fn_DatalayerPageLoad();
  }

  ngOnChanges() {
    this.totalnumber = this.violationDetails.length;
  }
  GetInvViolationDetails(personnelNumber: string) {
    return this._empService.GetInvViolationDetails(personnelNumber).subscribe(
      (data) => {
      this.violationDetails = data;
        this.totalnumber = this.violationDetails.length;
      })
  }
  GetCompViolationDetails(personnelNumber: string) {
    return this._empService.GetCompViolationDetails(personnelNumber).subscribe(
      (data) => {
        this.violationDetails = this.violationDetails.concat(data);
        this.totalnumber = this.violationDetails.length;
      }
    )
  }
  GetMBRViolationDetails(personnelNumber: string) {
    return this._empService.GetMBRViolationDetails(personnelNumber).subscribe(
      (data) => {
        this.violationDetails = this.violationDetails.concat(data);
        this.totalnumber = this.violationDetails.length;
      }
    )
  }
  showEmployeeProfile(personnelNbr: string) {
    this.createdByPersonnelNo = personnelNbr;
    this.employeeinformation = true;
  }
  setOrder(value: string) {
    this.order = value;
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
  }
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee Violations Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LaptopViolationDetails } from 'src/app/_models/employee-profile/laptop-violationdetail.model';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';

@Component({
  selector: 'app-laptop-empviolation',
  templateUrl: './laptop-empviolation.component.html',
  styleUrls: ['./laptop-empviolation.component.scss']
})
export class LaptopEmpviolationComponent implements OnInit {

  @Output() backToTails = new EventEmitter<any>();
  @Input() personnelNumber: string;
  violationDetailsForLaptop: LaptopViolationDetails[];
  constructor(private _empService: EmpProfileService) { }

  ngOnInit() {
    this.getLaptopInventoryDetails();
  }
  backtoSearch() {
    this.backToTails.emit();
  }
  getLaptopInventoryDetails() {
    this._empService.GetLaptopViolationDetailsByPersonnelNbr(this.personnelNumber).subscribe((data) => {
      this.violationDetailsForLaptop = data;
    });
  }

}

<div class="modal fade" id="myModalreports" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="static"  data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title generate-reports-text" id="exampleModalLabel">Generate Reports</h5>
        <button type="button" class="close" data-dismiss="modal" #closeBtn aria-label="Close" (click)="closemethod()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="report-form" [formGroup]="reportsform" #reportform>
        <div class="modal-body">
          <label>Category<sup>*</sup></label>
          <div class="form-group">
            <select class="form-control" formControlName="categoryinfo" id="categoryinfoo"
                    [(ngModel)]="selectedSubCategory" (change)="categorymethod($event)">
              <option value="">Select Category</option>
              <option *ngFor="let categoryData of reportCategoryData" value="{{categoryData.category}}">
                {{categoryData.category}}
              </option>
            </select>
          </div>
          <div class="reports-data" *ngIf="showTypeData">
            <label>Type<sup>*</sup></label>
            <div class="form-group">
              <select class="form-control" formControlName="reportinfo" id="reportinfoo"
                      [(ngModel)]="selectedTypeReport" (change)="reportmethodinfo()">
                <option value="">Select Report</option>
                <option *ngFor="let report of reportSubCategory" value="{{report.type}}">{{report.type}}</option>
              </select>
            </div>
          </div>
          <div class="reports-data" *ngIf="showRegionData">
            <label>Region<sup>*</sup></label>
            <div class="form-group">
              <select class="form-control" formControlName="regioninfo" id="regioninfoo" [(ngModel)]="selectedRegion"
                      (change)="regionmethodinfo($event)">
                <option value="" hidden selected>Select Option</option>
                <option value="ALL">ALL</option>
                <option *ngFor="let region of regionData" value="{{region.facilityCode}}">
                  {{region.facilityName}}
                </option>
              </select>
            </div>
          </div>
          <div class="reports-data" *ngIf="showOfficeData">
            <label>Office<sup>*</sup></label>
            <div class="form-group">
              <select class="form-control" formControlName="officedata" id="officedat" [(ngModel)]="selectedOffice"
                      (change)="officesData(selectedOffice)">
                <option value="" hidden selected>Select Option</option>
                <!-- <option value="">All</option> -->
                <option *ngFor="let office of facilityByRegion" value="{{office.facilityCode}}">
                  {{office.facilityName}}
                </option>
              </select>
            </div>
          </div>

          <div class="reports-data" *ngIf="showRecycleReassignemtData">
            <label>Filter</label>
            <div class="form-group">
              <select class="form-control" formControlName="recycleReassignemtData" id="RecycleReassignemtData">
                <option value="" hidden selected>Select Option</option>
                <option value="All">All</option>
                <option value="In transit to vendor">In transit to vendor</option>
                <option value="In transit to recycle">In transit to recycle</option>
                <option value="Lost/Stolen">Lost/Stolen</option>
              </select>
            </div>
          </div>

          <div class="reports-data" *ngIf="showReportDateData">
            <label for="sel1" class="violationtype ml-1">Report Date<sup>*</sup></label>
            <div class="form-group date-picker">
              <div class="row selected-date">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 my-date-picker-from">
                  <!-- <my-date-picker [options]="myDatePickerOptions" (dateChanged)="onDateChangedFrom($event)"
                    [selDate]="selectedDateFrom" [placeholder]="fromplaceholder" formControlName="datepickerfromval" class="fromDateClass">
                  </my-date-picker> -->
                  <my-date-picker [options]="myDatePickerOptions" name="mydate"
                                  (dateChanged)="onDateChangedFrom($event)" [selDate]="selDateFrom" class="fromDateClass" required>
                  </my-date-picker>
                </div>
                <div class="col-md-12 p-0" *ngIf="fromDateSelected">
                  <div class="fromdate-err-msg">Please select From Date</div>
                </div>

              </div>
              <div class="row selected-date">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 my-date-picker-to">
                  <!-- <my-date-picker [options]="myDatePickerOptions" (dateChanged)="onDateChangedTo($event)"
                  [selDate]="selDate" [placeholder]="toplaceholder" formControlName="datepickertoval" class="toDateClass"></my-date-picker> -->
                  <my-date-picker [options]="myDatePickerOptions" name="mydate" (dateChanged)="onDateChangedTo($event)"
                                  [selDate]="selDate" class="toDateClass" required></my-date-picker>
                </div>
                <div class="col-md-12 p-0" *ngIf="toDateSelected">
                  <div class="todate-err-msg">To Date must be greater than From Date</div>
                </div>
                <div class="col-md-12 p-0" *ngIf="toDateSelectedMust">
                  <div class="todate-err-msg">Please select To Date</div>
                </div>
                <div class="col-md-12 p-0" *ngIf="fromToDateSelected">
                  <div class="todate-err-msg">Please select both From date and To date</div>
                </div>
              </div>
            </div>
          </div>
          <div class="reports-data" *ngIf="showEmpAlias">
            <label>Employee Alias<sup>*</sup></label>
            <app-people-picker (selectedAlias)="selectedAlias($event)"></app-people-picker>
          </div>
          <div class="reports-data" *ngIf="showEmpPesonnelNo">
            <label>Employee Personnel Number<sup>*</sup></label>
            <input type="text" class="form-control" id="employealia" formControlName="personnelNumber" placeholder="Enter eight digit Employee Personnel No" maxlength="8">
          </div>
          <div>
            <span *ngIf="PNEmpty"
                  style="color: red;margin-left: 0px;">
              Personal Number must be provided.
            </span>
          </div>
          <div *ngIf="showDeviceReplacementCountData">
            <div class="reports-data">
              <div class="preview-div" id="previewDiv" *ngIf="!isEmployeeSelected">
                <div class="employeealiasvalues" id="preview">
                  <div class="employeealias-val">
                    <label class="employee-email">All</label>
                  </div>
                </div>
              </div>
              <div class="preview-div" *ngFor="let values of selectedEmployeeAlias;let i=index" id="previewDiv_{{i}}">
                <div class="employeealiasvalues" id="preview_{{i}}">
                  <div class="employeealias-val">
                    <label class="employee-email">{{values}}</label><span (click)="closeSelectedImei(values,i)"><i class="fa fa-close close-imei"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="reports-data">
              <label> Min Device Replacement Count</label>
              <div class="form-group">
                <select class="form-control" formControlName="mindevicereplacementdata" id="mindevicere" [disabled]="isEmployeeSelected"
                        [(ngModel)]="selectedMinimumDeviceReplacementCount">
                  <option value="" selected>All</option>
                  <option *ngFor="let mindevice of minimumDeviceCount" value="{{mindevice}}">
                    {{mindevice}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="labelwithcheckbox col-md-12 col-lg-12" style="margin-top:10px" *ngIf="showEmployeeData">
            <label for="sel1">Employees</label>

            <label class="custom-control fill-checkbox">
              <input type="radio" id="allcheck" value="all" name="employeetype" formControlName="employeetype"
                     class="form-control fill-control-input">
              <span class="fill-control-indicator" style="border-radius:8px !important" id="allvalueinfo"></span>
              <span class="violation-text">All</span>
            </label>
            <label class="custom-control fill-checkbox">
              <input type="radio" id="selectcheck" value="select" name="employeetype" formControlName="employeetype"
                     class="form-control fill-control-input" (click)="selectmethod()">
              <span class="fill-control-indicator" style="border-radius:8px !important"></span>
              <span class="violation-text">Select</span>
            </label>
          </div>
          <div class="form-group" *ngIf="showPeoplePickerData">
            <app-people-picker></app-people-picker>
          </div>
          <!--<div class="form-group" style="margin-top:12px" *ngIf="showMainDeviceReplacementcountData">
            <label for="sel1" class="audit-type">Main device replacement count </label>
            <div class="col-md-12">
              <select class="form-control" id="devicetypee" required>
                <option value="">Select Another data range</option>
                <option *ngFor="let maindevice of reports" value="{{maindevice.info.name}}">
                  {{maindevice.info.name}}
                </option>
              </select>
            </div>
          </div>-->
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" id="generate_Report" [disabled]="!reportsform.valid"
                    (click)="generateReport()">
              Generate Report
            </button>
          </div>
        </div>
        <div *ngIf="reportStatusResultSet?.length>0">
          <div class="notification-Data">
            <div class="row">
              <div class="offset-md-1 compliance-issues-header">
                <p class="compliance-issues">Report Status:</p>
              </div>
            </div>
            <div class="card" *ngFor="let reportStatus of reportStatusResultSet;let i=index">
              <div [ngClass]="(i%2===0)?'card-header':'card-body'">
                <div class="issue">
                  <label class="issue-text">Report Requested Date</label>
                  <p class="issue-data">{{reportStatus.reportReuestedDate}}</p>
                  <label class="issue-text">Report Requested</label>
                  <p class="issue-data">{{reportStatus.reportRequested}}</p>
                  <label class="issue-text">Report Status Description</label>
                  <p class="issue-data">{{reportStatus.reportStatusDesc}}</p>
                </div>
              </div>
              <div *ngIf="(!reportStatusResultSet) || (reportStatusResultSet.length == 0)"
                   class="notification-data">
                No Data Available
              </div>

            </div>
            
            <div class="card" style="visibility: hidden;">
              <div class="card-body">

              </div>

            </div>


          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillingEmployeeView } from 'src/app/_models/employee-profile/BillingEmployeeView';
import { CompEmployeeView } from 'src/app/_models/employee-profile/CompEmployeeView';
import { ComplianceExceptions } from 'src/app/_models/employee-profile/ComplianceExceptions';
import { ComplianceViolations } from 'src/app/_models/employee-profile/ComplianceViolations';
import { CoreEmployeeView } from 'src/app/_models/employee-profile/CoreEmployeeView';
import { InvEmployeeView } from 'src/app/_models/employee-profile/InvEmployeeView';
import { LaptopEmpTilesData } from 'src/app/_models/employee-profile/laptopEmpTiles.model';
import { MTNCancelledSuspendStatus } from 'src/app/_models/employee-profile/MTNCancelledSuspendStatus';
import { OrderEmployeeView } from 'src/app/_models/employee-profile/OrderEmployeeView';
import { FiltersMenuService } from 'src/app/_services/compliance/filters-menu.service';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
declare var _satellite: any;
declare var window: any;
import $ from 'jquery';

@Component({
  selector: 'app-emptiles',
  templateUrl: './emptiles.component.html',
  styleUrls: ['./emptiles.component.scss']
})
export class EmptilesComponent implements OnInit {

  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];
  laptopEmpInventoryInfo: boolean;
  laptopEmpViolationInfo: boolean;
  tilesnumber: number = 6;
  inventoryinformation: boolean = false;
  ordersinformation: boolean = false;
  violationsinformation: boolean = false;
  intuneinformation: boolean = false;
  mtngridinformation: boolean = false;
  imeigridinformation: boolean = false;
  mtninformation: boolean = false;
  mbrinformation: boolean = false;
  employeetileinformation: boolean = true;
  showtilesFiltersPanel: boolean = false;
  selectedMTN: string = '';
  selectedIMEI: string = '';
  @Input() personnelNumber: string;
  @Output() emitEventtileClose = new EventEmitter<any>();
  @Output() emitEventtileOpen = new EventEmitter<any>();
  @Input() violationDetails: ComplianceViolations[];
  @Input() exceptionDetails: ComplianceExceptions[];
  @Input() employeeDetails: CoreEmployeeView;
  @Input() inventoryEmpView: InvEmployeeView;
  @Input() laptopTileData: any;
  @Input() billingEmpView: BillingEmployeeView;
  @Input() compEmpView: CompEmployeeView;
  @Input() ordersEmpView: OrderEmployeeView;
  @Input() billingDetails: any;
  @Input() toggleValueForMobileLaptop: any;
  @Output() emitHideToggleEmpChild = new EventEmitter<any>();
  isViolationPageLoad = true;
  isIntunePageLoad = true;
  isMBRPageLoad = true;
  isMTNPageLoad = true;
  isOrdersPageLoad = true;
  showTileBasedModule: boolean = false;
  moduleName: string = this.filtersMenuService.selectedMainModuleName;
  MtnStatusDetails: MTNCancelledSuspendStatus[];

  constructor(private _empService: EmpProfileService, private filtersMenuService: FiltersMenuService) {  }
  
  ngOnInit() {
    
    this.fn_DatalayerPageLoad();
    if ((this.moduleName === 'Orders') || (this.moduleName === 'Compliance') || (this.moduleName === 'Delinquencies') || (this.moduleName === 'Monthly Bill Review') || (this.moduleName === 'Terms and Cancellation') || (this.moduleName === 'Inventory') || (this.moduleName === 'Inventory Reconciliation') || (this.moduleName == '')) {
      if (this.toggleValueForMobileLaptop == true) {
        this.showTileBasedModule = true;
      } else {
        this.showTileBasedModule = false;
      }
    } else {
      if (this.toggleValueForMobileLaptop == true) {
        this.showTileBasedModule = true;
      } else {
        this.showTileBasedModule = false;
      }
    }
  }
  ngOnChanges() {
    if ((this.moduleName === 'Orders') || (this.moduleName === 'Compliance') || (this.moduleName === 'Delinquencies') || (this.moduleName === 'Monthly Bill Review') || (this.moduleName === 'Terms and Cancellation') || (this.moduleName === 'Inventory') || (this.moduleName === 'Inventory Reconciliation') || (this.moduleName == '')) {
      if (this.toggleValueForMobileLaptop == true) {
        this.showTileBasedModule = true;
      } else {
        this.showTileBasedModule = false;
      }
    } else {
      if (this.toggleValueForMobileLaptop == true) {
        this.showTileBasedModule = true;
      } else {
        this.showTileBasedModule = false;
      }
    }
    // this._empService.GetMTNCancelledSuspendStatus(this.inventoryEmpView.currentMTN.mobileTelephoneNbr).subscribe(
    //   (data) => {
    //     debugger;
    //     this.MtnStatusDetails = data;       
    //         this.MtnStatusDetails.forEach((y,index)=>{             
    //             this.inventoryEmpView.isMBRCancelled = y['isMarkedForCancellation'];
    //             this.inventoryEmpView.isMBRSuspend = y['isMarkedForSuspend'];
    //           }              
    //         )          
    //   })
    // console.log('selected module name from emptiles ngOnChanges',this.filtersMenuService.selectedMainModuleName);
    // this.backtoSearch();
  }
  ngAfterViewInit() {

  }
  // toggleEventForMobile(event) {
  //   if(event == true) {
  //     this.showTileBasedModule = false;
  //   } else {
  //     this.showTileBasedModule = true;
  //   }
  // }

  inventorytile() {
    if (this.inventoryEmpView) {

      // if (this.inventoryEmpView.totalCount > 0) {
      this.inventoryinformation = true;
      this.employeetileinformation = false;
      $('#hide_toggle_emp_childs').hide();
      // }
    }
  }
  laptopInventorytile() {
    if (this.laptopTileData) {
      this.laptopEmpInventoryInfo = true;
      this.employeetileinformation = false;
      $('#hide_toggle_emp_childs').hide();
    }
  }
  // laptopSynctile() {
  //   if(this.laptopTileData) {
  //   this.laptopEmpSyncInfo = true;
  //   this.employeetileinformation = false;
  //   }
  // }
  laptopViolationstile() {
    if (this.laptopTileData) {
      this.laptopEmpViolationInfo = true;
      this.employeetileinformation = false;
      $('#hide_toggle_emp_childs').hide();
    }
  }
  closetileFilter(event) {
    this.emitEventtileClose.emit(event);
    this.showtilesFiltersPanel = true;

  }
  orderstile() {
    if (this.ordersEmpView) {
      if (this.ordersEmpView.totalCount > 0) {
        this.ordersinformation = true;
        this.employeetileinformation = false;
        this.isViolationPageLoad = false;
        this.isIntunePageLoad = false;
        this.isMBRPageLoad = false;
        this.isMTNPageLoad = false;
        this.isOrdersPageLoad = true;
        $('#hide_toggle_emp_childs').hide();
      }
    }
  }
  violationstile() {
    if (this.violationDetails) {
      // if (this.violationDetails.length > 0) {
        this.violationsinformation = true;
        this.employeetileinformation = false;
        this.isViolationPageLoad = true;
        this.isIntunePageLoad = false;
        this.isMBRPageLoad = false;
        this.isMTNPageLoad = false;
        this.isOrdersPageLoad = false;
        $('#hide_toggle_emp_childs').hide();
    }
  }
  intunetile() {
    if (this.compEmpView) {
      if (this.compEmpView.totalIntuneCount > 0) {
        this.intuneinformation = true;
        this.employeetileinformation = false;
        this.isViolationPageLoad = false;
        this.isIntunePageLoad = true;
        this.isMBRPageLoad = false;
        this.isMTNPageLoad = false;
        this.isOrdersPageLoad = false;
        $('#hide_toggle_emp_childs').hide();
      }
    }
  }
  mtntile() {
    this.mtninformation = true;
    this.employeetileinformation = false;
    this.isViolationPageLoad = false;
    this.isIntunePageLoad = false;
    this.isMBRPageLoad = false;
    this.isMTNPageLoad = true;
    this.isOrdersPageLoad = false;
    $('#hide_toggle_emp_childs').hide();
  }
  mbrtile() {
    if (this.billingDetails != null && this.billingDetails.carrier != null) {
      this.mbrinformation = true;
      this.employeetileinformation = false;
      this.isViolationPageLoad = false;
      this.isIntunePageLoad = false;
      this.isMBRPageLoad = true;
      this.isMTNPageLoad = false;
      this.isOrdersPageLoad = false;
      $('#hide_toggle_emp_childs').hide();
    }
  }

  backtoSearch() {
    this.employeetileinformation = true;
    this.inventoryinformation = false;
    this.ordersinformation = false;
    this.violationsinformation = false;
    this.intuneinformation = false;
    this.mtninformation = false;
    this.mbrinformation = false;
    this.mtngridinformation = false;
  }

  backtoInv() {
    this.employeetileinformation = false;
    this.inventoryinformation = true;
    this.ordersinformation = false;
    this.violationsinformation = false;
    this.intuneinformation = false;
    this.mtninformation = false;
    this.mbrinformation = false;
    this.mtngridinformation = false;
  }

  openImei(event) {
    this.selectedIMEI = event;

    // console.log('099999999999999999999999999999999999', event);
  }
  openMtn(event) {
    this.selectedMTN = event;
    // console.log('000000000000000000000000000000000066666666666', event);
  }
  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee Profile Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }
  backToTails(event) {
    $('#hide_toggle_emp_childs').show();
    console.log('called------------')
    this.inventoryinformation = false;
    this.employeetileinformation = true;
    this.isViolationPageLoad = false;
    this.isIntunePageLoad = false;
    this.isMBRPageLoad = false;
    this.isMTNPageLoad = false;
    this.isOrdersPageLoad = false;
    this.laptopEmpInventoryInfo = false;
    // this.laptopEmpSyncInfo = false;
    this.laptopEmpViolationInfo = false;
  }

  hideToggleEmpChild(hideOrShow:boolean){
    this.emitHideToggleEmpChild.emit(hideOrShow);
  }

}

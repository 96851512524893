<div class="jumbotron" *ngIf="employeetileinformation">

    <div class="container gridinfo" *ngIf="!showTileBasedModule">


        <div class="tile" (click)="inventorytile()">
            <div class="col-md-12 p-0 emp-tile-title">
                <h4 class="ml-2"> Inventory: {{inventoryEmpView?.totalCount || 0}} devices</h4>
            </div>
            <div *ngIf="inventoryEmpView?.currentMTN!=null">
                <div class="col-md-12 p-0">
                    <div class="col-md-6 p-0 dis-line">
                        <div>
                            <div class="alias">Current Device:</div>
                        </div>
                        <div copyToClipboard 
                            copiedString="{{inventoryEmpView?.currentMTN?.deviceModelName}}">
                            <span 
                            style="
                                min-width: 110px;
                                max-width: 110px;
                                display: inline-block;
                                vertical-align: middle;
                            " 
                            class="vip"
                            >{{inventoryEmpView?.currentMTN?.deviceModelName}}</span>
                        </div>
                    </div>
                    <div class="col-md-6 p-0 dis-line align-top">
                        <div>
                            <div class="alias">Manufacturer:</div>
                        </div>
                        <div>
                            <span class="vip">{{inventoryEmpView?.currentMTN?.deviceManufacturer}}</span>
                        </div>
                    </div>
                </div>
               <div class="col-md-12 p-0">
                    <div class="col-md-6 p-0 dis-line">
                        <div>
                            <div class="alias">Mobile Number</div>
                        </div>
                        <div>
                            <span class="vip mt-1"
                            copyToClipboard copiedString="{{inventoryEmpView?.currentMTN?.mobileTelephoneNbr}}"
                            >{{inventoryEmpView?.currentMTN?.mobileTelephoneNbr}}</span>
                        </div>
                    </div>
                    <div class="col-md-6 p-0 dis-line">
                        <div>
                            <div class="alias"></div>
                        </div>
                        <div>
                             <!-- <img src="assets/images/cancel.png" *ngIf="(inventoryEmpView?.currentMTN?.mtnStatus == 'Cancelled' || inventoryEmpView?.isMBRCancelled )" -->
                            <img src="assets/images/cancel.png" *ngIf="(inventoryEmpView?.currentMTN?.mtnStatus == 'Cancelled')"
                            style="height: 20px; padding-left: 10px;" title="Cancelled">
                            <!-- <img src="assets/images/Suspend_icon.ico" *ngIf=
                            "(inventoryEmpView?.currentMTN?.mtnStatus == 'Suspended' && !inventoryEmpView?.isMBRCancelled && inventoryEmpView?.currentMTN?.mtnStatus != 'Cancelled') ||
                            (inventoryEmpView?.isMBRSuspend  && !inventoryEmpView?.isMBRCancelled  && inventoryEmpView?.currentMTN?.mtnStatus != 'Cancelled')" -->
                            <img src="assets/images/Suspend_icon.ico" *ngIf="(inventoryEmpView?.currentMTN?.mtnStatus == 'Suspended')"
                                style="height: 20px; padding-left: 10px;" title="Suspended">
                        </div>
                    </div>
                </div>   
                
                <div class="col-md-12 p-0">
                    <div class="alias">Device ID:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip"
                    copyToClipboard copiedString="{{inventoryEmpView?.currentMTN?.imei}}"
                    >{{inventoryEmpView?.currentMTN?.imei}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="col-md-6 p-0 dis-line align-top">
                        <div>
                            <div class="alias">Device Carrier:</div>
                        </div>
                        <div>
                            <span
                        class="vip">{{inventoryEmpView?.currentMTN?.carrier}}</span>
                        </div>
                    </div>
                    <div class="col-md-6 p-0 dis-line">
                        <div>
                            <div class="alias">MTN Carrier:</div>
                        </div>
                        <div>
                            <span class="vip">{{inventoryEmpView?.currentMTN?.mtnCarrier}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias">Contract End Date:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip">{{(inventoryEmpView?.currentMTN?.contractEndDate !== '1900-01-01T00:00:00' && inventoryEmpView?.currentMTN?.contractEndDate  !== '0001-01-01T00:00:00') ? (inventoryEmpView?.currentMTN?.contractEndDate | date) : '-' }}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias">Eligibility Date:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip">{{inventoryEmpView?.eligibilityDateString.length>0?inventoryEmpView?.eligibilityDateString:'-'}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="col-md-8 p-0 dis-line">
                        <div>
                            <div class="alias">Color:</div>
                        </div>
                        <div>
                            <span class="vip"
                                *ngIf="inventoryEmpView?.currentMTN?.color != ''">{{inventoryEmpView?.currentMTN?.color}}</span>
                            <span class="vip" *ngIf="inventoryEmpView?.currentMTN?.color == ''">-</span>
                        </div>
                    </div>
                    <div class="col-md-4 p-0 dis-line">
                        <div>
                            <div class="alias">Size:</div>
                        </div>
                        <div>
                            <span class="vip"
                                *ngIf="inventoryEmpView?.currentMTN?.size != ''">{{inventoryEmpView?.currentMTN?.size}}</span>
                            <span class="vip" *ngIf="inventoryEmpView?.currentMTN?.size == ''">-</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 p-0">
                    <span>
                        <img class="padding-4 presenting-mobile" src="assets/images/Presenting.png"
                            title="Personal Device" *ngIf="inventoryEmpView?.currentMTN?.isPersonalDevice == 1">
                        <img class="padding-4 presenting-mobile" src="assets/images/Mobile.png"
                            title="Blacklisted Device" *ngIf="inventoryEmpView?.currentMTN?.isBlackListDevice == 1">
                    </span>
                </div>
            </div>
            <div *ngIf="inventoryEmpView?.currentMTN==null">
                <p class="alias device">No Current Device Data Available</p>
            </div>
        </div>
        <div class="tile" (click)="intunetile()">
            <div class="col-md-12 p-0 emp-tile-title">
                <h4 class="ml-2"> Intune: {{compEmpView?.totalIntuneCount}}</h4>
            </div>
            <div *ngIf="compEmpView?.totalIntuneCount>0">
                <div class="col-md-12 p-0">
                    <div class="alias">Last Heartbeat:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip">{{compEmpView?.lastHeartbeat|date}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias">Phone Number:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip"
                        copyToClipboard copiedString="{{compEmpView?.phoneNumber}}"
                    >{{compEmpView?.phoneNumber}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="col-md-6 p-0 dis-line">
                        <div>
                            <div class="alias">Current Device:</div>
                        </div>
                        <div>
                        
                            <span class="vip">{{compEmpView?.deviceModel}}</span>
                        </div>
                    </div>
                    <div class="col-md-6 p-0 dis-line align-top">
                        <div>
                            <div class="alias">Manufacturer:</div>
                        </div>
                        <div>
                            <span class="vip">{{compEmpView?.deviceManufacturer}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias">IMEI/ ESN:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip"
                        copyToClipboard copiedString="{{compEmpView?.imei}}"
                    >{{compEmpView?.imei}}</span>
                </div>
            </div>
            <div *ngIf="compEmpView?.totalIntuneCount==0">
                <p class="alias device">No Intune Data Available</p>
            </div>
        </div>
        <div class="tile" (click)="violationstile()">
            <div class="col-md-12 p-0 emp-tile-title">
                <h4 class="ml-2"> Violation: {{violationDetails?.length}}</h4>
            </div>

            <div class="col-md-12 p-0">
                <div class="alias">Compliance:</div>
            </div>
            <div class="col-md-12 p-0">
                <span class="vip">{{violationDetails[0]?.complianceRuleDesc}}</span>
            </div>

            <div class="col-md-12 p-0">
                <div class="alias">Since:</div>
            </div>
            <div class="col-md-12 p-0">
                <span class="vip">{{violationDetails[0]?.dateCreated | date:'mediumDate'}}</span>
            </div>

            <hr>
            <h4> Exception: {{exceptionDetails.length}}</h4>
            <div class="col-md-12 p-0">
                <div class="alias">Compliance:</div>
            </div>
            <div class="col-md-12 p-0">
                <span class="vip">{{exceptionDetails[0]?.complianceruledesc}}</span>
            </div>
            <div class="col-md-12 p-0">
                <div class="alias">Since:</div>
            </div>
            <div class="col-md-12 p-0">
                <span class="vip">{{exceptionDetails[0]?.addedDate | date:'mediumDate'}}</span>
            </div>
        </div>
    </div>
    <div class="container gridinfo2" *ngIf="showTileBasedModule">


        <div class="tile" (click)="laptopInventorytile()">
            <div class="col-md-12 p-0 emp-tile-title">
                <h4 class="ml-2">Laptop Inventory</h4>
            </div>
            <div>
                <div class="col-md-12 p-0">
                    <div class="alias">Description:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip">{{laptopTileData?.inventoryDetail?.description}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias">Model:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip mt-1">{{laptopTileData?.inventoryDetail?.model}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias">OS:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip mt-1">{{laptopTileData?.inventoryDetail?.os}}</span>
                </div>

                <div class="col-md-12 p-0">
                    <div class="alias">Serial Number:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip mt-1" copyToClipboard copiedString="{{laptopTileData?.inventoryDetail?.serialNumber}}">
                        {{laptopTileData?.inventoryDetail?.serialNumber}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias">Machine Name:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip" copyToClipboard copiedString="{{laptopTileData?.inventoryDetail?.machineName}}">
                        {{laptopTileData?.inventoryDetail?.machineName}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias">Asset Status:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip">{{laptopTileData?.inventoryDetail?.deviceStatusName}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="col-md-6 p-0 dis-line align-top">
                        <div>
                            <div class="alias">Lease End Date:</div>
                        </div>
                        <div>
                            <span
                        class="vip">{{(laptopTileData?.inventoryDetail?.currentEOLDate!== '1900-01-01T00:00:00' && laptopTileData?.inventoryDetail?.currentEOLDate!== '0001-01-01T00:00:00') ? (laptopTileData?.inventoryDetail?.currentEOLDate|date) : '--'}}</span>
                        </div>
                    </div>
                    <div class="col-md-6 p-0 dis-line">
                        <div>
                            <div class="alias">Druva Profile:</div>
                        </div>
                        <div>
                            <span class="vip">{{laptopTileData?.inventoryDetail?.profileName != '' ? laptopTileData?.inventoryDetail?.profileName: '--'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="laptopTileData?.inventoryDetail==null">
                <p class="alias device">No Current Device Data Available</p>
            </div>
        </div>
        <div class="tile">
            <div class="col-md-12 p-0 emp-tile-title">
                <h4 class="ml-2">Laptop Sync</h4>
            </div>
            <div>
                <div class="col-md-12 p-0">
                    <div class="alias">Last SCCM Heartbeat:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span
                        class="vip">{{(laptopTileData?.laptopsyncHeader?.lastSCCMSyncDate!== '1900-01-01T00:00:00' && laptopTileData?.laptopsyncHeader?.lastSCCMSyncDate!== '0001-01-01T00:00:00' && laptopTileData?.laptopsyncHeader?.lastSCCMSyncDate !== '' && laptopTileData?.laptopsyncHeader?.lastSCCMSyncDate !== null) ? (laptopTileData?.laptopsyncHeader?.lastSCCMSyncDate|date) : '--'}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias">AV Heartbeat:</div>
                </div>
                <div class="col-md-12 p-0" *ngIf="laptopTileData?.laptopsyncHeader.model==='APPLE'">
                    <span
                        class="vip">{{(laptopTileData?.laptopsyncHeader?.jamfCheckinDate!== '1900-01-01T00:00:00' && laptopTileData?.laptopsyncHeader?.jamfCheckinDate!== '0001-01-01T00:00:00' && laptopTileData?.laptopsyncHeader?.jamfCheckinDate !== '' && laptopTileData?.laptopsyncHeader?.jamfCheckinDate !== null) ? (laptopTileData?.laptopsyncHeader?.jamfCheckinDate|date) : '--'}}</span>
                </div>
                <div class="col-md-12 p-0" *ngIf="laptopTileData?.laptopsyncHeader.model!=='APPLE'">
                    <span
                        class="vip">{{(laptopTileData?.laptopsyncHeader?.crowdStrikeCheckinDate!== '1900-01-01T00:00:00' && laptopTileData?.laptopsyncHeader?.crowdStrikeCheckinDate!== '0001-01-01T00:00:00' && laptopTileData?.laptopsyncHeader?.crowdStrikeCheckinDate !== '' && laptopTileData?.laptopsyncHeader?.crowdStrikeCheckinDate !== null) ? (laptopTileData?.laptopsyncHeader?.crowdStrikeCheckinDate|date) : '--'}}</span>
                </div>

                <div class="col-md-12 p-0">
                    <div class="col-md-6 p-0 dis-line">
                        <div>
                            <div class="alias">Last Bitlocker Heartbeat:</div>
                        </div>
                        <div>
                            <span class="vip">{{(laptopTileData?.laptopsyncHeader?.lastBitlockerSyncDate!== '1900-01-01T00:00:00' && laptopTileData?.laptopsyncHeader?.lastBitlockerSyncDate!== '0001-01-01T00:00:00' && laptopTileData?.laptopsyncHeader?.lastBitlockerSyncDate !== '' && laptopTileData?.laptopsyncHeader?.lastBitlockerSyncDate !== null) ? (laptopTileData?.laptopsyncHeader?.lastBitlockerSyncDate|date) : '--'}}</span>  
                        </div>
                    </div>
                    <div class="col-md-6 p-0 dis-line">
                        <div>
                            <div class="alias">Last Druva Heartbeat:</div>
                        </div>
                        <div>    
                            <span class="vip">{{(laptopTileData?.laptopsyncHeader?.lastDruvaBackupSyncDate!== '1900-01-01T00:00:00' && laptopTileData?.laptopsyncHeader?.lastDruvaBackupSyncDate!== '0001-01-01T00:00:00' && laptopTileData?.laptopsyncHeader?.lastDruvaBackupSyncDate !== '' && laptopTileData?.laptopsyncHeader?.lastDruvaBackupSyncDate !== null) ? (laptopTileData?.laptopsyncHeader?.lastDruvaBackupSyncDate|date) : '--'}}</span>
                            <img src="assets/images/warning.png" *ngIf="laptopTileData?.inventoryDetail?.druvaBackupFailureStatus == 'yes'"
                        style="height: 15px;padding-left: 10px;" title="Druva Backup Failure">
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12 p-0">
                    <div class="alias">Model:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip">{{laptopTileData?.inventoryDetail?.model}}</span>
                </div> -->
                <div class="col-md-12 p-0">
                    <div class="col-md-6 p-0 dis-line">
                        <div>
                            <div class="alias">Model:</div>
                        </div>
                        <div>
                            <span class="vip">{{laptopTileData?.inventoryDetail?.model == ''? '--' : laptopTileData?.inventoryDetail?.model }}</span>  
                        </div>
                    </div>
                    <div class="col-md-6 p-0 dis-line">
                        <div>
                            <div class="alias">Machine Name:</div>
                        </div>
                        <div>    
                            <span class="vip" copyToClipboard copiedString="{{laptopTileData?.inventoryDetail?.machineName}}">
                                {{laptopTileData?.inventoryDetail?.machineName == ''? '--' : laptopTileData?.inventoryDetail?.machineName }}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12 p-0">
                    <div class="alias">Machine Name:</div>
                </div>
                <div class="col-md-12 p-0">
                    <span class="vip">{{laptopTileData?.inventoryDetail?.machineName}}</span>
                </div> -->
                <div class="col-md-12 p-0">
                    <div class="col-md-6 p-0 dis-line">
                        <div>
                    <div class="alias">Serial Number:</div>
                </div>
                <div>
               
                    <span class="vip" copyToClipboard copiedString="{{laptopTileData?.laptopsyncHeader?.serialNumber}}">
                        {{laptopTileData?.laptopsyncHeader?.serialNumber}}</span>
                </div>
                </div>
                <div class="col-md-6 p-0 dis-line">
                    <div>
                <div class="alias">Missing  Patches:</div>
            </div>
            <div>
           
                <span class="vip">{{laptopTileData?.laptopsyncHeader?.missingPatches == ''? '--' : laptopTileData?.laptopsyncHeader?.missingPatches }}</span>
            </div>
            </div>
                </div>
                <div class="col-md-12 p-0">
                    <div class="col-md-6 p-0 dis-line">
                        <div>
                            <div class="alias">VPS Encryption Status:</div>
                        </div>
                        <div>
                            <span class="vip"
                                *ngIf="laptopTileData?.laptopsyncHeader?.encryptionStatus?.toLowerCase() == 'true'">Active</span>
                            <span class="vip"
                                *ngIf="laptopTileData?.laptopsyncHeader?.encryptionStatus?.toLowerCase() == 'false'">Inactive</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="laptopTileData?.laptopsyncHeader==null">
                <p class="alias device">No Sync Data Available</p>
            </div>
        </div>

    </div>
    <div class="container grid-container" *ngIf="showTileBasedModule">
        <div class="tile" (click)="laptopViolationstile()" style="cursor: pointer;">
            <div class="col-md-12 p-0 emp-tile-title">
                <h4 class="ml-2">Laptop Violation:
                    {{laptopTileData?.listViolationDetails?.violationDetail?.violationCount}}</h4>
            </div>

            <div class="col-md-12 p-0"
                *ngIf="laptopTileData?.listViolationDetails?.violationDetail?.complianceRuleDesc !== null && laptopTileData?.listViolationDetails?.violationDetail?.complianceRuleDesc !== ''">
                <div class="alias">Compliance:</div>
            </div>
            <div class="col-md-12 p-0 laptop-text"
                *ngIf="laptopTileData?.listViolationDetails?.violationDetail?.complianceRuleDesc !== null && laptopTileData?.listViolationDetails?.violationDetail?.complianceRuleDesc !== ''">
                <span class="vip">{{laptopTileData?.listViolationDetails?.violationDetail?.complianceRuleDesc}}</span>
            </div>

            <div class="col-md-12 p-0"
                *ngIf="laptopTileData?.listViolationDetails?.violationDetail?.complianceRuleDesc !== null && laptopTileData?.listViolationDetails?.violationDetail?.complianceRuleDesc !== ''">
                <div class="alias">Since:</div>
            </div>
            <div class="col-md-12 p-0 laptop-text"
                *ngIf="laptopTileData?.listViolationDetails?.violationDetail?.complianceRuleDesc !== null && laptopTileData?.listViolationDetails?.violationDetail?.complianceRuleDesc !== ''">
                <span
                    class="vip">{{(laptopTileData?.listViolationDetails?.violationDetail?.createdDate !== '1900-01-01T00:00:00' && laptopTileData?.listViolationDetails?.violationDetail?.createdDate !== '0001-01-01T00:00:00') ? (laptopTileData?.listViolationDetails?.violationDetail?.createdDate |date) : '--'}}</span>
            </div>
            <div
                *ngIf="laptopTileData?.listViolationDetails?.violationDetail?.complianceRuleDesc == null || laptopTileData?.listViolationDetails?.violationDetail?.complianceRuleDesc == ''">
                <p class="alias">No Current Device Data Available</p>
            </div>

            <hr>
            <h4>Laptop Exception: {{laptopTileData?.listViolationDetails?.exceptionDetail?.exceptionCount}}</h4>
            <div class="col-md-12 p-0"
                *ngIf="laptopTileData?.listViolationDetails?.exceptionDetail?.complianceRuleDesc !== null && laptopTileData?.listViolationDetails?.exceptionDetail?.complianceRuleDesc !== ''">
                <div class="alias">Compliance:</div>
            </div>
            <div class="col-md-12 p-0 laptop-text"
                *ngIf="laptopTileData?.listViolationDetails?.exceptionDetail?.complianceRuleDesc !== null && laptopTileData?.listViolationDetails?.exceptionDetail?.complianceRuleDesc !== ''">
                <span class="vip">{{laptopTileData?.listViolationDetails?.exceptionDetail?.complianceRuleDesc}}</span>
            </div>
            <div class="col-md-12 p-0"
                *ngIf="laptopTileData?.listViolationDetails?.exceptionDetail?.complianceRuleDesc !== null && laptopTileData?.listViolationDetails?.exceptionDetail?.complianceRuleDesc !== ''">
                <div class="alias">Since:</div>
            </div>
            <div class="col-md-12 p-0 laptop-text"
                *ngIf="laptopTileData?.listViolationDetails?.exceptionDetail?.complianceRuleDesc !== null && laptopTileData?.listViolationDetails?.exceptionDetail?.complianceRuleDesc !== ''">
                <span
                    class="vip">{{(laptopTileData?.listViolationDetails?.exceptionDetail?.createdDate !== '1900-01-01T00:00:00' && laptopTileData?.listViolationDetails?.exceptionDetail?.createdDate !== '0001-01-01T00:00:00') ? (laptopTileData?.listViolationDetails?.exceptionDetail?.createdDate |date) : '--'}}</span>
            </div>
            <div
                *ngIf="laptopTileData?.listViolationDetails?.exceptionDetail?.complianceRuleDesc == null || laptopTileData?.listViolationDetails?.exceptionDetail?.complianceRuleDesc == ''">
                <p class="alias">No Current Device Data Available</p>
            </div>
        </div>
    </div>

    <div class="container grid-container" *ngIf="!showTileBasedModule">


        <div class="mobilebill-text" (click)="mbrtile()">

            <div class="col-md-12 p-0 emp-tile-title">
                <h4 class="ml-2 align-left"> Monthly Bill Review</h4>
            </div>
            <div class="align-left"
                *ngIf="billingDetails == null || billingDetails == undefined || (billingDetails?.carrier != 'ATT'&&billingDetails?.carrier != 'VERIZON'&&billingDetails?.carrier != 'SPRINT'&&billingDetails?.carrier != 'TMOBILE')">
                <p class="alias">No billing details found or this is the first month of the employee's corporate device.
                </p>
            </div>

            <div style="padding:10px" *ngIf="billingDetails != null">
                <div *ngIf="billingDetails?.carrier == 'ATT'">
                    <div class="row">
                        <div class="alias col-md-6 mt-1">MTN:</div>
                        <!-- <div class="col-md-1"></div> -->
                        <span class="vip mt-1 col-md-6" 
                            copyToClipboard copiedString="{{inventoryEmpView?.currentMTN?.mobileTelephoneNbr}}">{{inventoryEmpView?.currentMTN?.mobileTelephoneNbr}}</span>

                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">ReportDate:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails.reportDate | date:'mediumDate'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Current Charges:</div>
                        <!-- <div class="col-md-1"></div> -->
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalCurrentCharges | number :'1.0-2'}}</span>

                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Monthly Access Charges:</div>
                        <!-- <div class="col-md-1"></div> -->
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.monthlyAccessCharges | number :'1.0-2'}}</span>

                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Messaging Charges:</div>
                        <!-- <div class="col-md-1"></div> -->
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalMessagingCharges | number :'1.0-2'}}</span>

                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Data Usage Charges(Excluding Roaming):</div>
                        <!-- <div class="col-md-1"></div> -->
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalDataUsageCharges | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Data Usage:</div>
                        <!-- <div class="col-md-1"></div> -->
                        <span class="vip mt-1 col-md-6">{{billingDetails?.totalDataUsage | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Number of Events:</div>
                        <!-- <div class="col-md-1"></div> -->
                        <span class="vip mt-1 col-md-6">{{billingDetails?.totalNumberOfEvents | number :'1.0-2'}}</span>
                    </div>
                </div>
                <div *ngIf="billingDetails?.carrier == 'VERIZON'">
                    <div class="row">
                        <div class="alias col-md-6 mt-1">MTN:</div>
                        <span class="vip mt-1 col-md-6"
                        copyToClipboard copiedString="{{inventoryEmpView?.currentMTN?.mobileTelephoneNbr}}">{{inventoryEmpView?.currentMTN?.mobileTelephoneNbr}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">ReportDate:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails.reportDate | date:'mediumDate'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Current Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalCurrentCharges | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Monthly Access Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.monthlyAccessCharges | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Messaging Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalMessagingCharges | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Roaming Airtime Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.roamingAirtimeCharges | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Voice/Airtime Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.voiceOrAirtimeCharges | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Data Usage Charges(Excluding Roaming):</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalDataUsageCharges | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Roaming Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalRoamingCharges | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Used:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails?.used | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Billable:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails?.billable | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <h4 class="col-md-6 mt-1">Unbilled Usage - {{billingDetails?.unbilledDate}}:</h4>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Domestic KBs:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails?.domesticKBs | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Domestic Mobile BB Connect KBs:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails?.domesticMobileBBConnectKBs | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">International Travel Data KBs:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails?.internationalTravelDataKBs | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Intl Travel Mobile BB Connect KBs:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails?.intlTravelMobileBBConnectKBs | number :'1.0-2'}}</span>
                    </div>
                </div>
                <div *ngIf="billingDetails?.carrier == 'SPRINT'">
                    <div class="row">
                        <div class="alias col-md-6 mt-1">MTN:</div>
                        <span class="vip mt-1 col-md-6">{{inventoryEmpView?.currentMTN?.mobileTelephoneNbr}}</span>

                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">ReportDate:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails.reportDate | date:'mediumDate'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Current Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalCurrentCharges | number :'1.0-2'}}</span>

                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Monthly Access Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.monthlyAccessCharges | number :'1.0-2'}}</span>

                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Messaging Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalMessagingCharges | number :'1.0-2'}}</span>

                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Data Usage:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails?.totalDataUsage | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Voice/Airtime Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.voiceOrAirtimeCharges | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Data Usage Charges(Excluding Roaming):</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalDataUsageCharges | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Roaming Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalRoamingCharges | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Voice Minutes of Use:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails?.totalVoiceMinutesofUse}}</span>
                    </div>
                </div>
                <div *ngIf="billingDetails?.carrier == 'TMOBILE'">
                    <div class="row">
                        <div class="alias col-md-6 mt-1">MTN:</div>
                        <span class="vip mt-1 col-md-6">{{inventoryEmpView?.currentMTN?.mobileTelephoneNbr}}</span>

                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">ReportDate:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails.reportDate | date:'mediumDate'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Current Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalCurrentCharges | number :'1.0-2'}}</span>

                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Long Distance Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalLongDistanceCharges | number :'1.0-2'}}</span>

                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Overage:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails?.overage}}</span>

                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Minutes:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails?.minutes}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Plan Charge:</div>
                        <span class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.planCharge}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Data:</div>
                        <span class="vip mt-1 col-md-6">{{billingDetails?.data | number :'1.0-2'}}</span>
                    </div>
                    <div class="row">
                        <div class="alias col-md-6 mt-1">Total Roaming Charges:</div>
                        <span
                            class="vip mt-1 col-md-6"><span>&#36;</span>{{billingDetails?.totalRoamingCharges | number :'1.0-2'}}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="container grid-container" *ngIf="!showTileBasedModule">
        <div class="item8" (click)="orderstile()">
            <div class="col-md-12 p-0 emp-tile-title">
                <h4 class="ml-2"> Orders: {{ordersEmpView?.totalCount || 0}}</h4>
            </div>
            <div *ngIf="ordersEmpView?.orderDetails?.length>0">
                <div class="col-md-12 p-0">
                    <div class="alias col-md-6 mt-1 p-0">Last order Device:</div>
                </div>
                <div class="col-md-12 p-0 order-details-emptiles">
                    <span class="vip mt-1 col-md-6 p-0">{{ordersEmpView?.orderDetails[0]?.deviceModel}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias col-md-6 mt-1 p-0">Order Date:</div>
                </div>
                <div class="col-md-12 p-0 order-details-emptiles">
                    <span class="vip mt-1 col-md-6 p-0">{{ordersEmpView?.orderDetails[0]?.orderCreateDate|date}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias col-md-6 mt-1 p-0">Order No:</div>
                </div>
                <div class="col-md-12 p-0 order-details-emptiles">
                    <span class="vip mt-1 col-md-6 p-0"
                        copyToClipboard 
                        copiedString="{{ordersEmpView?.orderDetails[0]?.orderNbr}}"
                    >{{ordersEmpView?.orderDetails[0]?.orderNbr}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias col-md-6 mt-1 p-0">Carrier:</div>
                </div>
                <div class="col-md-12 p-0 order-details-emptiles">
                    <span class="vip mt-1 col-md-6 p-0">{{ordersEmpView?.orderDetails[0]?.carrierCode}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias col-md-6 mt-1 p-0">Order Status:</div>
                </div>
                <div class="col-md-12 p-0 order-details-emptiles">
                    <span class="vip mt-1 col-md-6 p-0">{{ordersEmpView?.orderDetails[0]?.orderStatus}}</span>
                </div>
                <div class="col-md-12 p-0">
                    <div class="alias col-md-6 mt-1 p-0">Mobile Number:</div>
                </div>
                <div class="col-md-12 p-0 order-details-emptiles">
                    <span class="vip mt-1 col-md-6 p-0"
                        copyToClipboard 
                        copiedString="{{ordersEmpView?.orderDetails[0]?.mobileTelephoneNbr}}"
                    >{{ordersEmpView?.orderDetails[0]?.mobileTelephoneNbr}}</span>
                </div>
            </div>
            <div *ngIf="!ordersEmpView?.orderDetails.length">
                <p class="alias">No orders against this employee</p>
            </div>
        </div>

    </div>



</div>


<!-- <div *ngIf="(inventoryinformation || ordersinformation || violationsinformation || intuneinformation || mtninformation || mbrinformation) && (!mtngridinformation && !imeigridinformation)"
    class="col-md-4" style="margin-left:-30px;cursor: pointer;">
    <div (click)="backtoSearch()">
        <img src="assets/images/collapse arrows.svg"><span class="hidedata-text"
            style="font-size:12px;color: #212529;">Back</span>
    </div>
</div> -->
<!-- <div *ngIf="mtngridinformation || imeigridinformation"
    class="col-md-4" style="margin-left:-30px;cursor: pointer;">
    <div (click)="backtoInv()">
        <img src="assets/images/collapse arrows.svg"><span class="hidedata-text"
            style="font-size:12px;font-weight:600;color:#56585a">Back</span>
    </div>
</div> -->
<app-empinventory *ngIf="inventoryinformation" [personnelNumber]="personnelNumber" (emitIMIE)="openImei($event)"
    (backToTails)="backToTails($event)" (emitMTN)="openMtn($event)"></app-empinventory>
<app-laptop-empinventory *ngIf="laptopEmpInventoryInfo" (backToTails)="backToTails($event)"
    [personnelNumber]="personnelNumber"></app-laptop-empinventory>

<app-emporders [isPageLoad]="isOrdersPageLoad" (backToTails)="backToTails($event)" *ngIf="ordersinformation"
    [personnelNumber]="personnelNumber"></app-emporders>

<app-empviolations [isPageLoad]="isViolationPageLoad" (backToTails)="backToTails($event)" *ngIf="violationsinformation"
    [personnelNumber]="personnelNumber" [violationDetails]="violationDetails"></app-empviolations>

<app-empintune [isPageLoad]="isIntunePageLoad" (backToTails)="backToTails($event)" *ngIf="intuneinformation"
    [personnelNumber]="personnelNumber"></app-empintune>
<!-- <app-laptop-empsync *ngIf="laptopEmpSyncInfo" [personnelNumber]="personnelNumber" (backToTails)="backToTails($event)">
</app-laptop-empsync> -->
<app-laptop-empviolation *ngIf="laptopEmpViolationInfo" [personnelNumber]="personnelNumber"
    (backToTails)="backToTails($event)"></app-laptop-empviolation>
<app-empmtn [isPageLoad]="isMTNPageLoad" (backToTails)="backToTails($event)" *ngIf="mtninformation"></app-empmtn>

<app-empmbr [isPageLoad]="isMBRPageLoad" (backToTails)="backToTails($event)" *ngIf="mbrinformation"
    [personnelNumber]="personnelNumber"></app-empmbr>

<app-mtn *ngIf="mtngridinformation" [selectedMTN]="selectedMTN"></app-mtn>
<app-imei *ngIf="imeigridinformation" [selectedIMEI]="selectedIMEI"></app-imei>

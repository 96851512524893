import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderDetails } from 'src/app/_models/employee-profile/OrderDetails';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
declare var _satellite: any;
declare var window: any;
@Component({
  selector: 'app-emporders',
  templateUrl: './emporders.component.html',
  styleUrls: ['./emporders.component.scss']
})
export class EmpordersComponent implements OnInit {

  constructor(private _empService: EmpProfileService) { }
  orderDetails: OrderDetails[];
  totalnumber = 0;
  @Input() personnelNumber: string;
  loggedInUserName: string = localStorage.getItem('loggedInUserName').split('@')[0];
  @Output() backToTails = new EventEmitter<any>();
  @Input() isPageLoad;
 
   backtoSearch() {
     this.backToTails.emit()
     this.isPageLoad= false;
   }
 
  ngOnInit() {
    this.GetEmployeeOrderDetails(this.personnelNumber);
    //this.fn_DatalayerPageLoad();

  }
  GetEmployeeOrderDetails(personnelNo: string) {
    return this._empService.GetEmployeeOrderDetails(personnelNo).subscribe(
      (data) => {
      this.orderDetails = data;
        this.totalnumber = this.orderDetails.length;
      }
    )
  }
  collection: any[] = [
    {
      // id: 1,
      info: {
        name: "praveen",
        alias: "dnesley",
        devicemodel: "Apple iphone 11",
        syncdate: "12 May 2019",
        isSelected: false
      }
    },
    {
      // id: 5,
      info: {
        name: "Hensley,Dillon1",
        alias: "dnesley",
        devicemodel: "Apple iphone 11",
        syncdate: "12 May 2019",
        isSelected: false
      }
    },
    {
      // id: 2,
      info: {
        name: "test2",
        alias: "jo",
        devicemodel: "Apple iphone 11",
        syncdate: "12 May 2019",
        isSelected: false
      }
    },

  ];

  fn_DatalayerPageLoad() {
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Employee Orders Page",
        'userID': this.loggedInUserName
      }
    };
    _satellite.track('pageView');
  }

}

import { Component, EventEmitter, forwardRef, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IMyOptions } from 'mydatepicker';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ComplianceViolationEx } from 'src/app/_models/compliance/compliance-search.model';
import { MTNCancelledSuspendStatus } from 'src/app/_models/employee-profile/MTNCancelledSuspendStatus';
import { GridData } from 'src/app/_models/grid.model';
import { CarrierModel } from 'src/app/_models/inventory/carrier.model';
import { ComplianceModel, DeviceModelCompliance, EmpStatusModel, MtnStatusModel, Office, region } from 'src/app/_models/side-filter-nav/sidenav-filter';
import { DataService } from 'src/app/_services/compliance/data.service';
import { FiltersMenuService } from 'src/app/_services/compliance/filters-menu.service';
import { RightSideCommentNavService } from 'src/app/_services/compliance/right-side-comment-nav.service';
import { EmpProfileService } from 'src/app/_services/employee-profile/emp-profile.service';
import { InventoryService } from 'src/app/_services/inventory/inventory.service';
import { ModalService } from 'src/app/_services/modal.service';
import { GlobalVariables } from 'src/app/_utils/global-variables';
import { SpinnerService } from '../../../_shared-component/spinner/spinner.service';
declare var _satellite: any;
declare var window: any;
import $ from 'jquery';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-side-filter-nav',
  templateUrl: './side-filter-nav.component.html',
  styleUrls: ['./side-filter-nav.component.scss']
})
export class SideFilterNavComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  selectedCountry: any = "0";
  selectedState = [];
  states = [];
  cities = [];
  @ViewChild('myForm', { static: false }) myForm: NgForm;
  @Output() emitEventFilterNavForClose = new EventEmitter<any>();
  @Output() emitEventFilterNavForOpen = new EventEmitter<any>();
  @Output() applyFilterEvent = new EventEmitter<any>();
  @Output() auditTypeEvent = new EventEmitter();
  showFiltersPanel: boolean;
  filtersdata: boolean;
  grid: any;
  fselectedDate: any;
  tselectedDate: any;
  selectedDate: any;
  fdate: any;
  tdate: any;
  fromplaceholder: string = 'From';
  toplaceholder: string = 'To';
  // myDatePickerOptions:any;
  regionVal: any;
  locationVal: any;
  notificationsSentSelectedVal: any;
  auditSelectedVal: any = "";
  _searchFilterForm: FormGroup;
  gridDataObj = new GridData();
  showAddFiltersDiv: boolean;
  complianceViolationEx = new ComplianceViolationEx();
  empStatusModel = new EmpStatusModel();
  mtnStatusModel = new MtnStatusModel();

  data: any;
  complianceModel = new ComplianceModel();
  checkedList: any[];
  notificationValues: any = {
    "": [{ "value": "Select Option" }],
    "Ineligible User": [{ "value": "All" }, { "value": "0" }, { "value": "1" }],
    "MDM Compliance": [{ "value": "All" }, { "value": "0" }, { "value": "1" }, { "value": "2" }, { "value": "3" }, { "value": "4" }, { "value": "5" }],
    "Multiple Enrollment": [{ "value": "All" }, { "value": "0" }, { "value": "1" }],
    "Non-Corporate Device": [{ "value": "All" }, { "value": "0" }, { "value": "1" }],
    "Pending Return": [{ "value": "All" }, { "value": "0" }, { "value": "1" }, { "value": "2" }, { "value": "3" }],
    "Pending Wipe": [{ "value": "All" }, { "value": "0" }, { "value": "1" }, { "value": "2" }, { "value": "3" }],
    "Personal Technology Audit": [{ "value": "All" }, { "value": "0" }, { "value": "1" }]
  }

  assetStatusList = [
    { 'AssetStatusID': 1, 'AssetStatusDesc': 'Assigned' },
    { 'AssetStatusID': 2, 'AssetStatusDesc': 'Loaner' },
    { 'AssetStatusID': 3, 'AssetStatusDesc': 'Training' },
    { 'AssetStatusID': 4, 'AssetStatusDesc': 'Test' }
  ];
 
  dataCollection: any = [
    {
      id: 1,
      name: 'Personal number',
      isSelected: false
    },
    {
      id: 2,
      name: 'MTN status',
      isSelected: false
    },
    {
      id: 3,
      name: 'MTN',
      isSelected: false
    },
    {
      id: 4,
      name: 'Employee status',
      isSelected: false
    },
    {
      id: 5,
      name: 'Job level group',
      isSelected: false
    }
  ]
  masterSelected: any;
  arr: any;
  selectedValues: any;
  auditSelectedValue: any;
  notificationSentValue: any;
  syncDateFrom: any;
  syncDateTo: any;
  loasyncDateFrom: any;
  loasyncDateTo: any;
  chartGridData: any;
  dropdowndata: any;
  regionItem: any;
  regionDropdowndata: any;
  gridData: any;
  locationData: any;
  location: any;
  region: any;
  errorMessage: any;
  hideAddFiltersDiv: boolean;
  hidePersonalNumber: boolean;
  hideMtnStatus: boolean;
  hideMtn: boolean;
  hideEmployeeStatus: boolean;
  hideJobLevelGroup: boolean;
  checkedCondition: any;
  personalnumber: any;
  personalnumberdata: string;
  mtnstatusdata: string;
  mtndata: string;
  joblevelgroupdata: string;
  employeestatusdata: string;
  isSpinnerLoader: boolean = false;
  eventOfAddingData: any;
  addFiltersData: boolean;
  showEmployeeFilter: boolean;
  showEmployeeStatusFilter: boolean;
  showMtnFilter: boolean;
  showDeviceTypeFilter: boolean;
  showOfficeFilter: boolean;
  showMtnStatusFilter: boolean;
  showLoaDateFilter: boolean;
  showLoaStatusFilter: boolean;
  showRegionFilter: boolean;
  showViolationAgeFilter: boolean;
  showJoblevelGroupFilter: boolean;
  empStatusData: any;
  audittypeMdm: boolean;
  showAssetStatusFilter: boolean;
  showAssetStatusFilterLabel: boolean = true;
  mtnStatusDetails: MtnStatusModel;
  officeData: any[];
  ICO: string;
  showspinner: boolean;
  fromDateSelected: boolean;
  toDateSelected: boolean;
  fromDate: any;
  toDate: any;
  fromToDateSelected: boolean;
  toDateSelectedMust: boolean;
  countryName: any;
  onDateChangedFromValue: any;
  onDateChangedToValue: any;
  messageResult: boolean;
  currentDate: Date;
  defaultFromDate: boolean;
  defaultToDate: boolean;
  public myDatePickerOptions: IMyOptions = {
    dateFormat: 'mm/dd/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: true,
  };
  deviceTypeDetails: DeviceModelCompliance[];
  regionData: region[];
  officeDataBasedRegion: Office[];
  regionFacilitycode: any = '';
  showLastHeartBeatDate: boolean = true;
  loggedInUserName: string = GlobalVariables.loggedInUserName;
  objMtnStatusDetails: MTNCancelledSuspendStatus[];
  DeviceModel12: CarrierModel[];
  dropdownSettings: IDropdownSettings = {};
  ShowFilter = false;
  showAll = true;
  disabled = false;
  carrierSelectedList: any;
  deviceTypeDropdownVal: any;
  empStatusDropdownVal: any;
  constructor(private _empService: EmpProfileService, private filtersMenuService: FiltersMenuService, 
    private rightSideCommentNavService: RightSideCommentNavService, private dataService: DataService,
    private router: Router, private formBuilder: FormBuilder, 
    public spinnerService: SpinnerService, private modalService: ModalService,private inventoryservice: InventoryService) { }

  ngOnInit(): void {
    // this.testingComplianceSearchResult();
    this.getFilterItems();
    this.getRegionFilterItems();
    this.getEmpStatusDetails();
    this.getMtnStatusDetails();
    this.getDeviceTypeData();
    this.getRegionData();
    this.dataService.totalGridData.subscribe(gridData => this.gridData = gridData);
    this.registerForm = this.formBuilder.group({
      notificationsent: [''],
      audittype: ['', Validators.required],
      regionval: [''],
      datepickerfromval: [''],
      employeefilterval: [''],
      // regionValue:[''],
      employeestatusfilterval: [''],
      mtnfilterval: [''],
      selecteddevicetypefilterval: [''],
      devicetypefilterval: [''],
      officefilterval: [''],
      selectedofficefilterval: [''],
      mtnstatusfilterval: [''],
      joblevelfilterval: [''],
      assetstatusfilterval: [''],
      violationagefilterval: [''],
      loastatusfilterval: [''],
      loadatefilterval: [''],
      datepickertoval: [''],
      selectedemployeefilterval: [''],
      employeestatusdropdown: [''],
      selectedmtnfilterval: [''],
      mtntatusdropdown: [''],
      joblevelgroupdropdown: [''],
      assetStatusdropdown:[''],
      violationagedropdown: [''],
      regiondropdown: [''],
      loastatusdropdown: [''],
      selectedloadatepickerfromval: [''],
      selectedloadatepickertoval: [''],
      statesData: [''],
      carrier: [''],
    });
    // $('#MySelect').val("12345");
    //this.SetDefaultDate();
   // this.fn_DatalayerPageLoad();
    this.inventoryservice.getAllCarrier().subscribe((data) => {
      this.DeviceModel12 = data.filter((val) => val.isActive == true);
    
        this.dropdownSettings = {
          singleSelection: false,
          defaultOpen: false,
          idField: 'carrierCode',
          textField: 'carrierName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableCheckAll: this.showAll,
          itemsShowLimit: this.DeviceModel12.length,
          allowSearchFilter: this.ShowFilter
        };
      // this.sortBy(this.DeviceModel12, 'carrierName');
    });
  }

  violationAge(event) {
    let res = event.target.value
    if (res) {
      switch (res) {
        case "1":
          this.notificationValues['MDM Compliance'] = [{ "value": "All" }, { "value": "0" }]
          break;
        case "2":
          this.notificationValues['MDM Compliance'] = [{ "value": "All" }, { "value": "0" }, { "value": "1" }]
          break;
        case "3":
          this.notificationValues['MDM Compliance'] = [{ "value": "All" }, { "value": "0" }, { "value": "1" }, { "value": "2" }]
          break;
        case "4":
          this.notificationValues['MDM Compliance'] = [{ "value": "All" }, { "value": "0" }, { "value": "1" }, { "value": "2" }, { "value": "3" }]
          break;
        case "5":
          this.notificationValues['MDM Compliance'] =[{ "value": "All" }, { "value": "0" }, { "value": "1" }, { "value": "2" }, { "value": "3" }, { "value": "4" }, { "value": "5" }]
          break;
        default:
          this.notificationValues['MDM Compliance'] = [{ "value": "All" }, { "value": "0" }, { "value": "1" }, { "value": "2" }, { "value": "3" }, { "value": "4" }, { "value": "5" }]
          break;
      }
    }
    else {
      this.notificationValues['MDM Compliance'] = [{ "value": "All" }, { "value": "0" }, { "value": "1" }, { "value": "2" }, { "value": "3" }, { "value": "4" }, { "value": "5" }]
    }
  }
  SetDefaultDate() {
    this.currentDate = new Date();
    let year = this.currentDate.getFullYear();
    let month = this.currentDate.getMonth() + 1;
    let lastOneMonth;
    let day;
    let fmDate;
    let cdate;
    let beginday;
    if (month === 1) {
      lastOneMonth = 12;
      year = year - 1;
    } else {
      lastOneMonth = month - 1;
    }
    day = this.currentDate.getDate();
    beginday = 1;
    if (lastOneMonth < 10) {
      fmDate = "0" + lastOneMonth + "/" + "0" + beginday + "/" + year;
    } else {
      fmDate = lastOneMonth + "/" + beginday + "/" + year;
    }
    if (month < 10) {
      cdate = "0" + month + "/" + day + "/" + year;
    } else {
      cdate = month + "/" + day + "/" + year;
    }
    //this.fromplaceholder = fmDate;
    //this.toplaceholder = cdate;
    this.fdate = { formatted: fmDate };
    this.tdate = { formatted: cdate };
    this.fselectedDate = this.fdate;
    this.tselectedDate = this.tdate;
    this.registerForm.controls["datepickerfromval"].setValue(this.fdate);
    this.registerForm.controls["datepickertoval"].setValue(this.tdate);
    this.defaultFromDate = true;
    this.defaultToDate = true;
  }
  get f() { return this.registerForm.controls; }

  onReset() {
    // if(!($('#employeecheck').is(":checked"))) {
    // $('#employeecheck').prop("checked",true);
    // }
    if (this.addFiltersData) {
      setTimeout(function () {
        $('#txtAddFilterID').click();
        $('#btnCancelID').click();
        setTimeout(function () {
          $('#btnCancelID').click();
          $('#txtAddFilterID').click();
        }, 100);
      }, 100);
    }
    this.submitted = false;
    $('#audittype').val('');

    this.registerForm.patchValue({
      notificationsent: '',
      audittype: '',
      regionval: '0',
      datepickerfromval:'',
      employeefilterval: '',
      // regionValue:[''],
      employeestatusfilterval: '',
      mtnfilterval:'',
      selecteddevicetypefilterval: '',
      devicetypefilterval: '',
      officefilterval: '',
      selectedofficefilterval: '',
      mtnstatusfilterval: '',
      joblevelfilterval: '',
      assetstatusfilterval: '',
      violationagefilterval: '',
      loastatusfilterval: '',
      loadatefilterval: '',
      datepickertoval: '',
      selectedemployeefilterval: '',
      employeestatusdropdown: '',
      assetStatusdropdown:'',
      selectedmtnfilterval: '',
      mtntatusdropdown: '',
      joblevelgroupdropdown: '',
      violationagedropdown: '',
      regiondropdown: '',
      loastatusdropdown: '',
      selectedloadatepickerfromval: '',
      selectedloadatepickertoval:'',
      statesData: '',
      carrier: ''

    })
    this.carrierSelectedList = undefined;
    
    this.toDateSelected = false;
    this.fromDateSelected = false;
    this.toDateSelectedMust = false;
    this.fromToDateSelected = false;
    $('#applyToFilterCompliance').css('pointer-events', '');
    //this.registerForm.controls["datepickerfromval"].setValue(this.fdate);
    //this.registerForm.controls["datepickertoval"].setValue(this.tdate);
    this.registerForm.controls["datepickerfromval"].setValue('');
    this.registerForm.controls["datepickertoval"].setValue('');
  }
  getDeviceTypeData() {
    this.filtersMenuService.getDeviceType().subscribe(deviceTypeDetails => {
      this.deviceTypeDetails = deviceTypeDetails.filter(x => x.isActive === true);
      // console.log('deviceTypeDetails', this.deviceTypeDetails);
    })
  }
  getRegionData() {
    this.filtersMenuService.getAllRegion().subscribe((data) => {
      this.regionData = data;
      // console.log('region', data);
    });
  }
  regionValueData() {
    this.registerForm.patchValue({ "statesData": '' });
    if (this.selectedCountry !== "" && this.selectedCountry !== "0" && this.selectedCountry !== null && this.selectedCountry !== undefined) {
      this.filtersMenuService.getAllOffice(this.selectedCountry).subscribe((data) => {
        this.officeDataBasedRegion = data;

        // console.log('office', data);
      });
    } else {
      this.officeDataBasedRegion = null;
    }

  }
  getEmpStatusDetails() {
    this.filtersMenuService.getEmpStatus().subscribe(empstatusval => {
      this.empStatusData = empstatusval;
      this.empStatusModel = this.empStatusData;
      // console.log('emp status data', this.empStatusData);
    });
  }
  getMtnStatusDetails() {
    this.filtersMenuService.getMtnStatus().subscribe(mtnstatusval => {
      this.mtnStatusDetails = mtnstatusval;
      this.mtnStatusModel = this.mtnStatusDetails;
      // console.log('mtnStatusModelValues', this.mtnStatusModel);
    });
  }
  addFilters() {
    this.showAddFiltersDiv = true;
    this.addFiltersData = true;
  }
  closeNavFilter(event) {
    this.registerForm = this.registerForm;
    this.emitEventFilterNavForClose.emit(event);
    this.showFiltersPanel = true;

  }
  filtersForOrders(event, registerForm) {
    // console.log('filters for orders employee div', JSON.stringify(this.registerForm.value));
    this.emitEventFilterNavForOpen.emit(event);
    this.showFiltersPanel = false;
  }


  getFilterItems() {
    this.filtersMenuService.filterItems().subscribe(data => {
      this.data = data;
      //// console.log('total-data',data);
      data.forEach(ele => {
        // console.log('dropdown', ele);
        this.dropdowndata = ele.complianceIssue;
        this.locationData = ele.compFacility;
      })
      this.complianceModel.complianceIssue = this.dropdowndata;
      this.registerForm.patchValue({
        audittype: ''
      })
      // // console.log('dropdown-data', this.complianceModel.complianceIssue);
      this.complianceModel.compFacility = this.locationData;
      //// console.log('this.complianceModel.compFacility',  this.complianceModel.compFacility);

    })
  }
  getRegionFilterItems() {
    this.filtersMenuService.regionFilterItems().subscribe(data => {
      this.regionItem = data;
      // console.log('total-data-region', data);
      data.forEach(ele => {
        // console.log('dropdown-region', ele);
        this.regionDropdowndata = ele;
      })
    })
  }

  onDateChangedFrom(event) {
    this.fromDate = event;
    this.fselectedDate = event.formatted;
    if (event != undefined) {
      this.fn_DateChangedEvent(event, 1);
    }
    this.syncDateFrom = event.formatted;
  }
  fn_DateChangedEvent(event, type) {
    let todateTxt: any;
    let fromdateTxt: any;
    this.toDateSelected = false;
    this.fromDateSelected = false;
    this.toDateSelectedMust = false;
    let toDateVal = $(".toDateClass")
      .find(".selection")
      .val();
    let fromDateVal = $(".fromDateClass")
      .find(".selection")
      .val();
    if (type == 1) {
      fromDateVal = this.fromDate.formatted;
    } else {
      toDateVal = this.toDate.formatted;
    }
    if (event.formatted !== null && event.formatted !== undefined) {
      if (
        toDateVal !== null &&
        toDateVal !== undefined &&
        toDateVal !== "" &&
        fromDateVal !== ""
      ) {
        if (this.fromDate != undefined) {
          fromdateTxt = new Date(
            this.fromDate.formatted.split("/")[2],
            this.fromDate.formatted.split("/")[0] - 1,
            this.fromDate.formatted.split("/")[1]
          );
          this.fselectedDate = this.fromDate.formatted;
        } else {
          fromdateTxt = this.fselectedDate.formatted;
        }
        if (this.toDate != undefined) {
          todateTxt = new Date(
            this.toDate.formatted.split("/")[2],
            this.toDate.formatted.split("/")[0] - 1,
            this.toDate.formatted.split("/")[1]
          );
          this.tselectedDate = this.toDate.formatted;
        } else {
          todateTxt = this.tselectedDate.formatted;
        }
        // console.log("Diff", fromdateTxt > todateTxt);
        if (fromdateTxt >= todateTxt) {
          $("#applyToFilterCompliance").css("pointer-events", "none");
          this.toDateSelected = true;
          this.fromDateSelected = false;
          this.toDateSelectedMust = false;
        } else {
          $("#applyToFilterCompliance").css("pointer-events", "");
          this.toDateSelected = false;
          this.fromDateSelected = false;
          this.toDateSelectedMust = false;
        }
      } else if (fromDateVal == "" && toDateVal == "") {
        this.toDateSelected = false;
        this.fromDateSelected = false;
        this.toDateSelectedMust = false;
        $("#applyToFilterCompliance").css("pointer-events", "");
      } else if (fromDateVal == "") {
        this.toDateSelected = false;
        this.fromDateSelected = true;
        this.toDateSelectedMust = false;
        $("#applyToFilterCompliance").css("pointer-events", "none");
      } else if (toDateVal == "") {
        $("#applyToFilterCompliance").css("pointer-events", "none");
        this.toDateSelected = false;
        this.fromDateSelected = false;
        if (this.defaultToDate == true) {
          this.toDateSelectedMust = false;
        } else {
          this.toDateSelectedMust = true;
        }
      } else {
        $("#applyToFilterCompliance").css("pointer-events", "");
        this.toDateSelected = false;
        this.fromDateSelected = false;
        this.toDateSelectedMust = false;
      }
    }
  }
  onDateChangedTo(event) {
    this.toDate = event;
    this.tselectedDate = event.formatted;
    if (event != undefined) {
      this.fn_DateChangedEvent(event, 2);
    }
    this.syncDateTo = event.formatted;
  }

  loaOnDateChangedFrom(event) {
    // console.log('loafromDate', event.formatted);
    this.loasyncDateFrom = event.formatted;

  }
  loaOnDateChangedTo(event) {
    // console.log('loatoDate', event.formatted);
    this.loasyncDateTo = event.formatted;

  }
  locationValue(event) {
    this.location = event;
  }
  regionValue(event) {
    this.region = event;
  }
  addDropdownData(event) {
    // console.log('adding', event);
    this.eventOfAddingData = event;
  }
  isAllSelected() {
    this.masterSelected = this.dataCollection.every((item: any) => {
      return item.isSelected == true;;
    })
    this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.dataCollection.length; i++) {
      if (this.dataCollection[i].isSelected)
        this.checkedList.push(this.dataCollection[i]);
    }
    // console.log('checked list', this.checkedList);
  }
  addData(event) {
    this.showAddFiltersDiv = false;
    this.filtersdata = true;
    this.addFiltersData = false;
    console.log('formValues of filter', JSON.stringify(this.registerForm.value, null, 4));

    if (this.registerForm.value.employeefilterval == true) {
      console.log('0----------------', this.showEmployeeFilter)
      if (!this.showEmployeeFilter) {
        this.showEmployeeFilter = true;
        setTimeout(function () {
          $('#employeefiltervalue').val('');
        }, 30);
      }
    } else {
      this.showEmployeeFilter = false;
    }


    if (this.registerForm.value.employeestatusfilterval == true) {
      this.showEmployeeStatusFilter = true;
    } else {
      this.showEmployeeStatusFilter = false;
    }

    if (this.registerForm.value.mtnfilterval == true) {
      if (!this.showMtnFilter) {
        this.showMtnFilter = true;
        setTimeout(function () {
          $('#mtnval').val('');
        }, 30);
      }
    } else {
      this.showMtnFilter = false;
    }

    if (this.registerForm.value.devicetypefilterval == true) {
      if (!this.showDeviceTypeFilter) {
        this.showDeviceTypeFilter = true;
        setTimeout(function () {
          $('#DDLDeviceTypeID').val("").change();
        }, 100);
      }
    } else {
      this.showDeviceTypeFilter = false;
    }

    if (this.registerForm.value.officefilterval == true) {
      if (!this.showOfficeFilter) {
        this.showOfficeFilter = true;
        setTimeout(function () {
          $('.DDLOfficeTypeClass').val("").change();
        }, 100);
      }
    } else {
      this.showOfficeFilter = false;
    }


    if (this.registerForm.value.mtnstatusfilterval == true) {
      this.showMtnStatusFilter = true;
    } else {
      this.showMtnStatusFilter = false;
    }

    if (this.registerForm.value.assetstatusfilterval == true) {
      this.registerForm.patchValue({'assetStatusdropdown': ''})      
      this.showAssetStatusFilter = true;
    } else {
      this.showAssetStatusFilter = false;
    }


    if (this.registerForm.value.joblevelfilterval == true) {
      if(!this.showJoblevelGroupFilter){
      this.showJoblevelGroupFilter = true;
      setTimeout(function () {
        $('#DDLJobLevelGroupID').val("").change();
      }, 100);
    }
    } else {
      this.showJoblevelGroupFilter = false;
    }

    if (this.registerForm.value.violationagefilterval == true) {
      if(!this.showViolationAgeFilter){
      this.showViolationAgeFilter = true;
      setTimeout(function () {
        $('#DDLViolationAgeID').val("").change();
      }, 100);
    }
    } else {
      this.showViolationAgeFilter = false;
    }

    // if(this.registerForm.value.loastatusfilterval == true) {
    //   this.showLoaStatusFilter = true;
    // }
    if (this.registerForm.value.loadatefilterval == true) {
      this.showLoaDateFilter = true;
    } else {
      this.showLoaDateFilter = false;
    }

  }
  auditValue(event) {
    $('.resetClass').click();
    if ((event == 'Pending Return') || (event == 'Pending Wipe')) {
      this.showLastHeartBeatDate = false;
    } else {
      this.showLastHeartBeatDate = true;
    }
    this.registerForm.patchValue({ "audittype": event });
    //// console.log('auditvalue',event);
    this.auditSelectedValue = event;
    this.rightSideCommentNavService.setAuditType(event);
    if (this.registerForm.value.audittype == 'MDM Compliance') {
      this.audittypeMdm = true;
    } else {
      this.audittypeMdm = false;
    }

    if (this.registerForm.value.audittype == 'Ineligible User' 
    || this.registerForm.value.audittype == 'Non-Corporate Device' 
    || this.registerForm.value.audittype == 'Personal Technology Audit'
    || this.registerForm.value.audittype == 'Multiple Enrollment') {
      this.showAssetStatusFilterLabel = false;
      this.closeAssetStatusDiv('');
    } else {
      this.showAssetStatusFilterLabel = true;
    }
    
    if (this.registerForm.value.audittype == 'Personal Technology Audit') {
      $('#compliance_disconnectUser').attr('disabled', 'disabled');
    }
  }
  empDropdownValue(empStatusDropdownVal) {
    this.registerForm.patchValue({ "loastatusdropdown": "" });
    if (empStatusDropdownVal == 'Active') {
      this.showLoaStatusFilter = true;
      setTimeout(function () {
        $('#DDLLoaStatusID').val("").change();
      }, 100);
    } else {
      this.showLoaStatusFilter = false;
    }
  }
  deviceTypeDropdownValue(deviceTypeDropdownVal) {
    // console.log('deviceTypeDropdownVal', deviceTypeDropdownVal);
  }
  officeDropdownValue(officeDropdownVal) {
    // console.log('officeDropdownVal', officeDropdownVal);
  }
  notificationsValue(event) {
    // console.log('notification', event);
    this.notificationSentValue = event;
  }
  // testingComplianceSearchResult() {
  //   this.gridDataObj.ComplianceIssue = 'Ineligible User';
  //   this.gridDataObj.NotificationSent = '2';
  //   this.gridDataObj.LastSyncStartDate = '2019-10-31T18:30:00.000Z';
  //   this.gridDataObj.LastSyncEndDate = '2019-11-16T18:30:00.000Z';
  //   this.gridDataObj.Location = '';
  //   this.gridDataObj.CurrentPage = 1;
  //   this.gridDataObj.ItemsPerPage = 50;
  //   this.gridDataObj.SortOrder = '';
  //   this.gridDataObj.TotalNoOfRecords = 100;
  //   this.gridDataObj.EmployeeInfo = 'jpidugu';
  //   this.gridDataObj.MTNStatus = '';
  //   this.gridDataObj.MobileTelephoneNbr = '1234567800';
  //   this.gridDataObj.EmpStatus = 'Active';
  //   this.gridDataObj.JobLevelGroup = 'Manager';
  //   this.gridDataObj.ViolationAge = '';
  //   this.gridDataObj.LOA_Flag = '';
  //   this.gridDataObj.LOA_DateBegin = '';
  //   this.gridDataObj.LOA_DateEnd = '';
  //   this.gridDataObj.DeviceType = 'Accessory';
  //   this.filtersMenuService.getGriddata(this.gridDataObj).subscribe((data)=>{
  //     // console.log('testing compliance result',data);
  //   })


  // }
  applyToFilterGridData(event) {
    this.fn_DatalayerOnClicks("Compliance Filter Apply Button");
    if (this.registerForm.value.audittype == 'Personal Technology Audit') {
      $('#compliance_disconnectUser').attr('disabled', 'disabled');
    }
    if (this.registerForm.value.audittype == 'MDM Compliance') {
      let notificationsentVal = false;
      let violationagedropdownVal = false;

      if (this.registerForm.value.notificationsent !== null && this.registerForm.value.notificationsent !== undefined && this.registerForm.value.notificationsent !== "") {
        notificationsentVal = true;
      } else if (this.registerForm.value.violationagedropdown !== null && this.registerForm.value.violationagedropdown != undefined && this.registerForm.value.violationagedropdown !== "") {
        violationagedropdownVal = true;
      }

      if (!notificationsentVal && !violationagedropdownVal) {
        // alert('Please select notification sent/violation age');
        this.modalService.notExist(
          'Please select Notifications Sent or Violation Age.'
        ).pipe(
          take(1) // take() manages unsubscription for us
        ).subscribe(result => {
          // console.log({ messageResult: result });
          this.messageResult = result;
        });
        return false;
        // this.submitted = false;
      }
    }
    this.applyFilterEvent.emit(event);
    this.submitted = true;

    // stop here if form is invalid
    // if(this.registerForm.value.selectedloadatepickerfromval !== '' && this.registerForm.value.selectedloadatepickertoval !== '') {
    if (this.registerForm.invalid) {
      return false;
    }

    this.router.navigate(['/compliance']);
    if (this.showEmployeeFilter == false) {
      this.registerForm.value.selectedemployeefilterval = '';
    }
    if (this.showEmployeeStatusFilter == false) {
      this.registerForm.value.employeestatusdropdown = '';
    }
    if(this.showAssetStatusFilter == false){
      this.registerForm.value.assetStatusdropdown = '';
    }
    if (this.showMtnFilter == false) {
      this.registerForm.value.selectedmtnfilterval = '';
    }
    if (this.showDeviceTypeFilter == false) {
      this.registerForm.value.selecteddevicetypefilterval = '';
    }
    if (this.showOfficeFilter == false) {
      this.registerForm.value.selectedState = '';
    }
    if (this.showJoblevelGroupFilter == false) {
      this.registerForm.value.joblevelgroupdropdown = '';
    }
    if (this.showViolationAgeFilter == false) {
      this.registerForm.value.violationagedropdown = '';
    }
    //this.spinnerService.showLoaderForGridData = true;
    //this.loaderService.show();


    this.gridDataObj.complianceIssue = this.registerForm.value.audittype;
    this.auditTypeEvent.emit(this.registerForm.value.audittype);

    this.gridDataObj.notificationSent = this.registerForm.value.notificationsent;
    if ((this.registerForm.value.notificationsent !== undefined) && (this.registerForm.value.notificationsent !== null)) {
      this.gridDataObj.notificationSent = this.registerForm.value.notificationsent;
    } else {
      this.gridDataObj.notificationSent = '';
    }
    if (this.registerForm.value.regionval == undefined) {
      this.registerForm.value.regionval = '';
    }
    if ($('#DDLRegionID :selected').val() == "0") {
      this.gridDataObj.location = "";
    } else {
      this.gridDataObj.location = $('#DDLRegionID :selected').text();
    }
    //this.registerForm.value.regionval;
    //// console.log('this.gridDataObj.LastSyncStartDate',this.registerForm.value.datepickerfromval);
    if (this.registerForm.value.datepickerfromval) {
      if ((this.registerForm.value.datepickerfromval.jsdate !== undefined) && (this.registerForm.value.datepickerfromval.jsdate !== this.fselectedDate.formatted)) {
        this.gridDataObj.lastSyncStartDate = this.registerForm.value.datepickerfromval.formatted;
        // console.log('date', this.registerForm.value.datepickerfromval.formatted);
        // console.log('date format', new Date(this.registerForm.value.datepickerfromval.formatted.split('/')[2], this.registerForm.value.datepickerfromval.formatted.split('/')[0] - 1, this.registerForm.value.datepickerfromval.formatted.split('/')[1]));
      }
    } else {
      this.gridDataObj.lastSyncStartDate = '';
    }
    if (this.registerForm.value.datepickertoval) {
      if ((this.registerForm.value.datepickertoval.jsdate !== undefined) && (this.registerForm.value.datepickertoval.jsdate !== this.tselectedDate.formatted)) {
        this.gridDataObj.lastSyncEndDate = this.registerForm.value.datepickertoval.formatted;
      }
    } else {
      this.gridDataObj.lastSyncEndDate = '';
    }
    // this.gridDataObj.LastSyncStartDate = this.registerForm.value.datepickerfromval.jsdate;
    // this.gridDataObj.LastSyncEndDate = this.registerForm.value.datepickertoval.jsdate;
    this.gridDataObj.currentPage = 1;
    this.gridDataObj.itemsPerPage = this.filtersMenuService.totalItemsForGrid;;
    this.gridDataObj.sortOrder = 'DateCreated DESC';
    this.gridDataObj.totalNoOfRecords = 50;
    if ((this.registerForm.value.selectedemployeefilterval !== null) && (this.registerForm.value.selectedemployeefilterval !== undefined)) {
      this.gridDataObj.employeeInfo = this.registerForm.value.selectedemployeefilterval;
    } else {
      this.gridDataObj.employeeInfo = '';
    }
    if ((this.registerForm.value.employeestatusdropdown !== null) && (this.registerForm.value.employeestatusdropdown !== undefined)) {
      this.gridDataObj.empStatus = this.registerForm.value.employeestatusdropdown;
    } else {
      this.gridDataObj.empStatus = '';
    }
    if ((this.registerForm.value.assetStatusdropdown !== null) && (this.registerForm.value.assetStatusdropdown !== undefined)) {
      this.gridDataObj.assetStatus = this.registerForm.value.assetStatusdropdown;
    } else {
      this.gridDataObj.assetStatus = '';
    }
    if ((this.registerForm.value.selectedmtnfilterval !== null) && (this.registerForm.value.selectedmtnfilterval !== undefined)) {

      this.gridDataObj.mobileTelephoneNbr = this.registerForm.value.selectedmtnfilterval;
    } else {
      this.gridDataObj.mobileTelephoneNbr = '';
    }
    // this.gridDataObj.mobileTelephoneNbr='';
    if ((this.registerForm.value.mtntatusdropdown !== null) && (this.registerForm.value.mtntatusdropdown !== undefined)) {

      this.gridDataObj.mtnStatus = this.registerForm.value.mtntatusdropdown;
    } else {
      this.gridDataObj.mtnStatus = '';
    }
    if ((this.registerForm.value.joblevelgroupdropdown !== null) && (this.registerForm.value.joblevelgroupdropdown !== undefined)) {

      this.gridDataObj.jobLevelGroup = this.registerForm.value.joblevelgroupdropdown;
    } else {
      this.gridDataObj.jobLevelGroup = '';
    }
    if ((this.registerForm.value.violationagedropdown !== null) && (this.registerForm.value.violationagedropdown !== undefined)) {

      this.gridDataObj.violationAge = this.registerForm.value.violationagedropdown;
    } else {
      this.gridDataObj.violationAge = '';
    }
    this.gridDataObj.loA_Flag = '';
    this.gridDataObj.loA_DateBegin = '';
    this.gridDataObj.loA_DateEnd = '';
    if ((this.registerForm.value.statesData !== undefined) && (this.registerForm.value.statesData !== null) && (this.registerForm.value.statesData !== 0)) {
      this.gridDataObj.FacilityCode = this.registerForm.value.statesData;
    } else {
      this.gridDataObj.FacilityCode = '';
    }
    if ((this.registerForm.value.loastatusdropdown !== null) && (this.registerForm.value.loastatusdropdown !== undefined)) {

      this.gridDataObj.loA_Flag = this.registerForm.value.loastatusdropdown;
    } else {
      this.gridDataObj.loA_Flag = '';
    }

    if ((this.registerForm.value.selecteddevicetypefilterval !== undefined) && (this.registerForm.value.selecteddevicetypefilterval !== null)) {
      this.gridDataObj.deviceType = this.registerForm.value.selecteddevicetypefilterval;
    } else {
      this.gridDataObj.deviceType = '';
    }
 // Add carrier value in grid api
 this.carrierSelectedList= this.registerForm.value.carrier;
 this.gridDataObj.Carrier = this.carrierSelectedList == undefined || this.carrierSelectedList == '' ? null : this.carrierSelectedList.map(function (val) {
   return val.carrierCode;
 }).join(',');
    //// console.log('gridDataObj',this.gridDataObj);
    if (this.gridDataObj.complianceIssue === 'Pending Return') {
      this.filtersMenuService.getPendingReturnData(this.gridDataObj).subscribe(data => {

        // console.log('filteredGridData', data);
        this.chartGridData = '';
        this.chartGridData = data;
        this.complianceViolationEx = this.chartGridData;
        this.router.navigate(['/compliance/search'], {state: {showAssetStatus: this.showAssetStatusFilterLabel}});
        // // console.log('ModelData',this.complianceViolationEx);
        // if(data.length === 0) {
        //   this.spinnerService.showLoaderForGridData = false;
        //   this.showspinner =  this.spinnerService.showLoaderForGridData;
        //   this.loaderService.hide();
        // }

      }),
        (error) => { this.errorMessage = error.message; }
    } else if (this.gridDataObj.complianceIssue === 'Pending Wipe') {
      this.filtersMenuService.getPendingReturnData(this.gridDataObj).subscribe(data => {

        // console.log('filteredGridData', data);
        this.chartGridData = '';
        this.chartGridData = data;
        this.complianceViolationEx = this.chartGridData;
        this.router.navigate(['/compliance/search'], {state: {showAssetStatus: this.showAssetStatusFilterLabel}});
        // // console.log('ModelData',this.complianceViolationEx);
        // if(data.length === 0) {
        //   this.spinnerService.showLoaderForGridData = false;
        //   this.loaderService.hide();
        // }

      }),
        (error) => { this.errorMessage = error.message; }
    }
    else {
      // console.log("this.gridDataObj", this.gridDataObj);
      this.filtersMenuService.getGriddata(this.gridDataObj).subscribe(data => {
        // console.log('filteredGridData', data);
        this.chartGridData = '';
        this.chartGridData = data;

        let mtnList = '';
        if (data)
        {
          data.forEach((x,index)=>{
            if(x.serverSync['mtn'].length > 0)
            {
              if (mtnList.length <= 0) mtnList = x.serverSync['mtn'];
              else  mtnList = mtnList + ',' + x.serverSync['mtn'];
            }
          })
        }        
        this._empService.GetMTNCancelledSuspendStatus(mtnList).subscribe(
          (data) => {            
            this.objMtnStatusDetails = data;
            this.chartGridData.forEach((x,index)=>{
                this.objMtnStatusDetails.forEach((y,index)=>{                  
                  if(x.serverSync['mtn']== y['mtn'])
                  {                    
                    x['isMarkedForCancellation'] = y['isMarkedForCancellation'];
                    x['isMarkedForSuspend'] = y['isMarkedForSuspend'];                    
                  }
                  else
                  {
                    x['isMarkedForCancellation'] = 0;
                    x['isMarkedForSuspend'] = 0; 
                  }                
                });            
            })
          });

        this.filtersMenuService.searchResultsList = this.chartGridData;

        this.complianceViolationEx = this.chartGridData;
        this.router.navigate(['/compliance/search'], {state: {showAssetStatus: this.showAssetStatusFilterLabel}});
        //  // console.log('ModelData',this.complianceViolationEx);
        //if(data.length === 0) {
        //  this.spinnerService.showLoaderForGridData = false;
        //  this.loaderService.hide();
        //}
        // if(this.complianceViolationEx == []) {
        //   this.spinnerService.showLoaderForGridData = false;
        // }
      }),
        (error) => { this.errorMessage = error.message; }
    }
    //// console.log('this.complianceViolationEx',this.complianceViolationEx);
    // this.dataService.postTableGridData(this.complianceViolationEx);
    //     setTimeout(()=> {
    //       this.router.navigated = false;

    //   this.router.navigate(['/compliance/search']);
    //   this.spinnerService.showLoaderForGridData = false;
    //   this.loaderService.hide();

    // },10000);
    return false;
  }
  cancelAddFilters() {
    this.showAddFiltersDiv = false;
    this.addFiltersData = false;
    // console.log('canceladdfilters', this.registerForm.controls.employeefilterval);
    if (this.showEmployeeFilter == true) {
      this.registerForm.controls['employeefilterval'].patchValue(true);
      this.registerForm.controls['employeefilterval'].setValue(true);
    } else {
      this.registerForm.controls['employeefilterval'].patchValue(false);
      this.registerForm.controls['employeefilterval'].setValue(false);
    }
    if (this.showEmployeeStatusFilter == true) {
      this.registerForm.controls['employeestatusfilterval'].patchValue(true);
      this.registerForm.controls['employeestatusfilterval'].setValue(true);
    } else {
      this.registerForm.controls['employeestatusfilterval'].patchValue(false);
      this.registerForm.controls['employeestatusfilterval'].setValue(false);
    }
    if (this.showAssetStatusFilter == true) {
      this.registerForm.controls['assetstatusfilterval'].patchValue(true);
      this.registerForm.controls['assetstatusfilterval'].setValue(true);
    } else {
      this.registerForm.controls['assetstatusfilterval'].patchValue(false);
      this.registerForm.controls['assetstatusfilterval'].setValue(false);
    }
    if (this.showMtnFilter == true) {
      this.registerForm.controls['mtnfilterval'].patchValue(true);
      this.registerForm.controls['mtnfilterval'].setValue(true);
    } else {
      this.registerForm.controls['mtnfilterval'].patchValue(false);
      this.registerForm.controls['mtnfilterval'].setValue(false);
    }
    if (this.showDeviceTypeFilter == true) {
      this.registerForm.controls['devicetypefilterval'].patchValue(true);
      this.registerForm.controls['devicetypefilterval'].setValue(true);
    } else {
      this.registerForm.controls['devicetypefilterval'].patchValue(false);
      this.registerForm.controls['devicetypefilterval'].setValue(false);
    }
    if (this.showOfficeFilter == true) {
      this.registerForm.controls['officefilterval'].patchValue(true);
      this.registerForm.controls['officefilterval'].setValue(true);
    } else {
      this.registerForm.controls['officefilterval'].patchValue(false);
      this.registerForm.controls['officefilterval'].setValue(false);
    }
    if (this.showMtnStatusFilter == true) {
      this.registerForm.controls['mtnstatusfilterval'].patchValue(true);
      this.registerForm.controls['mtnstatusfilterval'].setValue(true);
    } else {
      this.registerForm.controls['mtnstatusfilterval'].patchValue(false);
      this.registerForm.controls['mtnstatusfilterval'].setValue(false);
    }
    if (this.showJoblevelGroupFilter == true) {
      this.registerForm.controls['joblevelfilterval'].patchValue(true);
      this.registerForm.controls['joblevelfilterval'].setValue(true);
    } else {
      this.registerForm.controls['joblevelfilterval'].patchValue(false);
      this.registerForm.controls['joblevelfilterval'].setValue(false);
    }
    if (this.showViolationAgeFilter == true) {
      this.registerForm.controls['violationagefilterval'].patchValue(true);
      this.registerForm.controls['violationagefilterval'].setValue(true);
    } else {
      this.registerForm.controls['violationagefilterval'].patchValue(false);
      this.registerForm.controls['violationagefilterval'].setValue(false);
    }
    // if(this.showLoaStatusFilter == true) {
    //   this.registerForm.controls['loastatusfilterval'].patchValue(true);
    // this.registerForm.controls['loastatusfilterval'].setValue(true);
    // } else {
    //   this.registerForm.controls['loastatusfilterval'].patchValue(false);
    // this.registerForm.controls['loastatusfilterval'].setValue(false);
    // }
    if (this.showLoaDateFilter == true) {
      this.registerForm.controls['loadatefilterval'].patchValue(true);
      this.registerForm.controls['loadatefilterval'].setValue(true);
    } else {
      this.registerForm.controls['loadatefilterval'].patchValue(false);
      this.registerForm.controls['loadatefilterval'].setValue(false);
    }
  }
  closeDiv(event) {
    // console.log('event of closed div', event);
    var target = event.target || event.srcElement || event.currentTarget;
    // console.log('target', target);
    var idAttr = target.attributes.id;
    // console.log('idAttr', idAttr);
    var value = idAttr.nodeValue;
    // console.log('value', value);
  }
  closeEmployeeDiv(event) {
    this.registerForm.patchValue({ "selectedemployeefilterval": '' });
    // console.log('close employee div', JSON.stringify(this.registerForm.value, null, 4));
    this.registerForm.value.employeefilterval = false;
    this.registerForm.controls['selectedemployeefilterval'].reset();

    // console.log('close employee div', JSON.stringify(this.registerForm.value, null, 4));
    // this.registerForm.controls.employeefilterval.value = false;
    this.registerForm.controls['employeefilterval'].patchValue(false);
    this.registerForm.controls['employeefilterval'].setValue(false);
    this.showEmployeeFilter = false;

  }
  closeDeviceTypeDiv(event) {
    this.registerForm.patchValue({ "selecteddevicetypefilterval": '' });
    this.showDeviceTypeFilter = false;
    this.registerForm.value.selecteddevicetypefilterval = '';
    this.registerForm.controls['selecteddevicetypefilterval'].reset();
    this.registerForm.value.devicetypefilterval = false;
    this.registerForm.controls['devicetypefilterval'].patchValue(false);
    this.registerForm.controls['devicetypefilterval'].setValue(false);
  }
  closeOfficeDiv(event) {
    this.registerForm.patchValue({ "statesData": '' });
    this.showOfficeFilter = false;
    this.registerForm.value.selectedofficefilterval = '';
    this.registerForm.controls['statesData'].reset();
    this.registerForm.value.officefilterval = false;
    this.registerForm.controls['officefilterval'].patchValue(false);
    this.registerForm.controls['officefilterval'].setValue(false);
  }
  closeEmployeeStatusDiv(event) {
    this.registerForm.patchValue({ "employeestatusdropdown": '' });
    this.registerForm.patchValue({ "loastatusdropdown": '' });
    this.showEmployeeStatusFilter = false;
    this.showLoaStatusFilter = false;
    this.registerForm.value.employeestatusdropdown = '';
    this.registerForm.value.employeestatusfilterval = false;
    this.registerForm.controls['employeestatusdropdown'].reset();
    this.registerForm.controls['employeestatusfilterval'].patchValue(false);
    this.registerForm.controls['employeestatusfilterval'].setValue(false);
  }
  closeMtnDiv(event) {
    this.registerForm.patchValue({ "selectedmtnfilterval": '' });
    this.showMtnFilter = false;
    this.registerForm.value.mtnfilterval = false;
    this.registerForm.value.selectedmtnfilterval = '';
    this.registerForm.controls['selectedmtnfilterval'].reset();
    this.registerForm.controls['mtnfilterval'].patchValue(false);
    this.registerForm.controls['mtnfilterval'].setValue(false);
  }
  closeMtnStatusDiv(event) {
    this.registerForm.patchValue({ "mtntatusdropdown": '' });
    this.showMtnStatusFilter = false;
    this.registerForm.value.mtnstatusfilterval = false;
    this.registerForm.value.mtntatusdropdown = '';
    this.registerForm.controls['mtntatusdropdown'].reset();
    this.registerForm.controls['mtnstatusfilterval'].patchValue(false);
    this.registerForm.controls['mtnstatusfilterval'].setValue(false);
  }
  closeJoblevelDiv(event) {
    this.registerForm.patchValue({ "joblevelgroupdropdown": '' });
    this.showJoblevelGroupFilter = false;
    this.registerForm.value.joblevelfilterval = false;
    this.registerForm.value.joblevelgroupdropdown = '';
    this.registerForm.controls['joblevelgroupdropdown'].reset();
    this.registerForm.controls['joblevelfilterval'].patchValue(false);
    this.registerForm.controls['joblevelfilterval'].setValue(false);
  }
  closeViolationDiv(event) {
    this.registerForm.patchValue({ "violationagedropdown": '' });
    this.showViolationAgeFilter = false;
    this.registerForm.value.violationagefilterval = false;
    this.registerForm.value.violationagedropdown = '';
    this.registerForm.controls['violationagedropdown'].reset();
    this.registerForm.controls['violationagefilterval'].patchValue(false);
    this.registerForm.controls['violationagefilterval'].setValue(false);
  }
  closeLoaDiv(event) {
    this.registerForm.patchValue({ "loastatusdropdown": '' });
    this.showLoaStatusFilter = false;
    this.registerForm.value.loastatusfilterval = false;
    this.registerForm.value.loastatusdropdown = '';
    this.registerForm.controls['loastatusfilterval'].patchValue(false);
    this.registerForm.controls['loastatusfilterval'].setValue(false);
  }
  closeAssetStatusDiv(event) {
    this.registerForm.patchValue({ "assetStatusdropdown": '' });
    this.showAssetStatusFilter = false;
    this.registerForm.value.assetstatusfilterval = false;
    this.registerForm.value.assetStatusdropdown = '';
    this.registerForm.controls['assetstatusfilterval'].patchValue(false);
    this.registerForm.controls['assetstatusfilterval'].setValue(false);
  }
  closeLoaDateDiv(event) {
    this.registerForm.patchValue({ "employeestatusdropdown": '' });
    this.showLoaDateFilter = false;
    this.registerForm.value.loadatefilterval = false;
    this.registerForm.value.selectedloadatepickerfromval = '';
    this.registerForm.value.selectedloadatepickertoval = '';
    this.registerForm.controls['loadatefilterval'].patchValue(false);
    this.registerForm.controls['loadatefilterval'].setValue(false);
  }
  clearAll(form) {
    this.registerForm.reset();
  }
  // loaonDateChangedFrom(event) {
  //   // console.log(event);
  // }
  // loaonDateChangedTo(event) {
  //   // console.log(event);
  // }
  onSelectRegion(country_id: number, selectedCountry) {
    // console.log('selectedCountry', selectedCountry);
    this.countryName = selectedCountry;
    this.selectedCountry = country_id;
    // this.selectedState = 0;
    // this.cities = [];
    this.getOffices(country_id);
  }
  getOffices(country_id) {
    this.filtersMenuService.getFacilityCodes(country_id).subscribe(offices => {
      this.states = offices;
    });
  }

  fn_DatalayerOnClicks(Onclicktext) {    
    window.analyticsDataLayer =
    {
      'pageInfo': {
        'pageName': "Compliance Filter Page",
        'userID': this.loggedInUserName,
        'onclick': Onclicktext
      }
    };
    _satellite.track('onclicks');
  }

  onSelectAll(event) {

  }

}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PeoplePickerService } from 'src/app/_services/people-picker.service';

@Component({
  selector: 'app-people-picker',
  templateUrl: './people-picker.component.html',
  styleUrls: ['./people-picker.component.scss']
})
export class PeoplePickerComponent implements OnInit {

  @Input() list: string[];
  @Input() hideSuggestion?: boolean = false;
  @Input() public hasNoResult: boolean = true;
  @Output() selectedAlias = new EventEmitter();
  @Output() selectedData = new EventEmitter();
  @Output() personnelNumberEmitEvent = new EventEmitter();
  @Output() inputItemEventEmit = new EventEmitter();
  @Output() empDetails = new EventEmitter();
  @Output() emitEnteredInputText = new EventEmitter();
  @ViewChild('peoplePickerTemp', { static: true }) peoplePicker: ElementRef;
  // two way binding for input text
  inputItem = '';
  // enable or disable visiblility of dropdown
  listHidden = true;
  showError = false;
  selectedIndex = -1;
  selectedItem: any;

  // the list to be shown after filtering
  filteredList: string[] = [];
  showSearch: boolean;
  resultData: any[];
  personnelNumber: any;
  inputItemForFilteredList: any;
  selectedEmpName: any;
  searchedText: string = '';
  noResultFound: boolean = false;

  constructor(private peoplePickerService: PeoplePickerService) { }

  ngOnInit() {
    document.createElement('list-item');
    this.filteredList = this.list;
    fromEvent(this.peoplePicker.nativeElement, 'input').pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe((value: any) => {
        this.searchedText = value.target.value;
        this.getFilteredList(value.target.value);
      })
  }
  clearSearch() {
    this.showSearch = false;
    this.inputItem = '';
    this.searchedText = '';
  }
  keyUpEvent() {
    this.showSearch = true;
    if (this.inputItem == '') {
      this.showSearch = false;
      this.searchedText = '';
    }
  }

  // modifies the filtered list as per input
  getFilteredList(inputItem) {
    this.emitEnteredInputText.emit(inputItem);
    if ((inputItem == '') || (this.selectedEmpName !== inputItem)) {
      //$('#laning_page_submit').prop("disabled", "disabled");
    }
    if (inputItem == '') {
      this.resultData = [];
      this.listHidden = true;
      return;
    }
    this.inputItemForFilteredList = inputItem;
    if (this.inputItemForFilteredList !== '' && this.inputItemForFilteredList.length > 3) {
      this.peoplePickerService.getPeoplePickerData(inputItem).subscribe((resultData) => {
        this.empDetails.emit(resultData);
        this.resultData = resultData;
        if (this.resultData && this.resultData.length == 0) {
          this.listHidden = true;
          this.noResultFound = true;
        } else {
          this.listHidden = false;
          this.noResultFound = false;
        }
        // this.resultData.forEach((ele) => {
        //   this.personnelNumber = ele.personnelNumber;
        // });
        // this.personnelNumberEmitEvent.emit(this.personnelNumber);

        // this.selectedAlias.emit(this.resultData);
        // console.log('people-picker-comp', this.resultData);

      });
    }
    // this.listHidden = false;
    // this.selectedIndex = 0;
    // if (!this.listHidden && this.inputItem !== undefined) {
    //   this.filteredList = this.list.filter((item) => item.toLowerCase().startsWith(this.inputItem.toLowerCase()));
    // }
  }

  // select highlighted item when enter is pressed or any item that is clicked
  selectItem(empName, perNum, aliasName) {
    // this.personnelNumber = this.resultData.find(x => x.name == empName).personnelNumber;
    if (empName !== '') {
      //$('#laning_page_submit').prop("disabled", "");
    }
    this.selectedEmpName = empName;
    //else {
    //   $('#laning_page_submit').prop("disabled","");   
    // }
    // $('#laning_page_submit').prop("disabled","");
    // console.log('empName from people-picker', empName);
    this.selectedItem = this.resultData.filter(function (a) { return a.personnelNumber == perNum; });
    if (this.selectedItem && this.selectedItem.length > 0) {
      this.personnelNumber = this.selectedItem[0].personnelNumber;
    }
    this.inputItemEventEmit.emit(this.selectedItem);
    //$('.combobox-options').hide();
    this.listHidden = true;
    // this.inputItem = this.filteredList[ind];
    this.selectedAlias.emit(aliasName);
    // this.listHidden = true;
    // this.selectedIndex = ind;
    // // console.log('selectItem',ind);
    // alert(empName);
    if (empName != null && empName != "" && empName != undefined) {
      this.inputItem = empName;
    }
    this.personnelNumberEmitEvent.emit(this.personnelNumber);

  }

  // navigate through the list of items
  onKeyPress(event) {
    //$('.combobox-options').show();
    this.showSearch = true;
    // if( $('#peoplePickerInput').val() == ''){
    //   this.listHidden=true;
    //   this.inputItem='';
    //   this.resultData = [];
    //   this.showSearch=false;
    //   return;
    // }

    if (this.inputItem == '') {
      this.showSearch = false;
      this.inputItem = '';
      this.listHidden = true;
    } else {
      this.listHidden = false;
    }
    if (!this.listHidden) {
      if (event.key === 'Escape') {
        this.selectedIndex = -1;
        this.toggleListDisplay(0);
      }

      if (event.key === 'Enter') {

        this.toggleListDisplay(0);
      }
      if (event.key === 'ArrowDown') {

        this.listHidden = false;
        this.selectedIndex = (this.selectedIndex + 1) % this.filteredList.length;
        if (this.filteredList.length > 0 && !this.listHidden) {
          document.getElementsByTagName('list-item')[this.selectedIndex].scrollIntoView();
        }
      } else if (event.key === 'ArrowUp') {

        this.listHidden = false;
        if (this.selectedIndex <= 0) {
          this.selectedIndex = this.filteredList.length;
        }
        this.selectedIndex = (this.selectedIndex - 1) % this.filteredList.length;

        if (this.filteredList.length > 0 && !this.listHidden) {

          document.getElementsByTagName('list-item')[this.selectedIndex].scrollIntoView();
        }
      }
    }
  }

  // show or hide the dropdown list when input is focused or moves out of focus
  toggleListDisplay(sender: number) {
    // console.log('toggleListDisplay', this.inputItem);
    if (sender === 1) {
      // this.selectedIndex = -1;
      this.listHidden = false;
      this.getFilteredList(this.inputItem);
    } else {
      // helps to select item by clicking
      setTimeout(() => {
        this.selectItem(this.selectedItem.name, this.selectedItem.personnelNumber, this.selectedItem.value);
        this.listHidden = true;
        if (!this.list.includes(this.inputItem)) {
          this.showError = true;
          this.filteredList = this.list;
        } else {
          this.showError = false;
        }
      }, 500);
    }
  }
  isVipException() {
    return window.location.pathname.includes('vipException')
  }

}
